/* eslint-disable redux-saga/no-unhandled-errors */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeEvery, put, select, call } from 'redux-saga/effects'
import { success, error } from '@redux-requests/core'
import { push } from 'react-router-redux'
import {
  AuthService,
  WsLkService,
  WsStuNsiService,
  WsStuOrgEntities,
  WsStuPhysicalPersons,
} from '@lk-stu3/services'

import { putTokens, checkToken, removeTokens } from '@lk-stu3/utils'

import * as types from './types'
import * as actions from './actions'
import {
  routerStateSelector,
  getAuthUserInfo,
  getClientUser,
} from './selectors'

function* getSettings() {
  const settings = ['idRegistrator', 'idFolder']

  for (let i = 0; i < settings.length; i += 1) {
    yield put(
      WsLkService.actions.settingsSearchPOST({
        // eslint-disable-next-line security/detect-object-injection
        settingName: settings[i],
        userLogin: null,
      })
    )
  }
}

function* setSettingsSaga({ payload }) {
  const { settingValue, settingName } = (payload.data || [])[0] || {}
  if (settingValue && settingName)
    yield put(actions.setUserSetting({ [settingName]: settingValue }))
}

function* authSaga({ payload }) {
  yield put(AuthService.actions.login(payload))
}
function* loginSaga({ payload }) {
  const { token, refreshToken } = yield payload?.data || {}
  if (token && refreshToken) {
    yield putTokens({ token, refreshToken })
    yield put(actions.loginSuccess())
    yield put(AuthService.actions.getUser())
    yield put(WsLkService.actions.clientUserGET())
    const { path } = yield select(routerStateSelector)
    yield put(push(path || '/dashboard'))
  }
}
function* logoutSaga() {
  yield removeTokens()
}

function* loginOnLoad() {
  if (checkToken()) {
    yield put(actions.login())
    yield put(AuthService.actions.getUser())
    yield put(WsLkService.actions.clientUserGET())
  }
}

function* loadLoggedUserSaga({ payload }) {
  const { login, id } = payload.data || {}
  if (login && id) {
    yield put(actions.setAuthUserInfo({ ...payload.data }))
    const clientUser = yield select(getClientUser)
    if (clientUser !== null) yield put(actions.AuthSuccess())
  }
}

function* loginFailSaga({ payload }) {
  yield put(actions.loginFail(payload?.data?.message || 'Ошибка авторизации'))
  yield window.localStorage.clear()
}

function* clientUserSaga({ payload }) {
  yield call(getSettings)
  yield put(actions.setClientUser(payload.data))
  const authUserInfo = yield select(getAuthUserInfo)
  if (authUserInfo !== null) yield put(actions.AuthSuccess())
}

function* registrationInProcessSaga() {
  yield put(actions.registrationLoading())
}

function* registrationSuccess({ payload }) {
  const { message } = payload.data

  if (message) {
    yield put(actions.registrationEnd(message))
  } else {
    yield put(
      actions.registrationEnd(
        'Заявка на регистрацию принята. После проверки введенных данных на адрес электронной почты, указанный при регистрации, будет отправлено письмо с инструкциями для завершения регистрации.'
      )
    )
  }
}

function* registrationFail({ payload }) {
  const { message } = payload.data

  if (message) {
    yield put(actions.registrationEnd(message))
  } else {
    yield put(
      actions.registrationEnd('Что-то пошло не так, обратитесь в техподдержку.')
    )
  }
}

function* updateClientUser() {
  yield put(WsLkService.actions.clientUserGET())
}

export function* sagas() {
  yield loginOnLoad()
  yield all([
    takeEvery(types.LOGIN, authSaga),
    takeEvery(types.LOGOUT, logoutSaga),
    takeEvery(success(AuthService.actions.login.toString()), loginSaga),
    takeEvery(
      success(AuthService.actions.getUser.toString()),
      loadLoggedUserSaga
    ),
    takeEvery(
      success(WsLkService.actions.clientUserGET.toString()),
      clientUserSaga
    ),
    takeEvery(
      [
        error(AuthService.actions.login.toString()),
        error(AuthService.actions.getUser.toString()),
        error(WsLkService.actions.clientUserGET.toString()),
      ],
      loginFailSaga
    ),
    takeEvery(
      [
        WsStuNsiService.actions.createOrg.toString(),
        WsStuNsiService.actions.createPhys.toString(),
      ],
      registrationInProcessSaga
    ),
    takeEvery(
      [
        success(WsStuNsiService.actions.createOrg.toString()),
        success(WsStuNsiService.actions.createPhys.toString()),
      ],
      registrationSuccess
    ),
    takeEvery(
      [
        error(WsStuNsiService.actions.createOrg.toString()),
        error(WsStuNsiService.actions.createPhys.toString()),
      ],
      registrationFail
    ),
    takeEvery(
      [success(WsLkService.actions.settingsSearchPOST.toString())],
      setSettingsSaga
    ),
    takeEvery(
      [
        success(WsStuOrgEntities.actions.orgEntitiesPUT.toString()),
        success(WsStuPhysicalPersons.actions.physicalPersonsPUT.toString()),
        success(WsLkService.actions.clientUserPUT.toString()),
      ],
      updateClientUser
    ),
  ])
}
