import React, { ReactNode, useMemo, useEffect } from 'react'
import { Flex, Box } from 'theme-ui'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Spinner } from '@lk-stu3/components'
import { WsLkService, helpDeskService } from '@lk-stu3/services'
import { ColumnsType } from 'rc-table/lib/interface'
import { SettingsT } from '@lk-stu3/services/src/ws-stu/nsi-dicts'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { formatDate } from '@lk-stu3/utils'
import 'react-resizable/css/styles.css'

import { TechSupportTableI } from './module/interfaces'
import {
  requestsSelector,
  flagsSelector,
  searchParamsSelector,
} from './module/selectors'

import { setSearchParamsAC, setFlagsAC } from './module/actions'
import { AutoSizeTableWrap } from './table-wrap'

const { settingsSelector } = WsLkService

type RecordType = TechSupportTableI

const compareByColumnIndex = (a: SettingsT, b: SettingsT): number => {
  if (a.ColumnIndex > b.ColumnIndex) {
    return 1
  }
  if (a.ColumnIndex < b.ColumnIndex) {
    return -1
  }
  return 0
}

const compareByFixed = (a: SettingsT, b: SettingsT): number => {
  if (!a.IsFixed && b.IsFixed) {
    return 1
  }
  if (a.IsFixed && !b.IsFixed) {
    return -1
  }
  return 0
}

const toColumns = (settings: SettingsT[]): ColumnsType<RecordType> => {
  return settings
    .filter((el) => el.IsVisible)
    .sort(compareByColumnIndex)
    .sort(compareByFixed)
    .map((el) => {
      return {
        title: el.ShowName,
        width: el.ColumnWidth,
        dataIndex: el.FieldName,
        key: el.SortField,
        fixed: el.IsFixed,
        render: (value, row): ReactNode => {
          const isRed = value === row.status && row.expired

          return <Box style={{ color: isRed ? 'red' : 'black' }}>{value}</Box>
        },
      }
    })
}

export const TechSupportTable = (): React.ReactElement => {
  const dispatch = useDispatch()
  const settings = useSelector(settingsSelector)
  const columns = toColumns(settings?.settingsValue || [])
  const { allIds, byId } = useSelector(requestsSelector)
  const searchParams = useSelector(searchParamsSelector)
  const { sortedField, orderBy } = useSelector(searchParamsSelector)
  const { isLoading } = useSelector(flagsSelector)
  const isEmpty = allIds.length === 0
  useEffect(() => {
    dispatch(setFlagsAC({ isLoading: true }))

    Promise.all([
      dispatch(helpDeskService.actions.getHelpdeskTasks(searchParams)),
    ]).finally(() => dispatch(setFlagsAC({ isLoading: false })))
  }, [dispatch, searchParams])

  const hasParams = Object.keys(searchParams || {}).length > 0

  const sortChange = (
    sortedField: string | null,
    orderBy: 'ASC' | 'DESC' | null
  ): void => {
    dispatch(setSearchParamsAC({ sortedField, orderBy }))
  }

  const { login } = useSelector(getAuthUserInfo) || {}

  const onChangeColumnWidth = (dataIndex: string, width: number): void => {
    if (settings) {
      const currentSetting = settings.settingsValue.find(
        (el) => el.FieldName === dataIndex
      )
      const settingWithNewWidth = { ...currentSetting, ColumnWidth: width }
      const filteredSettings = settings.settingsValue.filter(
        (el) => el.FieldName !== dataIndex
      )
      dispatch(
        WsLkService.actions.saveSettings({
          settingName: 'helpdeskTableSettings',
          settingValue: JSON.stringify({
            helpdeskTableSettings: [...filteredSettings, settingWithNewWidth],
          }),
          userLogin: login,
        })
      )
    }
  }

  const data = useMemo(() => {
    const result = allIds.map((requestId) => {
      const {
        id,
        helpdeskNumber,
        typeTask,
        description,
        response,
        status,
        dateCreate,
        dateEnd,
        dateAssignmentExecutor,
        hdSource,
      } = byId[requestId]

      return {
        id,
        helpdeskNumber,
        idTypeTask: typeTask?.name,
        description,
        response,
        idStatus: status?.name,
        dateCreate: formatDate(dateCreate),
        dateEnd: formatDate(dateEnd),
        dateAssignmentExecutor: formatDate(dateAssignmentExecutor),
        hdSource: hdSource === 'STU' ? 'СТУ' : 'ЛК',
      }
    })

    return result
  }, [allIds, byId])

  return (
    <Flex sx={{ zIndex: 1, height: '100%' }}>
      <AutoSizeTableWrap>
        {(height, width): React.ReactElement => {
          const EmptyText = (): React.ReactElement => {
            return (
              <Flex
                sx={{
                  height: `${height}px`,
                  width: `${width}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isLoading && <Spinner title="Загрузка запросов" />}
                {!isLoading && isEmpty && !hasParams && 'Нет запросов'}
                {!isLoading &&
                  // !error &&
                  isEmpty &&
                  hasParams &&
                  'По данным параметрам запросы не найдены'}
                {/* {!isLoading && */}
                {/*   // error && */}
                {/*   'Произошла ошибка попробуйте перезагрузить страницу или обратитесь в службу поддержки'} */}
              </Flex>
            )
          }

          return (
            <Table<RecordType>
              scroll={{ x: width, y: height }}
              initialSort={{
                sortField: sortedField,
                sortMethod: orderBy,
              }}
              sortOnChange={sortChange}
              onChangeColumnWidth={onChangeColumnWidth}
              style={{
                width: '100%',
                height: '100%',
              }}
              rowKey="id"
              rowLinkCreator={(id: string | number): string =>
                `/dashboard/task/${id}/info`
              }
              data={data}
              columns={columns}
              emptyText={EmptyText}
            />
          )
        }}
      </AutoSizeTableWrap>
    </Flex>
  )
}
