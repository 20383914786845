import { TreeDataNode } from '@lk-stu3/utils'
import { InferValueTypes } from '@lk-stu3/utils/typescript'
import * as types from './types'

interface TreeUpdateI {
  type: typeof types.FLATTEN_TREE_UPDATE
  payload?: TreeDataNode[]
}

interface DeleteNodeI {
  type: typeof types.FLATTEN_TREE_DELETE_NODE
  payload?: string
}

export const actions = {
  deleteNode: (id: string): DeleteNodeI => {
    return {
      type: types.FLATTEN_TREE_DELETE_NODE,
      payload: id,
    } as const
  },
  treeUpdate: (nodes: TreeDataNode[]): TreeUpdateI => {
    return {
      type: types.FLATTEN_TREE_UPDATE,
      payload: nodes,
    } as const
  },
}

export type ActionTypes = ReturnType<InferValueTypes<typeof actions>>
