import moment from 'moment'

const FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'

type WithId = {
  id: string
}

export interface TaskI {
  id: string
  helpdeskNumber: string
  typeTask: {
    id: string
    name: string
  }
  description: string
  status: {
    id: string
    name: string
  }
  creator: {
    id: number
    nameLast: string
    nameFirst: string
    nameMiddle: string
  }
  hdSource: string
  dateCreate: string
  dateAssignmentExecutor: string
  dateEnd: string
  deleted: string
  response: string
  comment: string
}

export const normalizeData = <T extends WithId>(data: T[]) => {
  const allIds = data.map((el) => el.id)

  const byId = data.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: curr,
    }
  }, {})

  return {
    allIds,
    byId,
  }
}

export const formatDate = (date = null, format = 'DD.MM.YYYY') => {
  if (date !== null) {
    const momentDate = moment(date)
    if (momentDate.isValid()) return momentDate.format(format)
    return ''
  }
  return ''
}

export const getStringFromMomentDate = (momentDate: Date): string | null => {
  if (momentDate !== null && moment.isMoment(momentDate)) {
    return momentDate.isValid()
      ? momentDate.startOf('day').format(FORMAT)
      : null
  }
  if (momentDate !== null && moment(momentDate).isValid()) {
    return moment(momentDate).startOf('day').format(FORMAT)
  }
  return null
}

export const getStringFromMomentDateEndOfDay = (
  momentDate: Date
): string | null => {
  if (momentDate !== null && moment.isMoment(momentDate)) {
    return momentDate.isValid() ? momentDate.endOf('day').format(FORMAT) : null
  }
  if (momentDate !== null && moment(momentDate).isValid()) {
    return moment(momentDate).endOf('day').format(FORMAT)
  }
  return null
}

export const downloadMultiple = async (urlList, intervalMs = 200, target) => {
  const temporaryDownloadLink = document.createElement('a')
  temporaryDownloadLink.style.display = 'none'

  document.body.appendChild(temporaryDownloadLink)
  for (const url of urlList) {
    temporaryDownloadLink.setAttribute('href', url)
    if (target) {
      temporaryDownloadLink.setAttribute('target', target)
    }
    temporaryDownloadLink.click()

    await new Promise((res) => setTimeout(res, intervalMs))
  }

  document.body.removeChild(temporaryDownloadLink)
}
