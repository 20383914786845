import * as types from './types'

export const setPaginationAC = (payload) => ({
  type: types.SET_PAGINATION,
  payload,
})
export const setInboxPaginationAC = (payload) => ({
  type: types.SET_INBOX_PAGINATION,
  payload,
})
export const setOutboxPaginationAC = (payload) => ({
  type: types.SET_OUTBOX_PAGINATION,
  payload,
})
export const setInternalPaginationAC = (payload) => ({
  type: types.SET_INTERNAL_PAGINATION,
  payload,
})

export const setLoadingAC = (payload) => ({
  type: types.SET_LOADING,
  payload,
})

export const setInboxAC = (payload) => ({
  type: types.SET_INBOX,
  payload,
})
export const setOutboxAC = (payload) => ({
  type: types.SET_OUTBOX,
  payload,
})
export const setInternalAC = (payload) => ({
  type: types.SET_INTERNAL,
  payload,
})

export const toggleInboxElement = (notificationId) => ({
  type: types.TOGGLE_INBOX_ELEMENT,
  payload: notificationId,
})

export const checkAllInboxAC = () => ({
  type: types.CHECK_INBOX_ELEMENT_BULK,
})
export const uncheckAllInboxAC = () => ({
  type: types.UNCHECK_INBOX_ELEMENT_BULK,
})

export const toggleOutboxElement = (notificationId) => ({
  type: types.TOGGLE_OUTBOX_ELEMENT,
  payload: notificationId,
})
export const checkAllOutboxAC = () => ({
  type: types.CHECK_OUTBOX_ELEMENT_BULK,
})
export const uncheckAllOutboxAC = () => ({
  type: types.UNCHECK_OUTBOX_ELEMENT_BULK,
})
