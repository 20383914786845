import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from './theme'

interface TableRowLinkI {
  children: React.ReactNode
  to: string
}

const Link = styled(RouterLink)`
  display: table-row;
  color: ${colors.text};
  background-color: ${colors.background};
  transition: background 0.1s;
  transition-timing-function: ease-in-out;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${colors.muted};
    text-decoration: none;
  }
`

export const TableRowLink = ({
  children,
  to,
}: TableRowLinkI): React.ReactElement => {
  return <Link to={to}>{children}</Link>
}
