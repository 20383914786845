import React from 'react'
import { Modal } from '@ursip/components'
import { Flex, Text, Box } from 'theme-ui'
import { CloseIcon } from '../svg-icons'

type WrapperT = {
  children: React.ReactChild
  onClose: () => void
  title?: string
  isOpen: boolean
}

export const ModalWrapper = ({
  children,
  onClose,
  title,
  isOpen,
}: WrapperT): React.ReactElement => {
  return (
    <Modal isOpen={isOpen}>
      <Flex
        sx={{
          overflow: 'hidden',
          flexDirection: 'column',
          backgroundColor: 'background',
          padding: '32px',

          position: 'relative',
        }}
      >
        {title && (
          <Text
            as="h3"
            sx={{ marginBottom: '24px', marginRight: '12px', color: 'text' }}
          >
            {title}
          </Text>
        )}
        {children}
        <Box
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px',
            cursor: 'pointer',
          }}
        >
          <CloseIcon />
        </Box>
      </Flex>
    </Modal>
  )
}
