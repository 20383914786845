import React from 'react'
import { ThemeProvider } from 'theme-ui'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import { ConnectedRouter } from 'connected-react-router'
import { customTheme } from '@lk-stu3/components'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { WsStuNsiService, WsLkService } from '@lk-stu3/services'
import { moduleAuth } from '@lk-stu3/auth'
import { ToastProvider } from 'react-toast-notifications'
import { history, store } from './store'

import { Routes } from './routes'

const { dictsModule } = WsStuNsiService
const { wsLkModule } = WsLkService

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={customTheme}>
        <ConnectedRouter history={history}>
          <ToastProvider>
            <Helmet>
              <title>НИАЦ Личный кабинет</title>
            </Helmet>
            <DynamicModuleLoader
              modules={[moduleAuth(), dictsModule(), wsLkModule()]}
            >
              <Routes />
            </DynamicModuleLoader>
          </ToastProvider>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  )
}
