export const LOGIN = `@AUTH/LOGIN` as const
export const LOGIN_SUCCESS = `@AUTH/LOGIN_SUCCESS` as const
export const LOGIN_FAIL = `@AUTH/LOGIN_FAIL` as const
export const LOGOUT = `@AUTH/LOGOUT` as const

export const SET_AUTH_USER_INFO = `@AUTH/SET_AUTH_USER_INFO` as const
export const SET_CLIENT_USER = `@AUTH/SET_CLIENT_USER` as const

export const REGISTRATION_START_LOADING = `@AUTH/REGISTRATION_START_LOADING` as const
export const REGISTRATION_END_LOADING = `@AUTH/REGISTRATION_END_LOADING` as const
export const REGISTRATION_END_RESET = `@AUTH/REGISTRATION_END_RESET` as const
export const REGISTRATION_END = `@AUTH/REGISTRATION_END` as const
export const AUTH_SUCCESS = `@AUTH/AUTH_SUCCESS` as const

export const SET_USER_SETTING = `@AUTH/SET_USER_SETTING` as const
