/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable redux-saga/no-unhandled-errors */
import { all, put, select, takeEvery } from 'redux-saga/effects'
import { success, error } from '@redux-requests/core'
import { WsLkService, WsStuAppeal } from '@lk-stu3/services'
import { normalizeAppealInfo } from '@lk-stu3/utils'
import * as actions from './actions'
import * as types from './types'
import * as selectors from './selectors'
import { normalizeAppealInfoStatus } from './helpers'

function* fetchRequest({ payload: requetId }) {
  const id = yield select(selectors.appealIdS)
  if (id !== requetId) yield put(WsLkService.actions.requestGET(requetId))
}

function* setAppealSaga({ payload }) {
  yield put(actions.setAppealInfo(normalizeAppealInfo(payload.data)))
}

function* getAppealStatusSaga({ payload }) {
  yield put(
    actions.setAppealStatusInfo(
      normalizeAppealInfoStatus(payload.data.reverse())
    )
  )
}

function* startRequestLoading() {
  yield put(actions.startAppealLoading())
}

function* failRequestLoadingSaga() {
  yield put(actions.appealLoadingFail())
}

export function* sagas() {
  yield all([
    takeEvery(types.SET_REQUEST_ID, fetchRequest),
    takeEvery(
      success(WsLkService.actions.requestGET.toString()),
      setAppealSaga
    ),
    takeEvery(WsLkService.actions.requestGET.toString(), startRequestLoading),
    takeEvery(
      success(WsStuAppeal.actions.appealsStatusGET.toString()),
      getAppealStatusSaga
    ),
    takeEvery(
      error(WsLkService.actions.requestGET.toString()),
      failRequestLoadingSaga
    ),
  ])
}

// TODO: переписать SAGA по правилам eslint
