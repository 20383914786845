/** @jsx jsx */
import { jsx } from 'theme-ui'

import { useEffect, useRef, useState, useCallback, forwardRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

export interface TextAreaI extends React.HTMLProps<HTMLTextAreaElement> {
  readonly?: boolean
  maxHeight?: number
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextAreaI>(
  (
    { sx, readonly, onChange, maxHeight = 0, ...props },
    ref
  ): React.ReactElement => {
    const DOMElement = useRef<HTMLTextAreaElement>(null)

    const [height, setHeight] = useState(40)

    const changeHeight = useCallback(() => {
      const { scrollHeight = 0, clientHeight = 0 } = DOMElement?.current || {}
      let currentDiffHeight = 0

      if (scrollHeight > clientHeight) currentDiffHeight = scrollHeight
      if (scrollHeight < clientHeight) currentDiffHeight = clientHeight
      if (scrollHeight === clientHeight) currentDiffHeight = clientHeight

      const diff = currentDiffHeight - height

      if (diff > 0 || diff < 0) {
        setHeight(height + diff)
      }
    }, [height])

    useEffect(() => {
      changeHeight()
    }, [changeHeight, height])

    const onPatchChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
      if (onChange) onChange(event)
      changeHeight()
    }

    return (
      <textarea
        ref={mergeRefs([DOMElement, ref])}
        readOnly={readonly}
        onChange={onPatchChange}
        onFocus={(): void => changeHeight()}
        onBlur={(): void => changeHeight()}
        onKeyPress={(): void => changeHeight()}
        style={{
          height: `${height}px`,
          overflowY: maxHeight < height + 2 ? 'auto' : 'hidden',
        }}
        sx={{
          display: 'block',
          maxHeight: maxHeight > 0 ? `${maxHeight}px` : undefined,
          px: 3,
          py: 2,
          fontSize: 2,
          minHeight: '40px',
          width: '100%',
          color: 'text',
          fontFamily: 'body',
          outline: 'none',
          overflow: 'hidden',
          resize: 'vertical',
          borderColor: 'transparent',
          borderRadius: '2px',
          backgroundColor: 'muted',
          transitionDuration: '0.2s',
          transitionProperty: 'color, border-color, background-color',
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:focus': {
            borderColor: 'primary',
            backgroundColor: 'background',
          },
          '&:disabled': {
            color: 'subtitle',
            cursor: 'no-drop',
            borderColor: 'transparent',
          },
          ...sx,
        }}
        {...props}
      />
    )
  }
)
