import React from 'react'
import { Flex } from 'theme-ui'
import { NavButton } from '@lk-stu3/components'

type TabT = {
  title: string
  value: 'UNREAD' | 'READ'
}

interface TabsNotificationI {
  tabs: TabT[]
  activeTab: 'UNREAD' | 'READ'
  setActiveTab: (value: 'UNREAD' | 'READ') => void
}

export const TabsNotification = ({
  tabs,
  activeTab,
  setActiveTab,
}: TabsNotificationI): React.ReactElement => {
  return (
    <Flex
      sx={{
        paddingTop: '4px',
        border: '1px solid',
        borderColor: 'border',
      }}
    >
      {tabs.map((tab) => {
        const changeActiveTab = (): void => setActiveTab(tab.value)

        return (
          <NavButton
            key={tab.value}
            active={tab.value === activeTab}
            onClick={changeActiveTab}
          >
            {tab.title}
          </NavButton>
        )
      })}
    </Flex>
  )
}
