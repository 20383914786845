import React from 'react'
import { Flex, Button, Text, Box } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import { Skeleton } from '@lk-stu3/components'

interface TopPannelWrapperI {
  title: string
  children: React.ReactNode
  status: string
  loading: boolean
}

export const TopPannelWrapper = ({
  title,
  children,
  status,
  loading,
}: TopPannelWrapperI): React.ReactElement => {
  const history = useHistory()
  const goBack = (): void => history.push('/dashboard/appeals')
  return (
    <Flex
      sx={{
        padding: '16px',
        borderBottom: '1px solid',
        borderBottomColor: 'border',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        <Box>
          <Button
            variant="bordered"
            onClick={goBack}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '8px 14px',
              transition: 'none',
              color: 'text',
              '& svg': {
                fill: 'text',
              },
              '&:hover svg': {
                fill: 'background',
              },
              '&:hover': {
                color: 'background',
              },
            }}
          >
            <Flex sx={{ alignItems: 'center' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="1rem"
                height="1rem"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
              </svg>
              <Text sx={{ lineHeight: 1, paddingX: '8px' }}>Назад</Text>
            </Flex>
          </Button>
        </Box>
        <Box sx={{ marginLeft: '16px' }}>
          <Flex sx={{ flexDirection: 'column' }}>
            {loading ? (
              <Skeleton>
                <Text sx={{ fontSize: '16px', minWidth: '200px' }}>
                  {title}
                </Text>
              </Skeleton>
            ) : (
              <Text sx={{ fontSize: '16px' }}>{title}</Text>
            )}
            {loading ? (
              <Skeleton>
                <Text
                  as="span"
                  sx={{
                    fontSize: '10px',
                    minWidth: '150px',
                  }}
                >
                  {status}
                </Text>
              </Skeleton>
            ) : (
              <Text
                as="span"
                sx={{
                  color: status === 'Отказ' ? 'danger' : 'success',
                  fontSize: '10px',
                }}
              >
                {status}
              </Text>
            )}
          </Flex>
        </Box>
      </Flex>
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}
