import React, { ReactElement } from 'react'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { useSelector, useDispatch } from 'react-redux'
import { moduleNewAppeal } from './module'
import { FormNewAppeal } from './form-new-appeal'
import { ModalWrapper } from '../modal-wrapper'
import { request } from './module/selectors'
import { redirectAndClose } from './module/actions'

export const NewAppeal = ({
  modalIsOpen,
}: {
  modalIsOpen: boolean
}): ReactElement => {
  const { requestFail, requestOnLoad, requestSuccess } =
    useSelector(request) || {}
  const displayTitle = !requestFail && !requestOnLoad && !requestSuccess

  const dispatch = useDispatch()

  const onClose = (): void => {
    dispatch(redirectAndClose())
  }

  return (
    <DynamicModuleLoader modules={[moduleNewAppeal()]}>
      <ModalWrapper
        isOpen={modalIsOpen}
        onClose={onClose}
        title={displayTitle ? 'Подать заявку в ГАУ НИАЦ' : ''}
      >
        <FormNewAppeal onClose={onClose} />
      </ModalWrapper>
    </DynamicModuleLoader>
  )
}
