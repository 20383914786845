import * as types from './types'
import { ClientUserT, UserT } from './reducers'

interface LoginI {
  type: typeof types.LOGIN
  payload: {
    username: string
    password: string
  }
}

export const login = (user: { username: string; password: string }): LoginI => {
  return {
    type: types.LOGIN,
    payload: user,
  } as const
}

interface LoginSuccessI {
  type: typeof types.LOGIN_SUCCESS
}

export const loginSuccess = (): LoginSuccessI => {
  return {
    type: types.LOGIN_SUCCESS,
  } as const
}

interface AuthSuccessI {
  type: typeof types.AUTH_SUCCESS
}

export const AuthSuccess = (): AuthSuccessI => {
  return {
    type: types.AUTH_SUCCESS,
  } as const
}

interface LoginFailI {
  type: typeof types.LOGIN_FAIL
  payload: string
}

export const loginFail = (errorMessage: string): LoginFailI => {
  return {
    type: types.LOGIN_FAIL,
    payload: errorMessage,
  } as const
}

interface LogoutI {
  type: typeof types.LOGOUT
}

export const logout = (): LogoutI => {
  return {
    type: types.LOGOUT,
  } as const
}
interface SetAuthUserInfoI {
  type: typeof types.SET_AUTH_USER_INFO
  payload: UserT
}

export const setAuthUserInfo = (user: UserT): SetAuthUserInfoI => {
  return {
    type: types.SET_AUTH_USER_INFO,
    payload: user,
  } as const
}

interface SetClientUserI {
  type: typeof types.SET_CLIENT_USER
  payload: ClientUserT
}

export const setClientUser = (user: ClientUserT): SetClientUserI => {
  return {
    type: types.SET_CLIENT_USER,
    payload: user,
  } as const
}

interface RegistrationLoadingI {
  type: typeof types.REGISTRATION_START_LOADING
}

export const registrationLoading = (): RegistrationLoadingI => {
  return {
    type: types.REGISTRATION_START_LOADING,
  } as const
}

interface RegistrationEndI {
  type: typeof types.REGISTRATION_END
  payload: string
}

export const registrationEnd = (message: string): RegistrationEndI => {
  return {
    type: types.REGISTRATION_END,
    payload: message,
  } as const
}
interface RegistrationEndLoadingI {
  type: typeof types.REGISTRATION_END_LOADING
}

export const registrationEndLoading = (): RegistrationEndLoadingI => {
  return {
    type: types.REGISTRATION_END_LOADING,
  } as const
}
interface RegistrationEndResetI {
  type: typeof types.REGISTRATION_END_RESET
}

export const registrationEndReset = (): RegistrationEndResetI => {
  return {
    type: types.REGISTRATION_END_RESET,
  } as const
}

interface SetUserSettingI {
  type: typeof types.SET_USER_SETTING
  payload: {
    [settingName: string]: string
  }
}

export const setUserSetting = (setting: {
  [settingName: string]: string
}): SetUserSettingI => {
  return {
    type: types.SET_USER_SETTING,
    payload: setting,
  } as const
}
