import { v4 as uuidv4 } from 'uuid'
import { isEmpty } from 'lodash'
import { LinksState, LocalOption, TLinkError } from './models'

export const getLocalOption = () => {
  return {
    id: uuidv4(),
    comment: '',
    link: '',
  }
}

export const getInitState = (): LinksState => {
  const option = getLocalOption()

  return {
    allIds: [option.id],
    byId: {
      [option.id]: option,
    },
  }
}

export const validateLinks = (linksData: LocalOption[]): TLinkError => {
  const linkErrors = linksData.reduce((acc, { link, comment, id }) => {
    const accCopy = { ...acc }
    accCopy[id] = {}

    if (isEmpty(link) || link?.length < 5) {
      accCopy[id].link = 'Вставьте ссылку, минимальная длина 5 символов'
    }

    if (isEmpty(comment) || comment?.length < 5) {
      accCopy[id].comment = 'Введите комментарий, минимальная длина 5 символов'
    }

    return accCopy
  }, {})

  return linkErrors
}
