import React from 'react'
import { useHistory } from 'react-router'
import { Flex } from 'theme-ui'
import { NavLink } from '@lk-stu3/components'

const getActive = (path: string): string => path.split('/')[2]

export const Tabs = (): React.ReactElement => {
  const {
    location: { pathname },
  } = useHistory()

  const ArrayLink = pathname.split('/')

  const getUrl = (tab: string): string =>
    `${ArrayLink[0]}/${ArrayLink[1]}/${tab}`

  const tabs = [
    {
      title: 'Заявки',
      active: getActive(pathname) === 'appeals',
      to: getUrl('appeals'),
    },
    {
      title: 'Настройки',
      active: getActive(pathname) === 'settings',
      to: getUrl('settings'),
    },
    {
      title: 'Техподдержка',
      active: getActive(pathname) === 'tech-support',
      to: getUrl('tech-support'),
    },
  ]

  return (
    <Flex
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'border',
      }}
    >
      {tabs.map(({ title, active, to }) => (
        <NavLink key={to} active={active} to={to}>
          {title}
        </NavLink>
      ))}
    </Flex>
  )
}
