/* eslint-disable  */
// TODO: переписать по правилам линтера

import React from 'react'
import { AppDispatchT } from '@lk-stu3/main/src/store'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Text, Button } from 'theme-ui'
import { WsStuNsiService, WsNotificationsService } from '@lk-stu3/services'
import { getAuthUserInfo, getClientUser } from "@lk-stu3/auth";
import { Wrapper } from '../wrapper'
import { Tabs } from '../tabs'

export const Settings = (): React.ReactElement => {
  const dispatch = useDispatch<any>()

  const userData = useSelector(getAuthUserInfo)

  React.useEffect(() => {
    dispatch(WsStuNsiService.actions.dicts(['notifications']))
  }, [dispatch])
  const { notifications = [] } = useSelector(WsStuNsiService.dictsSelector)
  const { login, email } = useSelector(getClientUser) || {}

  const [activeTopic, setActiveTopic] = React.useState([])
  const loadSubscribeData = React.useCallback(() => {
    dispatch(WsNotificationsService.actions.searchSubscriptions()).then(
      (res) => {
        const data = (res.data?.content || [])
          .map((el) => ({ id: el.id, topic: el.subject.topic, authId: el.subscriber?.authId }))
          .filter((subscribe) => {
            return subscribe.authId === userData?.id
          })

        setActiveTopic(data)
      }
    )
  }, [dispatch, login, userData])

  React.useEffect(() => {
    loadSubscribeData()
  }, [loadSubscribeData])

  const subscribe = (notification) => {
    const { topic, personal = false, type } = notification
    dispatch(
      WsNotificationsService.actions.subscription({
        topic,
        personal,
        subscriber: {
          email,
          login,
        },
        types: [type],
      })
    ).then(() => loadSubscribeData())
  }

  const unSubscribe = (id) => {
    dispatch(WsNotificationsService.actions.unsubscribe(id)).then(() =>
      loadSubscribeData()
    )
  }
  return (
    <Wrapper navbar={<Tabs />}>
      <Flex sx={{ flexDirection: 'column' }}>
        {notifications.map((notification) => {
          const isActive = activeTopic.find(
            (el) => el.topic === notification.topic && el.authId === userData?.id
          )
          const handleClick = () =>
            isActive ? unSubscribe(isActive.id) : subscribe(notification)

          return (
            <Flex
              key={notification.topic}
              sx={{
                alignItems: 'center',
                padding: '16px',
                borderBottom: '1px solid',
                borderBottomColor: 'border',
                justifyContent: 'space-between',
              }}
            >
              <Text>{notification.title}</Text>
              <Button
                variant={isActive ? 'secondary' : 'primary'}
                onClick={handleClick}
              >
                {isActive ? 'Отписаться' : 'Подписаться'}
              </Button>
            </Flex>
          )
        })}
      </Flex>
    </Wrapper>
  )
}
