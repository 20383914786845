/* eslint-disable no-console */
import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render(): React.ReactElement | React.ReactNode {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return <h1>Что то пошло не так, сообщите нам об ошибке.</h1>
    }

    return children
  }
}
