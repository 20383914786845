import { sagas } from './sagas'
import * as types from './types'
import * as actions from './actions'
import reducers, { initialState } from './reducers'

export const techSupportRequestModule = {
  id: 'tech-support-request',
  reducerMap: {
    [types.namespace]: (state = initialState, action) => ({
      ...state,
      ...(reducers[action.type] && reducers[action.type](state, action)),
    }),
  },
  sagas: [sagas],
  actions,
  types,
}
