import { createAction } from '@reduxjs/toolkit'

/*
 *  MAIN STU3 http://stu3-dev.ursip.local/ws-stu/swagger-ui.html#/
 *  nginx proxy method
 */

const controller = 'ws-stu'
const api = `/${controller}/v1`

const EDIT_PHYSICAL_PERSONS = 'WS_STU_SERVICE-EDIT_PHYSICAL_PERSONS' as const

interface PhysicalPersonsI {
  comment: string | null
  dateBirth: string | null
  email: string | null
  firstName: string | null
  id: string
  // idOrgEntity: string | null
  lastName: string | null
  middleName: string | null
  personType: string | null
  phone: string | null
}

const actions = {
  physicalPersonsPUT: createAction(
    EDIT_PHYSICAL_PERSONS,
    (payload: PhysicalPersonsI) => ({
      payload: {
        request: {
          url: `${api}/physical-persons`,
          method: 'PUT',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      },
    })
  ),
}

export const WsStuPhysicalPersons = {
  actions,
}
