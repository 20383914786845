/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import * as logo from '../assets/logo.png'

export const LogoRound = (): React.ReactElement => {
  return (
    <Flex
      sx={{
        backgroundColor: 'background',
        borderRadius: '50%',
        width: '80px',
        height: '80px',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 0 10px rgba(0,0,0,0.5)',
      }}
    >
      <img src={logo} alt="НИАЦ" />
    </Flex>
  )
}
