import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'theme-ui'
import { TopPannelWrapper } from './top-pannel-wrapper'
import {
  statusS,
  appealNumberS,
  appealLoadingS,
  appealIdS,
  requestIdS,
  appealInfoS,
} from './module/selectors'
import { openModal } from '../../modals/module/actions'

export const TopPannel = (): React.ReactElement => {
  const dispatch = useDispatch()
  const status = useSelector(statusS)
  const appealNumber = useSelector(appealNumberS)
  const appealLoading = useSelector(appealLoadingS)
  const idAppeal = useSelector(appealIdS)
  const idRequest = useSelector(requestIdS)
  const appealInfo = useSelector(appealInfoS)

  const openChangeAppealModal = (): void => {
    if (idAppeal && idRequest)
      dispatch(
        openModal<{ idAppeal: string; idRequest: string }>('change_appeal', {
          idAppeal,
          idRequest,
        })
      )
  }
  const openRefusalAppealModal = (): void => {
    if (idAppeal && idRequest)
      dispatch(
        openModal<{ idAppeal: string; idRequest: string }>('refusal_appeal', {
          idAppeal,
          idRequest,
        })
      )
  }

  return (
    <TopPannelWrapper
      title={`Заявка ${appealNumber}`}
      status={status}
      loading={appealLoading}
    >
      <Button onClick={openChangeAppealModal}>Запрос на изменение</Button>
      {appealInfo?.buttonRefusalShow && (
        <Button onClick={openRefusalAppealModal} sx={{ marginLeft: '16px' }}>
          Запрос на отказ
        </Button>
      )}
    </TopPannelWrapper>
  )
}
