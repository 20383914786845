import React from 'react'
import { connect, FormikProps, FormikValues } from 'formik'
import { Input } from '@ursip/components'
import { InputProps } from '@ursip/components/dist/Input/Input'

import { SxProps } from 'theme-ui'
import { TitledComponent, TitledComponentT } from '../titled-component'

type InputT = {
  name: string
  loading?: boolean
  formik?: FormikProps<FormikValues>
  sx?: SxProps
  sideEffectFnc?: <T, U>(setFieldValue: T, value: U) => void
  gridAreaName?: boolean
}

type InputFormikT = TitledComponentT & InputT & InputProps

export const InputFormik = connect(
  ({
    title,
    required,
    icon,
    sideEffectFnc,
    name,
    formik,
    gridArea,
    gridAreaName,
    sx,
    ...props
  }: InputFormikT): React.ReactElement => {
    const { setFieldValue, setFieldTouched, values } = formik || {}
    const handleChange = async (
      event: React.FormEvent<HTMLInputElement>
    ): Promise<void> => {
      const { value } = event.currentTarget
      if (sideEffectFnc) sideEffectFnc(setFieldValue, value)
      if (setFieldValue) await setFieldValue(name, value)
      if (setFieldTouched) await setFieldTouched(name, true)
    }
    const formikErrors = name && formik ? formik.errors[name] : ''

    const formikTouched = formik?.touched[name]

    return (
      <TitledComponent
        title={title}
        required={required}
        error={formikErrors}
        touched={!!formikTouched}
        icon={icon}
        gridArea={gridAreaName ? name : null}
      >
        <Input
          alwaysShowMask
          onChange={handleChange}
          value={values && values[name]}
          sx={{ width: '100%', ...sx }}
          {...props}
        />
      </TitledComponent>
    )
  }
)
