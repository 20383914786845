import React from 'react'
import { useSelector } from 'react-redux'
import { getClientUser } from '@lk-stu3/auth'
import { Text } from 'theme-ui'

export const UserInfo = (): React.ReactElement | null => {
  const clientUser = useSelector(getClientUser)
  if (clientUser) {
    const { nameFirst, nameLast, nameMiddle } = clientUser
    const clientBIO = [nameLast, nameFirst, nameMiddle].join(' ')

    if (clientUser.org !== null) {
      return (
        <>
          <Text>{clientBIO}</Text>
          <Text>{clientUser.org.shortName || ''}</Text>
        </>
      )
    }

    if (clientUser.physic !== null) {
      return <Text>{clientBIO}</Text>
    }
  }
  return null
}
