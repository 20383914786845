/* eslint-disable redux-saga/no-unhandled-errors */
import { all, takeEvery, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as types from './types'

const delay = (ms: number): Promise<void> =>
  new Promise((res) => setTimeout(res, ms))

function* closeModalSaga(): Generator {
  yield put(actions.closeModalForm())
  yield delay(500)
  yield put(actions.clearModalData())
}

export function* sagas(): Generator {
  yield all([takeEvery(types.CLOSE_MODAL, closeModalSaga)])
}

// TODO: переписать SAGA по правилам eslint
