import { createSelector } from 'reselect'
import { initialState, InitialStateI } from './reducers'
import { moduleName, RootI } from './index'

const namespaceStateSelector = (state: RootI): InitialStateI =>
  state[moduleName] || initialState

export const modalTypeS = createSelector(
  namespaceStateSelector,
  (state) => state.modalType
)
export const modalPropsS = createSelector(
  namespaceStateSelector,
  (state) => state.modalProps
)
export const modalIsOpenS = createSelector(
  namespaceStateSelector,
  (state) => state.modalIsOpen
)
