import React from 'react'
import { TreeDataNode } from '@lk-stu3/utils'
import { Flex, Button } from 'theme-ui'
import { useDispatch } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { InputFormik } from '@lk-stu3/components'

type InitialValuesT = {
  name: string
}

const initialValues = (name = ''): InitialValuesT => ({
  name,
})

const shema = object().shape({
  name: string()
    .max(255, 'длина поля не более 255 символов')
    .required('введите название папки'),
})

export const RenameFolder = ({
  props,
  onClose,
}: {
  props: TreeDataNode
  onClose: () => void
}): React.ReactElement => {
  const dispatch = useDispatch()
  const onSubmit = (
    values: InitialValuesT,
    actions: FormikHelpers<InitialValuesT>
  ): void => {
    dispatch(
      WsStuFileAndFolder.actions.folderEdit({
        global: props.global || false,
        idAppeal: props.idAppeal || '',
        idParentFolder: props.idParentFolder || null,
        id: props.id,
        ...values,
      })
    )
    actions.setSubmitting(false)
    onClose()
  }

  const { name } = props

  return (
    <Formik
      initialValues={initialValues(name)}
      onSubmit={onSubmit}
      validationSchema={shema}
    >
      {({ handleSubmit }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Flex sx={{ flexDirection: 'column' }}>
            <InputFormik name="name" title="Название папки" />
            <Flex
              sx={{
                marginTop: '32px',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="submit"
                variant="primary"
                sx={{ width: '120px' }}
                mr={3}
              >
                Сохранить
              </Button>
              <Button
                type="submit"
                variant="secondary"
                sx={{ width: '120px' }}
                onClick={(e): void => {
                  e.preventDefault()
                  onClose()
                }}
              >
                Отмена
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
