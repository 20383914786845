import React from 'react'
import { Moment } from 'moment'
import {
  DatePicker,
  RangeDatePicker as LibRangeDatePicker,
} from '@ursip/components'
import { SxProps } from 'theme-ui'

interface DatePickerI {
  name?: string
  sx?: SxProps
  disabled?: boolean
  value?: Moment | null
}

const SingleDatePicker = ({
  sx,
  ...props
}: DatePickerI): React.ReactElement => {
  return (
    <DatePicker
      height="41px"
      sx={{ width: '100%', height: '41px', ...sx }}
      {...props}
    />
  )
}

const RangeDatePicker = ({ sx, ...props }: DatePickerI): React.ReactElement => {
  return (
    <LibRangeDatePicker
      sx={{ width: '100%', height: '41px', ...sx }}
      {...props}
    />
  )
}

export { SingleDatePicker, RangeDatePicker }
