import { ISagaModule } from 'redux-dynamic-modules-saga'
import { reducer, initialState, InitialStateI } from './reducers'

import { ActionTypes } from './actions.types'
import { sagas } from './sagas'

export * from './selectors'
export * from './actions'

const moduleName = 'modals'

export interface RootI {
  [moduleName: string]: InitialStateI
}

const moduleModals = (): ISagaModule<RootI> => {
  return {
    id: moduleName,
    reducerMap: {
      [moduleName]: (
        state: InitialStateI = initialState,
        action: ActionTypes
      ): InitialStateI => ({
        ...state,
        ...reducer(state, action),
      }),
    },
    sagas: [sagas],
  }
}

export { moduleName, moduleModals }
