import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Flex, Grid, Text, Radio, Label, Card, Button } from 'theme-ui'
import { Spinner } from '@lk-stu3/components'

import { Layout } from './components/layout'

import { OrgFormComponent } from './org'
import { PhysFormComponent } from './phys'
// import { clearRegistrationMessage } from './module/actions'
import { registrationMessageS, registrationProcessS } from './module/selectors'
import { registrationEndReset } from './module/actions'

export const login = 'login'

export const Registration = ({
  toLogin,
}: {
  toLogin: () => void
}): React.ReactElement => {
  const [clientType, setClientType] = React.useState<string>('ORG')
  const handleClientTypeChange = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    setClientType(e.currentTarget.value)
  }

  const delay = (ms: number): Promise<void> =>
    new Promise((res) => setTimeout(res, ms))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<any>()
  const registrationProcess = useSelector(registrationProcessS)
  const registrationMessage = useSelector(registrationMessageS)
  const registrationProcessReset = async (): Promise<void> => {
    toLogin()
    await delay(500)
    await dispatch(registrationEndReset())
  }
  if (registrationProcess) {
    return (
      <Card
        sx={{
          backgroundColor: 'background',
          boxShadow: '0 0 6px rgba(0,0,0,0.5)',
          padding: '28px 28px 28px 28px',
          maxWidth: 'calc(100vw - 30%)',
        }}
      >
        <Spinner title="Отправка заявки на регистрацию" />
      </Card>
    )
  }

  if (!registrationProcess && registrationMessage !== null) {
    return (
      <Card
        sx={{
          backgroundColor: 'background',
          boxShadow: '0 0 6px rgba(0,0,0,0.5)',
          padding: '28px 28px 28px 28px',
          maxWidth: 'calc(100vw - 30%)',
        }}
      >
        <Text>{registrationMessage}</Text>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Button
            variant="secondary"
            onClick={registrationProcessReset}
            sx={{ width: '110px', marginTop: '30px' }}
          >
            Назад
          </Button>
        </Flex>
      </Card>
    )
  }
  return (
    <Layout logo={false}>
      <Grid
        sx={{
          minWidth: '850px',
          gridTemplateRows: '30px 24px 650px 35px',
          paddingX: '2px',
        }}
      >
        <Text as="h2">Заявка на регистрацию</Text>
        <Flex>
          <Label
            sx={{
              width: 'fit-content',
              alignItems: 'center',
              marginRight: '16px',
              cursor: 'pointer',
              '& input:focus ~ svg': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            <Radio
              value="ORG"
              checked={clientType === 'ORG'}
              onChange={handleClientTypeChange}
            />
            Юридическое лицо
          </Label>
          <Label
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              width: 'fit-content',
              '& input:focus ~ svg': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            <Radio
              value="PHYS"
              checked={clientType === 'PHYS'}
              onChange={handleClientTypeChange}
            />
            Физическое лицо
          </Label>
        </Flex>
        {clientType === 'ORG' && <OrgFormComponent toLogin={toLogin} />}
        {clientType === 'PHYS' && <PhysFormComponent toLogin={toLogin} />}
      </Grid>
    </Layout>
  )
}
