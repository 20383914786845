import { namespace } from './types'
import { createSelector } from 'reselect'

export const techSupportRequestSelector = (state) => state[namespace]

export const isLoadingSelector = createSelector(
  techSupportRequestSelector,
  (state) => state.isLoading
)
export const isSubmittingSelector = createSelector(
  techSupportRequestSelector,
  (state) => state.isSubmitting
)
export const dictsSelector = createSelector(
  techSupportRequestSelector,
  (state) => state.dicts
)
