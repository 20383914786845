import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'

export const ContextMenuWrapper = styled.div<{ width: number }>`
  position: absolute;
  z-index: 1;
  background: #fff;
  width: ${({ width }): number => width}px;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

export const NotificationRowLink = styled(RouterLink)`
  display: flex;
  color: inherit;
`
