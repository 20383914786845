import { Textarea, TitledComponent } from '@lk-stu3/components'
import React, { forwardRef } from 'react'

export const CustomTextarea = forwardRef(
  ({ title, value, onChange, sx, error, touched, ...props }, ref) => {
    return (
      <TitledComponent title={title} error={error} touched={touched}>
        <Textarea
          ref={ref}
          onChange={onChange}
          value={value}
          sx={{ width: '100%', ...sx }}
          {...props}
        />
      </TitledComponent>
    )
  }
)
