import React, { useCallback, useEffect, useMemo } from 'react'
import { Flex, Box, Button, Spinner } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import {
  InputFormik,
  SelectFormik,
  RangeDatePickerFormik,
  Collapse,
} from '@lk-stu3/components'
import { Formik } from 'formik'
import { WsStuNsiService } from '@lk-stu3/services'
import {
  getStringFromMomentDate,
  getStringFromMomentDateEndOfDay,
} from '@lk-stu3/utils'

import { HeaderContent } from './header-content'
import { dictsSelector } from './module/selectors'
import { setSearchParamsAC, clearSearchParamsAC } from './module/actions'

const initialValues = {
  helpdeskNumber: '',
  dateRegistration: [],
  idTypeTask: '',
  idStatus: '',
}

export const CollapsePanel = () => {
  const dispatch = useDispatch()
  const { status_hd, type_task_hd } = useSelector(dictsSelector)

  const statusHdOptions = useMemo(() => {
    if (!status_hd) {
      return []
    }

    return status_hd.map((el) => {
      const nameEntity = el.values.find((option) => option.nick === 'name')
      const name = nameEntity?.valueAttr
      const id = nameEntity?.id

      return {
        label: name,
        value: id,
      }
    })
  }, [status_hd])

  const typeTaskHdOptions = useMemo(() => {
    if (!type_task_hd) {
      return []
    }

    return type_task_hd.map((el) => {
      const nameEntity = el.values.find((option) => option.nick === 'name')
      const name = nameEntity?.valueAttr
      const id = nameEntity?.id

      return {
        label: name,
        value: id,
      }
    })
  }, [type_task_hd])

  useEffect(() => {
    const asyncFn = async () => {
      await Promise.all([
        dispatch(WsStuNsiService.actions.getDict('type_task_hd')),
      ])
      await Promise.all([
        dispatch(WsStuNsiService.actions.getDict('status_hd')),
      ])
    }

    asyncFn()
  }, [dispatch])

  const handleSubmit = useCallback(
    (values) => {
      const dto = {
        helpdeskNumber: Number(values?.helpdeskNumber) || null,
        dateRegistrationFrom: values?.dateRegistration[0]
          ? getStringFromMomentDate(values?.dateRegistration[0])
          : null,
        dateRegistrationTo: values?.dateRegistration[1]
          ? getStringFromMomentDateEndOfDay(values?.dateRegistration[1])
          : null,
        idTypeTask: (values?.idTypeTask || []).map((el) => el.value),
        idStatus: (values?.idStatus || []).map((el) => el.value),
        page: 0,
      }

      dispatch(setSearchParamsAC(dto))
    },
    [dispatch]
  )

  const handleReset = useCallback(() => {
    dispatch(clearSearchParamsAC())
  }, [dispatch])

  if (!type_task_hd || !status_hd) {
    return (
      <Flex
        sx={{ height: '100px', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box sx={{ width: '20px', height: '20px' }}>
          <Spinner size={14} sx={{ color: '#000' }} />
        </Box>
      </Flex>
    )
  }

  return (
    <Flex sx={{ zIndex: 2 }}>
      <Collapse HeaderContent={<HeaderContent />}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          {({ values, handleSubmit, handleReset }) => (
            <Flex
              sx={{
                width: '100%',
                padding: '10px 16px 32px 16px',
                flexDirection: 'column',
              }}
            >
              <Flex>
                <Box sx={{ marginRight: '10px', width: '16%' }}>
                  <InputFormik
                    name="helpdeskNumber"
                    value={values?.helpdeskNumber}
                    title="Номер запроса"
                  />
                </Box>
                <Box sx={{ marginRight: '10px', width: '16%' }}>
                  <RangeDatePickerFormik
                    name={['dateRegistration']}
                    title="Дата регистрации (от до)"
                    sx={{ marginRight: '10px' }}
                  />
                </Box>
                <Box sx={{ marginRight: '10px', width: '16%' }}>
                  <SelectFormik
                    isMulti
                    sx={{ marginRight: '10px' }}
                    name="idTypeTask"
                    value={values?.idTypeTask}
                    title="Тип запроса"
                    options={typeTaskHdOptions}
                  />
                </Box>
                <Box sx={{ marginRight: '10px', width: '16%' }}>
                  <SelectFormik
                    isMulti
                    sx={{ marginRight: '10px' }}
                    name="idStatus"
                    value={values?.idStatus}
                    title="Статус"
                    options={statusHdOptions}
                  />
                </Box>
              </Flex>
              <Flex sx={{ justifyContent: 'flex-end' }}>
                <Button onClick={handleSubmit} variant="flat" type="submit">
                  Найти
                </Button>
                <Button
                  onClick={handleReset}
                  variant="flat"
                  title="Сбросить все фильтры"
                >
                  Сбросить фильтры
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </Collapse>
    </Flex>
  )
}
