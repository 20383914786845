import React from 'react'
import { Route, Switch, RouteProps, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PrivateRoute } from './components/private-route'

import { authSelector } from './module/selectors'
import { Signin } from './signin'

export { moduleAuth } from './module'
export { logout } from './module/actions'

export {
  getAuthUserInfo,
  getClientUser,
  userSettingS,
} from './module/selectors'

interface PathsI extends RouteProps {
  readonly privatePath?: boolean
  readonly key: string
}

export const Auth = ({ paths }: { paths: PathsI[] }): React.ReactElement => {
  const isAuth = useSelector(authSelector)

  return (
    <>
      <Redirect from="/" to="/signin" />
      <Switch>
        <Route path="/signin" component={Signin} />
        {paths.map(({ key, privatePath = false, ...routeProps }) =>
          privatePath ? (
            <PrivateRoute key={key} isAuth={isAuth} {...routeProps} />
          ) : (
            <Route key={key} {...routeProps} />
          )
        )}
      </Switch>
    </>
  )
}
