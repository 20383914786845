import React from 'react'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { WsLkService } from '@lk-stu3/services'
import { Wrapper } from '../wrapper'
import { CollapsePanel } from './collapse-panel'
import { AppealTable } from './table'
import { TablePages } from './table-pages'
import { Tabs } from '../tabs'

import { moduleAppeals } from './module'

export const Appeals = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { login } = useSelector(getAuthUserInfo) || {}
  React.useEffect(() => {
    if (login)
      dispatch(
        WsLkService.actions.getSettings({
          settingName: 'RequestTableSettings',
          userLogin: login,
        })
      )
  }, [dispatch, login])
  return (
    <DynamicModuleLoader modules={[moduleAppeals()]}>
      <Wrapper navbar={<Tabs />}>
        <CollapsePanel />
        <AppealTable />
        <TablePages />
      </Wrapper>
    </DynamicModuleLoader>
  )
}
