import { createSelector } from 'reselect'

import { NewAppealI } from './index'
import { InitialStateT } from './reducers'

const namespaceStateSelector = (state: NewAppealI): InitialStateT =>
  state.new_appeal

export const request = createSelector(namespaceStateSelector, (state) => state)
export const idRequestS = createSelector(
  namespaceStateSelector,
  (state) => state.idRequest
)
