import { ISagaModule } from 'redux-dynamic-modules-saga'

import reducers, { initialState } from './reducers'
import * as types from './types'
import { sagas } from './sagas'
import { InitialState } from './models'

export interface ModuleI {
  [moduleName: string]: InitialState
}

export const moduleNotifications = (): ISagaModule<ModuleI> => {
  return {
    id: types.namespace,
    reducerMap: {
      [types.namespace]: (state = initialState, action) => ({
        ...state,
        ...(reducers[action.type] && reducers[action.type](state, action)),
      }),
    },
    sagas: [sagas],
  }
}
