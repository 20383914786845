import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'theme-ui'
import { InputMui } from './components/input-mui'

import { LogInWrapper } from './components/login-wrapper'

import { loadingSelector, namespaceStateSelector } from './module/selectors'
import { login as loginAction } from './module/actions'

export const Login = ({
  toRegistration,
  toPasswordRecovery,
}: {
  toRegistration: () => void
  toPasswordRecovery: () => void
}): React.ReactElement => {
  const [username, setUsername] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')

  const { error, errorMessage } = useSelector(namespaceStateSelector)
  const loading = useSelector(loadingSelector)

  const dispatch = useDispatch()

  const changeLogin = (e: React.FormEvent<HTMLInputElement>): void =>
    setUsername(e.currentTarget.value)

  const changePassword = (e: React.FormEvent<HTMLInputElement>): void =>
    setPassword(e.currentTarget.value)

  const handleLogin = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    dispatch(loginAction({ username, password }))
  }

  return (
    <LogInWrapper
      loading={loading}
      error={error}
      errorMessage={errorMessage}
      onSubmit={handleLogin}
      inputs={
        <>
          <InputMui value={username} onChange={changeLogin} title="Логин" />
          <InputMui
            value={password}
            onChange={changePassword}
            title="Пароль"
            type="password"
          />
        </>
      }
      buttons={
        <>
          <Button variant="primary" p={16} type="submit">
            Войти
          </Button>
          <Button
            variant="secondary"
            p={16}
            onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
              e.preventDefault()
              toRegistration()
            }}
          >
            Зарегистрироваться
          </Button>
          <Button
            variant="flat"
            p="4px"
            onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
              e.preventDefault()
              toPasswordRecovery()
            }}
          >
            Я не помню свой пароль
          </Button>
        </>
      }
    />
  )
}
