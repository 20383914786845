import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, Spinner, ErrorCard } from '@lk-stu3/components'
import { Flex, Text, Button, Grid } from 'theme-ui'
import { WsNotificationsService } from '@lk-stu3/services'
import { status, StatusT } from '@lk-stu3/utils'
import { NotificationRowLink } from '../components'
import { readS, unreadS, errorS, loadingS } from '../module/selectors'

export const TableNotification = ({
  activeTab,
  close,
}: {
  activeTab: StatusT
  close: () => void
}): React.ReactElement => {
  const read = useSelector(readS)
  const unread = useSelector(unreadS)
  const error = useSelector(errorS)
  const loading = useSelector(loadingS)

  const notifications = React.useMemo(() => {
    if (activeTab === status.READ) return read.notifications
    if (activeTab === status.UNREAD) return unread.notifications
    return []
  }, [activeTab, read.notifications, unread.notifications])

  const notificationsId = notifications.map(({ id }) => id)

  const [allCheckboxCounter, setAllCheckboxCounter] = useState(false)

  const [checkboxValues, setCheckboxValues] = useState<string[]>([])

  const isEnteringSet = React.useCallback((): boolean => {
    // проверка на вхождение в массив
    const checkboxValuesSet = new Set(checkboxValues)
    const allCheckboxValues = new Set([...checkboxValues, ...notificationsId])
    return checkboxValuesSet.size === allCheckboxValues.size
  }, [checkboxValues, notificationsId])

  React.useEffect(() => {
    if (isEnteringSet()) {
      setAllCheckboxCounter(true)
    } else {
      setAllCheckboxCounter(false)
    }
  }, [checkboxValues, isEnteringSet, notifications])

  const changeCheckBoxValue = (id: string): void => {
    const hasValue = checkboxValues.includes(id)
    if (hasValue) {
      const newValues = checkboxValues.filter((savedId) => savedId !== id)
      setCheckboxValues([...newValues])
    } else {
      setCheckboxValues([...checkboxValues, id])
    }
  }

  const allCheckboxChange = (): void => {
    if (!isEnteringSet()) {
      const idSet = new Set([...notificationsId, ...checkboxValues])
      setCheckboxValues(Array.from(idSet))
      setAllCheckboxCounter(true)
    } else {
      const filteredIds = checkboxValues.filter(
        (savedId) => !notificationsId.find((id) => savedId === id)
      )
      setCheckboxValues(filteredIds)
      setAllCheckboxCounter(false)
    }
  }

  const dispatch = useDispatch()

  const buttonReadIsActive = checkboxValues.length !== 0

  const readNotifications = (): void => {
    if (buttonReadIsActive) {
      for (let i = 0; checkboxValues.length > i; i += 1) {
        dispatch(
          WsNotificationsService.actions.markReadNotification(checkboxValues[i])
        )
      }
      setAllCheckboxCounter(false)
    }
  }

  return (
    <Flex sx={{ flexDirection: 'column', height: '100%' }}>
      <Flex
        sx={{
          padding: '8px 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'border',
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          {activeTab === status.UNREAD ? (
            <Checkbox
              checked={allCheckboxCounter}
              onChange={allCheckboxChange}
            />
          ) : (
            <div style={{ height: '35px' }} />
          )}
          <Text sx={{ marginLeft: '16px' }}>Уведомление</Text>
        </Flex>
        {activeTab === status.UNREAD ? (
          <Button
            variant={buttonReadIsActive ? 'primary' : 'secondary'}
            title="Отметить выбранные уведомление прочитанными"
            onClick={readNotifications}
          >
            Прочитано
          </Button>
        ) : (
          <div />
        )}
      </Flex>
      <Grid
        sx={{
          overflowY: 'auto',
          gap: 0,
        }}
      >
        {loading && !error && notifications.length === 0 && (
          <Spinner title="Загрузка уведомлений" />
        )}
        {error && (
          <ErrorCard message="Произошла ошибка при получении уведомлений. Сервис недоступен." />
        )}

        {notifications.map((el) => {
          const { id, note, idRequest } = el
          const currentValue = !!checkboxValues.find(
            (savedId) => savedId === id
          )
          return (
            <Grid
              key={id}
              gap={activeTab === status.READ ? '0px' : '16px'}
              sx={{
                padding: '8px 16px',
                borderBottom: '1px solid',
                borderBottomColor: 'border',
                gridTemplateColumns:
                  activeTab === status.READ ? '1fr' : '18px 1fr',

                alignItems: 'center',
              }}
            >
              {activeTab === status.UNREAD ? (
                <Checkbox
                  checked={currentValue}
                  onChange={(): void => changeCheckBoxValue(id)}
                />
              ) : (
                <div />
              )}
              <NotificationRowLink
                to={`/dashboard/appeal/${idRequest}/info`}
                onClick={close}
                sx={{ marginLeft: '10px' }}
              >
                {note}
              </NotificationRowLink>
            </Grid>
          )
        })}
      </Grid>
    </Flex>
  )
}
