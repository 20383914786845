import React, { useCallback } from 'react'
import { Box, Flex, Text } from '@theme-ui/components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Button } from '@lk-stu3/components'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { downloadMultiple } from '@lk-stu3/utils/src/tech-support-utils'
import { MdDownload } from 'react-icons/md'

import { currentTaskSelector, filesSelector } from './module/selectors'
import { Row } from './row'
import { FilesList } from './file-list'
import { HeaderRow } from './header-row'

const FORMAT = 'DD.MM.YYYY HH:mm:ss'

const getDateStr = (value) => {
  if (!moment(value).isValid()) {
    return '-'
  }

  return moment(value).format(FORMAT)
}

const { getFileUrl } = WsStuFileAndFolder

export const ProgressTable = () => {
  const task = useSelector(currentTaskSelector)
  const { request: requestFiles, response: responseFiles } = useSelector(
    filesSelector
  )

  const handleDownloadAllReq = useCallback(() => {
    const urls = requestFiles.map(({ idFile }) => idFile).map(getFileUrl)
    downloadMultiple(urls, 200, '_blank')
  }, [requestFiles])

  const handleDownloadAllResp = useCallback(() => {
    const urls = responseFiles.map(({ idFile }) => idFile).map(getFileUrl)
    downloadMultiple(urls, 200, '_blank')
  }, [responseFiles])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <HeaderRow>
        <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
          Дата изменения
        </Flex>
        <Flex sx={{ width: '100%' }}>Комментарий</Flex>
        <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>Файл</Flex>
      </HeaderRow>
      {!!task?.dateCreate && (
        <Row>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
            {getDateStr(task?.dateCreate)}
          </Flex>
          <Flex sx={{ width: '100%', alignItems: 'center' }}>
            Зарегистрирован запрос
          </Flex>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
            <Box>
              <FilesList files={requestFiles} />
              {!!requestFiles?.length && (
                <Button
                  onClick={handleDownloadAllReq}
                  sx={{ marginTop: '12px' }}
                >
                  <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <MdDownload />
                    <Text sx={{ ml: '5px' }}>Скачать все</Text>
                  </Flex>
                </Button>
              )}
            </Box>
          </Flex>
        </Row>
      )}
      {!!task?.dateAssignmentExecutor && (
        <Row>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
            {getDateStr(task?.dateAssignmentExecutor)}
          </Flex>
          <Flex sx={{ width: '100%' }}>Назначен исполнитель</Flex>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }} />
        </Row>
      )}
      {!!task?.dateAssignmentExecutor && (
        <Row>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
            {getDateStr(task?.dateAssignmentExecutor)}
          </Flex>
          <Flex sx={{ width: '100%' }}>Запрос принят в работу</Flex>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }} />
        </Row>
      )}
      {!!task?.dateEnd && (
        <Row>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
            {getDateStr(task?.dateEnd)}
          </Flex>
          <Flex sx={{ width: '100%' }}>Получен ответ по запросу</Flex>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
            <Box>
              <FilesList files={responseFiles} />
              {!!responseFiles?.length && (
                <Button
                  onClick={handleDownloadAllResp}
                  sx={{ marginTop: '12px' }}
                >
                  <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <MdDownload />
                    <Text sx={{ ml: '5px' }}>Скачать все</Text>
                  </Flex>
                </Button>
              )}
            </Box>
          </Flex>
        </Row>
      )}
      {!!task?.dateEnd && (
        <Row>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }}>
            {getDateStr(task?.dateEnd)}
          </Flex>
          <Flex sx={{ width: '100%' }}>Завершено</Flex>
          <Flex sx={{ minWidth: '200px', alignItems: 'center' }} />
        </Row>
      )}
    </Flex>
  )
}
