import React, { forwardRef, useCallback } from 'react'
import { Button, Flex } from 'theme-ui'
import { Tooltip } from '@ursip/components'
import { LocalOption } from './models'
import { CustomTextarea } from './custom-text-area'

type LinkItemProps = {
  data: LocalOption
  errors: Record<string, string>
  onDelete: (id: string) => void
  onLinkChange: (id: string, value: string) => void
  onCommentChange: (id: string, value: string) => void
}

export const LinkItem = forwardRef<HTMLTextAreaElement, LinkItemProps>(
  ({ data, errors, onDelete, onLinkChange, onCommentChange }, ref) => {
    const handleLinkChange = useCallback(
      (e) => {
        if (onLinkChange) {
          onLinkChange(data.id, e?.target?.value)
        }
      },
      [onLinkChange]
    )

    const handleCommentChange = useCallback(
      (e) => {
        if (onCommentChange) {
          onCommentChange(data.id, e?.target?.value)
        }
      },
      [onCommentChange, data]
    )

    return (
      <Flex>
        <Flex sx={{ flexDirection: 'column', width: '90%' }}>
          <CustomTextarea
            ref={ref}
            maxHeight={80}
            touched
            name="link"
            title="Ссылка на файл"
            value={data.link}
            error={errors?.link}
            onChange={handleLinkChange}
          />
          <CustomTextarea
            maxHeight={80}
            touched
            name="comment"
            title="Комментарий"
            value={data.comment}
            error={errors?.comment}
            onChange={handleCommentChange}
          />
        </Flex>
        <Flex sx={{ padding: '20px 0' }}>
          <Tooltip title="Удалить">
            <Button
              variant="secondary"
              sx={{ marginLeft: '20px', height: '100%' }}
              onClick={() => onDelete(data.id)}
            >
              -
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    )
  }
)
