import React from 'react'
import { Flex, Box } from 'theme-ui'
import { status, StatusT } from '@lk-stu3/utils'
import { Pagination, Select } from '@lk-stu3/components'
import { useSelector, useDispatch } from 'react-redux'

import {
  unreadSearchParamsS,
  readSearchParamsS,
  readS,
  unreadS,
} from '../module/selectors'
import { actions } from '../module/actions'
import { NotificationDataI } from '../module/reducers'

export const TablePages = ({
  activeTab,
}: {
  activeTab: StatusT
}): React.ReactElement => {
  const unreadSearchParams = useSelector(unreadSearchParamsS)
  const readSearchParams = useSelector(readSearchParamsS)
  const read = useSelector(readS)
  const unread = useSelector(unreadS)

  const getCurrent = (): null | NotificationDataI => {
    if (status.READ === activeTab) return read
    if (status.UNREAD === activeTab) return unread
    return null
  }

  const { totalPages = 0 } = getCurrent() || {}

  const searchParams =
    activeTab === status.READ ? readSearchParams : unreadSearchParams

  const dispatch = useDispatch()

  const pageChange = (page: number): void => {
    dispatch(actions.setSearchParams({ ...searchParams, page }, activeTab))
  }

  // нельзя прокинуть типы в библиотеку
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countElementsChange = (value: any): void => {
    if (value && value.value) {
      dispatch(
        actions.setSearchParams(
          {
            ...searchParams,
            page: 0,
            size: value.value,
          },
          activeTab
        )
      )
    }
  }
  const options = [
    { label: 'Показывать по 10', value: '10' },
    { label: 'Показывать по 20', value: '20' },
    { label: 'Показывать по 50', value: '50' },
  ]

  return (
    <Flex
      sx={{
        padding: '16px',
        border: '1px solid',
        borderColor: 'border',
        justifyContent: 'space-between',
      }}
    >
      <Pagination
        onChange={pageChange}
        totalPages={totalPages}
        activePage={searchParams.page}
      />
      <Box sx={{ width: '175px' }}>
        <Select
          title="Количество элементов на странице"
          menuPlacement="top"
          value={{
            label: `Показывать по ${searchParams.size}`,
            value: `${searchParams.size}`,
          }}
          defaultValue={options[0]}
          options={options}
          onChange={countElementsChange}
        />
      </Box>
    </Flex>
  )
}
