import React from 'react'
import { Flex, Box } from 'theme-ui'
import { Pagination, Select } from '@lk-stu3/components'
import { useSelector, useDispatch } from 'react-redux'
import { paginationS, searchParamsS, queryParamsS } from './module/selectors'
import { setSearchParams } from './module/actions'

export const TablePages = (): React.ReactElement => {
  const { totalPages, size, number } = useSelector(paginationS)
  const searchParams = useSelector(searchParamsS)
  const queryParams = useSelector(queryParamsS)

  const dispatch = useDispatch()

  const pageChange = (page: number): void => {
    dispatch(setSearchParams(searchParams, { ...queryParams, page }))
  }

  // нельзя прокинуть типы в библиотеку
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countElementsChange = (value: any): void => {
    if (value && value.value) {
      dispatch(
        setSearchParams(searchParams, {
          ...queryParams,
          page: 0,
          size: value.value,
        })
      )
    }
  }
  const options = [
    { label: 'Показывать по 10', value: '10' },
    { label: 'Показывать по 20', value: '20' },
    { label: 'Показывать по 50', value: '50' },
  ]

  return (
    <Flex
      sx={{
        padding: '16px',
        border: '1px solid',
        borderColor: 'border',
        borderTop: 'none',
        justifyContent: 'space-between',
      }}
    >
      <Pagination
        onChange={pageChange}
        totalPages={totalPages}
        activePage={number}
      />
      <Box sx={{ width: '175px' }}>
        <Select
          title="Количество элементов на странице"
          menuPlacement="top"
          value={{ label: `Показывать по ${size}`, value: `${size}` }}
          defaultValue={options[0]}
          options={options}
          onChange={countElementsChange}
        />
      </Box>
    </Flex>
  )
}
