/** @jsx jsx */
import { jsx } from 'theme-ui'

interface PropsI
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange(value: boolean): void
}

const Checkbox: React.FunctionComponent<PropsI> = ({
  children,
  onChange,
  sx,
  ...shared
}: PropsI): React.ReactElement => {
  return (
    <input
      sx={{ width: '18px', height: '18px', cursor: 'pointer', ...sx }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
        onChange(e.target.checked)
      }
      {...shared}
    />
  )
}

Checkbox.defaultProps = {
  type: 'checkbox',
}

export { Checkbox }
