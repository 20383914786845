import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Tooltip } from '@ursip/components'
import { QuestionmarkIcon } from '@lk-stu3/dashboard/src/svg-icons'

const ToolTipContainer = (): ReactElement => {
  return (
    <Flex
      sx={{ flexDirection: 'column', alignItems: 'flex-start', padding: '2px' }}
    >
      <Text sx={{ fontSize: '12px' }}>ПБ - Пожарная безопасность</Text>
      <Text sx={{ fontSize: '12px' }}>КР - Конструктивные решения</Text>
      <Text sx={{ fontSize: '12px' }}>
        МОПБ - Меры по обеспечению пожарной безопасности
      </Text>
      <Text sx={{ fontSize: '12px' }}>НТЗ - Научно-техническое заключение</Text>
      <Text sx={{ fontSize: '12px' }}>РСС - Расстановка сил и средств</Text>
      <Text sx={{ fontSize: '12px' }}>РПР - Расчет пожарных рисков</Text>
    </Flex>
  )
}

export const ServiceTypeInfoIcon = (
  <Tooltip position="top" theme="light" html={<ToolTipContainer />}>
    <QuestionmarkIcon />
  </Tooltip>
)
