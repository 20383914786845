import { uniq } from 'lodash'
import { DataNode } from 'rc-tree/es/interface'

interface FolderServiceDataI {
  global: boolean
  id: string
  idAppeal: string
  idCase: string
  idParentFolder: string
  name: string
}

interface FileServiceDataI {
  dateLoading: string
  fileExt: string
  fileName: string
  fileSize: 0
  id: string
  idAppeal: string
  idCase: string
  idFile: string
  idFolder: string
  idTask: string
  userLoading: string
}

interface DataNodeI extends DataNode {
  pId: string | null | undefined
  id: string
  title: string | React.ReactNode
  children?: TreeDataNode[]
}
export interface FolderI extends FolderServiceDataI, DataNodeI {
  type: 'FOLDER'
}

export interface FileI extends FileServiceDataI, DataNodeI {
  type: 'FILE'
}

export type TreeDataNode = FolderI | FileI

export const folderDataNormalize = (
  data: FolderServiceDataI[] = []
): FolderI[] => {
  if (data.length === 0) return []
  return data.map((folder) => ({
    ...folder,
    type: 'FOLDER',
    key: folder.id,
    pId: folder.idParentFolder,
    title: folder.name,
    children: [],
    isLeaf: false,
  }))
}

export const fileDataNormalize = (data: FileServiceDataI[] = []): FileI[] => {
  if (data.length === 0) return []
  return data.map((file) => ({
    ...file,
    type: 'FILE',
    key: file.idFile,
    pId: file.idFolder,
    title: `${file.fileName}.${file.fileExt}`,
    isLeaf: true,
  }))
}

const findRelated = (
  id: string,
  data: TreeDataNode[],
  acc: TreeDataNode[] = []
): TreeDataNode[] => {
  const related = data.filter((el) => el.pId === id)
  if (related.length === 0) return acc
  const trees = related
    .map((el) => findRelated(el.id, data, [...acc, ...related]))
    .reduce((acc, el) => [...acc, ...el])
  return [...trees, ...acc]
}

export const findRelatedElements = (
  data: TreeDataNode[],
  id: string
): {
  related: TreeDataNode[]
  notRelated: TreeDataNode[]
} => {
  const flattData = uniq(data)
  const related: TreeDataNode[] = uniq(findRelated(id, flattData))

  const notRelated = flattData
    .filter((el) => !el.isLeaf)
    .filter((el) => el.id !== id)
    .filter((el) => !related.find((rel) => rel.id === el.id))

  return { related, notRelated }
}
