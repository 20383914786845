import React from 'react'
import { Flex, Text, Box, SxProps, Grid } from 'theme-ui'
import { FormikErrors, FormikValues } from 'formik'
import { Skeleton } from './skeleton'

export type TitledComponentT = {
  title: string
  error?:
    | string
    | string[]
    | FormikErrors<FormikValues>
    | FormikErrors<FormikValues>[]
  icon?: React.ReactNode
  required?: boolean
  sx?: SxProps
  gridArea?: string | null
  touched?: boolean
  loading?: boolean
}

export const TitledComponent = ({
  title,
  error,
  children,
  icon,
  required,
  sx,
  gridArea,
  touched = false,
  loading = false,
  ...restProps
}: TitledComponentT & { children: React.ReactChild }): React.ReactElement => {
  const errorStyle =
    error && touched
      ? {
          boxShadow: '0px 0px 2px 1px rgba(255,79,79,0.60)',
        }
      : {}

  return (
    <Grid
      gap="4px"
      sx={{
        width: '100%',
        gridTemplateRows: 'max-content max-content 1fr',
        ...(gridArea ? { gridArea } : {}),
        ...sx,
      }}
      {...restProps}
    >
      <Flex sx={{ justifyContent: 'space-between', marginBottom: '8px' }}>
        <Flex>
          <Text sx={{ color: 'grey', fontSize: '12px' }}>{title}</Text>
          {required && (
            <Text
              sx={{
                marginLeft: '4px',
                fontSize: '12px',
                color: 'error',
                fontWeight: 'bold',
              }}
            >
              *
            </Text>
          )}
        </Flex>
        {icon && <Box sx={{ lineHeight: '1', cursor: 'help' }}>{icon}</Box>}
      </Flex>
      {loading ? (
        <Skeleton
          sx={{
            height: '100%',
            width: '100%',
            borderRadius: '4px',
          }}
        >
          {children}
        </Skeleton>
      ) : (
        <Box
          sx={{
            borderRadius: '4px',
            ...errorStyle,
          }}
        >
          {children}
        </Box>
      )}
      <Flex sx={{ minHeight: '1rem', position: 'relative' }}>
        {error && touched && (
          <Text
            as="p"
            variant="error"
            sx={{ maxWidth: 'fit-content', position: 'absolute' }}
          >
            {error}
          </Text>
        )}
      </Flex>
    </Grid>
  )
}
