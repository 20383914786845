import React from 'react'
import { Flex, SxProps, Box } from 'theme-ui'

type RowT = {
  children: React.ReactChild | React.ReactNodeArray
  col?: number
  sx?: SxProps
  proportions?: number[]
}

export const Row = ({
  children,
  col,
  sx,
  proportions,
}: RowT): React.ReactElement => {
  const count = col || React.Children.count(children)

  const proportionsCheck =
    proportions && proportions.reduce((acc, val) => acc + val, 0) === col

  const getWidth = (proportion: number): string => {
    return `${(100 / count) * proportion}%`
  }

  return (
    <Flex
      sx={{
        alignItems: 'stretch',
        height: '100%',
        width: '100%',
        marginBottom: '6px',
        ...sx,
      }}
    >
      {React.Children.map(children, (child, index) => {
        const proportion =
          proportions && proportionsCheck ? proportions[index] || 1 : 1
        return (
          <Box
            sx={{
              width: getWidth(proportion),
              marginLeft: index === 0 ? 0 : '15px',
            }}
          >
            {child}
          </Box>
        )
      })}
    </Flex>
  )
}
