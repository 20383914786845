const READ = 'READ' as const
const UNREAD = 'UNREAD' as const
export const status = { READ, UNREAD }

type UNREADT = typeof UNREAD
type READT = typeof READ
export type StatusT = UNREADT | READT

export interface SearchParamsI {
  status: UNREADT | READT
  page: number
  size: number
}

export interface NotificationsI {
  id: string
  note: string
  status: READT | UNREADT
  idRequest: string
}

interface NotificationResponseI {
  content: {
    id: string
    creationDate: string
    subscription: {
      id: string
      dateCreation: string
      subject: {
        id: string
        dateCreation: string
        topic: string
        subscriptionType: 'WS'
        eventType: 'ALL'
      }
      subscriber: {
        id: string
        dateCreation: string
        login: string
      }
    }
    event: {
      id: string
      dateCreation: string
      type: string
      headers: null
      payload: string // информация уведомления
    }
    dateOfReading: null
    status: StatusT
    type: string
  }[]
  pageable: {
    sort: {
      sorted: true
      unsorted: false
      empty: false
    }
    pageSize: 10
    pageNumber: 0
    offset: 0
    unpaged: false
    paged: true
  }
  last: false
  totalPages: 32
  totalElements: 314
  first: true
  sort: {
    sorted: true
    unsorted: false
    empty: false
  }
  numberOfElements: 10
  size: 10
  number: 0
  empty: false
}

type PayloadT = {
  note: string
  type: string
  idRequest: string
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export interface NotificationI {
  status: StatusT
  notifications: NotificationsI[]
  totalPages: number
  totalElements: number
}

export const NotificationNormalizer = (
  responseData: NotificationResponseI | null | undefined,
  status: StatusT,
  requestId?: string
): NotificationI | null => {
  if (responseData) {
    const normalized = responseData.content.map((el) => {
      const payload: PayloadT = JSON.parse(el.event.payload)
      if (requestId && requestId !== payload.idRequest) return null
      return {
        id: el.id,
        status: el.status,
        note: payload.note,
        idRequest: payload.idRequest,
      }
    })

    return {
      status,
      notifications: normalized.filter(notEmpty),
      totalPages: responseData.totalPages,
      totalElements: responseData.totalElements,
    }
  }
  return null
}
