import React from 'react'
import { TreeDataNode } from '@lk-stu3/utils'
import { Flex, Button } from 'theme-ui'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { useDispatch } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { mixed, object } from 'yup'
import { SelectFormik } from '@lk-stu3/components'

type InitialValuesT = {
  idFolder: {
    label: string
    value: string
  } | null
}

const initialValues: InitialValuesT = {
  idFolder: null,
}

const shema = object().shape({
  idFolder: mixed().required('выберите папку'),
})

export const ReplaceDocuments = ({
  props,
  onClose,
}: {
  props: {
    node: TreeDataNode
    related: TreeDataNode[]
    notRelated: TreeDataNode[]
  }
  onClose: () => void
}): React.ReactElement => {
  const { node, related, notRelated } = props
  const dispatch = useDispatch()
  const files = related.filter((el) => el.isLeaf)
  const folders = related.filter((el) => !el.isLeaf)

  const onSubmit = (
    values: InitialValuesT,
    actions: FormikHelpers<InitialValuesT>
  ): void => {
    const { idFolder } = values
    // перемещаем все папки
    for (let i = 0; i < folders.length; i += 1) {
      const currentFolder = folders[i]
      dispatch(
        WsStuFileAndFolder.actions.folderEdit({
          global: currentFolder.global || false,
          name: currentFolder.name || '',
          idAppeal: currentFolder.idAppeal || '',
          id: currentFolder.id,
          idParentFolder: idFolder?.value || null,
        })
      )
    }
    // перемещаем все файлы
    for (let i = 0; i < files.length; i += 1) {
      const currentFile = files[i]
      dispatch(
        WsStuFileAndFolder.actions.fileEdit({
          idFolder: idFolder?.value || '',
          dateLoading: currentFile.dateLoading || '',
          id: currentFile.id,
          idAppeal: currentFile.idAppeal || '',
          idFile: currentFile.idFile || '',
          userLoading: currentFile.userLoading || '',
        })
      )
    }
    // удаляем корневую папку
    dispatch(
      WsStuFileAndFolder.actions.folderDelete(node.id, node.idParentFolder)
    )
    actions.setSubmitting(false)
    onClose()
  }

  const idFolderOptions = notRelated.map((el) => ({
    value: el.id,
    label: `${el.title}`,
  }))

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={shema}
    >
      {({ handleSubmit }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Flex sx={{ flexDirection: 'column' }}>
            <SelectFormik
              name="idFolder"
              title="Папки доступные для перемещения"
              options={idFolderOptions}
            />
            <Flex
              sx={{
                marginTop: '32px',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button type="submit" variant="primary" mr={3}>
                Переместить
              </Button>
              <Button
                type="submit"
                variant="secondary"
                sx={{ width: '120px' }}
                onClick={(e): void => {
                  e.preventDefault()
                  onClose()
                }}
              >
                Отмена
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
