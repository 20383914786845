import { createAction } from '@reduxjs/toolkit'
import { createNamespace } from './config'

/*
 *  AUTHENTICATION http://stu3-dev.ursip.local/ws-auth/swagger-ui.html#/
 *  nginx proxy method
 */

const controller = 'ws-auth'
const api = `/${controller}/v1`
const namespace = createNamespace(controller)

const actions = {
  login: createAction(`${namespace}_login`, (formData) => ({
    payload: {
      request: {
        url: `${api}/login`,
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      public: true,
    },
  })),
  getUser: createAction(`${namespace}_getUser`, () => ({
    payload: {
      request: {
        url: `${api}/authorized/getUser`,
      },
    },
  })),
  refresh: createAction(`${namespace}_refresh`, (refreshToken) => ({
    payload: {
      request: {
        url: `${api}/token/refresh`,
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${refreshToken}`,
        },
      },
      public: true,
    },
  })),
}

export const AuthService = { namespace, actions }
