export const validate = (values) => {
  const errors = {}

  if (!values?.requestType) {
    errors.requestType = 'Укажите тип запроса'
  }

  if (!values?.description) {
    errors.description = 'Введите описание'
  }

  return errors
}
