import Menu, { MenuItem } from 'rc-menu'
import { Icon } from '@lk-stu3/components'
import { Dropdown, Tooltip } from '@ursip/components'
import { Button } from 'theme-ui'
import { Flex } from '@theme-ui/components'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutsideClick } from 'rooks'

import { openModal } from '../../modals/module/actions'
import { currentTaskSelector } from './module/selectors'

export const ButtonActions = () => {
  const dispatch = useDispatch()
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const task = useSelector(currentTaskSelector)

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  useOutsideClick(ref, handleOutsideClick)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleCreate = useCallback(() => {
    dispatch(openModal('tech-support-request'))
  }, [dispatch])

  const handleDelete = useCallback(() => {
    dispatch(openModal('delete-hd-request'))
  }, [dispatch])

  const menu = (
    <Menu>
      <MenuItem onClick={handleCreate}>Создать новый запрос</MenuItem>
      {!task?.dateAssignmentExecutor && (
        <MenuItem onClick={handleDelete}>Удалить</MenuItem>
      )}
    </Menu>
  )

  return (
    <Dropdown visible={isOpen} trigger="click" overlay={menu}>
      <Flex
        ref={ref}
        sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}
      >
        <Tooltip html="Дополнительные действия">
          <Button
            variant="secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '6px 10px',
              height: '34px',
            }}
            onClick={handleOpen}
          >
            <Icon
              name="more_horiz"
              sx={{ fontSize: '13px', lineHeight: '0' }}
            />
          </Button>
        </Tooltip>
      </Flex>
    </Dropdown>
  )
}
