import * as types from './types'
import { ActionTypes } from './actions.types'

export interface InitialStateI {
  modalIsOpen: boolean
  modalType: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalProps: any
}

export const initialState: InitialStateI = {
  modalIsOpen: false,
  modalType: null,
  modalProps: null,
}

export const reducer = (
  state: InitialStateI = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case types.OPEN_MODAL: {
      return {
        ...state,
        modalIsOpen: true,
        ...action.payload,
      }
    }
    case types.CLOSE_MODAL_FORM: {
      return {
        ...state,
        modalIsOpen: false,
      }
    }
    case types.CLEAR_MODAL_DATA: {
      return {
        ...state,
        modalType: initialState.modalType,
        modalProps: null,
      }
    }
    default:
      return state
  }
}
