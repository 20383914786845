/* eslint-disable redux-saga/no-unhandled-errors */
import { createAction } from '@reduxjs/toolkit'
import { TreeDataNode } from '@lk-stu3/utils'
import { createNamespace } from '../config'

/*
 *  MAIN STU3 http://stu3-dev.ursip.local/ws-stu/swagger-ui.html#/
 *  nginx proxy method
 */

const controller = 'ws-stu'
const api = `/${controller}/v1`
const namespace = createNamespace(controller)

const FOLDER_SEARCH = 'WS_STU_SERVICE-FOLDER_SEARCH' as const
const FOLDER_CREATE = 'WS_STU_SERVICE-FOLDER_CREATE' as const
const FILE_SEARCH = 'WS_STU_SERVICE-FILE_SEARCH' as const

interface FolderSearchI {
  global: boolean
  id?: string
  idAppeal: string
  idCase?: string
  idParentFolder?: string
  name?: string
}

interface FileSearchI {
  dateLoading?: string
  id?: string
  idAppeal: string
  idCase?: string
  idFile?: string
  idFolder?: string
  idTask?: string
  userLoading?: string
}

interface FolderCreateT {
  global: boolean
  idAppeal: string
  idParentFolder: string | null
  name: string
  idCase?: string
}

interface FolderEditT extends FolderCreateT {
  id?: string
}

interface FileEditI {
  dateLoading: string
  deleted?: string
  id: string
  idAppeal: string
  idCase?: string
  idFile: string
  idFolder: string
  idTask?: string
  userLoading: string
}

const actions = {
  // папки
  folderSearch: createAction(FOLDER_SEARCH, (payload: FolderSearchI) => ({
    payload: {
      request: {
        url: `${api}/folder/search`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
  folderCreate: createAction(FOLDER_CREATE, (payload: FolderCreateT) => ({
    payload: {
      request: {
        url: `${api}/folder`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
    meta: {
      id: payload.idParentFolder,
    },
  })),
  folderEdit: createAction('folder-edit', (payload: FolderEditT) => ({
    payload: {
      request: {
        url: `${api}/folder`,
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
    meta: {
      id: payload.idParentFolder,
      deleteId: payload.id,
    },
  })),
  folderDelete: createAction('folder-delete', (id, idParentFolder) => ({
    payload: {
      request: {
        url: `${api}/folder/${id}`,
        method: 'DELETE',
        responseType: null,
      },
    },
    meta: {
      id: idParentFolder,
      deleteId: id,
    },
  })),
  // файлы
  fileSearch: createAction(FILE_SEARCH, (payload: FileSearchI) => ({
    payload: {
      request: {
        url: `${api}/source-files/simple-search`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
  fileUpload: createAction('file-upload', (formData: FormData, idFolder) => ({
    payload: {
      request: {
        url: `${api}/source-files`,
        method: 'POST',
        body: formData,
      },
    },
    meta: {
      idFolder,
    },
  })),
  fileEdit: createAction('file-edit', (node: FileEditI) => ({
    payload: {
      request: {
        url: `${api}/source-files`,
        method: 'PUT',
        body: JSON.stringify(node),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
    meta: {
      idFolder: node.idFolder,
      deleteId: node.id,
    },
  })),
  fileDelete: createAction('file-delete', (node: TreeDataNode) => ({
    payload: {
      request: {
        url: `${api}/source-files/${node.id}`,
        method: 'DELETE',
        responseType: null,
      },
    },
    meta: {
      idFolder: node.pId,
      deleteId: node.id,
    },
  })),
}

const devServer = 'http://stu3-dev-lk.ursip.local'
const baseURL = process.env.NODE_ENV === 'development' ? devServer : ''

const apiFileLibrary = `${baseURL}/ws-filelibrary/v1`

const getFileUrl = (id: string, isRead = false): string =>
  `${apiFileLibrary}/file/${id}?isRead=${isRead}`

export const WsStuFileAndFolder = {
  namespace,
  actions,
  getFileUrl,
}
