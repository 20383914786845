import React from 'react'
import { TreeDataNode } from '@lk-stu3/utils'
import { Flex, Button, Text } from 'theme-ui'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { useDispatch } from 'react-redux'

export const DeleteFile = ({
  props: fileNode,
  onClose,
}: {
  props: TreeDataNode
  onClose: () => void
}): React.ReactElement => {
  const dispatch = useDispatch()

  const deleteFile = (): void => {
    dispatch(WsStuFileAndFolder.actions.fileDelete(fileNode))

    onClose()
  }

  return (
    <Flex sx={{ flexDirection: 'column', maxWidth: 'calc(100vw - 20%)' }}>
      <Text>
        Вы действительно хотите удалить файл:
        <Text as="span" sx={{ fontStyle: 'italic', marginX: '8px' }}>
          {fileNode.fileName} ?
        </Text>
      </Text>
      <Flex
        sx={{
          marginTop: '32px',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="primary"
          sx={{ backgroundColor: 'danger' }}
          mr={3}
          onClick={deleteFile}
        >
          Удалить
        </Button>
        <Button
          variant="secondary"
          sx={{ width: '120px' }}
          onClick={(e): void => {
            e.preventDefault()
            onClose()
          }}
        >
          Отмена
        </Button>
      </Flex>
    </Flex>
  )
}
