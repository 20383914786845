import { createAction } from '@reduxjs/toolkit'

const controller = 'ws-stu'
const api = `/${controller}/v1`

const actions = {
  getStatusRecords: createAction('get-status-records', () => ({
    payload: {
      request: {
        url: `${api}/status-record-lk/condition-status`,
        method: 'GET',
      },
    },
  })),
}

export const WsStuStatusRecord = {
  actions,
}
