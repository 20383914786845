import React, { Fragment, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Select,
  TitledComponent,
  SingleDatePicker,
  ErrorCard,
  Textarea,
} from '@lk-stu3/components'
import { Input } from '@ursip/components'

import { Text } from 'theme-ui'
import { Tooltip } from 'react-tippy'
import { InfoWrapper } from './info-wrapper'
import { appealInfoS, appealLoadingS, appealErrorS } from './module/selectors'

const getSelectStyles = (isExpired: boolean | undefined) => {
  return {
    singleValue: (styles: {}) => ({
      ...styles,
      color: isExpired ? 'red' : undefined,
    }),
  }
}

export const Info = (): React.ReactElement => {
  const disabled = true
  const appealInfo = useSelector(appealInfoS)
  const appealLoading = useSelector(appealLoadingS)
  const appealError = useSelector(appealErrorS)

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)

  const handleMouseEnter = useCallback(() => {
    if (appealInfo?.idStatusName) {
      setIsTooltipOpen(true)
    }
  }, [appealInfo])

  const handleMouseLeave = useCallback(() => {
    setIsTooltipOpen(false)
  }, [appealInfo])

  if (appealError) {
    return <ErrorCard message="Произошла ошибка при загрузке заявки" />
  }

  return (
    <InfoWrapper>
      <TitledComponent
        title="Номер заявки"
        sx={{ gridArea: 'appealNumber' }}
        loading={appealLoading}
      >
        <Input value={appealInfo?.appealNumber} disabled={disabled} />
      </TitledComponent>
      <Tooltip
        theme="light"
        interactive
        arrow
        position="bottom-start"
        open={isTooltipOpen}
        html={
          <Text sx={{ color: appealInfo?.idStatusExpired ? 'red' : undefined }}>
            {appealInfo?.idStatusName}
          </Text>
        }
      >
        <TitledComponent
          title="Статус заявки"
          sx={{ gridArea: 'idStatus' }}
          loading={appealLoading}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Select
            defaultValue={appealInfo?.idStatus}
            isDisabled={disabled}
            styles={getSelectStyles(appealInfo?.idStatusExpired)}
          />
        </TitledComponent>
      </Tooltip>

      <TitledComponent
        title="Наименование объекта"
        sx={{ gridArea: 'objectName' }}
        loading={appealLoading}
      >
        <Input value={appealInfo?.objectName} disabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Тип объекта"
        sx={{ gridArea: 'idObjectType' }}
        loading={appealLoading}
      >
        <Select defaultValue={appealInfo?.idObjectType} isDisabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Вид услуги"
        sx={{ gridArea: 'idServiceType' }}
        loading={appealLoading}
      >
        <Select
          defaultValue={appealInfo?.idServiceType}
          isDisabled={disabled}
        />
      </TitledComponent>
      <TitledComponent
        title="Дата регистрации"
        sx={{ gridArea: 'dateRegistration' }}
        loading={appealLoading}
      >
        <SingleDatePicker value={appealInfo?.dateRegistration} disabled />
      </TitledComponent>
      <TitledComponent
        title="Номер договора, дата"
        sx={{ gridArea: 'contractNumber' }}
        loading={appealLoading}
      >
        <Input value={appealInfo?.contractNumber} disabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Адресный ориентир"
        sx={{ gridArea: 'landmark' }}
        loading={appealLoading}
      >
        <Input value={appealInfo?.landmark} disabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Регион"
        sx={{ gridArea: 'idRegion' }}
        loading={appealLoading}
      >
        <Select defaultValue={appealInfo?.idRegion} isDisabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Город"
        sx={{ gridArea: 'idCity' }}
        loading={appealLoading}
      >
        <Select defaultValue={appealInfo?.idCity} isDisabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Округ"
        sx={{ gridArea: 'idPrefect' }}
        loading={appealLoading}
      >
        <Select defaultValue={appealInfo?.idPrefect} isDisabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Район"
        sx={{ gridArea: 'idDistrict' }}
        loading={appealLoading}
      >
        <Select defaultValue={appealInfo?.idDistrict} isDisabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Улица"
        sx={{ gridArea: 'idStreet' }}
        loading={appealLoading}
      >
        <Select defaultValue={appealInfo?.idStreet} isDisabled={disabled} />
      </TitledComponent>
      <TitledComponent
        title="Дом / строение"
        sx={{ gridArea: 'building' }}
        loading={appealLoading}
      >
        <Input value={appealInfo?.building} disabled={disabled} />
      </TitledComponent>
      {appealInfo?.reasonRefusal && (
        <TitledComponent
          title="Причина отказа"
          sx={{ gridArea: 'reasonRefusal' }}
          loading={appealLoading}
        >
          <Textarea
            disabled
            value={appealInfo?.reasonRefusal}
            sx={{ color: '#9D9D9D', fontSize: '14px' }}
          />
        </TitledComponent>
      )}
      {!!appealInfo?.links?.length && (
        <>
          {appealInfo?.links.map((l, i) => (
            <Fragment key={`${l.link}-${i}`}>
              <TitledComponent
                title="Ссылка"
                sx={{ gridArea: `link${i}` }}
                loading={appealLoading}
              >
                <Input value={l.link} disabled={disabled} />
              </TitledComponent>
              <TitledComponent
                title="Комментарий к ссылке"
                sx={{ gridArea: `comment${i}` }}
                loading={appealLoading}
              >
                <Textarea
                  value={l.comment}
                  disabled
                  sx={{ color: '#9D9D9D', fontSize: '14px' }}
                />
              </TitledComponent>
            </Fragment>
          ))}
        </>
      )}
    </InfoWrapper>
  )
}
