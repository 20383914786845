import React from 'react'
import styled from '@emotion/styled'
import { ResizableBox as libResizable, ResizableProps } from 'react-resizable'
import { Box, Flex } from 'theme-ui'
import { colors } from './theme'

const StyledResizeble = styled(libResizable)<ResizableProps>`
  & .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    top: 0;
    cursor: col-resize;
  }
  & .react-resizable-handle {
    background-image: none;
    padding: 0;
    width: 10px;
    height: 100%;
    border-right: 1px solid ${colors.border};
  }
`

export const Resizable = ({
  children,
  width,
  height,
}: {
  children: React.ReactNode
  width: number
  height: number
}): React.ReactElement => {
  const [containers, setContainers] = React.useState<number>(width / 2)
  if (React.Children.count(children) > 2)
    throw Error('в компоненте больше двух потомков')
  return (
    <Flex sx={{ width: `${width}px`, height: `${height}px` }}>
      {children
        ? React.Children.map(children, (child, index) => {
            if (index === 0) {
              return (
                <StyledResizeble
                  width={width / 2}
                  height={height}
                  axis="x"
                  onResize={(e, { size }): void => setContainers(size.width)}
                  minConstraints={[300, height]}
                  maxConstraints={[width / 2, height]}
                >
                  {child}
                </StyledResizeble>
              )
            }
            return (
              <Box
                sx={{
                  width: `${width - containers}px`,
                  padding: '16px',
                }}
              >
                {child}
              </Box>
            )
          })
        : null}
    </Flex>
  )
}
