import { createStore, IModuleStore } from 'redux-dynamic-modules-core'
import { getSagaExtension, ISagaModule } from 'redux-dynamic-modules-saga'
import {
  routerMiddleware,
  connectRouter,
  RouterState,
  LocationChangeAction,
} from 'connected-react-router'
import { createBrowserHistory, LocationState } from 'history'
import { handleRequests } from '@redux-requests/core'
import { createDriver } from '@redux-requests/fetch'
import { Reducer } from 'redux'

import { onRequest, onError } from './interceptors'

export const history = createBrowserHistory()

const devServer = 'http://stu3-dev-lk.ursip.local'

const baseURL = process.env.NODE_ENV === 'development' ? devServer : ''

const { requestsMiddleware } = handleRequests({
  driver: createDriver(window.fetch, {
    baseURL,
    AbortController: window.AbortController,
  }),
  onRequest,
  onError,
})

declare type InitialModule = ISagaModule<
  Reducer<RouterState<LocationState>, LocationChangeAction<LocationState>>
>

export const initialModule: InitialModule = {
  id: 'initial',
  reducerMap: {
    router: connectRouter(history),
  },
  middlewares: [routerMiddleware(history), ...requestsMiddleware],
}

export const store: IModuleStore<{}> = createStore(
  {
    extensions: [getSagaExtension()],
  },
  initialModule
)

export type AppDispatchT = typeof store.dispatch
