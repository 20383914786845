import { mapValues } from 'lodash'
import moment from 'moment'
import { Option } from '@lk-stu3/dashboard/src/modals/new-appeal/models'

type SelectProp = {
  label: string
  value: string | boolean | number
}

interface AppealT {
  objectName: string
  landmark: string
  building: string
  idServiceType: SelectProp | null
  idObjectType: SelectProp | null
  idRegion: SelectProp | null
  idCity: SelectProp | null
  idPrefect: SelectProp | null
  idDistrict: SelectProp | null
  idStreet: SelectProp | null
  idSourceReceipt: string
  idStatusAppeal: string
}

interface PropertiesT {
  userLoading: string
  idFolder: string
}

interface AppealPhysicT extends AppealT {
  idClientPhysic: string
}

interface AppealOrgT extends AppealT {
  idClientOrg: string
}

interface NormalizedAppealPhysicT
  extends Record<keyof AppealPhysicT, string | null> {
  dateRegistration: string | null
  fromLk: boolean
  registrator: {
    executorType: string
    idExecutor: string
  }
}
interface NormalizedAppealOrgT extends Record<keyof AppealOrgT, string | null> {
  registrator: {
    executorType: string
    idExecutor: string
  }
}

interface NormalizedPropertiesT extends PropertiesT {
  dateLoading: string
}

const requestPOSTnormalize = (
  appealFormData: AppealPhysicT | AppealOrgT,
  fileList: FileList | null,
  propertiesData: PropertiesT,
  links: Option[]
): FormData => {
  const appealValues = mapValues(
    appealFormData,
    (value: string | { value: string } | null) => {
      if (typeof value === 'string') return value
      if (value?.value) return `${value.value}`
      return null
    }
  )
  const properties: NormalizedPropertiesT = {
    ...propertiesData,
    dateLoading: new Date().toISOString().slice(0, -1),
  }
  const appeal: NormalizedAppealPhysicT | NormalizedAppealOrgT = {
    ...appealValues,
    dateRegistration: moment().toISOString(true).split('+')[0],
    fromLk: true,
    registrator: {
      executorType: 'EMPLOYEE',
      idExecutor: propertiesData.userLoading,
    },
  }

  const formData = new FormData()
  formData.append('appeal', JSON.stringify(appeal))
  formData.append('properties', JSON.stringify(properties))
  if (fileList !== null) {
    for (let i = 0; i < fileList.length; i += 1) {
      formData.append('files', fileList[i])
    }
  }
  if (links.length) {
    formData.append('links', JSON.stringify(links))
  }

  return formData
}

export const dashboardUtils = { requestPOSTnormalize }

export enum TableType {
  RequestTableSettings = 'RequestTableSettings',
  HelpdeskTableSetting = 'helpdeskTableSettings',
}

export const getTableType = new Map<string, TableType>([
  ['RequestTableSettings', TableType.RequestTableSettings],
  ['helpdeskTableSettings', TableType.HelpdeskTableSetting],
])
