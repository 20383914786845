import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Button, Flex } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { WsNotificationsItemService } from '@lk-stu3/services'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { TabsNotification } from './tabs'
import { Inbox } from './inbox'
import { Outbox } from './outbox'
import { moduleNotifications } from './module'
import * as selectors from './module/selectors'
import { appealIdS } from '../module/selectors'
import {
  setLoadingAC,
  uncheckAllInboxAC,
  uncheckAllOutboxAC,
} from './module/actions'

export const Notification = ({ match }): React.ReactElement => {
  const dispatch = useDispatch()
  const {
    location: { pathname },
  } = useHistory()

  const user = useSelector(getAuthUserInfo)
  const appealId = useSelector(appealIdS)
  const { inbox, outbox, internal } = useSelector(
    selectors.checkedElementsSelector
  )

  const { url } = match

  const tab = useMemo(() => pathname.split('/')[5], [pathname])

  const checked = useMemo(() => {
    switch (tab) {
      case 'inbox':
        return inbox
      case 'outbox':
        return outbox

      default:
        return {}
    }
  }, [tab, inbox, outbox])

  const checkedIds = useMemo(
    () => Object.keys(checked).filter((id) => !!checked[id]),
    [checked]
  )

  const hasChecked = !!checkedIds.length

  useEffect(() => {
    if (appealId) {
      dispatch(
        setLoadingAC({
          inbox: true,
          outbox: true,
        })
      )

      Promise.all([
        dispatch(
          WsNotificationsItemService.actions.searchNotificationsInbox({
            status: 'ALL',
            subscriptionType: 'ALL',
            authId: user?.id,
            payloadPattern: appealId,
            page: 0,
            size: 100,
          })
        ),
      ]).finally(() => {
        dispatch(setLoadingAC({ inbox: false }))
      })

      Promise.all([
        dispatch(
          WsNotificationsItemService.actions.searchNotificationsOutbox({
            status: 'ALL',
            subscriptionType: 'ALL',
            payloadPattern: appealId,
            page: 0,
            size: 500,
          })
        ),
      ]).finally(() => {
        dispatch(setLoadingAC({ outbox: false }))
      })
    }
  }, [user, appealId, dispatch])

  const handleMarkRead = useCallback(async () => {
    const apiRequests = checkedIds.map((id) =>
      dispatch(WsNotificationsItemService.actions.markReadNotification(id))
    )

    await Promise.all(apiRequests).then(() => {
      dispatch(uncheckAllInboxAC())
    })

    dispatch(setLoadingAC({ inbox: true }))

    Promise.all([
      dispatch(
        WsNotificationsItemService.actions.searchNotificationsInbox({
          status: 'ALL',
          subscriptionType: 'ALL',
          authId: user?.userId,
          payloadPattern: appealId,
          page: 0,
          size: 100,
        })
      ),
    ]).finally(() => {
      dispatch(setLoadingAC({ inbox: false }))
    })
  }, [checkedIds, dispatch, user, appealId])

  const handleResend = useCallback(async () => {
    // Задача #76, временно отключаю метод
    return undefined

    const apiRequests = checkedIds.map((id) =>
      dispatch(WsNotificationsItemService.actions.resendNotification(id))
    )

    await Promise.all(apiRequests).then(() => {
      dispatch(uncheckAllOutboxAC())
    })

    dispatch(setLoadingAC({ outbox: true }))

    Promise.all([
      dispatch(
        WsNotificationsItemService.actions.searchNotificationsOutbox({
          status: 'ALL',
          subscriptionType: 'ALL',
          payloadPattern: appealId,
          page: 0,
          size: 500,
        })
      ),
    ]).finally(() => {
      dispatch(setLoadingAC({ outbox: false }))
    })
  }, [checkedIds, dispatch, appealId])

  return (
    <DynamicModuleLoader modules={[moduleNotifications]}>
      <Flex
        style={{
          flexDirection: 'column',
          margin: '16px',
          height: `100%`,
          border: '1px solid #F4F4F4',
        }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Flex>
            <TabsNotification match={match} />
          </Flex>
          <Flex>
            {tab === 'inbox' && (
              <Button
                variant={!hasChecked ? 'secondary' : 'primary'}
                disabled={!hasChecked}
                onClick={handleMarkRead}
                type="button"
              >
                Прочитано
              </Button>
            )}
          </Flex>
        </Flex>

        <Flex sx={{ marginTop: '10px', height: '100%' }}>
          <Switch>
            <Route path={`${url}/inbox`} component={Inbox} />
            <Route exact path={`${url}/outbox`} component={Outbox} />
            <Redirect from={url} to={`${url}/inbox`} />
          </Switch>
        </Flex>
      </Flex>
    </DynamicModuleLoader>
  )
}
