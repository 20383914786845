import React from 'react'
import { Button, Flex, Grid, Text } from 'theme-ui'
import { mapValues } from 'lodash'
import { useDispatch } from 'react-redux'
import { object, string } from 'yup'
import { InputFormik } from '@lk-stu3/components'
import { Formik, FormikHelpers } from 'formik'
import { WsStuNsiService } from '@lk-stu3/services'
import type { OrgT } from './module/reducers'

type InitialValuesT = {
  fullName: string
  shortName: string
  phone: string
  email: string
  website: string
  addressOrg: string
  addressActual: string
  inn: string
  kpp: string
  ogrn: string
  typeOrgEntity: string
  lastName: string
  firstName: string
  middleName: string
  phonerepresentatives: string
  emailrepresentatives: string
}
const initialValues = (initialData: OrgT | undefined): InitialValuesT => {
  const defaultParams: InitialValuesT = {
    typeOrgEntity: 'CLIENT',
    fullName: '',
    shortName: '',
    phone: '',
    email: '',
    website: '',
    addressOrg: '',
    addressActual: '',
    inn: '',
    kpp: '',
    ogrn: '',
    lastName: '',
    firstName: '',
    middleName: '',
    phonerepresentatives: '',
    emailrepresentatives: '',
  }
  if (initialData) {
    defaultParams.addressActual = initialData.addressActual
    defaultParams.addressOrg = initialData.addressOrg
    defaultParams.email = initialData.email
    defaultParams.fullName = initialData.fullName
    defaultParams.inn = initialData.inn
    defaultParams.kpp = initialData.kpp
    defaultParams.ogrn = initialData.ogrn
    defaultParams.phone = initialData.phone
    defaultParams.shortName = initialData.shortName
    defaultParams.website = initialData.website
  }

  return defaultParams
}

type RepresentativesT = {
  comment?: string | null // stu.physical_persons.comment
  dateBirth?: string | null // stu.physical_persons.date_birth
  email: string | null // stu.physical_persons.email
  firstName: string | null // stu.physical_persons.first_name Обязательный параметр
  lastName: string | null // stu.physical_persons.last_name Обязательный параметр
  middleName: string | null // stu.physical_persons.middle_name
  phone: string | null // stu.physical_persons.phone
}
type ReqOrgT = {
  addressActual: string | null // stu.org_entities.address_actual
  addressOrg: string | null // stu.org_entities.address_org
  comment: string | null // stu.org_entities.comment
  email: string | null // stu.org_entities.email
  fullName: string | null // stu.org_entities.full_name
  inn: string | null // stu.org_entities.inn Обязательный параметр
  kpp: string | null // stu.org_entities.kpp
  ogrn: string | null // stu.org_entities.ogrn
  phone: string | null // stu.org_entities.phone
  shortName: string | null // stu.org_entities.short_name Обязательный параметр
  typeOrgEntity: string | null // stu.org_entities.type_org_entity Обязательный параметр
  website: string | null // stu.org_entities.website
  representatives: RepresentativesT[]
}

export interface SubmitOrgValuesI extends Omit<ReqOrgT, ''> {
  type: 'ORG'
}

const normalizePhysReq = (formValues: InitialValuesT): ReqOrgT => {
  const values = mapValues(formValues, (value) => value || null)
  const {
    emailrepresentatives,
    firstName,
    lastName,
    middleName,
    phonerepresentatives,
    ...rest
  } = values
  const representativesObj = {
    email: emailrepresentatives,
    phone: phonerepresentatives,
    firstName,
    lastName,
    middleName,
    comment: null,
  }
  return { ...rest, comment: null, representatives: [representativesObj] }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shema = (representatives: boolean): any =>
  object().shape({
    shortName: string().required('введите краткое наименование'),
    inn: string()
      .required('введите ИНН')
      .test(
        'len',
        'введите корректный ИНН',
        (value) => !value || value.length === 10 || value.length === 12
      ),
    phone: string()
      .matches(
        // TODO: переписать регулярное выражение по правилам eslint
        // eslint-disable-next-line security/detect-unsafe-regex
        /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
        'введите корректный номер телефона'
      )
      .required('введите номер телефона'),
    email: string()
      .email('не корректный email адрес')
      .required('введите электронную почту'),
    ...(representatives
      ? {
          lastName: string().required('введите фамилию'),
          firstName: string().required('введите имя'),
          phonerepresentatives: string()
            .matches(
              // TODO: переписать регулярное выражение по правилам eslint
              // eslint-disable-next-line security/detect-unsafe-regex
              /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
              'введите корректный номер телефона'
            )
            .required('введите номер телефона'),
          emailrepresentatives: string()
            .email('не корректный email адрес')
            .required('введите электронную почту'),
        }
      : {}),
  })

export const OrgFormComponent = ({
  CustomButtons,
  CustomSubmit,
  toLogin,
  initialData,
}: {
  CustomButtons?: (props: {
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }) => React.ReactElement
  CustomSubmit?: (values: SubmitOrgValuesI) => void
  toLogin?: () => void
  initialData?: OrgT
}): React.ReactElement => {
  const dispatch = useDispatch()

  const getGuestToken = async (): Promise<null | string> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await dispatch({
      type: 'GUEST_TOKEN',
      payload: {
        request: {
          url: `/ws-guest/v1/guest-access`,
          method: 'GET',
        },
      },
      meta: {
        runOnRequest: false,
      },
    })
    if (!res.action.error) return res.action.payload.data.token
    return null
  }

  const onSubmit = async (
    values: InitialValuesT,
    actions: FormikHelpers<InitialValuesT>
  ): Promise<void> => {
    if (CustomSubmit) {
      CustomSubmit({ type: 'ORG', ...normalizePhysReq(values) })
    } else {
      const token = await getGuestToken()

      if (token) {
        dispatch(
          WsStuNsiService.actions.createOrg(token, normalizePhysReq(values))
        )
      }
    }
    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues(initialData)}
      onSubmit={onSubmit}
      validationSchema={shema(!CustomSubmit)}
    >
      {({ handleSubmit }): React.ReactElement => {
        return (
          <>
            <Grid
              sx={{
                overflowY: 'auto',
                marginBottom: '24px',
                rowGap: '0px',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridTemplateAreas: `"fullName fullName fullName"
                "shortName shortName shortName"
                "phone email website"
                "addressOrg addressOrg addressOrg"
                "addressActual addressActual addressActual"
                "inn kpp ogrn"
                "representatives representatives representatives"
                "lastName firstName middleName"
                "phonerepresentatives emailrepresentatives ."`,
              }}
            >
              <InputFormik
                title="Полное наименование"
                name="fullName"
                gridAreaName
              />
              <InputFormik
                title="Краткое наименование"
                required
                name="shortName"
                gridAreaName
              />
              <InputFormik
                title="Телефон"
                required
                name="phone"
                mask="+7 (999) 999-99-99"
                gridAreaName
              />
              <InputFormik
                title="Электронная почта"
                required
                name="email"
                gridAreaName
              />
              <InputFormik title="Сайт" name="website" gridAreaName />
              <InputFormik
                title="Юридический адрес"
                name="addressOrg"
                gridAreaName
              />
              <InputFormik
                title="Фактический адрес"
                name="addressActual"
                gridAreaName
              />
              <InputFormik title="ИНН" required name="inn" gridAreaName />
              <InputFormik title="КПП" name="kpp" gridAreaName />
              <InputFormik title="ОГРН" name="ogrn" gridAreaName />

              {!initialData && (
                <>
                  <Text
                    as="h3"
                    sx={{
                      gridArea: 'representatives',
                      fontWeight: 'bold',
                      marginBottom: '8px',
                    }}
                  >
                    Контактное лицо
                  </Text>
                  <InputFormik
                    title="Фамилия"
                    required
                    name="lastName"
                    gridAreaName
                  />
                  <InputFormik
                    title="Имя"
                    required
                    name="firstName"
                    gridAreaName
                  />
                  <InputFormik
                    title="Отчество"
                    name="middleName"
                    gridAreaName
                  />
                  <InputFormik
                    title="Телефон"
                    required
                    name="phonerepresentatives"
                    mask="+7 (999) 999-99-99"
                    gridAreaName
                  />
                  <InputFormik
                    title="Электронная почта"
                    required
                    name="emailrepresentatives"
                    gridAreaName
                  />
                </>
              )}
            </Grid>

            {CustomButtons ? (
              <CustomButtons handleSubmit={handleSubmit} />
            ) : (
              <Flex sx={{ justifyContent: 'space-between' }}>
                {toLogin && (
                  <Button
                    variant="secondary"
                    onClick={toLogin}
                    sx={{ width: '110px' }}
                  >
                    Назад
                  </Button>
                )}
                <Button
                  type="submit"
                  onClick={(): void => handleSubmit()}
                  sx={{ width: '110px' }}
                >
                  Отправить
                </Button>
              </Flex>
            )}
          </>
        )
      }}
    </Formik>
  )
}
