import * as R from 'ramda'

interface GetTokensI {
  readonly token: string | null
  readonly refreshToken: string | null
}

export const getTokens = (): GetTokensI => ({
  token: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
})

interface ErrorI {
  status: number
  data: {
    message: string
  }
}

export const checkIfUnauthorized = (error: ErrorI): boolean =>
  error &&
  (error.status === 401 ||
    (R.hasPath(['data', 'message'], error) &&
      (error.data.message.includes('401') ||
        error.data.message.includes(
          'Ошибка получения пользователя по токену'
        ))))

export const checkToken = (): boolean => {
  if (localStorage.getItem('token') !== null) {
    return true
  }
  return false
}

export const removeTokens = (): void => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
}

export const putTokens = ({ token, refreshToken }: GetTokensI): void => {
  if (token && refreshToken) {
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
  }
}
