import { ISagaModule } from 'redux-dynamic-modules-saga'

import { reducer, initialState, InitialStateI } from './reducers'

import { ActionTypes } from './actions'
import { sagas } from './sagas'

const moduleName = 'appeal-files'

export interface ModuleI {
  [moduleName: string]: InitialStateI
}

const moduleAppealFiles = (): ISagaModule<ModuleI> => {
  return {
    id: moduleName,
    reducerMap: {
      [moduleName]: (
        state: InitialStateI = initialState,
        action: ActionTypes
      ): InitialStateI => ({
        ...state,
        ...reducer(state, action),
      }),
    },
    sagas: [sagas],
  }
}

export { moduleName, moduleAppealFiles }
