/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable redux-saga/no-unhandled-errors */
import {
  all,
  call,
  put,
  select,
  takeEvery,
  fork,
  take,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { success, error } from '@redux-requests/core'
import { WsNotificationsService } from '@lk-stu3/services'
import { NotificationNormalizer, getTokens } from '@lk-stu3/utils'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { actions } from './actions'
import * as types from './types'
import { unreadSearchParamsS, readSearchParamsS } from './selectors'

// TODO: доработать получение уведомлений по сокетам

const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'

function initWebsocket() {
  const loc = window.location
  const newUri = `${loc.protocol === 'https:' ? 'wss:' : 'ws:'}//${loc.host}/`
  const socketBase =
    process.env.NODE_ENV === 'development'
      ? 'ws://stu3-dev-lk.ursip.local/'
      : newUri

  const wsUrl = `${socketBase}ws-notifications/ws`

  const { token } = getTokens()

  return eventChannel((emitter) => {
    const ws = new WebSocket(wsUrl)
    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: 'CONNECT',
          data: {
            token: `Bearer ${token}`,
          },
        })
      )
    }
    ws.onerror = (error) => {
      // eslint-disable-next-line no-console
      console.error(`WebSocket error ${error}`)
    }
    ws.onmessage = (e) => {
      let msg = null
      try {
        msg = JSON.parse(e.data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error parsing : ${e.data}`)
      }
      if (msg) {
        return emitter({
          type: UPDATE_NOTIFICATION,
        })
      }
      return null
    }
    // unsubscribe function
    return () => {
      // eslint-disable-next-line no-console
      console.log('Socket off')
    }
  })
}
function* wsSagas() {
  const channel = yield call(initWebsocket)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

/* ---------------------------- */

function* setNotificationsSaga({ payload, meta }) {
  const normalized = NotificationNormalizer(payload.data, meta.type)
  yield put(actions.setNotifications(normalized))
}

function* errorNotificationSaga({ meta }) {
  yield put(actions.setError(meta.type))
}

function* fetchNotifications({ params, activeTab: status }) {
  const userData = yield select(getAuthUserInfo)

  yield put(
    WsNotificationsService.actions.searchNotifications({
      ...params,
      status,
      authId: userData.id,
    })
  )
}

function* updateAllNotifications() {
  const unreadSearchParams = yield select(unreadSearchParamsS)
  const readSearchParams = yield select(readSearchParamsS)
  yield call(fetchNotifications, {
    params: { ...unreadSearchParams },
    activeTab: 'UNREAD',
  })
  yield call(fetchNotifications, {
    params: { ...readSearchParams },
    activeTab: 'READ',
  })
}

const {
  searchNotifications,
  markReadNotification,
} = WsNotificationsService.actions

export function* sagas() {
  yield all([
    fork(wsSagas),
    takeEvery(success(searchNotifications.toString()), setNotificationsSaga),
    takeEvery(error(searchNotifications.toString()), errorNotificationSaga),
    takeEvery(
      [success(markReadNotification.toString()), UPDATE_NOTIFICATION],
      updateAllNotifications
    ),
    takeEvery(types.SET_SEARCH_PARAMS, fetchNotifications),
  ])
}

// TODO: переписать SAGA по правилам eslint
