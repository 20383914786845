import { AppealInfoI, NormalizeAppealInfoStatusI } from '@lk-stu3/utils'
import * as types from './types'
import { ActionTypes } from './actions.types'

export interface InitialStateI {
  appealId: string | null
  requestId: string | null
  appealInfo: AppealInfoI | null
  appealStatusInfo: NormalizeAppealInfoStatusI[] | null
  loading: boolean
  error: boolean
}

export const initialState: InitialStateI = {
  appealId: null,
  requestId: null,
  appealInfo: null,
  appealStatusInfo: null,
  loading: false,
  error: false,
}

export const reducer = (
  state: InitialStateI = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case types.SET_REQUEST_ID: {
      return {
        ...state,
        requestId: action.payload || initialState.requestId,
      }
    }
    case types.SET_APPEAL_INFO: {
      if (action.payload) {
        const { requestId, appealId, ...appealInfo } = action.payload
        return {
          ...state,
          requestId: requestId || initialState.requestId,
          appealId: appealId || initialState.requestId,
          appealInfo: appealInfo || initialState.appealInfo,
          loading: false,
        }
      }
      return {
        ...initialState,
        error: true,
      }
    }
    case types.SET_APPEAL_STATUS_INFO: {
      if (action.payload) {
        const data = action.payload
        return {
          ...state,
          appealStatusInfo: data || initialState.appealStatusInfo,
        }
      }
      return {
        ...initialState,
        error: true,
      }
    }
    case types.START_APPEAL_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case types.APPEAL_LOADING_FAIL: {
      return {
        ...state,
        error: true,
      }
    }
    default:
      return state
  }
}
