import React from 'react'
import {
  Flex,
  Button,
  Grid,
  Text,
  Input as ThemeUIInput,
  InputProps,
} from 'theme-ui'
import {
  WsStuOrgEntities,
  WsStuPhysicalPersons,
  WsLkService,
} from '@lk-stu3/services'
import { PhysFormComponent, SubmitPhysValuesI } from '@lk-stu3/auth/src/phys'
import { OrgFormComponent, SubmitOrgValuesI } from '@lk-stu3/auth/src/org'
import type { ClientUserT, UserT } from '@lk-stu3/auth/src/module/reducers'
import { useDispatch } from 'react-redux'
import { ModalWrapper } from './modal-wrapper'

const Input = ({
  sx,
  ...props
}: React.PropsWithoutRef<InputProps>): React.ReactElement => {
  return (
    <ThemeUIInput
      sx={{
        padding: '4px',
        borderColor: 'border',
        backgroundColor: 'muted',
        '&:focus': {
          outlineColor: 'primary',
        },
        '&:disabled': {
          color: '#3a3a3a !important',
          backgroundColor: 'transparent !important',
          borderColor: 'transparent',
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export const Profile = ({
  onClose,
  userData,
  authUser,
}: {
  onClose: () => void
  userData: ClientUserT
  authUser: UserT
}): React.ReactElement => {
  const {
    nameLast = '',
    nameMiddle = '',
    nameFirst = '',
    phone = '',
    email = '',
    physic,
    org,
    login,
  } = userData
  const BIO = [nameLast, nameMiddle, nameFirst].join(' ')

  const initialState = {
    nameLast,
    nameMiddle,
    nameFirst,
    phone,
    email,
    login,
  }

  const initialPassState = {
    pass1: '',
    pass2: '',
  }

  const [disabled, setDisabled] = React.useState(true)
  const [value, setValue] = React.useState(initialState)
  const [isPasswordForm, setIsPasswordForm] = React.useState(false)
  const [password, setPassword] = React.useState(initialPassState)

  const viewPasswordForm = (): void => {
    setIsPasswordForm(true)
    setDisabled(true)
  }
  const viewProfileForm = (): void => setIsPasswordForm(false)

  const [editClient, setEditClient] = React.useState(false)

  const openEditClient = (): void => setEditClient(true)
  const closeEditClient = (): void => setEditClient(false)

  const formData = [
    {
      value: value.nameLast,
      title: 'Фамилия',
      onChange: (e: React.FormEvent<HTMLInputElement>): void =>
        setValue({ ...value, nameLast: e.currentTarget.value }),
      disabled,
    },
    {
      value: value.nameFirst,
      title: 'Имя',
      onChange: (e: React.FormEvent<HTMLInputElement>): void =>
        setValue({ ...value, nameFirst: e.currentTarget.value }),
      disabled,
    },
    {
      value: value.nameMiddle,
      title: 'Отчество',
      onChange: (e: React.FormEvent<HTMLInputElement>): void =>
        setValue({ ...value, nameMiddle: e.currentTarget.value }),
      disabled,
    },
    {
      value: value.phone,
      title: 'Телефон',
      onChange: (e: React.FormEvent<HTMLInputElement>): void =>
        setValue({ ...value, phone: e.currentTarget.value }),
      disabled,
    },
    {
      value: value.email,
      title: 'Электронный адрес',
      onChange: (e: React.FormEvent<HTMLInputElement>): void =>
        setValue({ ...value, email: e.currentTarget.value }),
      disabled,
    },
    {
      value: value.login,
      title: 'Логин',
      disabled: true,
    },
  ]

  const CustomButtons = ({
    handleSubmit,
  }: {
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }): React.ReactElement => (
    <Flex sx={{ justifyContent: 'space-between' }}>
      <Button
        variant="secondary"
        onClick={closeEditClient}
        sx={{ width: '110px' }}
      >
        Отмена
      </Button>
      <Button
        type="submit"
        title="Сохранить изменения"
        onClick={(): void => handleSubmit()}
        sx={{ width: '110px' }}
      >
        Сохранить
      </Button>
    </Flex>
  )

  const dispatch = useDispatch()

  const saveUser = (): void => {
    dispatch(
      WsLkService.actions.clientUserPUT({
        id: userData.id,
        idPhysic: userData.physic?.id || null,
        idOrg: userData.org?.id || null,
        idUser: userData.userId,
        user: {
          ...authUser,
          ...value,
          password: undefined,
        },
      })
    )
  }

  const [passErrorMessage, viewPassErrorMessage] = React.useState(false)

  const changePasswordUser = (): void => {
    if (password.pass1 === password.pass2) {
      dispatch(
        WsLkService.actions.clientUserPUT({
          id: userData.id,
          idPhysic: userData.physic?.id || null,
          idOrg: userData.org?.id || null,
          idUser: userData.userId,
          user: {
            ...authUser,
            ...initialState,
            password: password.pass1,
          },
        })
      )
      viewProfileForm()
    } else {
      viewPassErrorMessage(true)
    }
  }

  const changePass = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: 'pass1' | 'pass2'
  ): void => {
    viewPassErrorMessage(false)
    setPassword({ ...password, [key]: e.currentTarget.value })
  }

  const customSubmit = (values: SubmitPhysValuesI | SubmitOrgValuesI): void => {
    if (values.type === 'ORG' && org) {
      dispatch(
        WsStuOrgEntities.actions.orgEntitiesPUT({ id: org.id, ...values })
      )
    }
    if (values.type === 'PHYS' && physic) {
      dispatch(
        WsStuPhysicalPersons.actions.physicalPersonsPUT({
          id: physic.id,
          ...values,
        })
      )
    }
    closeEditClient()
  }

  return (
    <Flex sx={{ flexDirection: 'column', width: '600px' }}>
      <Grid sx={{ marginBottom: '16px' }}>
        <Text sx={{ fontSize: '16px' }}>{BIO}</Text>
        {userData.org && <Text>{userData.org.shortName}</Text>}
      </Grid>
      {isPasswordForm ? (
        <Flex
          sx={{
            flexDirection: 'column',
            position: 'relative',
            marginTop: '16px',
          }}
        >
          <Grid
            sx={{
              gridTemplateColumns: '200px 1fr',
              width: '100%',
              alignItems: 'center',
              marginBottom: '5px',
            }}
          >
            <Text>Новый пароль</Text>
            <Input
              type="password"
              required
              onChange={(e): void => changePass(e, 'pass1')}
            />
          </Grid>
          <Grid
            sx={{
              gridTemplateColumns: '200px 1fr',
              width: '100%',
              alignItems: 'center',
              marginBottom: '5px',
            }}
          >
            <Text>Повторите новый пароль</Text>
            <Input
              type="password"
              required
              onChange={(e): void => changePass(e, 'pass2')}
            />
          </Grid>
          <Grid
            sx={{
              gridTemplateColumns: '200px 1fr',
              width: '100%',
              alignItems: 'center',
              marginBottom: '5px',
            }}
          >
            <Text sx={{ height: '1.2rem' }} />
            {passErrorMessage ? (
              <Text sx={{ color: 'danger' }}>пароли не совпадают</Text>
            ) : (
              <div />
            )}
          </Grid>
        </Flex>
      ) : (
        <Flex sx={{ flexDirection: 'column', position: 'relative' }}>
          <Flex
            sx={{
              position: 'absolute',
              top: '-28px',
              right: 0,
            }}
          >
            {!disabled ? (
              <>
                <Text
                  title="Сохранить изменения"
                  sx={{
                    color: 'grey',
                    textDecoration: 'underline',
                    marginRight: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={(): void => {
                    saveUser()
                    setDisabled(true)
                  }}
                >
                  сохранить
                </Text>
                <Text
                  sx={{
                    color: 'grey',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={(): void => {
                    setDisabled(true)
                    setValue(initialState)
                  }}
                >
                  сбросить
                </Text>
              </>
            ) : (
              <Text
                title="Редактировать учетные данные"
                sx={{
                  color: 'grey',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(): void => setDisabled(false)}
              >
                редактировать
              </Text>
            )}
          </Flex>
          {formData.map(({ title, ...formElement }) => (
            <Grid
              key={title}
              sx={{
                gridTemplateColumns: '150px 1fr',
                width: '100%',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Text>{title}</Text>
              <Input {...formElement} />
            </Grid>
          ))}
          <Grid
            sx={{
              gridTemplateColumns: '150px 120px 140px',
              width: '100%',
              alignItems: 'center',
              marginBottom: '5px',
            }}
          >
            <Text>Пароль</Text>
            <Text sx={{ padding: '4px' }}>******</Text>
            <Text
              title="Изменить пароль"
              sx={{
                color: 'grey',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={viewPasswordForm}
            >
              cменить пароль
            </Text>
          </Grid>
        </Flex>
      )}

      {isPasswordForm ? (
        <Flex
          sx={{
            marginTop: '32px',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            title="Изменить пароль"
            variant="primary"
            sx={{ width: '120px' }}
            onClick={(): void => {
              changePasswordUser()
            }}
          >
            Изменить
          </Button>
          <Flex>
            <Button
              type="submit"
              variant="secondary"
              sx={{ width: '120px' }}
              onClick={(): void => {
                setPassword(initialPassState)
                viewPassErrorMessage(false)
                viewProfileForm()
              }}
            >
              Назад
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex
          sx={{
            marginTop: '32px',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {org !== null && (
            <>
              <Button
                title="Редактировать юр.лицо"
                variant="primary"
                onClick={openEditClient}
              >
                Редактировать юр.лицо
              </Button>
              <ModalWrapper isOpen={editClient} onClose={closeEditClient}>
                <OrgFormComponent
                  initialData={org}
                  CustomButtons={CustomButtons}
                  CustomSubmit={customSubmit}
                />
              </ModalWrapper>
            </>
          )}
          {physic !== null && (
            <>
              <Button
                title="Редактировать физ.лицо"
                variant="primary"
                onClick={openEditClient}
              >
                Редактировать физ.лицо
              </Button>
              <ModalWrapper isOpen={editClient} onClose={closeEditClient}>
                <PhysFormComponent
                  initialData={physic}
                  CustomButtons={CustomButtons}
                  CustomSubmit={customSubmit}
                />
              </ModalWrapper>
            </>
          )}
          <Flex>
            <Button
              type="submit"
              title="Отменить ввод и закрыть карточку профиля"
              variant="secondary"
              sx={{ width: '120px' }}
              onClick={(e): void => {
                e.preventDefault()
                onClose()
              }}
            >
              Отмена
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
