export const namespace = 'notifications'

export const SET_PAGINATION = `${namespace}/SET_PAGINATION`
export const SET_INBOX_PAGINATION = `${namespace}/SET_INBOX_PAGINATION`
export const SET_OUTBOX_PAGINATION = `${namespace}/SET_OUTBOX_PAGINATION`
export const SET_INTERNAL_PAGINATION = `${namespace}/SET_INTERNAL_PAGINATION`
export const SET_LOADING = `${namespace}/SET_LOADING`
export const SET_INBOX = `${namespace}/SET_INBOX`
export const SET_OUTBOX = `${namespace}/SET_OUTBOX`
export const SET_INTERNAL = `${namespace}/SET_INTERNAL`
export const TOGGLE_INBOX_ELEMENT = `${namespace}/TOGGLE_INBOX_ELEMENT`
export const CHECK_INBOX_ELEMENT_BULK = `${namespace}/CHECK_INBOX_ELEMENT_BULK`
export const UNCHECK_INBOX_ELEMENT_BULK = `${namespace}/UNCHECK_INBOX_ELEMENT_BULK`
export const TOGGLE_OUTBOX_ELEMENT = `${namespace}/TOGGLE_OUTBOX_ELEMENT`
export const CHECK_OUTBOX_ELEMENT_BULK = `${namespace}/CHECK_OUTBOX_ELEMENT_BULK`
export const UNCHECK_OUTBOX_ELEMENT_BULK = `${namespace}/UNCHECK_OUTBOX_ELEMENT_BULK`
export const TOGGLE_INTERNAL_ELEMENT = `${namespace}/TOGGLE_INTERNAL_ELEMENT`
export const CHECK_INTERNAL_ELEMENT_BULK = `${namespace}/CHECK_INTERNAL_ELEMENT_BULK`
export const UNCHECK_INTERNAL_ELEMENT_BULK = `${namespace}/UNCHECK_INTERNAL_ELEMENT_BULK`
