import { createSelector } from 'reselect'
import { RouterState } from 'react-router-redux'
import { Location } from 'history'

import { AuthI } from './index'
import { initialState, InitialStateI } from './reducers'

interface RouterStateI extends RouterState {
  location: Location<{ path?: string }>
}
interface RouterI {
  router: RouterStateI
}

const locationSelector = (state: RouterI): Location<{ path?: string }> =>
  state.router.location

export const routerStateSelector = createSelector(
  locationSelector,
  (state) => state.state || {}
)

export const namespaceStateSelector = (state: AuthI): InitialStateI =>
  state.auth || initialState

export const authSelector = createSelector(
  namespaceStateSelector,
  (state) => state.isAuth
)

export const loadingSelector = createSelector(
  namespaceStateSelector,
  (state) => state.loading
)

export const errorSelector = createSelector(
  namespaceStateSelector,
  (state) => state.error
)

export const getAuthUserInfo = createSelector(
  namespaceStateSelector,
  (state) => state.userAuthInfo
)

export const getClientUser = createSelector(
  namespaceStateSelector,
  (state) => state.clientUser
)

export const registrationProcessS = createSelector(
  namespaceStateSelector,
  (state) => state.registrationProcess
)

export const registrationMessageS = createSelector(
  namespaceStateSelector,
  (state) => state.registrationMessage
)

export const userSettingS = createSelector(
  namespaceStateSelector,
  (state) => state.userSetting
)
