/* eslint-disable redux-saga/no-unhandled-errors */
import { createAction } from '@reduxjs/toolkit'
import { createNamespace } from '../config'

/*
 *  MAIN STU3 http://stu3-dev.ursip.local/ws-stu/swagger-ui.html#/
 *  nginx proxy method
 */

const controller = 'ws-stu'
const api = `/${controller}/v1`
const namespace = createNamespace(controller)

const actions = {
  appealsStatusGET: createAction(
    `${namespace}_appeals-status`,
    ({ idAppeal }: { idAppeal: string }) => ({
      payload: {
        request: {
          url: `${api}/status-record-lk/${idAppeal}`,
          method: 'GET',
        },
      },
    })
  ),
}

export const WsStuAppeal = {
  namespace,
  actions,
}
