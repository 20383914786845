import React, { useCallback } from 'react'
import { Flex } from '@theme-ui/components'
import { useDispatch, useSelector } from 'react-redux'
import { helpDeskService } from '@lk-stu3/services'
import { useToasts } from 'react-toast-notifications'
import { Button } from '@lk-stu3/components'
import { useHistory } from 'react-router-dom'

import {
  currentTaskSelector,
  taskLoadingS,
} from '../../../routes/task-item/module/selectors'
import { setTaskLoading } from '../../../routes/task-item/module/actions'

export const DeleteRequestModal = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()

  const { id, helpdeskNumber } = useSelector(currentTaskSelector) || {}
  const loading = useSelector(taskLoadingS)

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleDelete = useCallback(() => {
    Promise.all([dispatch(helpDeskService.actions.deleteRequest(id))])
      .then(() => {
        dispatch(setTaskLoading(false))
        handleClose()
        addToast(`Запрос №${helpdeskNumber} удален`, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })
      })
      .catch(() => {
        addToast('Ошибка удаления запроса', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })
      })
      .finally(() => {
        history.push('/dashboard/tech-support')
      })
  }, [id, history, handleClose, dispatch, addToast, helpdeskNumber])

  return (
    <Flex sx={{ justifyContent: 'flex-end' }}>
      <Button
        loading={loading}
        bg="red !important"
        sx={{ mr: '16px', width: '96px', height: '40px' }}
        onClick={handleDelete}
      >
        Да
      </Button>
      <Button
        sx={{ width: '96px', height: '40px' }}
        color="black !important"
        type="secondary"
        onClick={onClose}
      >
        Нет
      </Button>
    </Flex>
  )
}
