/** @jsx jsx */
import { jsx, SxStyleProp, Box } from 'theme-ui'
import { useRef, useEffect } from 'react'

type OutsideClickT = {
  children: React.ReactNode
  close: () => void
  sx?: SxStyleProp
}

export const OutsideClick = (props: OutsideClickT): React.ReactElement => {
  const { close, children, sx } = props
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside: EventListener = (event) => {
      if (
        wrapperRef.current &&
        event.target &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        close()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, close])

  return (
    <Box ref={wrapperRef} sx={sx}>
      {children}
    </Box>
  )
}
