import React, { useState } from 'react'
import { range } from 'ramda'
import { Text, Flex, Button } from 'theme-ui'
import { Input } from '@ursip/components'
import { colors } from './theme'

import { IconDott } from './svg-icon'

interface PrevNextButtonI {
  changePage: (param: number) => void
  activePage: number
}

const PrevButton = ({
  changePage,
  activePage,
}: PrevNextButtonI): React.ReactElement => (
  <Button
    variant="bordered"
    onClick={(): void => changePage(activePage - 1)}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '32px',
      padding: '6px 10px',
      transition: 'all 0.2s easy-out',
      '&:hover svg': {
        fill: colors.background,
      },
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1rem"
      height="1rem"
      fill={colors.text}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
    </svg>
  </Button>
)
const NextButton = ({
  changePage,
  activePage,
}: PrevNextButtonI): React.ReactElement => (
  <Button
    variant="bordered"
    onClick={(): void => changePage(activePage + 1)}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '8px',
      padding: '6px 10px',
      color: 'background',
      '&:hover svg': {
        fill: colors.background,
      },
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1rem"
      height="1rem"
      fill={colors.text}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
    </svg>
  </Button>
)

interface ChangePageI {
  changePage: (param: number) => void
}

const ChangePage = ({ changePage }: ChangePageI): React.ReactElement => {
  const [page, setPage] = useState<number | null>(null)
  return (
    <Flex sx={{ ml: '32px', alignItems: 'center' }}>
      <Input
        type="number"
        value={page || ''}
        onChange={(e: React.FormEvent<HTMLInputElement>): void => {
          if (e.currentTarget.value) setPage(Number(e.currentTarget.value))
        }}
        sx={{ height: '30px', width: '60px', border: 'none' }}
      />
      <Button
        title="Перейти на выбранную страницу"
        variant="secondary"
        onClick={(): void => {
          if (page) changePage(page - 1)
        }}
        sx={{
          padding: '6px 16px',
          marginLeft: '24px',
        }}
      >
        Перейти
      </Button>
    </Flex>
  )
}

interface PageButtonI {
  changePage: (param: number) => void
  activePage: number
  el: number
}

const PageButton = ({
  el,
  activePage,
  changePage,
}: PageButtonI): React.ReactElement => {
  return (
    <Button
      variant={activePage === el ? 'primary' : 'bordered'}
      onClick={(): void => changePage(el)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '8px',
        padding: '14px 10px',
        minWidth: '30px',
      }}
    >
      <Text sx={{ fontSize: '14px', lineHeight: '0', padding: '0' }}>
        {el + 1}
      </Text>
    </Button>
  )
}

const getPages = (total: number, activePage: number): number[] => {
  const pages = range(1, total || 1)
  if (total < 8) return pages
  if (activePage <= 3) return pages.splice(0, 4)
  if (activePage > 3 && activePage < total - 3)
    return pages.splice(activePage - 2, 3)
  if (activePage >= total - 3) return pages.splice(total - 5, 7)
  return pages
}

interface PaginationI {
  onChange: (number: number) => void
  totalPages: string | number
  activePage: number
}

export const Pagination = ({
  onChange,
  totalPages,
  activePage,
}: PaginationI): React.ReactElement => {
  const lastIndexPage = Number(totalPages) - 1

  const pages = getPages(lastIndexPage, activePage)

  const changePage = (page: number): void => {
    if (lastIndexPage > page - 1 && page >= 0) {
      onChange(page)
    }
  }

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Text sx={{ color: 'grey', textAlign: 'center' }}>{`${
        activePage + 1
      } из ${totalPages}`}</Text>
      <PrevButton changePage={changePage} activePage={activePage} />
      <PageButton el={0} activePage={activePage} changePage={changePage} />
      {totalPages > 8 ? (
        <>
          {Number(activePage) > 3 && <IconDott />}
          {pages.map((el) => (
            <PageButton
              key={el}
              el={el}
              activePage={activePage}
              changePage={changePage}
            />
          ))}
          {totalPages > 1 && Number(activePage) < lastIndexPage - 3 && (
            <IconDott />
          )}
          {totalPages > 1 && (
            <PageButton
              el={lastIndexPage}
              activePage={activePage}
              changePage={changePage}
            />
          )}
        </>
      ) : (
        <>
          {pages.map((el) => (
            <PageButton
              key={el}
              el={el}
              activePage={activePage}
              changePage={changePage}
            />
          ))}
          {totalPages > 1 && (
            <PageButton
              el={lastIndexPage}
              activePage={activePage}
              changePage={changePage}
            />
          )}
        </>
      )}

      <NextButton changePage={changePage} activePage={activePage} />
      <ChangePage changePage={changePage} />
    </Flex>
  )
}
