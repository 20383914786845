import React from 'react'
import { createSelector } from 'reselect'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { TreeDataNode, flattenDataToTree } from '@lk-stu3/utils'
import { userSettingS } from '@lk-stu3/auth'
import { useSelector, useDispatch } from 'react-redux'
import { EventDataNode } from 'rc-tree/es/interface'
import { DirectoryTree, Resizable } from '@lk-stu3/components'
import Preview from '@lk-stu3/viewer2d'
import { MenuActionsI } from '@lk-stu3/components/interfaces'
import { moduleAppealFiles } from './module'
import { appealIdS } from '../module/selectors'
import { dataTreeS } from './module/selectors'
import { TreeTitle } from './title'
import { MenuActions } from './menu-actions'

export interface FilesI {
  height: number
  width: number
}

const withLabel = (
  data: TreeDataNode[],
  menuActions: MenuActionsI
): TreeDataNode[] =>
  data.map((node) => ({
    ...node,
    title: TreeTitle(`${node.title}`, node, menuActions),
  }))

const getPreferredFolderS = createSelector(
  dataTreeS,
  userSettingS,
  (treeData, { idFolder }) =>
    treeData.filter((td) => td.id === idFolder || td.isLeaf)
)

export const Files = ({ height, width }: FilesI): React.ReactElement => {
  const idAppeal = useSelector(appealIdS)
  const { idRegistrator, idFolder } = useSelector(userSettingS)
  const treeData = useSelector(getPreferredFolderS)

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (idAppeal && idFolder)
      dispatch(
        WsStuFileAndFolder.actions.folderSearch({
          id: idFolder,
          idAppeal,
          global: true,
        })
      )
  }, [dispatch, idAppeal, idFolder])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoadData = ({ key: idFolder }: EventDataNode): Promise<any> => {
    const actions = []
    if (idAppeal && idFolder) {
      actions.push(
        dispatch(
          WsStuFileAndFolder.actions.folderSearch({
            idAppeal,
            global: false,
            idParentFolder: `${idFolder}`,
          })
        )
      )
      actions.push(
        dispatch(
          WsStuFileAndFolder.actions.fileSearch({
            idAppeal,
            idFolder: `${idFolder}`,
            // userLoading: idRegistrator,
          })
        )
      )
    }
    return Promise.allSettled(actions)
  }

  const { getFileUrl } = WsStuFileAndFolder

  const menuActions: MenuActionsI = MenuActions(
    dispatch,
    `${idAppeal}`,
    idRegistrator,
    treeData
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedFile, setSelectedFile] = React.useState<any | null>(null)
  const { idFile, idFileConverted } = selectedFile || {}
  const initialTreeData = flattenDataToTree(withLabel(treeData, menuActions))

  return (
    <DynamicModuleLoader modules={[moduleAppealFiles()]}>
      <Resizable width={width} height={height}>
        <DirectoryTree
          treeData={initialTreeData}
          loadData={onLoadData}
          // menuActions={menuActions}
          onSelect={(_, { node }): void => {
            if (node.isLeaf) setSelectedFile(node)
          }}
        />
        <Preview
          selectedFile={selectedFile}
          fileUrl={
            selectedFile ? getFileUrl(`${idFileConverted || idFile}`) : null
          }
        />
      </Resizable>
    </DynamicModuleLoader>
  )
}
