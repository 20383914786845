import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Grid } from 'theme-ui'

import { fileLinksS } from './module/selectors'

export const InfoWrapper = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const links = useSelector(fileLinksS)
  return (
    <Flex sx={{ justifyContent: 'center' }}>
      <Grid
        sx={{
          padding: '16px',
          minWidth: '720px',
          width: '100%',
          rowGap: '0px',
          columnGap: '16px',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateAreas: `"${[
            'appealNumber idStatus',
            'objectName objectName',
            'idObjectType idServiceType',
            'dateRegistration contractNumber',
            'landmark landmark',
            'idRegion idCity',
            'idPrefect idDistrict',
            'idStreet building',
            'reasonRefusal reasonRefusal',
          ].join('" "')}"
          ${links.map((l_, i) => `"link${i} comment${i}"`).join('\n')}`
        }}
      >
        {children}
      </Grid>
    </Flex>
  )
}
