import React from 'react'
import styled from 'styled-components'
import { Box } from 'theme-ui'

export const Button = styled(Box)`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  position: relative;
  top: 1px;
  font-size: 12px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  transition: all 0.2s linear;
  border-color: ${({ active }: { active: boolean }): string =>
    active ? '#0091ea' : 'rgba(0, 0, 0, 0)'};
  color: ${({
    disabled,
    active,
  }: {
    active: boolean
    disabled: boolean
  }): string => {
    if (active) return '#0091ea'
    if (!disabled) return '#333'
    return '#b5b5b5'
  }};
  ${({ disabled }: { disabled: boolean }): string =>
    disabled
      ? ''
      : `
      &:hover {
        text-decoration: none;
        border-color: #0091ea;
        color: #0091ea;
        background-color: #f9f9f9;
      }
      `}
`
interface NavButtonI {
  children: string | React.ReactNode
  active: boolean
  title: string
  onClick: () => void
  disabled?: boolean
}

export const NavButton = ({
  children,
  active,
  title,
  onClick,
  disabled = false,
}: NavButtonI): React.ReactElement => {
  return (
    <Box ml={3}>
      <Button
        disabled={disabled}
        active={active}
        onClick={onClick}
        title={title}
      >
        {children}
      </Button>
    </Box>
  )
}
