import React from 'react'
import { Flex, Box, IconButton, Text, Spinner } from 'theme-ui'
import { TreeDataNode } from '@lk-stu3/utils'
import { Dropdown } from '@ursip/components'
import Menu, { MenuItem } from 'rc-menu'
import { svgIcons } from '@lk-stu3/components'
import { MenuActionsI } from '@lk-stu3/components/interfaces'
import { FileUploader } from './file-button'
import { dateFormat } from '@lk-stu3/utils/src/moment-utils'

const { AddFileIcon, MenuIcon } = svgIcons

const DropdownMenu = ({
  menu,
  children,
}: {
  menu: (() => React.ReactElement) | React.ReactElement
  children: React.ReactElement
}): React.ReactElement => {
  return (
    <Dropdown minOverlayWidthMatchTrigger overlay={menu} trigger="click">
      {children}
    </Dropdown>
  )
}

const TreeTitleComponent = ({ title, node, menuActions }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const MenuContent = (): React.ReactElement => {
    if (node.type === 'FILE') {
      return (
        <Menu>
          <MenuItem>
            <Flex
              sx={{
                '& a': {
                  color: 'text',
                },
                '& a:hover': {
                  color: 'text',
                  textDecoration: 'none',
                },
              }}
            >
              <a href={menuActions.downloadFile(node)}>Скачать файл</a>
            </Flex>
          </MenuItem>
          <MenuItem onClick={(): void => menuActions.deleteFile(node)}>
            Удалить файл
          </MenuItem>
        </Menu>
      )
    }
    return <div />
    //   <Menu>
    //     <MenuItem onClick={(): void => menuActions.newFolder(node)}>
    //       Добавить папку
    //     </MenuItem>
    //     {isChildFolder && (
    //       <MenuItem onClick={(): void => menuActions.renameFolder(node)}>
    //         Переименовать папку
    //       </MenuItem>
    //     )}
    //     {isChildFolder && (
    //       <MenuItem onClick={(): void => menuActions.deleteFolder(node)}>
    //         Удалить папку
    //       </MenuItem>
    //     )}
    //   </Menu>
  }
  return (
    <Flex
      title={title}
      sx={{ alignItems: 'center', justifyContent: 'space-between', flex: 1 }}
    >
      <Text
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </Text>
      <Flex
        id="submenu"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          lineHeight: 1,
          position: 'absolute',
          right: 0,
          backgroundColor: 'background',
          paddingRight: '12px',
        }}
        onClick={(e): void => e.stopPropagation()}
      >
        {node.type === 'FOLDER' && (
          <Box sx={{ width: '35px' }}>
            {isLoading ? (
              <Spinner size={20} color="black" />
            ) : (
              <FileUploader
                addFile={menuActions.addFile}
                onStart={() => setIsLoading(true)}
                onFinish={() => setIsLoading(false)}
                node={node}
              >
                <AddFileIcon />
              </FileUploader>
            )}
          </Box>
        )}
        {node.type === 'FILE' && (
          <>
            <Box sx={{ margin: '0 10px 0 15px' }}>
              {dateFormat(node?.dateLoading, 'DD.MM.YYYY HH:mm')}
            </Box>
            <Box sx={{ width: '35px' }}>
              <DropdownMenu menu={MenuContent}>
                <IconButton variant="ring-black">
                  <MenuIcon />
                </IconButton>
              </DropdownMenu>
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export const TreeTitle = (
  title: string,
  node: TreeDataNode,
  menuActions: MenuActionsI
): React.ReactElement => {
  return (
    <TreeTitleComponent title={title} node={node} menuActions={menuActions} />
  )
}
