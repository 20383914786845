/* eslint-disable redux-saga/no-unhandled-errors */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, put, takeEvery, select, takeLatest } from 'redux-saga/effects'
import { success, error } from '@redux-requests/core'
import { WsLkService } from '@lk-stu3/services'
import { WsStuStatusRecord } from '@lk-stu3/services/src/ws-stu/status-record-lk/status-record-lk'
import * as types from './types'
import {
  setAppeals,
  setAppealsPagination,
  setError,
  setStatusRecordsAC,
} from './actions'
import { searchParamsS, queryParamsS } from './selectors'

function* getAppeals() {
  const queryParams = yield select(queryParamsS)
  const { dateRegistration, ...searchParams } = yield select(searchParamsS) ||
    {}
  const formattedSearchParams = dateRegistration
    ? {
        dateRegistrationTo: `${dateRegistration[1].format(
          'YYYY-MM-DD'
        )}T23:59:59.999`,
        dateRegistrationToFrom: `${dateRegistration[0].format(
          'YYYY-MM-DD'
        )}T00:00:00.000`,
        ...searchParams,
      }
    : searchParams || {}
  yield put(
    WsLkService.actions.requestSearchPOST(formattedSearchParams, queryParams)
  )
}

function* setAppealsSaga({ payload }) {
  const appeals = payload.data.content
  const { totalElements, totalPages, number, size } = payload.data
  yield put(setAppeals(appeals))
  yield put(setAppealsPagination({ totalElements, totalPages, number, size }))
}

function* setErrorSaga() {
  yield put(setError())
}

function* setStatusRecordsSaga({ payload }) {
  yield put(setStatusRecordsAC(payload.data))
}

export function* sagas() {
  yield all([
    takeEvery(
      [
        types.SET_SEARCH_PARAMS,
        types.RESET_SEARCH_PARAMS,
        success(WsLkService.actions.requestPOST.toString()),
      ],
      getAppeals
    ),
    takeLatest(
      success(WsLkService.actions.requestSearchPOST.toString()),
      setAppealsSaga
    ),
    takeLatest(
      error(WsLkService.actions.requestSearchPOST.toString()),
      setErrorSaga
    ),
    takeEvery(
      success(WsStuStatusRecord.actions.getStatusRecords.toString()),
      setStatusRecordsSaga
    ),
  ])
}
