import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { Badge, Flex } from 'theme-ui'

const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  position: relative;
  top: 1px;
  font-size: 12px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  transition: all 0.2s linear;
  border-color: ${({ active }: { active: boolean }): string =>
    active ? '#0091ea' : 'rgba(0, 0, 0, 0)'};
  color: ${({
    disabled,
    active,
  }: {
    active: boolean
    disabled: boolean
  }): string => {
    if (active) return '#0091ea'
    if (!disabled) return '#333'
    return '#b5b5b5'
  }};
  ${({ disabled }: { disabled: boolean }): string =>
    disabled
      ? ''
      : `
      &:hover {
        text-decoration: none;
        border-color: #0091ea;
        color: #0091ea;
        background-color: #f9f9f9;
      }
      `}
`

interface NavLinkI {
  children: string | React.ReactNode
  active: boolean
  to: string
  disabled: boolean
  badgeCount?: number
}

const NavLink = ({
  children,
  active,
  disabled,
  to,
  badgeCount,
}: NavLinkI): React.ReactElement => {
  const renderBadge = !(badgeCount && badgeCount > 0)
  return (
    <Flex ml={3}>
      <Link disabled={disabled} active={active} to={to}>
        {children}
      </Link>
      {!renderBadge && (
        <Flex
          sx={{
            marginLeft: '-6px',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Badge
            sx={{
              backgroundColor: 'danger',
              borderRadius: '50%',
              animation: 'pulse-red 3s infinite',
              '@keyframes pulse-red': {
                '0%': {
                  boxShadow: '0 0 0 0 rgba(255, 82, 82, 0.7)',
                },
                '70%': {
                  boxShadow: '0 0 0 10px rgba(255, 82, 82, 0)',
                },
                '100%': {
                  boxShadow: '0 0 0 0 rgba(255, 82, 82, 0)',
                },
              },
            }}
          >
            {badgeCount}
          </Badge>
        </Flex>
      )}
    </Flex>
  )
}

NavLink.defaultProps = {
  disabled: false,
  active: false,
  link: '/',
}

export { NavLink }
