import produce from 'immer'
import * as types from './types'

export const initialState = {
  isLoading: false,
  isSubmitting: false,
  dicts: {},
}

export default {
  [types.SET_DICT]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.dicts = {
        ...state.dicts,
        ...payload,
      }
    }),
  [types.SET_LOADING]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.isLoading = payload
    }),
  [types.SET_SUBMITTING]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.isSubmitting = payload
    }),
}
