import moment, { Moment } from 'moment'

export const getMomentDate = (
  value: undefined | string | null
): null | Moment => {
  if (!value) return null
  const result = moment(value)
  if (result.isValid()) return result
  return null
}

export const dateFormat = (
  dateString: string | undefined | null,
  format = 'DD.MM.YYYY'
): string => {
  if (!dateString) return ''
  const momentDate = moment(dateString)
  if (!momentDate.isValid()) return ''
  return momentDate.format(format)
}
