/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable redux-saga/no-unhandled-errors */
import { all, takeEvery, put } from 'redux-saga/effects'
import { error, success } from 'redux-saga-requests'
import { helpDeskService, WsStuNsiService } from '@lk-stu3/services'
import { setDictsAC, setRequestsAC, setStatusesAC } from './actions'

function* setTasksSaga({ payload }) {
  yield put(setRequestsAC(payload.data))
}

function* setTasksErrorSaga({ payload }) {
  // console.log('setTasksErrorSaga', payload)
}

function* setDictSaga({ payload, meta }) {
  yield put(setDictsAC({ [meta.dictName]: payload.data.elements }))
}

function* setStatusesSaga({ payload }) {
  yield put(setStatusesAC(payload.data))
}
function* setStatusesErrorSaga({ payload }) {
  // console.log('setStatusesErrorSaga', payload)
}

export function* sagas() {
  yield all([
    takeEvery(
      success(helpDeskService.actions.getHelpdeskTasks.toString()),
      setTasksSaga
    ),
    takeEvery(
      error(helpDeskService.actions.getHelpdeskTasks.toString()),
      setTasksErrorSaga
    ),
    takeEvery(success(WsStuNsiService.actions.getDict.toString()), setDictSaga),
    takeEvery(
      success(helpDeskService.actions.getStatuses.toString()),
      setStatusesSaga
    ),
    takeEvery(
      error(helpDeskService.actions.getStatuses.toString()),
      setStatusesErrorSaga
    ),
  ])
}
