import React, { useCallback } from 'react'
import { Flex, Box } from 'theme-ui'
import { Pagination, Select } from '@lk-stu3/components'
import { useSelector, useDispatch } from 'react-redux'
import { searchParamsSelector, totalPagesSelector } from './module/selectors'
import { setSearchParamsAC } from './module/actions'

export const TablePages = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { page, size } = useSelector(searchParamsSelector)
  const totalPages = useSelector(totalPagesSelector)

  const changePage = useCallback((page) => {
      dispatch(setSearchParamsAC({ page }))
    },
    [dispatch]
  )

  const changeSize = useCallback(
    (option) => {
      dispatch(setSearchParamsAC({ size: option.value, page: 0 }))
    },
    [dispatch]
  )

  const options = [
    { label: 'Показывать по 10', value: '10' },
    { label: 'Показывать по 20', value: '20' },
    { label: 'Показывать по 50', value: '50' },
  ]

  return (
    <Flex
      sx={{
        padding: '16px',
        border: '1px solid',
        borderColor: 'border',
        borderTop: 'none',
        justifyContent: 'space-between',
      }}
    >
      <Pagination
        onChange={changePage}
        totalPages={totalPages}
        activePage={page}
      />
      <Box sx={{ width: '175px' }}>
        <Select
          title="Количество элементов на странице"
          menuPlacement="top"
          value={{ label: `Показывать по ${size}`, value: `${size}` }}
          defaultValue={options[0]}
          options={options}
          onChange={changeSize}
        />
      </Box>
    </Flex>
  )
}
