import { createSelector } from 'reselect'
import { initialState, InitialStateI } from './reducers'
import { moduleName, ModuleI } from './index'

const namespaceStateSelector = (state: ModuleI): InitialStateI =>
  state[moduleName] || initialState

export const appealsS = createSelector(
  namespaceStateSelector,
  (state) => state.appeals || []
)

export const searchParamsS = createSelector(
  namespaceStateSelector,
  (state) => state.searchParams || {}
)

export const queryParamsS = createSelector(
  namespaceStateSelector,
  (state) => state.queryParams
)

export const paginationS = createSelector(
  namespaceStateSelector,
  (state) => state.pagination
)

export const errorS = createSelector(
  namespaceStateSelector,
  (state) => state.error
)
export const loadingS = createSelector(
  namespaceStateSelector,
  (state) => state.loading
)

export const statusRecordsSelector = createSelector(
  namespaceStateSelector,
  (state) => state.statusRecords
)
