import React from 'react'
import { Card, Flex, SxStyleProp } from 'theme-ui'
import { LogoRound } from '@lk-stu3/components'

export const Layout = ({
  children,
  logo = true,
  sx,
}: {
  children: React.ReactNode
  logo?: boolean
  sx?: SxStyleProp
}): React.ReactElement => (
  <Card
    sx={{
      position: 'relative',
      backgroundColor: 'background',
      minWidth: '400px',
      minHeight: '300px',
      boxShadow: '0 0 6px rgba(0,0,0,0.5)',
      padding: `${logo ? '50px' : '28px'} 28px 28px 28px`,
      ...sx,
    }}
  >
    {logo && (
      <Flex
        sx={{
          position: 'absolute',
          top: '-30px',
          height: '40px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          right: 0,
        }}
      >
        <LogoRound />
      </Flex>
    )}

    <Flex
      sx={{
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 60px)',
        height: '100%',
      }}
    >
      {children}
    </Flex>
  </Card>
)
