/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable redux-saga/no-unhandled-errors */
import { all, takeEvery, put, select } from 'redux-saga/effects'
import { success } from '@redux-requests/core'
import { WsNotificationsItemService } from '@lk-stu3/services'
import { uniqBy } from 'ramda'
import { paginationSelector } from './selectors'
import { setInboxAC, setOutboxAC, setPaginationAC } from './actions'

const mapToPayload = (el) => {
  let dtoPayload

  const isEmail = el.subscription.subject.topic.includes('Email')

  try {
    dtoPayload = JSON.parse(el.event.payload)
  } catch (e) {
    console.error('Ошибка парсинга данных', e)

    return undefined
  }

  return {
    notificationId: el.id,
    isEmail,
    status: el.status,
    eventId: el.event.id,
    ...dtoPayload,
  }
}

function* inboxSaga({ payload }) {
  const { content } = payload.data
  const { inbox: pagination } = yield select(paginationSelector)

  const inbox = content
    .map(mapToPayload)
    .filter(Boolean)
    .filter((el) => el?.sender === 'fromSTU')

  yield put(setInboxAC(inbox))

  yield put(
    setPaginationAC({
      inbox: {
        page: 0,
        size: pagination.size,
        total: Math.ceil(inbox.length / pagination.size),
      },
    })
  )
}
function* outboxSaga({ payload }) {
  const { content } = payload.data
  const { outbox: pagination } = yield select(paginationSelector)

  const rawOutbox = content
    .map(mapToPayload)
    .filter(Boolean)
    .filter((el) => el?.sender === 'fromLK')

  const outbox = uniqBy((el) => el.eventId, rawOutbox)

  yield put(setOutboxAC(outbox))

  yield put(
    setPaginationAC({
      outbox: {
        page: 0,
        size: pagination.size,
        total: Math.ceil(outbox.length / pagination.size),
      },
    })
  )
}

export function* sagas() {
  yield all([
    takeEvery(
      success(
        WsNotificationsItemService.actions.searchNotificationsInbox.toString()
      ),
      inboxSaga
    ),
    takeEvery(
      success(
        WsNotificationsItemService.actions.searchNotificationsOutbox.toString()
      ),
      outboxSaga
    ),
  ])
}
