import styled from 'styled-components'
import FolderClose from './assets/folder-black-18dp.svg'
import FolderOpen from './assets/folder_open-black-18dp.svg'
import ArrowRight from './assets/keyboard_arrow_right-black-18dp.svg'
import File from './assets/description-black-18dp.svg'
import Loop from './assets/loop-black-18dp.svg'

export const TreeWrapper = styled.div`
  & .rc-tree-child-tree {
    display: block;
  }

  & .node-motion {
    transition: all 0.3s;
    overflow-y: hidden;
  }

  & .rc-tree {
    overflow: hidden;
    margin: 0;
    border: 1px solid transparent;

    &-focused:not(&-active-focused) {
      border-color: cyan;
    }

    .rc-tree-treenode {
      margin: 0;
      padding: 0;
      line-height: 30px;
      white-space: nowrap;
      list-style: none;
      outline: 0;
      border-bottom: 1px solid #ecebeb;

      display: inline-block;
      padding: 8px 14px;

      &:active,
      &:hover,
      &:hover #submenu,
      &:active #submenu {
        background-color: #f5f5f5;
      }

      .draggable {
        color: #3a3a3a;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        /* Required to make elements draggable in old WebKit */
        -khtml-user-drag: element;
        -webkit-user-drag: element;

        & > div {
          background-color: #b4d8ff !important;
          height: 4px !important;
        }
      }

      &.drop-container {
        .draggable::after {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          box-shadow: inset 0 0 0 2px #419af9;
          content: '';
        }
        & ~ .rc-tree-treenode {
          border-left: 2px solid #419af9;
        }
      }
      &.drop-target {
        background-color: #f5f5f5;
        color: #3a3a3a;
        & ~ .rc-tree-treenode {
          border-left: none;
        }
      }
      &.filter-node {
        > .rc-tree-node-content-wrapper {
          color: #419af9 !important;
          font-weight: bold !important;
        }
      }
      ul {
        margin: 0;
        padding: 0 0 0 18px;
      }
      .rc-tree-node-content-wrapper {
        display: inline-block;
        align-items: center;
        margin: 0;
        padding: 0;
        text-decoration: none;
        vertical-align: top;
        cursor: pointer;
      }
      span {
        &.rc-tree-switcher,
        &.rc-tree-checkbox,
        &.rc-tree-iconEle {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          vertical-align: -0.125em;
          background-color: transparent;
          background-repeat: no-repeat;
          background-attachment: scroll;
          border: 0 none;
          outline: none;
          cursor: pointer;

          &.rc-tree-icon__customize {
            background-image: none;
          }
        }
        &.rc-tree-icon_loading {
          vertical-align: top;
          background: url(${Loop}) no-repeat scroll 0 0 transparent;
          animation: spin 4s linear infinite;
        }
        &.rc-tree-switcher {
          background-image: url(${ArrowRight});
          transition: transform 0.2s;
          position: relative;
          &::before {
            position: absolute;
            content: '';
            right: -10px;
            left: -10px;
            top: -10px;
            bottom: -10px;
          }
          &.rc-tree-switcher-noop {
            background-image: none;
            cursor: auto;
          }
          &.rc-tree-switcher_open {
            transform: rotate(90deg);
          }
        }
        &.rc-tree-checkbox {
          width: 13px;
          height: 13px;
          margin: 0 3px;
          background-position: 0 0;
          &-checked {
            background-position: -14px 0;
          }
          &-indeterminate {
            background-position: -14px -28px;
          }
          &-disabled {
            background-position: 0 -56px;
          }
          &.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
            background-position: -14px -56px;
          }
          &.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
            position: relative;
            background: #ccc;
            border-radius: 3px;
            &::after {
              position: absolute;
              top: 5px;
              left: 3px;
              width: 5px;
              height: 0;
              border: 2px solid #fff;
              border-top: 0;
              border-left: 0;
              -webkit-transform: scale(1);
              transform: scale(1);
              content: ' ';
            }
          }
        }
      }
    }
    &:not(.rc-tree-show-line) {
      .rc-tree-treenode {
        .rc-tree-switcher-noop {
          background: none;
        }
      }
    }
    &.rc-tree-show-line {
      .rc-tree-treenode:not(:last-child) {
        > ul {
          background: url('data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7')
            0 0 repeat-y;
        }
        > .rc-tree-switcher-noop {
          background-position: -56px -18px;
        }
      }
      .rc-tree-treenode:last-child {
        > .rc-tree-switcher-noop {
          background-position: -56px -36px;
        }
      }
    }
    &-child-tree {
      display: none;
      &-open {
        display: block;
      }
    }
    &-treenode-disabled {
      > span:not(.rc-tree-switcher),
      > a,
      > a span {
        color: #767676;
        cursor: not-allowed;
      }
    }
    &-treenode-active {
      background: rgba(0, 0, 0, 0.1);

      // .rc-tree-node-content-wrapper {
      //   background: rgba(0, 0, 0, 0.1);
      // }
    }
    &-node-selected {
      opacity: 0.8;
    }
    &-icon__open {
      margin-right: 2px;
      background-image: url(${FolderOpen});
    }
    &-icon__close {
      background-image: url(${FolderClose});
      margin-right: 2px;
    }
    &-icon__docu {
      margin-right: 2px;
      background-image: url(${File});
    }
    &-icon__customize {
      margin-right: 2px;
      vertical-align: top;
    }
    &-title {
      display: inline-block;
    }
    &-indent {
      display: inline-block;
      vertical-align: bottom;
      height: 0;
    }
    &-indent-unit {
      width: 16px;
      display: inline-block;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`
