import React from 'react'
import { Moment } from 'moment'
import { connect, FormikProps, FormikValues } from 'formik'
import { DatePicker, RangeDatePicker } from '@ursip/components'
import { TitledComponent, TitledComponentT } from '../titled-component'

interface DatePickerI {
  name: string
  loading?: boolean
  formik?: FormikProps<FormikValues>
  sideEffectFnc?: <T, U>(setFieldValue: T, value: U) => void
}

type SingleDatePickerFormikT = TitledComponentT & DatePickerI

const SingleDatePickerFormik = connect(
  ({
    title,
    required,
    sideEffectFnc,
    name,
    formik,
    sx,
    icon,
    ...props
  }: SingleDatePickerFormikT): React.ReactElement => {
    const handleChange = async (date: Moment): Promise<void> => {
      const { setFieldValue, setFieldTouched } = formik || {}
      if (setFieldValue) await setFieldValue(name, date)
      if (setFieldTouched) await setFieldTouched(name, true)
    }
    const formikErrors = formik && formik.errors[name]
    const formikTouched = formik?.touched[name]

    return (
      <TitledComponent
        title={title}
        required={required}
        error={formikErrors}
        touched={!!formikTouched}
        icon={icon}
      >
        <DatePicker
          selectedValue={formik?.values[name]}
          value={formik?.values[name]}
          height="41px"
          onChange={handleChange}
          sx={{ width: '100%', height: '41px', ...sx }}
          {...props}
        />
      </TitledComponent>
    )
  }
)

const RangeDatePickerFormik = connect(
  ({
    title,
    required,
    sideEffectFnc,
    name,
    formik,
    sx,
    icon,
    ...props
  }: SingleDatePickerFormikT): React.ReactElement => {
    const handleChange = async (date: Moment): Promise<void> => {
      const { setFieldValue, setFieldTouched } = formik || {}
      if (setFieldValue) await setFieldValue(name, date)
      if (setFieldTouched) await setFieldTouched(name, true)
    }
    const formikErrors = formik && formik.errors[name]
    const formikTouched = formik?.touched[name]

    return (
      <TitledComponent
        title={title}
        required={required}
        error={formikErrors}
        touched={!!formikTouched}
        icon={icon}
      >
        <RangeDatePicker
          selectedValue={formik?.values[name]}
          value={formik?.values[name]}
          height="41px"
          onChange={handleChange}
          sx={{ width: '100%', height: '41px', ...sx }}
          {...props}
        />
      </TitledComponent>
    )
  }
)

export { SingleDatePickerFormik, RangeDatePickerFormik }
