import { createSelector } from 'reselect'
import { initialState, InitialStateI } from './reducers'
import { moduleName, ModuleI } from './index'

const namespaceStateSelector = (state: ModuleI): InitialStateI =>
  state[moduleName] || initialState

export const appealIdS = createSelector(
  namespaceStateSelector,
  (state) => state.appealId
)

export const requestIdS = createSelector(
  namespaceStateSelector,
  (state) => state.requestId
)

export const appealLoadingS = createSelector(
  namespaceStateSelector,
  (state) => state.loading
)

export const appealErrorS = createSelector(
  namespaceStateSelector,
  (state) => state.error
)

export const appealInfoS = createSelector(
  namespaceStateSelector,
  (state) => state.appealInfo
)

export const appealStatusInfo = createSelector(
  namespaceStateSelector,
  (state) => state.appealStatusInfo
)

export const statusS = createSelector(
  appealInfoS,
  (state) => state?.status || ''
)

export const appealNumberS = createSelector(
  appealInfoS,
  (state) => state?.appealNumber || ''
)

export const fileLinksS = createSelector(
  appealInfoS,
  (state) => state?.links || []
)
