/** @jsx jsx */
import { jsx, Flex, SxStyleProp, Text } from 'theme-ui'

import * as logo from '../assets/logo.png'

interface LogoI {
  text?: string
  sxContainer?: SxStyleProp
  sxText?: SxStyleProp
}

export const Logo = ({
  text,
  sxContainer,
  sxText,
}: LogoI): React.ReactElement => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'transform 0.2s',
        '&:active': {
          transform: 'scale(1.1)',
        },
        ...sxContainer,
      }}
    >
      <img src={logo} alt="НИАЦ" />
      {text && (
        <Text
          as="h2"
          sx={{
            marginLeft: '8px',
            fontSize: 2,
            color: 'background',
            letterSpacing: '1px',
            ...sxText,
          }}
        >
          {text}
        </Text>
      )}
    </Flex>
  )
}
