import React from 'react'
// import { SystemStyleObject } from '@styled-system/css'
import { Button as LibButton, Spinner } from 'theme-ui'
import { Icon } /*, { IconProps }*/ from './icon'
// import { tuple } from '../utils/type-script'

// const ButtonHTMLTypes = tuple('submit', 'button', 'reset')
// const ButtonTypes = tuple('primary', 'secondary', 'bordered', 'flat')
// const ButtonSizes = tuple('small', 'medium', 'large')
// const ButtonIconPlacements = tuple('left', 'right')

// export interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'type'> {
//   htmlType?: typeof ButtonHTMLTypes[number]
//   type?: typeof ButtonTypes[number]
//   size?: typeof ButtonSizes[number]
//   disabled?: boolean
//   loading?: boolean
//   icon?: IconProps
//   iconPlacement?: typeof ButtonIconPlacements[number]
//   sx?: SystemStyleObject
// }

const sizes = {
  small: {
    height: '32px',
    lineHeight: 1,
  },
  medium: {
    height: '40px',
  },
  large: {
    height: '48px',
  },
}

const types = {
  primary: {
    color: 'white',
    bg: 'primary',
    borderColor: 'primary',
  },
  secondary: {
    color: 'white',
    borderColor: 'secondary',
    bg: 'secondary',
  },
  bordered: {
    color: 'primary',
    bg: 'transparent',
    border: '1px solid',
    borderColor: 'primary',
  },
  flat: {
    color: 'primary',
    bg: 'transparent',
  },
}

export const Button = ({ type, children, loading, sx, icon, size, iconPlacement, htmlType, ...props }) => {
  return (
    <LibButton
      type={htmlType}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        outline: 'none',
        fontFamily: 'body',
        pl: icon && iconPlacement === 'left' && children ? 2 : 3,
        pr: icon && iconPlacement === 'right' && children ? 2 : 3,
        verticalAlign: 'middle',
        transition: 'opacity 100ms',
        '&:hover': {
          opacity: '0.8',
        },
        '&:disabled': {
          cursor: 'no-drop',
          color: 'muted',
          borderColor: 'secondary',
          backgroundColor: 'secondary',
          '&:hover': {
            opacity: '1',
          },
        },
        ...sizes[size || 'medium'],
        ...types[type || 'primary'],
        ...sx,
      }}
      variant={type}
      {...props}
    >
      {loading && iconPlacement === 'left' && (
        <Spinner mr={2} size={14} sx={{ color: 'inherit', position: 'relative', top: '3px' }} />
      )}
      {icon && !loading && iconPlacement === 'left' && <Icon {...icon} sx={{ mr: children ? 2 : 0, ...icon.sx }} />}
      {children}
      {icon && !loading && iconPlacement === 'right' && <Icon {...icon} sx={{ ml: children ? 2 : 0, ...icon.sx }} />}
      {loading && iconPlacement === 'right' && (
        <Spinner ml={2} size={14} sx={{ color: 'inherit', position: 'relative', top: '3px' }} />
      )}
    </LibButton>
  )
}

Button.defaultProps = {
  htmlType: 'button',
  type: 'primary',
  size: 'medium',
  iconPlacement: 'left',
  loading: false,
  disabled: false,
}
