import React from 'react'
import { Box } from 'theme-ui'
import { components, IndicatorProps, Props } from 'react-select'
import { Select as UrsipSelect } from '@ursip/components'

interface ValueI {
  label: string
  value: string
}

export const Select = (props: Props): React.ReactElement => {
  const DropdownIndicator = (
    dropDownProps: IndicatorProps<ValueI>
  ): React.ReactElement => {
    return (
      <components.DropdownIndicator {...dropDownProps}>
        <Box
          sx={{
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: '6px solid',
            borderTopColor: 'grey',
          }}
        />
      </components.DropdownIndicator>
    )
  }
  return <UrsipSelect components={{ DropdownIndicator }} {...props} />
}
