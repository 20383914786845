import { AppealInfoStatusI, NormalizeAppealInfoStatusI } from 'packages/utils'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const normalizeAppealInfoStatus = (
  data: AppealInfoStatusI[]
): NormalizeAppealInfoStatusI[] => {
  const resultInfoStatus: NormalizeAppealInfoStatusI[] = []

  const filteredStatus = (data || []).filter(
    (el) => !el.idStatusLkElement.name && el.lastChangeStatus
  )

  const maxStatusOrder = filteredStatus.reduce((acc, curr) => {
    if (!curr?.statusOrder) {
      return acc
    }

    return acc >= curr.statusOrder ? acc : curr.statusOrder
  }, 0)

  filteredStatus.forEach((el) => {
    const statusGroupName = el.idStatusLkElement.groupName
    let statusColor = 'green'
    const lastElem = data[data.length - 1]?.statusOrder

    const filteredData = data.filter(
      (el) =>
        el.idStatusLkElement.groupName === statusGroupName &&
        el.idStatusLkElement.name &&
        el.lastChangeStatus
    )

    if (filteredStatus[filteredStatus.length - 1].id === el.id) {
      statusColor = 'blue'
    }

    if (el.idStatusLkElement.nick === 'close_contract') {
      statusColor = 'grey'
    }

    if (
      el.idStatusLkElement.nick === 'contract_terminated' ||
      el.idStatusLkElement.nick === 'refusal'
    ) {
      statusColor = 'brown'
    }

    filteredData.forEach((status) => {
      if (status.expired && status?.statusOrder >= maxStatusOrder) {
        statusColor = 'red'
      }
    })

    resultInfoStatus.push({
      parentItem: el,
      items: filteredData,
      statusColor,
      lastElem,
    })
  })

  return resultInfoStatus
}
