import React from 'react'
import { SxStyleProp, Text } from 'theme-ui'

export const tuple = <T extends string[]>(...args: T): Array<string> => args

export const IconTypes = tuple(
  'account_circle',
  'account_box',
  'assignment_ind',
  'delete',
  'delete_forever',
  'edit',
  'filter_none',
  'arrow_upward',
  'arrow_downward',
  'arrow_back',
  'arrow_forward',
  'arrow_drop_down',
  'arrow_drop_up',
  'keyboard_arrow_left',
  'keyboard_arrow_right',
  'keyboard_arrow_down',
  'keyboard_arrow_up',
  'add_circle',
  'add_circle_outline',
  'search',
  'assignment',
  'add',
  'remove_circle_outline',
  'remove_circle',
  'save',
  'more_horiz',
  'more_vert',
  'close',
  'get_app',
  'send',
  'home',
  'help',
  'help_outline',
  'folder_open',
  'check',
  'check_box',
  'check_box_outline_blank',
  'radio_button_checked',
  'radio_button_unchecked',
  'fiber_manual_record',
  'equalizer',
  'description',
  'account_balance_wallet',
  'business',
  'data_usage',
  'pan_tool',
  'assessment',
  'straighten',
  'flip',
  'play_arrow',
  'vpn_key',
  'supervisor_account',
  'school',
  'remove_red_eye'
)

export interface IconProps {
  name?: typeof IconTypes[number]
  sx?: SxStyleProp
  title?: string
  onClick?: (e: React.MouseEvent) => void
}

const Icon: React.FC<IconProps> = ({ name, sx, ...props }) => {
  return (
    <Text
      {...props}
      sx={{
        fontFamily: 'Material Icons',
        fontSize: 3,
        display: 'inline-block',
        position: 'relative',
        p: 0,
        lineHeight: 1,
        fontWeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'none',
        verticalAlign: 'middle',
        color: 'inherit',
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
        direction: 'rtl',
        /* Support for all WebKit browsers. */
        '--webkit-font-smoothing': 'antialiased',
        /* Support for Safari and Chrome. */
        textRendering: 'optimizeLegibility',
        /* Support for Firefox. */
        '-moz-osx-font-smoothing': 'grayscale',
        /* Support for IE. */
        fontFeatureSettings: 'liga',
        ...sx,
      }}
    >
      {name}
    </Text>
  )
}

Icon.defaultProps = {
  name: 'help',
}

export { Icon }
