import * as types from './types'
import { ActionTypes } from './actions.types'

export type UserT = {
  customParam1: string | null
  customParam2: string | null
  customParam3: string | null
  customParam4: string | null
  deleteDate: string | null
  department: string | null
  email: string | null
  fromOshs: boolean
  id: number
  login: string | null
  nameFirst: string | null
  nameLast: string | null
  nameMiddle: string | null
  password: string | null
  phone: string | null
  position: string | null
  role: string | null
}

export type OrgT = {
  id: string
  addressActual: string
  addressOrg: string
  email: string
  fullName: string
  inn: string
  kpp: string
  ogrn: string
  phone: string
  shortName: string
  website: string
}
export type PhysT = {
  id: string
  dateBirth: string
  email: string
  firstName: string
  phone: string
  lastName: string
  middleName: string
}

export type ClientUserT = {
  id: string
  login: string
  userId: string
  phone: string
  email: string
  nameFirst: string
  nameMiddle: string
  nameLast: string
  org: OrgT | null
  physic: PhysT | null
}

export interface InitialStateI {
  isAuth: boolean
  loading: boolean
  error: boolean
  errorMessage: string | null
  registrationMessage: string | null
  registrationProcess: boolean
  userAuthInfo: UserT | null
  clientUser: ClientUserT | null
  userSetting: {
    [settingName: string]: string
  }
}

export const initialState: InitialStateI = {
  isAuth: false,
  loading: false,
  error: false,
  errorMessage: null,
  registrationMessage: null,
  registrationProcess: false,
  userAuthInfo: null,
  clientUser: null,
  userSetting: {},
}

export const reducer = (
  state: InitialStateI = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case types.LOGIN: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        registrationProcess: false,
        registrationMessage: initialState.registrationMessage,
      }
    }
    case types.LOGIN_FAIL: {
      return {
        ...state,
        isAuth: false,
        loading: false,
        error: true,
        errorMessage: action.payload,
      }
    }
    case types.AUTH_SUCCESS: {
      return {
        ...state,
        isAuth: true,
        registrationProcess: false,
        registrationMessage: initialState.registrationMessage,
        loading: false,
        error: false,
      }
    }
    case types.LOGOUT: {
      return {
        ...initialState,
      }
    }
    case types.SET_AUTH_USER_INFO: {
      return {
        ...state,
        userAuthInfo: action.payload,
      }
    }
    case types.SET_CLIENT_USER: {
      return {
        ...state,
        clientUser: action.payload,
      }
    }
    case types.REGISTRATION_START_LOADING: {
      return {
        ...state,
        registrationProcess: true,
      }
    }
    case types.REGISTRATION_END_LOADING: {
      return {
        ...state,
        registrationProcess: false,
      }
    }
    case types.REGISTRATION_END: {
      return {
        ...state,
        registrationProcess: false,
        registrationMessage: action.payload,
      }
    }
    case types.REGISTRATION_END_RESET: {
      return {
        ...initialState,
      }
    }
    case types.SET_USER_SETTING: {
      return {
        ...state,
        userSetting: {
          ...state.userSetting,
          ...action.payload,
        },
      }
    }
    default:
      return state
  }
}
