import React, { FC } from 'react'
import { Flex } from 'theme-ui'

type Props = {
  noHover?: boolean
  onClick?: () => void
  sx?: Record<string, string | number>
}

export const Row: FC<Props> = ({
  noHover = false,
  onClick,
  sx: sxProp,
  children,
}) => {
  const sx = {
    borderBottom: '1px solid #EDEDED',
    padding: '12px 8px',
    cursor: onClick ? 'pointer' : undefined,
    '&:hover': {
      background: !noHover ? '#E5F4FD' : undefined,
    },

    ...sxProp,
  }
  return (
    <Flex onClick={onClick} sx={sx}>
      {children}
    </Flex>
  )
}
