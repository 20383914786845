import React, { ReactNode } from 'react'
import * as R from 'ramda'
import { List, arrayMove, OnChangeMeta } from 'react-movable'
import { useSelector, useDispatch } from 'react-redux'
import { WsLkService } from '@lk-stu3/services'
import { Checkbox } from '@lk-stu3/components'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { Input } from '@ursip/components'
import { Flex, Box, Text, Button } from 'theme-ui'
import { SettingsT } from '@lk-stu3/services/src/ws-stu/nsi-dicts'

const { settingsSelector } = WsLkService

const alter = R.curry((value, id, idKey, key, items) =>
  R.map(R.when(R.propEq(idKey, id), R.assoc(key, value)), items)
)

const compareByColumnIndex = (a: SettingsT, b: SettingsT): number => {
  if (a.ColumnIndex > b.ColumnIndex) {
    return 1
  }
  if (a.ColumnIndex < b.ColumnIndex) {
    return -1
  }
  return 0
}

export const TableSetting = ({
  onClose,
}: {
  onClose: () => void
}): React.ReactElement => {
  const dispatch = useDispatch()
  const { settingsValue = [], tableType } = useSelector(settingsSelector) || {}
  const { login } = useSelector(getAuthUserInfo) || {}
  type SettingsT = typeof settingsValue

  const [items, setItems] = React.useState<SettingsT>([])

  const saveSettings = (): void => {
    dispatch(
      WsLkService.actions.saveSettings({
        settingName: tableType,
        settingValue: JSON.stringify({ [`${tableType}`]: items }),
        userLogin: login,
      })
    )
  }

  const refreshItems = React.useCallback(
    () => settingsValue.sort(compareByColumnIndex),
    [settingsValue]
  )

  React.useEffect(() => {
    setItems(refreshItems)
  }, [refreshItems, settingsValue])

  const fixedCount = items.filter((el) => el.IsFixed).length

  const updateSort = (list: SettingsT): SettingsT =>
    list.map((el, index) => R.assoc('ColumnIndex', index)(el))

  const moveAndAddSort = ({ oldIndex, newIndex }: OnChangeMeta): void => {
    setItems(updateSort(arrayMove(items, oldIndex, newIndex)))
  }

  const onValueChange = (key: string) => (id: string) => (
    value: boolean | string | number
  ): void => {
    setItems(alter(value, id, 'Name', key, items))
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    saveSettings()
    onClose()
  }

  const checkedAll = !items.find((el) => !el.IsVisible)

  const onAllCheck = (): void => {
    setItems(items.map((el) => ({ ...el, IsVisible: !checkedAll })))
  }
  return (
    <form onSubmit={onSubmit}>
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex
          p={3}
          sx={{
            bg: 'muted',
            borderBottom: 'solid 1px',
            borderColor: 'border',
            alignItems: 'center',
            paddingRight: '30px',
          }}
        >
          <Flex sx={{ justifyContent: 'center' }}>
            <Checkbox checked={checkedAll} onChange={onAllCheck} />
          </Flex>
          <Box sx={{ width: 322, pl: 3 }}>
            <Text>Название</Text>
          </Box>
          <Box sx={{ width: 320, pl: 3 }}>
            <Text>Экранное название</Text>
          </Box>
          <Box sx={{ width: 144, pl: 3 }}>
            <Text>Ширина</Text>
          </Box>
          <Box>
            <Text>Фиксировать</Text>
          </Box>
        </Flex>
        <Box
          sx={{
            maxHeight: 'calc(100vh - 400px)',
            overflowY: 'auto',
            '& div:focus': {
              outline: 'none',
            },
            '& > div:active': {
              backgroundColor: 'primaryLight',
            },
          }}
        >
          <List
            values={items}
            onChange={moveAndAddSort}
            renderList={({ children, props }): React.ReactNode => (
              <div {...props}>{children}</div>
            )}
            renderItem={({ value, props }): ReactNode => (
              <Box
                {...props}
                style={{
                  ...props.style,
                  zIndex: 100,
                  cursor: 'move',
                  borderBottom: 'solid 1px #ecebeb',
                  borderRight: 'solid 1px #ecebeb',
                  background: 'white',
                  padding: 16,
                }}
              >
                <Flex>
                  <Box>
                    <Flex
                      sx={{
                        alignItems: 'center',
                        height: 40,
                        flexDirection: 'row',
                      }}
                    >
                      <Checkbox
                        onChange={(checked: boolean): void => {
                          onValueChange('IsVisible')(value.Name)(checked)
                        }}
                        checked={value.IsVisible}
                      />
                    </Flex>
                  </Box>
                  <Box sx={{ width: 320 }}>
                    <Flex
                      sx={{
                        width: '100%',
                        px: 3,
                        alignItems: 'center',
                        height: 40,
                        flexDirection: 'row',
                      }}
                    >
                      <Text sx={{ width: 288 }}>{value.Name}</Text>
                    </Flex>
                  </Box>
                  <Box sx={{ width: 320 }}>
                    <Box sx={{ mx: 3 }}>
                      <Input
                        sx={{ width: 288 }}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          onValueChange('ShowName')(value.Name)(e.target.value)
                        }}
                        value={value.ShowName}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: 144 }}>
                    <Box sx={{ mx: 3 }}>
                      <Input
                        type="number"
                        sx={{ width: 80 }}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          onValueChange('ColumnWidth')(value.Name)(
                            Number(e.target.value)
                          )
                        }
                        value={value.ColumnWidth}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Flex
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 40,
                        flexDirection: 'row',
                      }}
                    >
                      <Checkbox
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onChange={(checked: boolean): void => {
                          onValueChange('IsFixed')(value.Name)(checked)
                        }}
                        checked={value.IsFixed}
                        disabled={!value.IsFixed && fixedCount >= 3}
                        title={
                          !value.IsFixed && fixedCount >= 3
                            ? 'Можно зафиксировать не более трех колонок'
                            : ''
                        }
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            )}
          />
        </Box>
        <Flex
          sx={{ marginTop: '32px', width: '100%', justifyContent: 'flex-end' }}
        >
          <Button
            type="submit"
            variant="primary"
            sx={{ width: '120px' }}
            mr={3}
          >
            Сохранить
          </Button>
          <Button
            type="submit"
            variant="secondary"
            sx={{ width: '120px' }}
            onClick={(e): void => {
              e.preventDefault()
              onClose()
            }}
          >
            Отмена
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}
