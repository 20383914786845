/** @jsx jsx */
import { jsx, Flex, SxProps, Text } from 'theme-ui'

const UploadIcon = (): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M5 4v2h14V4H5zm0 10h4v6h6v-6h4l-7-7-7 7z" />
    </svg>
  )
}

interface FileUploaderI {
  name: string
  title: string
  onChange: (files: FileList) => void
  sx?: SxProps
}

export const FileUploader = ({
  sx,
  name,
  title,
  onChange: onChangeFromProps,
}: FileUploaderI): React.ReactElement => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.currentTarget
    if (files) onChangeFromProps(files)

    e.target.value = ''
  }

  return (
    <Flex
      sx={{
        position: 'relative',
        height: '100%',
        ...sx,
      }}
    >
      <input
        type="file"
        name={name}
        id={name}
        multiple
        onChange={onChange}
        sx={{
          width: '0.1px',
          height: '0.1px',
          opacity: '0',
          overflow: 'hidden',
          position: 'absolute',
          zIndex: '-1',
        }}
      />
      <label
        htmlFor={name}
        sx={{
          padding: '10px 16px',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#0091ea',
          color: '#fff',
          transition: 'opacity 0.2s linear',
          cursor: 'pointer',
          '&:hover': {
            opacity: '0.8',
          },
        }}
      >
        <UploadIcon />
        <Text as="span" sx={{ marginLeft: '12px' }}>
          {title}
        </Text>
      </label>
    </Flex>
  )
}

FileUploader.defaultProps = {
  sx: {},
}
