import { NotificationI, SearchParamsI } from '@lk-stu3/utils'
import { ActionTypes } from './actions'
import * as types from './types'

export type NotificationDataI = Omit<NotificationI, 'status'>
export interface InitialStateI {
  author: string
  UNREAD: NotificationDataI
  READ: NotificationDataI
  unreadSearchParams: SearchParamsI
  readSearchParams: SearchParamsI
  error: boolean
  loading: boolean
}

export const initialState: InitialStateI = {
  author: '',
  UNREAD: {
    notifications: [],
    totalPages: 0,
    totalElements: 0,
  },
  READ: {
    notifications: [],
    totalPages: 0,
    totalElements: 0,
  },
  unreadSearchParams: {
    status: 'UNREAD',
    page: 0,
    size: 10,
  },
  readSearchParams: {
    status: 'READ',
    page: 0,
    size: 10,
  },
  error: false,
  loading: false,
}

export const reducer = (
  state: InitialStateI = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case types.SET_AUTHOR: {
      const author = action.author || 'Логин не найден'
      return {
        ...state,
        author,
      }
    }
    case types.SET_SEARCH_PARAMS: {
      const { activeTab, params } = action
      if (activeTab === 'READ' && params)
        return {
          ...state,
          readSearchParams: params,
        }
      if (activeTab === 'UNREAD' && params)
        return {
          ...state,
          unreadSearchParams: params,
        }
      return state
    }
    case types.SET_NOTIFICATIONS: {
      if (!action.payload) return state
      const { status, ...props } = action.payload
      return {
        ...state,
        error: false,
        loading: false,
        [status]: { ...props },
      }
    }
    default:
      return state
  }
}
