/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable redux-saga/no-unhandled-errors */
import { all, put, select, take, takeEvery } from 'redux-saga/effects'
import { success, error } from '@redux-requests/core'
import { WsLkService, WsStuNsiService } from '@lk-stu3/services'
import { dashboardUtils, findValue } from '@lk-stu3/utils'
import { push } from 'react-router-redux'
import { getAuthUserInfo, getClientUser } from '@lk-stu3/auth'
import * as types from './types'
import * as actions from './actions'
import { idRequestS } from './selectors'

import { closeModal } from '../../module/actions'

const { dictsSelector, getOptionFromNSIDict, dictNames } = WsStuNsiService
const { sourceReceipt, statusAppeal } = dictNames

function* getSetting(name) {
  yield put(
    WsLkService.actions.settingsSearchPOST({
      settingName: name,
      userLogin: null,
    })
  )
  const {
    payload: { data },
  } = yield take([
    success(WsLkService.actions.settingsSearchPOST.toString()),
    error(WsLkService.actions.settingsSearchPOST.toString()),
  ])

  return (data || [])[0]?.settingValue || null
}

function* createAppealSaga(props) {
  const { files, values, links } = props.payload

  const clientUser = yield select(getClientUser)
  const userData = yield select(getAuthUserInfo)
  const dicts = yield select(dictsSelector)

  if (userData !== null && clientUser !== null) {
    const idClientOrg = clientUser.org?.id
    const idClientPhysic = clientUser.physic?.id

    const idSourceReceipt = findValue(
      'Личный кабинет',
      getOptionFromNSIDict(sourceReceipt, dicts)
    )
    const idStatusAppeal = findValue(
      'В работе',
      getOptionFromNSIDict(statusAppeal, dicts)
    )

    const valuesWithUserData = {
      ...values,
      idClientOrg,
      idClientPhysic,
      idSourceReceipt,
      idStatusAppeal,
    }

    const idRegistrator = yield getSetting('idRegistrator')
    const idFolder = yield getSetting('idFolder')

    if (idRegistrator !== null && idFolder !== null) {
      const properties = {
        userLoading: idRegistrator,
        idFolder,
      }

      yield put(
        WsLkService.actions.requestPOST(
          dashboardUtils.requestPOSTnormalize(
            valuesWithUserData,
            files || null,
            properties,
            links
          )
        )
      )
    }
  }
}

function* successSaga({ payload }) {
  const requestId = payload.data?.request?.id
  yield put(actions.requestSuccess(requestId))
}

function* failSaga() {
  yield put(actions.requestFail())
}

function* redirectSaga() {
  const idRequest = yield select(idRequestS)
  yield put(closeModal())
  if (idRequest) yield put(push(`/dashboard/appeal/${idRequest}/info`))
  yield put(actions.clearForm())
}

export function* sagas() {
  yield all([
    takeEvery(types.CREATE_APPEAL, createAppealSaga),
    takeEvery(success(WsLkService.actions.requestPOST.toString()), successSaga),
    takeEvery(
      [
        error(WsLkService.actions.requestPOST.toString()),
        error(WsLkService.actions.settingsSearchPOST.toString()),
      ],
      failSaga
    ),
    takeEvery(types.REDIRECT_AND_CLOSE, redirectSaga),
  ])
}
