import {
  DictValues,
  DictsStateI,
  DictData,
} from '@lk-stu3/services/src/ws-stu/nsi-dicts'

import { hasOwnProperty } from './type-utils'

export const dictNames = {
  statusRequest: 'status_request',
  serviceType: 'service_type',
  objectType: 'object_type',
  region: 'region',
  city: 'city',
  prefect: 'prefect',
  district: 'district',
  street: 'street',
  sourceReceipt: 'source_receipt',
  statusAppeal: 'status_appeal',
  documentsForApplication: 'documents_for_application',
}

export const getOptionFromNSIDict = (
  name: string,
  dicts: DictsStateI | null
): DictValues[] => {
  if (dicts !== null) {
    const dict = dicts[name]
    if (dict) return dict
    return []
  }
  return []
}

export const dictToObjectOptions = (
  arrayOfDicts: DictData[] = []
): DictsStateI => {
  return arrayOfDicts.reduce(
    (dictObject, currentDict) => ({
      ...dictObject,
      [currentDict?.nick]: currentDict.elements
        .map((el) => {
          if (currentDict?.nick === 'notifications') {
            const { valueAttr: title } =
              el.values.find((val) => val.nick === 'name') || {}
            const { valueAttr: topic } =
              el.values.find((val) => val.nick === 'topic') || {}
            const { valueAttr: personal } =
              el.values.find((val) => val.nick === 'personal') || {}
            const { valueAttr: type } =
              el.values.find((val) => val.nick === 'type') || {}
            const { valueAttr: module } =
              el.values.find((val) => val.nick === 'module') || {}

            if (topic && title && personal && type && module)
              return { title, topic, personal, type, module }
            return null
          }
          const name = el.values.find((val) => val.nick === 'name')
          const shortname = el.values.find((val) => val.nick === 'short_name')
          const topic = el.values.find((val) => val.nick === 'topic')
          const nick = el.values.find((val) => val.nick === 'nick')
          const { valueAttr: serviceType } =
            el.values.find((val) => val.nick === 'service_type') || {}

          if (currentDict?.nick === dictNames.serviceType) {
            return {
              label: shortname?.valueAttr,
              value: shortname?.id,
              nick: nick?.valueAttr,
            }
          }

          if (currentDict?.nick === dictNames.documentsForApplication) {
            return {
              label: name?.valueAttr,
              value: name?.id,
              serviceType,
            }
          }

          if (shortname)
            return {
              label: shortname.valueAttr,
              value: shortname.id,
              topic: topic && topic.valueAttr,
            }
          if (name)
            return {
              label: name.valueAttr,
              value: name.id,
              topic: topic && topic.valueAttr,
            }

          return null
        })
        .filter((value) => {
          if (value !== null && hasOwnProperty(value, 'module'))
            return value.module === 'lk'
          return value !== null
        }),
    }),
    {}
  )
}

export const findValue = (name: string, dict: DictValues[] = []): string => {
  if (dict.length === 0) return 'значение не найдено'
  const currentValue = dict.find((el) => el.label === name)
  if (currentValue) return currentValue.value
  return 'значение не найдено'
}
