import produce from 'immer'
import { normalizeData } from '@lk-stu3/utils'
import * as types from './types'

const initialSearchParams = {
  size: 10,
  page: 0,
  sortedField: null,
  orderBy: null,
  helpdeskNumber: null,
  dateRegistrationFrom: null,
  dateRegistrationTo: null,
  idTypeTask: [],
  idStatus: [],
  hdSource: ['LK'],
}

export const initialState = {
  flags: {
    isDeleting: false,
    isSubmitting: false,
    isFilesUploading: false,
    isEdit: false,
    isLoading: false,
    isFullScreen: false,
    isAssigning: false,
    requestResponseLoading: false,
  },
  searchParams: initialSearchParams,
  dicts: {},
  statuses: {},
  requests: {
    byId: {},
    allIds: [],
  },
  totalElements: '-',
  totalPages: 0,
  currentTask: null,
}

export default {
  [types.SET_LOADING]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.isLoading = payload
    }),
  [types.SET_SEARCH_PARAMS]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.searchParams = {
        ...state.searchParams,
        ...payload,
      }
    }),
  [types.CLEAR_SEARCH_PARAMS]: (state) =>
    produce(state, (draftState) => {
      draftState.searchParams = {
        ...initialSearchParams,
        size: state.searchParams.size,
      }
    }),
  [types.SET_DICTS]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.dicts = {
        ...state.dicts,
        ...payload,
      }
    }),
  [types.TOGGLE_FULLSCREEN]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.isFullScreen = payload
    }),
  [types.SET_STATUSES]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.statuses = payload
    }),
  [types.SET_REQUESTS]: (state, { payload }) =>
    produce(state, (draftState) => {
      const { allIds, byId } = normalizeData(payload.content)

      draftState.totalElements = payload.totalElements
      draftState.totalPages = payload.totalPages

      draftState.requests = {
        allIds,
        byId,
      }
    }),
  [types.SET_CURRENT_TASK]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.currentTask = payload
    }),
  [types.SET_IS_EDIT]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.isEdit = payload
    }),
  [types.SET_IS_SUBMITTING]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.isSubmitting = payload
    }),
  [types.SET_FLAG]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.flags = {
        ...state.flags,
        ...payload,
      }
    }),
}
