import React, { FC, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Flex, Box } from 'theme-ui'
import { Select, Pagination } from '@lk-stu3/components'
import * as selectors from './module/selectors'
import { setInboxPaginationAC, setOutboxPaginationAC } from './module/actions'

const options = [
  { label: 'Показывать по 20', value: 20 },
  { label: 'Показывать по 30', value: 30 },
  { label: 'Показывать по 50', value: 50 },
]

type Props = {
  type: 'inbox' | 'outbox' | 'internal'
}

export const TableBottom: FC<Props> = ({ type }) => {
  const dispatch = useDispatch()
  const { inbox, outbox } = useSelector(selectors.namespaceSelector)
  const { inbox: paginationInbox, outbox: paginationOutbox } = useSelector(
    selectors.paginationSelector
  )

  const { page, size, total } = useMemo(() => {
    switch (type) {
      case 'inbox':
        return paginationInbox
      case 'outbox':
        return paginationOutbox

      default:
        return {}
    }
  }, [type, paginationInbox, paginationOutbox])

  const { allIds } = useMemo(() => {
    switch (type) {
      case 'inbox':
        return inbox
      case 'outbox':
        return outbox

      default:
        return {}
    }
  }, [type, inbox, outbox])

  const changePage = useCallback(
    (page) => {
      switch (type) {
        case 'inbox':
          dispatch(setInboxPaginationAC({ page }))
          break
        case 'outbox':
          dispatch(setOutboxPaginationAC({ page }))
          break
      }
    },
    [type]
  )

  const changeSize = useCallback(
    (option) => {
      const payload = {
        page: 0,
        size: option.value,
        total: Math.ceil(allIds.length / option.value),
      }

      switch (type) {
        case 'inbox':
          dispatch(setInboxPaginationAC(payload))
          break
        case 'outbox':
          dispatch(setOutboxPaginationAC(payload))
          break
      }
    },
    [type]
  )

  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        padding: '16px',
        borderColor: 'grey',
        width: '100%',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Pagination
          onChange={changePage}
          totalPages={total}
          activePage={page}
        />
      </Flex>
      <Box sx={{ width: '180px' }}>
        <Select
          title="Количество элементов на странице"
          value={{ label: `Показывать по ${size}` }}
          onChange={changeSize}
          menuPlacement="top"
          options={options}
        />
      </Box>
    </Flex>
  )
}
