import React, { FC } from 'react'
import { Flex } from '@theme-ui/components'
import { Button } from '@lk-stu3/components'
import { SxStyleProp, Text } from 'theme-ui'

type Props = {
  files: [string, File][]
  onDelete?: (id: string) => void
  hideDefaultText?: boolean
  sx?: SxStyleProp
}

export const FileListLocal: FC<Props> = ({
  files,
  hideDefaultText = false,
  onDelete,
  sx,
}) => {
  if (!files?.length) {
    return hideDefaultText ? null : <>'Файлы отсутствуют'</>
  }

  return (
    <Flex sx={{ flexDirection: 'column', ...sx }}>
      {files?.map(([id, { name }]) => {
        if (!name) {
          return null
        }

        return (
          <Flex
            key={id}
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <Text>{name}</Text>
            {!!onDelete && (
              <Button type="flat" onClick={() => onDelete(id)} size="small">
                X
              </Button>
            )}
          </Flex>
        )
      })}
    </Flex>
  )
}
