import { createSelector } from 'reselect'
import { initialState, InitialStateI } from './reducers'
import { moduleName, ModuleI } from './index'

const namespaceStateSelector = (state: ModuleI): InitialStateI =>
  state[moduleName] || initialState

export const currentTaskSelector = createSelector(
  namespaceStateSelector,
  (state) => state.currentTask
)

export const taskLoadingS = createSelector(
  namespaceStateSelector,
  (state) => state.loading
)

export const filesSelector = createSelector(
  namespaceStateSelector,
  (state) => state.files
)
