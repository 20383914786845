import { NotificationI, SearchParamsI } from '@lk-stu3/utils'
import { InferValueTypes } from '@lk-stu3/utils/typescript'
import * as types from './types'

interface SetNotificationsI {
  type: typeof types.SET_NOTIFICATIONS
  payload?: NotificationI
}
interface SetAuthorI {
  type: typeof types.SET_AUTHOR
  author?: string
}

interface SetSearchParamsI {
  type: typeof types.SET_SEARCH_PARAMS
  params?: SearchParamsI
  activeTab?: 'UNREAD' | 'READ'
}

interface SetErrorI {
  type: typeof types.SET_ERROR
}

export const actions = {
  setNotifications: (data: NotificationI): SetNotificationsI => {
    return {
      type: types.SET_NOTIFICATIONS,
      payload: data,
    } as const
  },
  setAuthor: (login: string): SetAuthorI => {
    return {
      type: types.SET_AUTHOR,
      author: login,
    } as const
  },
  setError: (): SetErrorI => {
    return {
      type: types.SET_ERROR,
    } as const
  },
  setSearchParams: (
    params: SearchParamsI,
    activeTab: 'UNREAD' | 'READ'
  ): SetSearchParamsI => {
    return {
      type: types.SET_SEARCH_PARAMS,
      params,
      activeTab,
    } as const
  },
}

export type ActionTypes = ReturnType<InferValueTypes<typeof actions>>
