/* eslint-disable redux-saga/no-unhandled-errors */
import { ISagaModule } from 'redux-dynamic-modules-saga'
import { createAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import {
  dictToObjectOptions,
  getOptionFromNSIDict,
  dictNames,
} from '@lk-stu3/utils'
import { createNamespace } from '../config'

/*
 *  MAIN STU3 http://stu3-dev.ursip.local/ws-stu/swagger-ui.html#/
 *  nginx proxy method
 */

const controller = 'ws-stu'
const api = `/${controller}/v1`
const namespace = createNamespace(controller)

const NSI_DICTS = 'WS_STU_SERVICE-NSI_DICTS' as const
const NSI_DICTS_SUCCESS = 'WS_STU_SERVICE-NSI_DICTS_SUCCESS' as const
const NSI_DICT = 'WS_STU_SERVICE-NSI_DICT' as const

const CREATE_ORG = 'WS_STU_SERVICE-ORG_CREATE' as const
const CREATE_PHYS = 'WS_STU_SERVICE-PHYS_CREATE' as const

const actions = {
  dicts: createAction(NSI_DICTS, (dicts: string[]) => ({
    payload: {
      request: dicts.map((dict) => ({
        method: 'GET',
        url: `${api}/nsi/dict/${dict}`,
      })),
    },
  })),
  getDict: createAction(NSI_DICT, (type: string) => ({
    payload: {
      request: {
        url: `${api}/nsi/dict/${type}`,
        method: 'GET',
      },
    },
    meta: {
      dictName: type,
    },
  })),
  createOrg: createAction(CREATE_ORG, (token, payload) => ({
    meta: {
      runOnRequest: false,
    },
    payload: {
      request: {
        url: `${api}/org-entities/org-entities-representative`,
        method: 'POST',
        body: JSON.stringify({ ...payload, source: 'LK' }),
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${token}`,
        },
      },
    },
  })),
  createPhys: createAction(CREATE_PHYS, (token, payload) => ({
    meta: {
      runOnRequest: false,
    },
    payload: {
      request: {
        url: `${api}/physical-persons`,
        method: 'POST',
        body: JSON.stringify({ ...payload, source: 'LK' }),
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${token}`,
        },
      },
    },
  })),
}

type ValuesT = {
  id: string
  linkValueId: string
  nick: string
  valueAttr: string
}

type ElementsT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any[]
  dateCreate: string
  id: string
  idDict: string
  idParent: string
  note: string
  sortValue: 0
  values: ValuesT[]
}

export type DictData = {
  context: string
  elements: ElementsT[]
  groupName: string
  hierarchyDict: boolean
  name: string
  nick: string
  note: string
}

interface ActionTypes {
  type: typeof NSI_DICTS_SUCCESS
  payload?: { data: DictData[] }
}

export type DictValues = {
  label: string
  value: string
}

export type SettingsT = {
  ColumnIndex: number
  Name: string
  FieldName: string
  ShowName: string
  IsVisible: boolean
  IsFixed: boolean
  ColumnWidth: number
  SortField: string
}

interface TableSettingsI {
  RequestTableSettings: SettingsT[]
}

export interface DictsStateI {
  [name: string]: DictValues[]
}

export interface InitialStateI {
  dicts: DictsStateI | null
  tableSettings: null | TableSettingsI
}

const initialState: InitialStateI = {
  dicts: null,
  tableSettings: null,
}

const dictsReducer = (
  state = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case NSI_DICTS_SUCCESS: {
      return {
        ...state,
        dicts: {
          ...state.dicts,
          ...dictToObjectOptions(action.payload?.data || []),
        },
      }
    }
    default:
      return state
  }
}

const dictNamespace = 'ws-stu' as const

export interface ModuleI {
  [dictNamespace: string]: InitialStateI
}

const dictsModule = (): ISagaModule<ModuleI> => {
  return {
    id: dictNamespace,
    reducerMap: {
      [dictNamespace]: (
        state: InitialStateI = initialState,
        action: ActionTypes
      ): InitialStateI => ({
        ...state,
        ...dictsReducer(state, action),
      }),
    },
  }
}
export interface DictsModuleI {
  [moduleName: string]: InitialStateI
}

const namespaceStateSelector = (state: DictsModuleI): InitialStateI =>
  state[dictNamespace] || {}

const dictsSelector = createSelector(
  namespaceStateSelector,
  (state) => state.dicts || {}
)

export const WsStuNsiService = {
  namespace,
  actions,
  dictsModule,
  dictsSelector,
  getOptionFromNSIDict,
  dictNames,
}
