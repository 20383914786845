import React, { FC, useMemo } from 'react'
import { Flex } from 'theme-ui'
import { NavButton } from '@lk-stu3/components'
import { useHistory } from 'react-router'

type TabT = {
  title: string
  value: string
  tabId: string
}

interface TabsNotificationI {
  match: Record<string, string>
}

const tabs = [
  {
    tabId: 'inbox',
    value: 'Входящие',
    title: 'Просмотреть входящие уведомления',
  },
  {
    tabId: 'outbox',
    value: 'Исходящие',
    title: 'Просмотреть исходящие уведомления',
  },
]

export const TabsNotification: FC<TabsNotificationI> = ({ match }) => {
  const history = useHistory()

  const activeTab = useMemo(() => history.location.pathname.split('/')[5], [
    history.location.pathname,
  ])

  const { url } = match

  const handleClick = (tabId: string) => () => {
    history.push(`${url}/${tabId}`)
  }

  return (
    <Flex
      sx={{
        paddingTop: '4px',
        borderColor: 'border',
      }}
    >
      {tabs.map((tab) => {
        return (
          <NavButton
            key={tab.tabId}
            title={tab.title}
            active={tab.tabId === activeTab}
            onClick={handleClick(tab.tabId)}
          >
            {tab.value}
          </NavButton>
        )
      })}
    </Flex>
  )
}
