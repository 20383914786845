import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Flex } from 'theme-ui'
import { NavLink } from '@lk-stu3/components'
import { WsNotificationsItemService } from '@lk-stu3/services'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { inboxSelector } from './notification/module/selectors'
import { appealErrorS, appealIdS } from './module/selectors'

const getActive = (path: string): string => path.split('/')[4]

export const Tabs = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { byId: inboxById, allIds: inboxAllIds } = useSelector(inboxSelector)
  const appealError = useSelector(appealErrorS)
  const user = useSelector(getAuthUserInfo)
  const appealId = useSelector(appealIdS)

  const unreadCount = useMemo(() => {
    return (
      inboxAllIds
        .map((id) => inboxById[id])
        .filter((el) => el.status === 'UNREAD').length || 0
    )
  }, [inboxAllIds, inboxById])

  useEffect(() => {
    if (appealId && user?.id) {
      dispatch(
        WsNotificationsItemService.actions.searchNotificationsInbox({
          status: 'ALL',
          subscriptionType: 'ALL',
          authId: user?.id,
          payloadPattern: appealId,
          page: 0,
          size: 100,
        })
      )
    }
  }, [appealId, user?.id])

  const {
    location: { pathname },
  } = useHistory()

  const ArrayLink = pathname.split('/')

  const getUrl = (tab: string): string =>
    `${ArrayLink[0]}/${ArrayLink[1]}/${ArrayLink[2]}/${ArrayLink[3]}/${tab}`

  const tabs = [
    {
      title: 'Общие сведения',
      active: getActive(pathname) === 'info',
      to: getUrl('info'),
    },
    {
      title: 'Файлы',
      active: getActive(pathname) === 'files',
      to: getUrl('files'),
    },
    {
      title: 'Уведомления',
      active: getActive(pathname) === 'notification',
      to: getUrl('notification'),
      badgeCount: unreadCount,
    },
    {
      title: 'Сведения о прохождении',
      active: getActive(pathname) === 'appeal-status',
      to: getUrl('appeal-status'),
    },
  ]

  return (
    <Flex
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'border',
      }}
    >
      {tabs.map(({ title, active, to, badgeCount }) => (
        <NavLink
          disabled={appealError}
          key={to}
          active={active}
          to={to}
          badgeCount={badgeCount}
        >
          {title}
        </NavLink>
      ))}
    </Flex>
  )
}
