import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, Flex } from 'theme-ui'
import { colors } from '@lk-stu3/components'

import { BadgeLk } from '@lk-stu3/components/src/badge-lk/badge-lk'
import { WsStuStatusRecord } from '@lk-stu3/services/src/ws-stu/status-record-lk/status-record-lk'
import {
  paginationS,
  queryParamsS,
  searchParamsS,
  statusRecordsSelector,
} from './module/selectors'

import { openModal } from '../../modals'
import { setSearchParams } from './module/actions'

export const HeaderContent = (): React.ReactElement => {
  const dispatch = useDispatch()

  const searchParams = useSelector(searchParamsS)
  const queryParams = useSelector(queryParamsS)
  const { inWork, expired, refusal, completed } =
    useSelector(statusRecordsSelector) || {}

  const openModalTableSetting = (): void => {
    dispatch(openModal('table_setting'))
  }

  const { totalElements } = useSelector(paginationS)

  useEffect(() => {
    dispatch(WsStuStatusRecord.actions.getStatusRecords())
  }, [dispatch])

  const handleBadgeSelect = useCallback(
    (conditionStatus) => {
      dispatch(
        setSearchParams(
          {
            ...searchParams,
            conditionStatus:
              searchParams?.conditionStatus === conditionStatus
                ? null
                : conditionStatus,
          },
          { ...queryParams, page: 0 }
        )
      )
    },
    [dispatch, searchParams, queryParams]
  )

  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: '20px',
      }}
    >
      <Text>Список заявок ({totalElements})</Text>
      <Flex>
        <BadgeLk
          title="Показать заявки в работе"
          variant="green"
          badge={inWork?.count}
          value="IN_WORK"
          activeValue={searchParams?.conditionStatus}
          onClick={handleBadgeSelect}
        >
          В работе
        </BadgeLk>
        <BadgeLk
          title="Показать завершенные заявки"
          variant="grey"
          badge={completed?.count}
          value="COMPLETE"
          activeValue={searchParams?.conditionStatus}
          onClick={handleBadgeSelect}
        >
          Завершено
        </BadgeLk>
        <BadgeLk
          title="Показать заявки в отказе"
          variant="brown"
          badge={refusal?.count}
          value="REFUSE"
          activeValue={searchParams?.conditionStatus}
          onClick={handleBadgeSelect}
        >
          Отказ
        </BadgeLk>
        <BadgeLk
          title="Показать просроченные заявки"
          variant="red"
          badge={expired?.count}
          value="EXPIRED"
          activeValue={searchParams?.conditionStatus}
          onClick={handleBadgeSelect}
        >
          Просрочено
        </BadgeLk>
      </Flex>
      <Flex
        sx={{
          zIndex: 1,
          '& svg': {
            fill: colors.grey,
          },
        }}
      >
        <Flex
          title="Настройки"
          onClick={openModalTableSetting}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover svg': {
              animationIterationCount: 'infinite',
              animationDuration: '5s',
              animationName: 'rotate',
              animationTimingFunction: 'linear',
              '@keyframes rotate': {
                from: {
                  transform: 'scale(1.2) rotate(0deg)',
                },
                to: {
                  transform: 'scale(1.2) rotate(360deg)',
                },
              },
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20px"
            height="20px"
          >
            <g>
              <path d="M0,0h24v24H0V0z" fill="none" />
              <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
            </g>
          </svg>
        </Flex>
      </Flex>
    </Flex>
  )
}
