import { TreeDataNode } from '@lk-stu3/utils'
import { ActionTypes } from './actions'
import * as types from './types'

export interface InitialStateI {
  dataTree: TreeDataNode[]
}

export const initialState: InitialStateI = {
  dataTree: [],
}

export const reducer = (
  state: InitialStateI = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case types.FLATTEN_TREE_UPDATE: {
      const newNodes = action.payload || []
      const filtered = state.dataTree.filter(
        (el) => !newNodes.find((node) => node.id === el.id)
      )
      return {
        ...state,
        dataTree: [...filtered, ...newNodes] || initialState.dataTree,
      }
    }
    case types.FLATTEN_TREE_DELETE_NODE: {
      const id = action.payload || []
      const filtered = state.dataTree.filter((el) => id !== el.id)

      return {
        ...state,
        dataTree: [...filtered] || initialState.dataTree,
      }
    }
    default:
      return state
  }
}
