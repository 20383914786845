/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable redux-saga/no-unhandled-errors */
import { all, takeEvery, put, select } from 'redux-saga/effects'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { folderDataNormalize, fileDataNormalize } from '@lk-stu3/utils'
import { success } from '@redux-requests/core'
import { userSettingS } from '@lk-stu3/auth'
import { actions } from './actions'

import { appealIdS } from '../../module/selectors'

const { treeUpdate, deleteNode } = actions

function* setFoldersToTree({ payload }) {
  const newData = yield folderDataNormalize(payload.data)
  yield put(treeUpdate(newData))
}

function* updateFolders({ meta }) {
  const idAppeal = yield select(appealIdS)
  yield put(
    WsStuFileAndFolder.actions.folderSearch({
      idAppeal,
      global: false,
      idParentFolder: meta.id,
    })
  )
  if (meta.deleteId) yield put(deleteNode(meta.deleteId))
}

function* setFilesToTree({ payload }) {
  const newData = yield fileDataNormalize(payload.data)
  yield put(treeUpdate(newData))
}

function* updateFiles({ meta }) {
  const idAppeal = yield select(appealIdS)
  const userSetting = yield select(userSettingS)
  yield put(
    WsStuFileAndFolder.actions.fileSearch({
      idAppeal,
      idFolder: `${meta.idFolder}`,
      userLoading: userSetting.idRegistrator,
    })
  )
  if (meta.deleteId) yield put(deleteNode(meta.deleteId))
}

export function* sagas() {
  yield all([
    takeEvery(
      [success(WsStuFileAndFolder.actions.folderSearch.toString())],
      setFoldersToTree
    ),
    takeEvery(
      [
        success(WsStuFileAndFolder.actions.folderCreate.toString()),
        success(WsStuFileAndFolder.actions.folderEdit.toString()),
        success(WsStuFileAndFolder.actions.folderDelete.toString()),
      ],
      updateFolders
    ),
    takeEvery(
      [success(WsStuFileAndFolder.actions.fileSearch.toString())],
      setFilesToTree
    ),
    takeEvery(
      [
        success(WsStuFileAndFolder.actions.fileUpload.toString()),
        success(WsStuFileAndFolder.actions.fileEdit.toString()),
        success(WsStuFileAndFolder.actions.fileDelete.toString()),
      ],
      updateFiles
    ),
  ])
}
