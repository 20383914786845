import React from 'react'
import PdfLoader from './PdfLoader'
import PdfAnnotator from './PdfAnnotator'

import DocumentLoading from './DocumentLoading'

import './style/App.css'

const resetHash = () => {
  window.location.hash = ''
}

function App({ noThumbnail, fileUrl }) {
  const onReset = () => {}
  
  if (!fileUrl) return 'Выберите файл'

  console.log(fileUrl)

  return (
    <div
      className="App"
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <PdfLoader url={fileUrl} beforeLoad={<DocumentLoading />}>
          {pdfDocument => (
            <PdfAnnotator
              noThumbnail={noThumbnail}
              highlights={[]}
              pdfDocument={pdfDocument}
              onScrollChange={resetHash}
              url={fileUrl}
              onReset={onReset}
            />
          )}
        </PdfLoader>
      </div>
    </div>
  )
}

export default App
