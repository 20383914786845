import * as types from './types'
import { ActionTypes } from './actions.types'

export type InitialStateT = {
  requestOnLoad: boolean
  requestSuccess: boolean
  requestFail: boolean
  idRequest: string | null
}

export const initialState = {
  requestOnLoad: false,
  requestSuccess: false,
  requestFail: false,
  idRequest: null,
}

export const reducer = (
  state: InitialStateT = initialState,
  action: ActionTypes
): InitialStateT => {
  switch (action.type) {
    case types.CLEAR_NEW_APPEAL: {
      return initialState
    }
    case types.CREATE_APPEAL: {
      return { ...initialState, requestOnLoad: true }
    }
    case types.REQUEST_FAIL: {
      return { ...initialState, requestFail: true }
    }
    case types.REQUEST_SUCCESS: {
      return {
        ...initialState,
        requestSuccess: true,
        idRequest: action.payload || initialState.idRequest,
      }
    }
    default:
      return state
  }
}
