import * as types from './types'
import { Option } from '../models'

interface InitialI {
  type: typeof types.CLEAR_NEW_APPEAL
}

export const clearForm = (): InitialI => {
  return {
    type: types.CLEAR_NEW_APPEAL,
  } as const
}
export interface CreateAppealI<ValuesT> {
  type: typeof types.CREATE_APPEAL
  payload: {
    values: ValuesT
    links: Option[]
    files?: File[]
  }
}

export const createAppeal = <ValuesT>(
  values: ValuesT,
  links: Option[],
  files?: File[]
): CreateAppealI<ValuesT> => {
  return {
    type: types.CREATE_APPEAL,
    payload: { values, files, links },
  } as const
}

interface OnSuccessI {
  type: typeof types.REQUEST_SUCCESS
  payload: string
}

export const requestSuccess = (id: string): OnSuccessI => {
  return {
    type: types.REQUEST_SUCCESS,
    payload: id,
  } as const
}

interface OnFailI {
  type: typeof types.REQUEST_FAIL
}

export const requestFail = (): OnFailI => {
  return {
    type: types.REQUEST_FAIL,
  } as const
}

interface RedirectAndCloseI {
  type: typeof types.REDIRECT_AND_CLOSE
}

export const redirectAndClose = (): RedirectAndCloseI => {
  return {
    type: types.REDIRECT_AND_CLOSE,
  } as const
}
