import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'theme-ui'
import {
  SelectFormik,
  TextAreaFormik,
  Button,
  FileUploader,
} from '@lk-stu3/components'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { WsStuNsiService, helpDeskService } from '@lk-stu3/services'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { useToasts } from 'react-toast-notifications'
import { v4 as uuidv4 } from 'uuid'
import { useDropzone } from 'react-dropzone'
import { isEmpty } from 'lodash'

import { searchParamsSelector } from '../../../routes/tech-support/module/selectors'
import { validate } from './helpers'
import { techSupportRequestModule } from './module'
import { setLoadingAC, setSubmittingAC } from './module/actions'
import {
  dictsSelector,
  isLoadingSelector,
  isSubmittingSelector,
} from './module/selectors'

const initialValues = {
  requestType: '',
  description: '',
}

export const TechSupportRequestModal = ({ onClose }) => {
  return (
    <DynamicModuleLoader modules={[techSupportRequestModule]}>
      <Content onClose={onClose} />
    </DynamicModuleLoader>
  )
}

const Content = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()

  const { type_task_hd, status_hd } = useSelector(dictsSelector)
  const user = useSelector(getAuthUserInfo)
  const isLoading = useSelector(isLoadingSelector)
  const isSubmitting = useSelector(isSubmittingSelector)
  const searchParams = useSelector(searchParamsSelector)

  const [files, setFiles] = useState([])

  const typeOptions = useMemo(() => {
    return type_task_hd?.map((el) => {
      const description = el.values.find((obj) => obj?.nick === 'description')
        ?.valueAttr
      const name = el.values.find((obj) => obj?.nick === 'name')?.valueAttr
      const id = el.values.find((obj) => obj?.nick === 'name')?.id

      return {
        label: name,
        value: id,
        description,
      }
    })
  }, [type_task_hd])

  useEffect(() => {
    const getDicts = async () => {
      dispatch(setLoadingAC(true))

      dispatch(helpDeskService.actions.getStatuses())
      await Promise.all([
        dispatch(WsStuNsiService.actions.getDict('type_task_hd')),
      ]).finally(() => dispatch(setLoadingAC(false)))

      dispatch(WsStuNsiService.actions.getDict('status_hd'))
    }

    getDicts()
  }, [dispatch])

  const handleModalSubmit = useCallback(
    async (values, formApi) => {
      if (isSubmitting) {
        return
      }

      const formData = new FormData()

      const properties = {
        idTypeTask: values.requestType.value,
        description: values.description,
        hdSource: 'LK',
      }
      formData.append('properties', JSON.stringify(properties))

      for (const [key, file] of files) {
        formData.append('files', file)
      }

      dispatch(setSubmittingAC(true))

      await dispatch(helpDeskService.actions.createHDRequest(formData))
        .then(({ data }) => {
          addToast(
            'Заявка отправлена успешно. Проверить её статус можно в разделе "Техподдержка"',
            {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            }
          )

          const { task } = data

          if (
            !isEmpty(task) &&
            history.location.pathname === '/dashboard/tech-support'
          ) {
            setTimeout(
              () =>
                dispatch(
                  helpDeskService.actions.getHelpdeskTasks(searchParams)
                ),
              1000
            )
          }
        })
        .finally(() => {
          setTimeout(() => dispatch(dispatch(setSubmittingAC(false))), 1000)
        })

      setTimeout(() => onClose(), 1000)
    },
    [dispatch, onClose, addToast, files, isSubmitting, history, searchParams]
  )

  const handleFileInputChange = useCallback(
    (e) => {
      const fileList = [...files]
      const newFiles = e.currentTarget ? e.currentTarget?.files : e

      for (const file of newFiles) {
        fileList.push([uuidv4(), file])
      }

      setFiles(fileList)
    },
    [files]
  )

  const { getRootProps } = useDropzone({
    onDrop: handleFileInputChange,
    noClick: true,
  })

  const handleFileDelete = useCallback(
    (key) => {
      const result = files.filter(([id, file]) => id !== key)

      setFiles(result)
    },
    [files]
  )

  return (
    <Box sx={{ minWidth: '400px' }} {...getRootProps()}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleModalSubmit}
        validate={validate}
        sx={{ minWidth: '400px' }}
      >
        {({ values, handleSubmit }) => {
          return (
            <Flex sx={{ flexDirection: 'column' }}>
              <Flex>
                <SelectFormik
                  name="requestType"
                  value={values?.requestType}
                  title="Тип запроса"
                  options={typeOptions}
                  required
                />
              </Flex>
              <Flex sx={{ marginTop: '10px' }}>
                <TextAreaFormik
                  required
                  name="description"
                  value={values?.description}
                  title="Описание"
                  sx={{ minHeight: '100px' }}
                />
              </Flex>
              <Flex sx={{ marginTop: '20px', width: '180px' }}>
                <FileUploader
                  sx={{
                    '& label': {
                      background: '#b7b0b0 !important',
                      color: 'black',
                    },

                    '& svg': {
                      fill: 'black',
                    },
                  }}
                  title="Прикрепить файл"
                  name="files"
                  onChange={(e) => handleFileInputChange(e)}
                />
              </Flex>
              <Flex>
                <FilesList fileList={files} onDelete={handleFileDelete} />
              </Flex>

              <Flex sx={{ justifyContent: 'flex-end', marginTop: '20px' }}>
                <Button
                  loading={isSubmitting}
                  type="primary"
                  sx={{ mr: '16px' }}
                  onClick={handleSubmit}
                >
                  Создать
                </Button>

                <Button
                  color="black !important"
                  type="secondary"
                  onClick={onClose}
                >
                  Отмена
                </Button>
              </Flex>
            </Flex>
          )
        }}
      </Formik>
    </Box>
  )
}

const FilesList = ({ fileList, onDelete }) => {
  return (
    <Flex sx={{ flexDirection: 'column' }}>
      {fileList?.map(([key, value]) => {
        if (!value?.name) {
          return null
        }

        return (
          <Flex key={key} sx={{ justifyContent: 'flex-start' }}>
            <Flex sx={{ alignItems: 'center' }}>{value?.name}</Flex>
            <Button type="flat" onClick={() => onDelete(key)} size="small">
              X
            </Button>
          </Flex>
        )
      })}
    </Flex>
  )
}
