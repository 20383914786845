/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui'
import React from 'react'

import { Navbar } from './navbar'

type DashboardT = {
  children: React.ReactNode
}

// TODO: написать функцию высчитывающую headerHeight и footerHeight

const headerHeight = '54px'
const footerHeight = '30px'

export const Layout = ({ children }: DashboardT): React.ReactElement => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minWidth: '1360px',
        maxWidth: '1920px',
        height: '100vh',
        minHeight: '768px',
        margin: '0 auto',
        overflow: 'hidden',

        boxShadow: '0px 0px 12px -4px rgba(0,0,0,0.75)',
        backgroundColor: 'background',
      }}
    >
      <header
        sx={{
          width: '100%',
          height: headerHeight,
          zIndex: 1,
        }}
      >
        <Navbar />
      </header>
      <main
        sx={{
          width: '100%',
          height: `calc(100% - ${headerHeight} - ${footerHeight})`,
          zIndex: 1,
          overflow: 'auto',
        }}
      >
        {children}
      </main>
      <footer
        sx={{
          width: '100%',
          padding: '5px 8px',
          display: 'flex',
          justifyContent: 'center',
          borderTop: '1px solid',
          borderColor: 'border',
          backgroundColor: 'light',
          height: footerHeight,
          zIndex: 1,
        }}
      >
        <Text sx={{ color: '#c3c3c3' }}>
          URSIP © {new Date().getFullYear()}
        </Text>
      </footer>
    </Flex>
  )
}
