import React, { FC } from 'react'
import { Flex } from 'theme-ui'

export const Layout: FC = ({ children }) => {
  return (
    <Flex
      sx={{
        height: '100%',
        width: '100%',
        padding: '0 18px',
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          height: '100%',
          width: '99%', // убирает горизонтальный скролл
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'auto',
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}
