import { Moment } from 'moment'
import { dateFormat, getMomentDate } from './moment-utils'

interface GetRequestByIdResponseI {
  allFilesUploaded: boolean
  appeal: {
    activiti: {
      active: boolean
      complete: boolean
      formKey: string
      idSectionCase: string
      idTask: string
      processInstanceId: number
      processName: string
      taskName: string
    }
    appealNumber: string
    building: string
    city: {
      id: string
      name: string
    }
    client: {
      firstName: string
      fullName: string
      id: string
      lastName: string
      middleName: string
      shortName: string
      type: string
    }
    comment: string
    dateRegistration: string
    district: {
      id: string
      name: string
    }
    id: string
    landmark: string
    objectName: string
    objectType: {
      id: string
      name: string
    }
    prefect: {
      id: string
      name: string
    }
    region: {
      id: string
      name: string
    }
    registrator: {
      firstName: string
      id: string
      lastName: string
      middleName: string
    }
    serviceType: {
      id: string
      name: string
    }
    sourceReceipt: {
      id: string
      name: string
    }
    statusAppeal: {
      id: string
      name: string
    }
    street: {
      id: string
      name: string
    }
    reasonRefusal: string
    buttonRefusalShow: boolean
  }
  contract: {
    contractNumber: string
    cost: number
    id: string
    dateContract: string
  }
  files: {
    dateLoading: string
    file: {
      fileExt: string
      fileName: string
      fileSize: number
      idFile: string
    }
    id: string
    idAppeal: string
    idCase: string
    idFolder: string
    idTask: string
    userLoading: string
  }[]
  request: {
    id: string
    idOrg: string
    idPhysic: string
    idTask: string
    status: {
      id: string
      name: string
      expired: boolean
      idStatusLkElement: {
        id: string
        nick: string
        name: string
        groupName: string
      }
    }
    user: {
      deleteDate: string
      department: string
      email: string
      id: number
      login: string
      nameFirst: string
      nameLast: string
      nameMiddle: string
      password: string
      phone: string
      position: string
      role: string
    }
  }
  links: FileLink[]
}

type OptionT = {
  label: string
  value: string
} | null

export interface AppealInfoI {
  appealNumber: string
  idStatus: OptionT
  idStatusName: string
  idStatusExpired: boolean
  objectName: string
  idObjectType: OptionT
  idServiceType: OptionT
  dateRegistration: Moment | null
  contractNumber: string
  landmark: string
  idRegion: OptionT
  idCity: OptionT
  idPrefect: OptionT
  idDistrict: OptionT
  idStreet: OptionT
  building: string
  status: string
  reasonRefusal: string
  buttonRefusalShow: boolean
  links: FileLink[]
}
export interface AppealInfoStatusI {
  dateAssignStatus: string | null
  dateValue: string | null
  expired: boolean
  id: string
  idAppeal: string
  idStatusLkElement: {
    finish: boolean
    groupName: string
    id: string
    name: string | null
    nick: string
  }
  lastChangeStatus: boolean
  numberValue: string | null
  statusOrder: number | null
  textValue: string | null
}

export interface NormalizeAppealInfoStatusI {
  parentItem: AppealInfoStatusI
  items: AppealInfoStatusI[]
  statusColor: string
  lastElem: number | null
}

export interface NormalizeAppealInfo extends AppealInfoI {
  requestId: string
  appealId: string
  idStatusName: string
  idStatusExpired: boolean
}

export interface FileLink {
  link: string
  comment: string
}

export const normalizeAppealInfo = (
  req: GetRequestByIdResponseI
): NormalizeAppealInfo => {
  const { appeal, contract, request, links } = req
  const {
    objectType,
    serviceType,
    region,
    city,
    prefect,
    district,
    street,
    reasonRefusal,
    buttonRefusalShow,
  } = appeal || {}

  return {
    reasonRefusal,
    buttonRefusalShow,
    appealId: appeal.id,
    requestId: request.id,
    appealNumber: appeal?.appealNumber || '',
    idStatus: request?.status?.idStatusLkElement && {
      value: request?.status?.idStatusLkElement.id,
      label: request?.status?.idStatusLkElement.groupName,
    },
    idStatusName: request?.status?.idStatusLkElement?.name,
    idStatusExpired: request?.status?.expired,
    objectName: appeal?.objectName,
    idObjectType: objectType && {
      value: objectType.id,
      label: objectType.name,
    },
    idServiceType: serviceType && {
      value: serviceType.id,
      label: serviceType.name,
    },
    dateRegistration: getMomentDate(appeal.dateRegistration),
    contractNumber: contract?.contractNumber
      ? `${contract?.contractNumber} от ${dateFormat(contract?.dateContract)}`
      : '',
    landmark: appeal.landmark || '',
    idRegion: region && {
      value: region.id,
      label: region.name,
    },
    idCity: city && {
      value: city.id,
      label: city.name,
    },
    idPrefect: prefect && {
      value: prefect.id,
      label: prefect.name,
    },
    idDistrict: district && {
      value: district.id,
      label: district.name,
    },
    idStreet: street && {
      value: street.id,
      label: street.name,
    },
    building: appeal.building || '',
    status: request?.status?.name || '',
    links: links || [],
  }
}
