import React, { FC, useCallback, useMemo } from 'react'
import { Spinner, Flex, Text } from 'theme-ui'
import { Checkbox } from '@ursip/components'
import { Icon } from '@lk-stu3/components'
import { useDispatch, useSelector } from 'react-redux'
import { TableBottom } from './table-bottom'
import {
  loadingSelector,
  outboxCheckedAllSelector,
  outboxCheckedSelector,
  outboxSelector,
  paginationSelector,
} from './module/selectors'
import {
  checkAllOutboxAC,
  toggleOutboxElement,
  uncheckAllOutboxAC,
} from './module/actions'
import { HeaderRow } from './header-row'
import { Row } from './row'
import { Layout } from './layout'

type Props = {}

export const Outbox: FC<Props> = () => {
  const dispatch = useDispatch()
  const { allIds, byId } = useSelector(outboxSelector)
  const { outbox: loading } = useSelector(loadingSelector)
  const checked = useSelector(outboxCheckedSelector)
  const checkedAll = useSelector(outboxCheckedAllSelector)

  const {
    outbox: { page, size },
  } = useSelector(paginationSelector)

  const data = useMemo(
    () => allIds.map((id) => byId[id]).slice(page * size, page * size + size),
    [allIds, byId, page, size]
  )

  const handleCheck = (notificationId: string) => () => {
    dispatch(toggleOutboxElement(notificationId))
  }

  const toggleCheckAll = useCallback(
    (e: Event) => {
      if (checkedAll) {
        dispatch(uncheckAllOutboxAC())
      } else {
        dispatch(checkAllOutboxAC())
      }
    },
    [checkedAll, dispatch]
  )

  if (loading) {
    return (
      <Flex
        sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}
      >
        <Spinner color="black" />
      </Flex>
    )
  }

  return (
    <Layout>
      <Flex
        sx={{
          flexDirection: 'column',
          overflowY: 'scroll',
          height: '100%',
        }}
      >
        {data.length > 0 ? (
          <HeaderRow>
            <Flex sx={{ width: '30px', alignItems: 'center' }}>
              <Checkbox checked={checkedAll} onChange={toggleCheckAll} />
            </Flex>
            <Text>Уведомление</Text>
          </HeaderRow>
        ) : (
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            Отсутствуют входящие уведомления
          </Flex>
        )}

        {data.map((notification) => (
          <Row
            key={notification.notificationId}
            onClick={handleCheck(notification.notificationId)}
          >
            <Flex sx={{ width: '30px', alignItems: 'center' }}>
              <Checkbox checked={checked[notification.notificationId]} />
            </Flex>
            <Flex sx={{ width: '30px', alignItems: 'center' }}>
              {notification.isEmail ? (
                <Icon
                  name="mail"
                  title="Уведомление отправлено на почту"
                  sx={{ color: '#000' }}
                />
              ) : (
                <Icon
                  name="notifications"
                  title="Уведомление отправлено в колокольчик"
                  sx={{ color: '#000' }}
                />
              )}
            </Flex>
            <Flex
              sx={{
                alignItems: 'center',
                color: '#000',
                width: '100%',
              }}
            >
              {notification?.note || notification?.text}
            </Flex>
          </Row>
        ))}
      </Flex>

      <TableBottom type="outbox" />
    </Layout>
  )
}
