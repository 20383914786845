import { NormalizeAppealInfoStatusI, NormalizeAppealInfo } from '@lk-stu3/utils'
import * as types from './types'

export interface ModalPropsT {
  [key: string]: string | boolean | number
}
interface IsetRequestId {
  type: typeof types.SET_REQUEST_ID
  payload?: string
}

export const setRequestId = (requestId: string): IsetRequestId => {
  return {
    type: types.SET_REQUEST_ID,
    payload: requestId,
  } as const
}

interface SetAppealInfoI {
  type: typeof types.SET_APPEAL_INFO
  payload?: NormalizeAppealInfo
}

export const setAppealInfo = (
  dataAppeal: NormalizeAppealInfo
): SetAppealInfoI => {
  return {
    type: types.SET_APPEAL_INFO,
    payload: dataAppeal,
  } as const
}

interface SetAppealStatusInfoI {
  type: typeof types.SET_APPEAL_STATUS_INFO
  payload?: NormalizeAppealInfoStatusI[]
}

export const setAppealStatusInfo = (
  dataAppealStatus: NormalizeAppealInfoStatusI[]
): SetAppealStatusInfoI => {
  return {
    type: types.SET_APPEAL_STATUS_INFO,
    payload: dataAppealStatus,
  }
}

interface StartAppealLoadingI {
  type: typeof types.START_APPEAL_LOADING
}

export const startAppealLoading = (): StartAppealLoadingI => {
  return {
    type: types.START_APPEAL_LOADING,
  } as const
}

interface AppealLoadingFailI {
  type: typeof types.APPEAL_LOADING_FAIL
}

export const appealLoadingFail = (): AppealLoadingFailI => {
  return {
    type: types.APPEAL_LOADING_FAIL,
  } as const
}
