import React from 'react'

import { Flex, Grid, Text, Button } from 'theme-ui'
import { Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
import { InputFormik, Spinner } from '@lk-stu3/components'

import { useDispatch } from 'react-redux'
import { WsLkService } from 'packages/services'
import { Layout } from './components/layout'

export const login = 'login'

type StatusT = null | 'success' | 'error' | 'not-found'

const initialValues = {
  email: '',
  login: '',
}

const shema = object().shape({
  email: string()
    // .email('не корректный email адрес')
    .required('введите электронную почту'),
  login: string().required('введите логин'),
})

export const RecoveryPassword = ({
  toLogin,
}: {
  toLogin: () => void
}): React.ReactElement => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [status, setStatus] = React.useState<StatusT>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<any>()

  const onSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ): Promise<void> => {
    const res = await dispatch({
      type: 'GUEST_TOKEN',
      payload: {
        request: {
          url: `/ws-guest/v1/guest-access`,
          method: 'GET',
        },
      },
      meta: {
        runOnRequest: false,
      },
    })
    if (!res.action.error) {
      const { token } = res.action.payload.data
      Promise.all([
        setLoading(true),
        dispatch(WsLkService.actions.userPOST(values, token)),
      ])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((props: any): void => {
          const status = props[1].action?.payload?.data?.status
          if (!status) {
            setStatus('error')
          } else if (status === 'failure') {
            setStatus('not-found')
          } else {
            setStatus(status)
          }
        })
        .then(() => setLoading(false))
    } else {
      actions.setFieldError('email', 'Ошибка сервиса, попробуйте позднее.')
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout
      logo={false}
      sx={{
        minHeight: '250px',
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={shema}
      >
        {({ handleSubmit }): React.ReactElement => (
          <Grid
            sx={{
              minWidth: '400px',
              height: '100%',
              overflowY: 'auto',
              paddingX: '2px',
              gridTemplateRows: '45px 1fr max-content',
              alignItems: 'center',
            }}
          >
            <Text as="h2">Восстановление пароля</Text>
            {loading && <Spinner title="Отправка запроса" />}
            {!loading && status === 'error' && (
              <Text
                sx={{
                  maxWidth: '400px',
                }}
              >
                Ошибка сервиса, попробуйте позже или свяжитесь со службой
                поддержки.
              </Text>
            )}
            {!loading && status === 'not-found' && (
              <Text
                sx={{
                  maxWidth: '400px',
                }}
              >
                Пользователь с таким логином и адресом электронной почты не
                найден
              </Text>
            )}
            {!loading && status === 'success' && (
              <Text
                sx={{
                  maxWidth: '400px',
                }}
              >
                Запрос на восстановление пароля успешно отправлен. В ближайшее
                время на указанный адрес электронной почты поступит письмо.
              </Text>
            )}
            {!loading && status === null && (
              <>
                <InputFormik
                  title="Введите адрес электронной почты, указанный при регистрации"
                  name="email"
                />
                <InputFormik title="Введите ваш логин" name="login" />
              </>
            )}
            {!loading && (
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  variant="secondary"
                  onClick={toLogin}
                  sx={{ width: '110px' }}
                >
                  {status === 'success' ? 'Назад' : 'Отмена'}
                </Button>
                {status === null && (
                  <Button
                    type="submit"
                    onClick={(): void => handleSubmit()}
                    sx={{ width: '110px' }}
                  >
                    Отправить
                  </Button>
                )}
              </Flex>
            )}
          </Grid>
        )}
      </Formik>
    </Layout>
  )
}
