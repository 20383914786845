import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import { Flex, Text, Box } from 'theme-ui'
import { CustomThemeT } from './theme'
import { ArrowTop, ArrowBottom } from './svg-icon'

interface CollapseWrapperI extends CustomThemeT {
  readonly noBorder: boolean
}

const CollapseWrapper = styled.div<CollapseWrapperI>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid
    ${(props): string => (props.noBorder ? 'noone' : props.theme.colors.border)};
`
interface CollapseHeaderI extends CustomThemeT {
  readonly isOpen: boolean
  readonly leftArrow?: boolean
}

const CollapseHeader = styled.div<CollapseHeaderI>`
  position: relative;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: ${({ theme, isOpen }): string =>
    isOpen ? `1px solid ${theme.colors.border}` : '1px solid transparent'};
  justify-content: ${({ leftArrow }) =>
    leftArrow ? 'flex-start' : 'space-between'};

  &:hover {
    background-color: ${(props): string => props.theme.colors.muted};
    cursor: pointer;
  }
  &:active {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }
`

interface CollapseContent extends CustomThemeT {
  readonly height: number
  readonly isOpen: boolean
  readonly overflow: boolean
}

const CollapseContent = styled.div<CollapseContent>`
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  height: ${({ height, isOpen }): number => (isOpen ? height : 0)}px;
  overflow: ${({ overflow }): string => (overflow ? 'visible' : 'hidden')};
`

interface CollapseI {
  children: React.ReactNode
  autoHeight?: boolean
  height?: number
  HeaderContent?: React.ReactNode
  hideDefaultText: boolean
  sx?: Record<string, string>
  leftArrow?: boolean
  isOpen?: boolean
  onClick?: (isOpen: boolean) => void
}

export const Collapse = ({
  children,
  HeaderContent,
  autoHeight = true,
  height = 10,
  hideDefaultText = false,
  sx,
  leftArrow = false,
  isOpen: isOpenFromProps,
  onClick,
}: CollapseI): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [overflow, setOverflow] = React.useState(false)
  const openCollapse = (): void => {
    setIsOpen(!isOpen)
    setOverflow(false)
    onClick && onClick(!isOpen)
  }

  const [calcheight, setHeight] = React.useState(0)

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (isOpenFromProps != undefined) {
      setIsOpen(isOpenFromProps)
    }
  }, [isOpenFromProps])

  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  return (
    <CollapseWrapper noBorder>
      <CollapseHeader leftArrow={leftArrow} isOpen={isOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '0',
            left: '0',
          }}
          onClick={openCollapse}
        />
        {leftArrow ? (
          <Box
            sx={{
              marginRight: '10px',
            }}
          >
            {isOpen ? <ArrowTop /> : <ArrowBottom />}
          </Box>
        ) : undefined}
        {HeaderContent}
        {!leftArrow ? (
          <Flex
            onClick={openCollapse}
            sx={{ alignItems: 'center', minWidth: 'fit-content' }}
          >
            {isOpen ? <ArrowTop /> : <ArrowBottom />}
            {!hideDefaultText && <Text ml={2}>Расширенный поиск</Text>}
          </Flex>
        ) : undefined}
      </CollapseHeader>
      <CollapseContent
        height={autoHeight ? calcheight : height}
        isOpen={isOpen}
        overflow={overflow}
        onTransitionEnd={(): void => {
          if (isOpen) setOverflow(true)
        }}
      >
        <Box ref={measuredRef} style={{ width: '100%' }} sx={sx}>
          {children}
        </Box>
      </CollapseContent>
    </CollapseWrapper>
  )
}
