import React, { FC } from 'react'
import { Flex } from '@theme-ui/components'

export const HeaderRow: FC = ({ children }) => {
  return (
    <Flex
      sx={{
        padding: '8px',
        backgroundColor: '#ECEBEB',
        borderBottom: '1px solid #EDEDED',
      }}
    >
      {children}
    </Flex>
  )
}
