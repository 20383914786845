import React from 'react'
import { Resizable, ResizeCallbackData, ResizableProps } from 'react-resizable'
import styled from '@emotion/styled'
import { Flex } from 'theme-ui'

import { TableProps } from 'rc-table/lib/Table'
import RcTable from 'rc-table'
import {
  ColumnType,
  ColumnsType,
  CellType,
  TableComponents,
  DefaultRecordType,
} from 'rc-table/lib/interface'
import { TableRowLink } from './table-row-link'
import { CustomThemeT, colors } from './theme'

import 'react-resizable/css/styles.css'

const StyledResizeble = styled(Resizable)<ResizableProps>`
  & .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    top: 0;
    cursor: col-resize;
  }
  & .react-resizable-handle {
    background-image: none;
    padding: 0;
    width: 10px;
    height: 100%;
    border-right: 1px solid ${colors.border};
  }
`

const prefixCls = 'ursip-table'

const StyledContainer = styled.div<CustomThemeT>`
  & .${prefixCls} {
    position: relative;
  }
  & .${prefixCls} table {
    border-spacing: 0px;
    width: 100%;
  }
  & .${prefixCls} th {
    color: ${(props): string => props.theme.colors.text};
  }
  & .${prefixCls} th,
  & .${prefixCls} td {
    position: relative;
    border: 1px solid ${(props): string => props.theme.colors.border};
    border-top: 0;
    border-left: 0;
    border-right: 0;
    transition: box-shadow 0.3s;
    padding: 16px 8px;
    box-sizing: border-box;
    background-color: ${(props): string => props.theme.colors.background};
    white-space: normal;
  }
  & .${prefixCls} tr:last-child td {
    border-bottom: 0;
  }
  & .${prefixCls}-cell-fix-left, & .${prefixCls}-cell-fix-right {
    z-index: 1;
  }
  & .${prefixCls}-cell-fix-right:last-child {
    border-right-color: transparent;
  }
  & .${prefixCls}-cell-fix-left-last::after {
    pointer-events: none;
    content: '';
    transition: box-shadow 0.3s;
    position: absolute;
    top: 0;
    bottom: -1px;
    width: 20px;
    right: -1px;
    transform: translateX(100%);
  }
  & .${prefixCls}-cell-fix-right-first {
    /* box-shadow: -1px 0 0 #ff0000; */
  }
  & .${prefixCls}-cell-fix-right-first::after {
    pointer-events: none;
    content: '';
    transition: box-shadow 0.3s;
    position: absolute;
    top: 0;
    bottom: -1px;
    width: 20px;
    left: -1px;
    transform: translateX(-100%);
  }
  & .${prefixCls}-cell.${prefixCls}-cell-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &
    .${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-left-last,
    &
    .${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-right-first {
    overflow: visible;
  }
  &
    .${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-left-last.${prefixCls}-cell-content,
    &
    .${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-right-first
    .${prefixCls}-cell-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  & .${prefixCls}-ping-left & .${prefixCls}-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px #008000;
  }
  & .${prefixCls}-ping-right & .${prefixCls}-cell-fix-right-first::after {
    box-shadow: inset -10px 0 8px -8px #008000;
  }
  & .${prefixCls}-expand-icon-col {
    width: 60px;
  }
  & .${prefixCls}-row-expand-icon-cell {
    text-align: center;
  }
  & .${prefixCls} thead td,
  & .${prefixCls} thead th {
    padding: 8px;
    background: ${(props): string => props.theme.colors.muted};
    text-align: left;
    font-weight: 500;
    font-size: 14px;
  }
  & .${prefixCls} thead .${prefixCls}-cell-scrollbar::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: -1px;
    width: 1px;
    background: #f7f7f7;
  }
  & .${prefixCls}-header {
    border-right: 0;
    border-bottom: 0;
  }
  & .${prefixCls}-placeholder {
    text-align: center;
  }
  & .${prefixCls} tbody tr td,
  & .${prefixCls} tbody tr th {
    background-color: #fff;
    font-size: 12px;
    font-weight: 300;
  }
  & .${prefixCls}-content {
    border-right: 0;
    border-bottom: 0;
    border-radius: 5px 0 0 0;
  }
  & .${prefixCls}-body {
    border-right: 0;
    border-bottom: 0;
    border-top: 0;
  }
  & .${prefixCls}-fixed-column .${prefixCls}-body::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    border-right: 1px solid white;
    z-index: 1;
  }
  & .${prefixCls}-expanded-row .${prefixCls}-cell {
    box-shadow: inset 0 8px 8px -8px #008000;
  }
  & .${prefixCls}-expanded-row-fixed {
    box-sizing: border-box;
    margin: -16px -8px;
    padding: 16px 8px;
    margin-right: -10px;
  }
  & .${prefixCls}-expanded-row-fixed::after {
    content: '';
    position: absolute;
    width: 0;
    top: 0;
    bottom: 0;
    right: 1px;
    border-right: 1px solid white;
  }
  & .${prefixCls}-row-expand-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid currentColor;
    color: #aaa;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    line-height: 16px;
  }
  & .${prefixCls}-row-expand-icon.${prefixCls}-row-expanded::after {
    content: '-';
  }
  & .${prefixCls}-row-expand-icon.${prefixCls}-row-collapsed::after {
    content: '+';
  }
  & .${prefixCls}-row-expand-icon.${prefixCls}-row-spaced {
    visibility: hidden;
  }
  & .${prefixCls}-title {
    // border: 1px solid #ff0000;
    border-bottom: 0;
    padding: 16px 8px;
  }
  & .${prefixCls}-footer {
    // border: 1px solid #ff0000;
    border-top: 0;
    padding: 16px 8px;
  }
`

interface ResizableTitleI<RecordType> extends CellType<RecordType> {
  onResize: (e: React.SyntheticEvent, data: ResizeCallbackData) => void
  onChangeColumnWidth: (dataIndex: string, width: number) => void
  title: string | null
  width: number
  onClick: () => void
  sortField: string
  sortMethod: 'ASC' | 'DESC'
  dataIndex: string
}

type ArrowsT = {
  sortField: string
  sortMethod: 'ASC' | 'DESC'
  dataIndex: string
}

const ArrowBottom = styled.div`
  position: absolute;
  border: 5px solid transparent;
  border-top: 5px solid;
  border-top-color: ${({ isActive }) => (isActive ? '#3a3a3a' : '#b1b1b1')};
  right: 8px;
  top: 16px;
`
const ArrowTop = styled.div`
  position: absolute;
  border: 5px solid transparent;
  border-bottom: 5px solid;
  border-bottom-color: ${({ isActive }) => (isActive ? '#3a3a3a' : '#b1b1b1')};
  right: 8px;
  bottom: 16px;
`

const ArrowContainer = styled.div`
  position: absolute;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);

  &:hover {
    background-color: #ececeb;
  }
`

const Arrows = ({
  sortField,
  sortMethod,
  dataIndex,
}: ArrowsT): React.ReactElement => {
  return (
    <>
      <ArrowTop isActive={sortField === dataIndex && sortMethod === 'ASC'} />
      <ArrowBottom
        isActive={sortField === dataIndex && sortMethod === 'DESC'}
      />
    </>
  )
}

const ResizableTitle = <RecordType extends {}>(
  props: ResizableTitleI<RecordType>
): React.ReactElement => {
  const {
    onResize,
    onChangeColumnWidth,
    width,
    children,
    className,
    colSpan,
    rowSpan,
    style,
    onClick,
    sortField,
    sortMethod,
    dataIndex,
  } = props

  const thProps: React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  > = {
    className,
    colSpan,
    rowSpan,
    style,
  }

  const ArrowProps = {
    sortField,
    sortMethod,
    dataIndex,
  }

  if (!width) {
    return <th {...thProps}>{children}</th>
  }

  return (
    <StyledResizeble
      width={width}
      height={0}
      onResize={onResize}
      onResizeStop={(e: React.SyntheticEvent, data: ResizeCallbackData): void =>
        onChangeColumnWidth(dataIndex, data.size.width)
      }
    >
      <th {...thProps}>
        <Flex sx={{ marginRight: '18px', overflow: 'hidden' }}>{children}</Flex>
        {onClick && (
          <ArrowContainer onClick={onClick}>
            <Arrows {...ArrowProps} />
          </ArrowContainer>
        )}
      </th>
    </StyledResizeble>
  )
}

interface TableI<RecordType> extends TableProps<RecordType> {
  rowLinkCreator: (id: number | string) => string
  onChangeColumnWidth: (dataIndex: string, width: number) => void
  sortOnChange?: (
    sortField: string | null,
    sortMethod: 'ASC' | 'DESC' | null
  ) => void
  initialSort?: {
    sortField: string | number | boolean
    sortMethod: string | number | boolean
  }
}

export const Table = <RecordType extends DefaultRecordType>(
  prop: TableI<RecordType>
): React.ReactElement => {
  const {
    rowLinkCreator,
    columns: columnsData = [],
    sortOnChange,
    initialSort = {
      sortField: null,
      sortMethod: null,
    },
    onChangeColumnWidth,
    ...restProps
  } = prop
  const Link = ({
    children,
    'data-row-key': id,
  }: {
    children: React.ReactNode
    'data-row-key': string | number
  }): React.ReactElement => {
    return id ? (
      <TableRowLink to={rowLinkCreator(id)}>{children}</TableRowLink>
    ) : (
      <>{children}</>
    )
  }

  const components: TableComponents<RecordType> = {
    header: {
      cell: ResizableTitle,
    },
    body: {
      row: Link,
    },
  }

  const [columns, setColumns] = React.useState<ColumnsType<RecordType>>(
    columnsData
  )
  const refreshColumns = React.useMemo(
    () =>
      columnsData.length > 0
        ? [
            ...columnsData,
            {
              title: '',
              dataIndex: 'empty',
              key: 'empty',
            },
          ]
        : [],
    [columnsData]
  )
  React.useEffect(() => {
    setColumns(refreshColumns)
  }, [refreshColumns])

  const handleResize = (index: number) => (
    e: React.SyntheticEvent,
    { size }: ResizeCallbackData
  ): void => {
    setColumns((columns: ColumnsType<RecordType>) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      return nextColumns
    })
  }

  const [sort, setSort] = React.useState(initialSort)

  React.useEffect(() => {
    const { sortField, sortMethod } = initialSort
    if (sortField !== sort.sortField && sortMethod !== sort.sortMethod) {
      setSort(initialSort)
    }
  }, [initialSort, sort])

  const { sortField, sortMethod } = sort

  const changeSort = (column: ColumnType<RecordType>): void => {
    const { dataIndex } = column
    if (typeof dataIndex === 'string') {
      if (dataIndex !== sortField) {
        setSort({ sortField: dataIndex, sortMethod: 'DESC' })
        if (sortOnChange) sortOnChange(dataIndex, 'DESC')
      }
      if (dataIndex === sortField && sortMethod === 'DESC') {
        setSort({ sortField, sortMethod: 'ASC' })
        if (sortOnChange) sortOnChange(sortField, 'ASC')
      }
      if (dataIndex === sortField && sortMethod === 'ASC') {
        setSort({ sortField: null, sortMethod: null })
        if (sortOnChange) sortOnChange(null, null)
      }
    }
  }

  const resColumns: ColumnsType<RecordType> = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<RecordType>): RecordType => {
      return {
        ...column,
        onResize: handleResize(index),
        ...(sortOnChange ? { onClick: () => changeSort(col) } : {}),
        onChangeColumnWidth,
        sortField,
        sortMethod,
        // TODO: переписать без any !!!
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any
    },
    onCell: (data) => {
      return data
    },
  }))

  return (
    <StyledContainer>
      <RcTable<RecordType>
        prefixCls={prefixCls}
        columns={resColumns}
        components={components}
        {...restProps}
      />
    </StyledContainer>
  )
}
