/** @jsx jsx */
import { jsx, Flex, Grid, Button, Text, IconButton } from 'theme-ui'
import { useSelector, useDispatch } from 'react-redux'
import Menu, { MenuItem } from 'rc-menu'
import { Input, Dropdown } from '@ursip/components'
import { Logo } from '@lk-stu3/components'
import { logout, getAuthUserInfo } from '@lk-stu3/auth'
import { Notification as NotificationModule } from '@lk-stu3/notification'
import { Icon } from '@lk-stu3/components'

import { UserInfo } from './user-info'

import { AddIcon, SearchIcon, NotificationIcon, AccountIcon } from './svg-icons'

import { openModal } from './modals'

const DropdownMenu = (): React.ReactElement => {
  const dispatch = useDispatch()
  const signout = (): void => {
    dispatch(logout())
  }

  const openProfile = (): void => {
    dispatch(openModal('profile'))
  }

  const menu = (
    <Menu>
      <MenuItem onClick={openProfile}>Мой профиль</MenuItem>
      <MenuItem onClick={signout}>Выйти</MenuItem>
    </Menu>
  )
  return (
    <Dropdown minOverlayWidthMatchTrigger overlay={menu} trigger="click">
      <IconButton variant="ring">
        <AccountIcon />
      </IconButton>
    </Dropdown>
  )
}

export const Navbar = (): React.ReactElement => {
  const dispatch = useDispatch()
  const user = useSelector(getAuthUserInfo)

  const openModalCreateAppeal = (): void => {
    dispatch(openModal('create_appeal'))
  }

  const openNotificationModal = (): void => {
    dispatch(openModal('notification'))
  }

  const handleTechSupportClick = (): void => {
    dispatch(openModal('tech-support-request'))
  }

  return (
    <Grid
      gap="8px"
      sx={{ padding: '8px 0', backgroundColor: 'primary', color: 'background' }}
      columns={['230px 180px 3fr 80px minmax(min-content, auto) 80px']}
    >
      <Flex>
        <Logo text="Личный кабинет" />
      </Flex>
      <Flex
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '25px',
        }}
      >
        <Button
          sx={{
            padding: '4px 12px',
            height: '32px',
            background: 'linear-gradient(90deg, #419af9, #79b9ff)',
          }}
          variant="outlined"
          onClick={openModalCreateAppeal}
        >
          <Flex>
            <AddIcon />
            <Text sx={{ paddingLeft: '8px' }}>Подать заявку</Text>
          </Flex>
        </Button>
      </Flex>
      <Flex
        sx={{
          alignItems: 'center',
          width: '100%',
          '& > div': { width: '100%' },
        }}
      >
        <Input size="small" placeholder="Поиск" prefix={<SearchIcon />} />
      </Flex>
      <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Button>
          <Icon
            name="help_outline"
            sx={{ fontSize: 4, color: 'white' }}
            onClick={handleTechSupportClick}
            title="Создать запрос в техническую поддержку"
          />
        </Button>
        <NotificationModule
          login={user?.login || 'error_login_not_found'}
          openNotificationTable={openNotificationModal}
        >
          {({ openPortal }): React.ReactElement => (
            <IconButton variant="ring" onClick={openPortal}>
              <NotificationIcon />
            </IconButton>
          )}
        </NotificationModule>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <UserInfo />
      </Flex>
      <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <DropdownMenu />
      </Flex>
    </Grid>
  )
}
