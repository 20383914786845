import React from 'react'
import { PortalWithState } from 'react-portal'
import { OutsideClick } from './outside-click'

export type ContextMenuChildrenPropT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openPortal: (event?: any) => void
  isOpen: boolean
}

export type ContextMenuPropsT = {
  children: (props: ContextMenuChildrenPropT) => React.ReactNode
  menu: (props: { close: () => void; isOpen: boolean }) => React.ReactElement
}

export const ContextMenu = (
  ContextMenuProps: ContextMenuPropsT
): React.ReactElement => {
  const { children, menu: Menu } = ContextMenuProps
  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ openPortal, isOpen, closePortal, portal }): React.ReactElement => {
        return (
          <>
            {children({ openPortal, isOpen })}
            {portal(
              <OutsideClick close={closePortal}>
                <Menu close={closePortal} isOpen={isOpen} />
              </OutsideClick>
            )}
          </>
        )
      }}
    </PortalWithState>
  )
}
