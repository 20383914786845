import {
  AppealsResponse,
  AppealTableI,
} from '@lk-stu3/dashboard/src/routes/appeals/module/interfaces'

import { dateFormat } from './moment-utils'

export const toTableData = (
  appealFromResponse: AppealsResponse[]
): AppealTableI[] | null => {
  const currentFormat = appealFromResponse.map((el) => ({
    id: el.request?.id,
    appealNumber: el.appeal?.appealNumber || '',
    serviceType: el.appeal?.serviceType?.name || '',
    objectType: el.appeal?.objectType?.name || '',
    objectName: el.appeal?.objectName || '',
    address:
      [
        el.appeal?.region?.name,
        el.appeal?.city?.name,
        el.appeal?.prefect?.name,
        el.appeal?.district?.name,
        el.appeal?.street?.name,
        el.appeal?.building,
      ]
        .filter((el) => el)
        .join(', ') || '',
    dateRegistration: dateFormat(el.appeal?.dateRegistration),
    contractNumber: el.contract?.contractNumber || '',
    status: el.request?.status?.idStatusLkElement?.groupName || '',
    landmark: el.appeal?.landmark || '',
    region: el.appeal?.region?.name || '',
    city: el.appeal?.city?.name || '',
    prefect: el.appeal?.prefect?.name || '',
    district: el.appeal?.district?.name || '',
    street: el.appeal?.street?.name || '',
    building: el.appeal?.building || '',
    authorAppeal: [
      el.request?.user?.nameLast,
      el.request?.user?.nameFirst,
      el.request?.user?.nameMiddle,
    ]
      .filter(Boolean)
      .join(' '),
    expired: !!el?.request?.status?.expired,
  }))

  return currentFormat.length > 0 ? currentFormat : null
}
