import { createAction } from '@reduxjs/toolkit'
import { baseURL } from './config'

const controller = 'ws-hd'
const api = `${baseURL}/${controller}/v1`

const actions = {
  getHelpdeskTasks: createAction('get-helpdesk-tasks', (params) => ({
    payload: {
      request: {
        url: `${api}/tasks/search`,
        method: 'POST',
        body: JSON.stringify({ ...params, lkSearch: true }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
  getStatuses: createAction('get-statuses', () => ({
    payload: {
      request: {
        url: `${api}/tasks/status`,
        method: 'GET',
      },
    },
  })),
  createHDRequest: createAction('create-hd-request', (params) => ({
    payload: {
      request: {
        url: `${baseURL}/ws-stu/v1/helpdesk/request`,
        method: 'POST',
        body: params,
      },
    },
  })),
  getTask: createAction('get-task', (id) => ({
    payload: {
      request: {
        url: `${api}/tasks/${id}`,
        method: 'GET',
      },
    },
  })),
  getFiles: createAction('get-files-hd', (id) => ({
    payload: {
      request: {
        url: `${api}/helpdesk-files/task/${id}`,
        method: 'GET',
      },
    },
  })),
  deleteFile: createAction('delete-file-hd', (id) => ({
    payload: {
      request: {
        url: `${api}/helpdesk-files/${id}`,
        method: 'DELETE',
        responseType: null,
      },
    },
  })),
  deleteRequest: createAction('delete-request-hd', (id) => ({
    payload: {
      request: {
        url: `${api}/tasks/${id}`,
        method: 'DELETE',
        responseType: null,
      },
    },
  })),
  uploadFile: createAction('upload-file', (formData, fileName) => ({
    payload: {
      request: {
        url: `${api}/helpdesk-files`,
        method: 'POST',
        body: formData,
      },
    },
    meta: {
      fileName,
    },
  })),
  editTask: createAction('edit-task-hd', (payload) => ({
    payload: {
      request: {
        url: `${api}/tasks`,
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
}

export default { actions }
