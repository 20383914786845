/** @jsx jsx */
import { jsx, InputProps, Flex, Text } from 'theme-ui'

interface InputMui extends InputProps {
  title: string
}

export const InputMui = ({
  value = '',
  onChange,
  title,
  type = 'text',
}: InputProps): React.ReactElement => {
  return (
    <Flex
      sx={{
        position: 'relative',
      }}
    >
      <input
        value={value}
        onChange={onChange}
        type={type}
        required
        sx={{
          position: 'relative',
          backgroundColor: '#f5f5f5',
          border: 0,
          width: '100%',
          borderRadius: '6px',
          padding: '18px',
          '&:focus': {
            border: 0,
            outline: 0,
          },
          '&:focus + #placeholder, &:valid + #placeholder, &:-internal-autofill-selected + #placeholder': {
            backgroundColor: '#f5f5f5',
            transform: 'translate(0.625rem, calc(-100% - 15px)) scale(0.8)',
          },
        }}
      />
      <Text
        id="placeholder"
        sx={{
          pointerEvents: 'none',
          borderRadius: '6px',
          position: 'absolute',
          zIndex: 0,
          top: '50%',
          left: 0,
          padding: '0 0.5rem',
          transform: 'translate(18px, -50%)',
          transformOrigin: '0% 0%',
          backgroundColor: 'transparent',
          transition: 'transform 0.35s, background 0.35s',
        }}
      >
        {title}
      </Text>
    </Flex>
  )
}
