import { createAction } from '@reduxjs/toolkit'

/*
 *  MAIN STU3 http://stu3-dev.ursip.local/ws-stu/swagger-ui.html#/
 *  nginx proxy method
 */

const controller = 'ws-stu'
const api = `/${controller}/v1`

const EDIT_ORG_ENTITIES = 'WS_STU_SERVICE-EDIT_ORG_ENTITIES' as const

interface OrgEntitiesI {
  addressActual: string | null
  addressOrg: string | null
  comment: string | null
  email: string | null
  fullName: string | null
  id: string
  inn: string | null
  kpp: string | null
  ogrn: string | null
  phone: string | null
  shortName: string | null
  typeOrgEntity: string | null
  website: string | null
}

const actions = {
  orgEntitiesPUT: createAction(EDIT_ORG_ENTITIES, (payload: OrgEntitiesI) => ({
    payload: {
      request: {
        url: `${api}/org-entities`,
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
}

export const WsStuOrgEntities = {
  actions,
}
