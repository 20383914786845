import { createSelector } from 'reselect'
import { namespace } from './types'
import { initialState } from './reducers'

export const namespaceSelector = (state) => state[namespace] || initialState

// TODO: replace with real data
export const notificationsS = createSelector(namespaceSelector, (state) => ({
  READ: [],
  UNREAD: [],
}))
//
export const loadingSelector = createSelector(
  namespaceSelector,
  (state) => state.loading
)
export const paginationSelector = createSelector(
  namespaceSelector,
  (state) => state.pagination
)

export const inboxSelector = createSelector(
  namespaceSelector,
  (state) => state.inbox
)
export const outboxSelector = createSelector(
  namespaceSelector,
  (state) => state.outbox
)

export const checkedElementsSelector = createSelector(
  namespaceSelector,
  (state) => state.checkedElements
)
export const inboxCheckedSelector = createSelector(
  checkedElementsSelector,
  (state) => state.inbox
)
export const outboxCheckedSelector = createSelector(
  checkedElementsSelector,
  (state) => state.outbox
)

export const checkAllSelector = createSelector(
  namespaceSelector,
  (state) => state.checkAll
)
export const inboxCheckedAllSelector = createSelector(
  checkAllSelector,
  (state) => state.inbox
)
export const outboxCheckedAllSelector = createSelector(
  checkAllSelector,
  (state) => state.outbox
)
