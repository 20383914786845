import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './app'

import './fonts/HelveticaNeue/stylesheet.css'
// в StrictMode не работает удаление модулей redux-dynamic-modules
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
)
