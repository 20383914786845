import React from 'react'
import { Flex } from 'theme-ui'
import * as image from '../assets/backgroundLoginPage.webp'

export const Background = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        backgroundSize: 'cover',
        background: '#53aae0',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
      }}
    >
      {children}
    </Flex>
  )
}
