import * as types from './types'

export const setDictAC = (payload) => {
  return {
    type: types.SET_DICT,
    payload,
  }
}
export const setLoadingAC = (payload) => {
  return {
    type: types.SET_LOADING,
    payload,
  }
}
export const setSubmittingAC = (payload) => {
  return {
    type: types.SET_SUBMITTING,
    payload,
  }
}
