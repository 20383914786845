import React from 'react'
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { ErrorBoundary, NotFound } from '@lk-stu3/components'

import { Layout } from './layout'
import { Modals, moduleModals } from './modals'

import AppealItem from './routes/appeal-item'
import Appeals from './routes/appeals'
import Settings from './routes/settings'
import TechSupport from './routes/tech-support'
import { TaskItem } from './routes/task-item'

export const Dashboard = ({
  match: { url },
  location,
}: RouteComponentProps): React.ReactElement => {
  return (
    <DynamicModuleLoader modules={[moduleModals()]}>
      <Layout>
        <ErrorBoundary>
          <Switch key={url.split('/')[1]} location={location}>
            <Route
              exact
              path={`${url}`}
              component={(): React.ReactElement => (
                <Redirect to={`${url}/appeals`} />
              )}
            />
            <Route path={`${url}/appeal/`} component={AppealItem} />
            <Route exact path={`${url}/appeals`} component={Appeals} />
            <Route exact path={`${url}/settings`} component={Settings} />
            <Route exact path={`${url}/tech-support`} component={TechSupport} />
            <Route path={`${url}/task/`} component={TaskItem} />
            <Route component={NotFound} />
          </Switch>
        </ErrorBoundary>
        <Modals />
      </Layout>
    </DynamicModuleLoader>
  )
}
