import React from 'react'
import { Card, Text, Flex } from 'theme-ui'
import { useLocation } from 'react-router'
import { Background } from './background'

export const NotFound: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <Background>
      <Card
        sx={{
          backgroundColor: 'background',
          boxShadow: '0 0 6px rgba(0,0,0,0.5)',
          padding: `28px`,

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Flex sx={{ marginBottom: '1rem' }}>
          <Text as="h1" sx={{ display: 'inline', color: 'tomato' }}>
            404
          </Text>
          <Text as="h1" sx={{ marginLeft: '1rem' }}>
            {pathname}
          </Text>
        </Flex>
        <Text as="h3">Запрашиваемая страница не найдена...</Text>
      </Card>
    </Background>
  )
}
