import React from 'react'
import { Flex } from 'theme-ui'

interface ErrorCardI {
  message: string
}

export const ErrorCard = ({ message }: ErrorCardI): React.ReactElement => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        fontSize: '16px',
        fontWeight: '700',
      }}
    >
      {message}
    </Flex>
  )
}
