import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button as LibButton } from 'theme-ui'
import { Flex, Spinner as LibSpinner, Text } from '@theme-ui/components'
import moment from 'moment'
import { Tooltip } from '@ursip/components'
import { WsStuFileAndFolder, helpDeskService } from '@lk-stu3/services'
import { Icon, Button } from '@lk-stu3/components'
import * as router from 'connected-react-router'
import { downloadMultiple } from '@lk-stu3/utils/src/tech-support-utils'
import { MdDownload } from 'react-icons/md'

import { ButtonActions } from './button-actions'
import { ProgressTable } from './progress-table'
import {
  currentTaskSelector,
  taskLoadingS,
  filesSelector,
} from './module/selectors'
import { FilesList } from './file-list'

// поля из ProgressTable, по кототым берутся значения для отображения
const fieldNames = [
  'dateCreate',
  'dateAssignmentExecutor',
  'dateAssignmentExecutor',
  'dateEnd',
  'dateEnd',
]

type TParams = { id: string }

export const TaskTechSupport = ({
  match,
}: RouteComponentProps<TParams>): React.ReactElement => {
  const {
    params: { id },
  } = match

  const dispatch = useDispatch()
  const task = useSelector(currentTaskSelector)
  const loading = useSelector(taskLoadingS)
  const { request: requestFiles, response: responseFiles } = useSelector(
    filesSelector
  )

  const { getFileUrl } = WsStuFileAndFolder

  const totalCount = useMemo(() => {
    if (!task) {
      return 0
    }

    return fieldNames.reduce((acc, fieldName) => {
      const value = task[fieldName]

      if (value) {
        return acc + 1
      }

      return acc
    }, 0)
  }, [task])

  useEffect(() => {
    if (id) {
      dispatch(helpDeskService.actions.getTask(id))
      dispatch(helpDeskService.actions.getFiles(id))
    }
  }, [dispatch, id])

  const handleGoBack = useCallback(() => {
    dispatch(router.push('/dashboard/tech-support'))
  }, [dispatch])

  const handleDownloadAllReq = useCallback(() => {
    const urls = requestFiles.map(({ idFile }) => idFile).map(getFileUrl)
    downloadMultiple(urls, 200, '_blank')
  }, [requestFiles, getFileUrl])

  const handleDownloadAllResp = useCallback(() => {
    const urls = responseFiles.map(({ idFile }) => idFile).map(getFileUrl)
    downloadMultiple(urls, 200, '_blank')
  }, [responseFiles, getFileUrl])

  if (!task || loading) {
    return (
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LibSpinner sx={{ color: '#000' }} />
      </Flex>
    )
  }

  return (
    <Flex
      sx={{
        width: '100%',
        padding: '16px',
        fontSize: '12px',
        overflow: 'auto',
      }}
    >
      <Flex
        sx={{
          border: '1px solid',
          width: '100%',
          borderColor: '#efefef',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Flex
          sx={{
            borderBottom: '1px solid #efefef',
            height: '55px',
            padding: '10px',
            justifyContent: 'space-between',
          }}
        >
          <Flex>
            <LibButton variant="secondary" onClick={handleGoBack}>
              <Icon name="keyboard_arrow_left" />
              Назад
            </LibButton>
            <Flex sx={{ alignItems: 'center', marginLeft: '15px' }}>
              Запрос № {task?.helpdeskNumber}
            </Flex>
          </Flex>
          <Flex>
            <ButtonActions />
          </Flex>
        </Flex>
        <Flex
          sx={{
            width: '50%',
            flexDirection: 'column',
            paddingLeft: '15px',
            marginTop: '15px',
          }}
        >
          {task?.helpdeskNumber && (
            <BorderedRow>
              <Halfrow>Номер</Halfrow>
              <Halfrow>{task?.helpdeskNumber}</Halfrow>
            </BorderedRow>
          )}
          {!!task?.typeTask?.name && (
            <BorderedRow>
              <Halfrow>Тип запроса</Halfrow>
              <Halfrow>{task?.typeTask?.name}</Halfrow>
            </BorderedRow>
          )}
          {task?.dateCreate && (
            <BorderedRow>
              <Halfrow>Дата создания</Halfrow>
              <Halfrow>{moment(task?.dateCreate).format('DD.MM.YYYY')}</Halfrow>
            </BorderedRow>
          )}
          {task?.creator && (
            <BorderedRow>
              <Halfrow>Автор</Halfrow>
              <Halfrow>{`${task?.creator?.nameLast || ''} ${
                task?.creator?.nameFirst || ''
              }`}</Halfrow>
            </BorderedRow>
          )}
          {!!task?.status?.name && (
            <BorderedRow>
              <Halfrow>Статус</Halfrow>
              <Halfrow>{task?.status?.name}</Halfrow>
            </BorderedRow>
          )}
          {task?.dateEnd && (
            <BorderedRow>
              <Halfrow>Дата закрытия</Halfrow>
              <Halfrow>
                <Halfrow>{moment(task?.dateEnd).format('DD.MM.YYYY')}</Halfrow>
              </Halfrow>
            </BorderedRow>
          )}
          {!!task?.description && (
            <BorderedRow>
              <Halfrow>Описание</Halfrow>
              <Halfrow>{task?.description}</Halfrow>
            </BorderedRow>
          )}
          {!!task?.response && (
            <BorderedRow>
              <Halfrow>Ответ на запрос</Halfrow>
              <Halfrow>{task?.response}</Halfrow>
            </BorderedRow>
          )}
          {!!task?.comment && (
            <BorderedRow>
              <Halfrow>Комментарий</Halfrow>
              <Halfrow>{task?.comment}</Halfrow>
            </BorderedRow>
          )}
          <BorderedRow>
            <Halfrow>Файлы запроса</Halfrow>
            <Halfrow
              sx={{
                flexDirection: 'column',
                position: 'relative',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <FilesList sx={{ margin: '12px 0' }} files={requestFiles} />
              {!!requestFiles?.length && (
                <Flex sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <Tooltip title="Скачать все файлы запроса">
                    <Button
                      size="xs"
                      sx={{ bg: '#b5b5b5' }}
                      onClick={handleDownloadAllReq}
                    >
                      <MdDownload />
                    </Button>
                  </Tooltip>
                </Flex>
              )}
            </Halfrow>
          </BorderedRow>
          <BorderedRow>
            <Halfrow>Файлы ответа</Halfrow>
            <Halfrow sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <FilesList
                sx={{ margin: '12px 0' }}
                hideDefault
                files={responseFiles}
              />
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '12px',
                }}
              >
                <Flex sx={{ marginRight: '12px' }}>
                  {responseFiles?.length ? (
                    <Button onClick={handleDownloadAllResp}>
                      <Flex
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <MdDownload />
                        <Text sx={{ ml: '5px' }}>Скачать все</Text>
                      </Flex>
                    </Button>
                  ) : (
                    'Файлы отсутствуют'
                  )}
                </Flex>
              </Flex>
            </Halfrow>
          </BorderedRow>
        </Flex>

        <Flex sx={{ marginTop: '20px', flexDirection: 'column' }}>
          <Flex sx={{ mb: '12px', fontSize: '16px' }}>
            Ход выполнения ({totalCount})
          </Flex>
          <ProgressTable />
        </Flex>
      </Flex>
    </Flex>
  )
}

const BorderedRow = ({ children }: { children?: any }) => {
  return (
    <Flex
      sx={{
        borderBottom: '1px solid #efefef',
        minHeight: '50px',
        alignItems: 'center',
      }}
    >
      {children}
    </Flex>
  )
}

const Halfrow = ({ children, sx, ...props }: { children?: any; sx?: any }) => {
  return (
    <Flex
      sx={{
        width: '50%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Flex>
  )
}
