import { ISagaModule } from 'redux-dynamic-modules-saga'

import { reducer, initialState, InitialStateI } from './reducers'

import { ActionTypes } from './actions.types'
import { setSearchParams } from './actions'
import { sagas } from './sagas'

const moduleName = 'appeals-table'

export interface ModuleI {
  [moduleName: string]: InitialStateI
}

const moduleAppeals = (): ISagaModule<ModuleI> => {
  return {
    id: moduleName,
    reducerMap: {
      [moduleName]: (
        state: InitialStateI = initialState,
        action: ActionTypes
      ): InitialStateI => ({
        ...state,
        ...reducer(state, action),
      }),
    },
    sagas: [sagas],
    initialActions: [setSearchParams({}, { page: 0, size: 10 })],
  }
}

export { moduleName, moduleAppeals }
