/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const calcNewState = (info, prevState) => {
  const dropKey = info.node.props.eventKey
  const dragKey = info.dragNode.props.eventKey
  const dropPos = info.node.props.pos.split('-')
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

  const loop = (data, key, callback) => {
    data.forEach((item, index, arr) => {
      if (item.key === key) {
        callback(item, index, arr)
        return
      }
      if (item.children) {
        loop(item.children, key, callback)
      }
    })
  }
  const data = [...prevState]

  // Find dragObject
  let dragObj
  loop(data, dragKey, (item, index, arr) => {
    arr.splice(index, 1)
    dragObj = item
  })

  if (!info.dropToGap) {
    // Drop on the content
    loop(data, dropKey, (item) => {
      item.children = item.children || []
      // where to insert 示例添加到尾部，可以是随意位置
      item.children.push(dragObj)
    })
  } else if (
    (info.node.props.children || []).length > 0 && // Has children
    info.node.props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, (item) => {
      item.children = item.children || []
      // where to insert 示例添加到尾部，可以是随意位置
      item.children.unshift(dragObj)
    })
  } else {
    // Drop on the gap
    let ar
    let i
    loop(data, dropKey, (item, index, arr) => {
      ar = arr
      i = index
    })
    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj)
    } else {
      ar.splice(i + 1, 0, dragObj)
    }
  }
  return data
}
