import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { moduleTaskItem } from './module'
import { TaskTechSupport } from './task'

type TParams = { url: string }

export const TaskItem = (
  props: RouteComponentProps<TParams>
): React.ReactElement => {
  const {
    match: { url },
  } = props

  return (
    <DynamicModuleLoader modules={[moduleTaskItem()]}>
      <Route exact path={`${url}/:id/info`} component={TaskTechSupport} />
    </DynamicModuleLoader>
  )
}
