import React, {
  createRef,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Button, Text, Box } from 'theme-ui'
import {
  Spinner,
  InputFormik,
  SelectFormik,
  Row,
  FileUploader,
  Collapse,
} from '@lk-stu3/components'
import { Formik, FormikHelpers, FormikErrors } from 'formik'
import { object, mixed, string } from 'yup'
import { WsStuNsiService } from '@lk-stu3/services'
import { AppDispatchT } from '@lk-stu3/main/src/store'
import { Tooltip } from '@ursip/components'
import { Tooltip as TooltipTippy } from 'react-tippy'
import { DictsStateI, DictValues } from '@lk-stu3/services/src/ws-stu/nsi-dicts'

import { ServiceTypeInfoIcon } from '@lk-stu3/components/custom'
import { useDropzone } from 'react-dropzone'
import produce from 'immer'
import { isEmpty } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { createAppeal } from './module/actions'
import { CloseIcon, QuestionmarkIcon } from '../../svg-icons'

import { request } from './module/selectors'
import { LinkItem } from './Option'
import { LinksState, TLinkError } from './models'
import { getInitState, getLocalOption, validateLinks } from './helpers'
import { FileListLocal } from '@lk-stu3/components/src/file-list-local'

const {
  actions,
  dictsSelector,
  getOptionFromNSIDict,
  dictNames,
} = WsStuNsiService

const {
  serviceType,
  objectType,
  region,
  city,
  prefect,
  district,
  street,
  sourceReceipt,
  statusAppeal,
  documentsForApplication,
} = dictNames

const outsideFormikControls: Record<string, unknown> = {
  values: null,
}

const setOutsideFormikControls = (values: InitialValuesI) => {
  outsideFormikControls.values = values
}

type SelectPropT = {
  label: string
  value: string | boolean | number
}
export interface InitialValuesI {
  objectName: string
  building: string
  landmark: string
  idServiceType: SelectPropT | null
  idObjectType: SelectPropT | null
  idRegion: SelectPropT | null
  idCity: SelectPropT | null
  idPrefect: SelectPropT | null
  idDistrict: SelectPropT | null
  idStreet: SelectPropT | null
}

const getInitialValues = (dict: DictsStateI): InitialValuesI => {
  return {
    objectName: '',
    landmark: '',
    building: '',
    idServiceType: null,
    idObjectType: null,
    idRegion:
      getOptionFromNSIDict(region, dict).find((el) => el.label === 'Москва') ||
      null,
    idCity:
      getOptionFromNSIDict(city, dict).find((el) => el.label === 'Москва') ||
      null,
    idPrefect: null,
    idDistrict: null,
    idStreet: null,
  }
}

const shema = object().shape({
  idServiceType: mixed().required('выберите вид услуги'),
  objectName: string()
    .max(255, 'длина поля не более 255 символов')
    .required('введите название объекта'),
})

const validate = (values: InitialValuesI): FormikErrors<InitialValuesI> => {
  const {
    landmark,
    idRegion,
    idCity,
    idPrefect,
    idDistrict,
    idStreet,
    building,
  } = values
  const errors: FormikErrors<InitialValuesI> = {}

  if (
    !(
      landmark.length > 0 ||
      idRegion ||
      idCity ||
      idPrefect ||
      idDistrict ||
      idStreet ||
      building
    )
  ) {
    errors.landmark = 'Необходимо указать адрес объекта или адресный ориентир'
    // errors.idRegion = 'Необходимо указать адрес объекта или адресный ориентир'
    // errors.idCity = 'Необходимо указать адрес объекта или адресный ориентир'
    // errors.idPrefect = 'Необходимо указать адрес объекта или адресный ориентир'
    // errors.idDistrict = 'Необходимо указать адрес объекта или адресный ориентир'
    // errors.idStreet = 'Необходимо указать адрес объекта или адресный ориентир'
    // errors.building = 'Необходимо указать адрес объекта или адресный ориентир'
    errors.idRegion = ' '
    errors.idCity = ' '
    errors.idPrefect = ' '
    errors.idDistrict = ' '
    errors.idStreet = ' '
    errors.building = ' '
  }
  return errors
}

const flags = {
  prevLength: 1,
}

export const FormNewAppeal = ({
  onClose,
}: {
  onClose: () => void
}): ReactElement => {
  // Файлы
  const [fileList, setFileList] = React.useState<[string, File][]>([])
  const [_, setFormValues] = useState<InitialValuesI | undefined>()
  const [isOpen, setIsOpen] = useState(false)
  const [links, setLinks] = useState<LinksState>(getInitState())
  const [linkErrors, setLinkErrors] = useState<TLinkError>({})

  const [linkRefs, setLinkRefs] = useState<
    Record<string, RefObject<HTMLTextAreaElement>>
  >({})

  const { byId: linksById, allIds: linksAllIds } = links

  const { values } = outsideFormikControls || {}

  useEffect(() => {
    setLinkRefs((prev) => {
      const linksMap = links.allIds.reduce(
        (acc, linkId) => ({ ...acc, [linkId]: prev[linkId] || createRef() }),
        {}
      )

      return linksMap
    })
  }, [links.allIds])

  const addLink = useCallback(() => {
    const option = getLocalOption()
    flags.prevLength = links.allIds.length

    setLinks((prev) =>
      produce(prev, (draftState) => {
        draftState.allIds = [...prev.allIds, option.id]
        draftState.byId[option.id] = option
      })
    )
  }, [links.allIds.length])

  useEffect(() => {
    if (links.allIds.length > flags.prevLength) {
      const lastLinkId = links.allIds[links.allIds.length - 1]

      linkRefs[lastLinkId]?.current?.focus()
    }
  }, [linkRefs, links.allIds, links.allIds.length])

  const removeLink = useCallback((id: string) => {
    setLinks((prev) =>
      produce(prev, (draftState) => {
        if (prev.allIds.length <= 1) {
          return
        }

        draftState.allIds = prev.allIds.filter((el) => el !== id)
        delete draftState.byId[id]
      })
    )
  }, [])

  const handleLinkChange = useCallback((id, value) => {
    setLinks((prev) =>
      produce(prev, (draftState) => {
        if (!prev?.byId[id]) {
          draftState.byId[id] = getLocalOption()
        }

        draftState.byId[id].link = value
      })
    )
  }, [])

  const handleCommentChange = useCallback((id, value) => {
    setLinks((prev) =>
      produce(prev, (draftState) => {
        if (!prev?.byId[id]) {
          draftState.byId[id] = getLocalOption()
        }

        draftState.byId[id].comment = value
      })
    )
  }, [])

  const fileUpload = (e: React.FormEvent<HTMLInputElement>): void => {
    const fileListClone = [...fileList]
    const newFiles = e.currentTarget ? e.currentTarget?.files : e

    for (const file of newFiles) {
      fileListClone.push([uuidv4(), file])
    }

    setFileList(fileListClone)
  }

  const handleFileDelete = useCallback(
    (key) => {
      const result = fileList.filter(([id, file]) => id !== key)

      setFileList(result)
    },
    [fileList]
  )

  const { getRootProps } = useDropzone({
    onDrop: fileUpload,
    noClick: true,
  })

  // отправка формы
  const dispatch: AppDispatchT = useDispatch()

  const handleCollapseClick = useCallback((newValue) => {
    setIsOpen((prev) => {
      if (newValue !== prev) {
        return newValue
      }

      return prev
    })
  }, [])

  const onSubmit = (
    values: InitialValuesI,
    actions: FormikHelpers<InitialValuesI>
  ): void => {
    const linksData = links.allIds.map((id) => links.byId[id])
    // const errors = validateLinks(linksData)

    // if (!isEmpty(errors)) {
    //   return
    // }

    const linksDto = linksData
      .map(({ link, comment }) => ({
        link,
        comment,
      }))
      .filter(({ link, comment }) => link || comment)

    dispatch(
      createAppeal<InitialValuesI>(
        values,
        linksDto,
        fileList.map(([id, file]) => file)
      )
    )
    actions.setSubmitting(false)
  }

  // словари для селектов

  React.useEffect(() => {
    dispatch(
      actions.dicts([
        serviceType,
        objectType,
        region,
        city,
        prefect,
        district,
        street,
        sourceReceipt,
        statusAppeal,
        documentsForApplication,
      ])
    )
  }, [dispatch])

  const dicts = useSelector(dictsSelector)
  const getOption = (name: string): DictValues[] =>
    getOptionFromNSIDict(name, dicts)

  const { requestFail, requestOnLoad, requestSuccess } = useSelector(request)

  const documentsForApplicationList = useMemo(() => {
    const result = getOptionFromNSIDict(documentsForApplication, dicts).filter(
      (el) => el?.serviceType === values?.idServiceType?.nick
    )

    return result
  }, [dicts, values, values?.idServiceType])

  const DocumentsList = ({ sx }) => {
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          ...sx,
        }}
      >
        {documentsForApplicationList.length > 0 && (
          <Box>Документы для вида услуг: {values?.idServiceType?.label}</Box>
        )}
        {documentsForApplicationList.map((el, index) => {
          return (
            <Box key={el.value} sx={{ textAlign: 'left' }}>
              {index + 1}. {el.label}
            </Box>
          )
        })}
      </Flex>
    )
  }

  if (requestOnLoad) {
    return (
      <Flex
        sx={{
          minWidth: '400px',
          minHeight: '400px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner title="Отправка заявки" />
      </Flex>
    )
  }

  if (!requestOnLoad && requestSuccess) {
    return (
      <Flex
        sx={{
          minWidth: '400px',
          minHeight: '400px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text>Заявка отправлена успешно</Text>
      </Flex>
    )
  }

  if (!requestOnLoad && requestFail) {
    return (
      <Flex
        sx={{
          minWidth: '400px',
          minHeight: '400px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text>Ошибка при отправке заявки</Text>
      </Flex>
    )
  }

  return (
    <Formik
      initialValues={getInitialValues(dicts)}
      onSubmit={onSubmit}
      validationSchema={shema}
      validate={validate}
    >
      {({ handleSubmit, values, errors }): React.ReactElement => {
        setOutsideFormikControls(values)
        setFormValues(values) // triggers re-render

        const errorKeys = Object.keys(errors)
        const shouldOpen = errorKeys.find((el) => el === 'landmark')

        if (errorKeys.length && shouldOpen && !isOpen) {
          setIsOpen(true)
        }

        // const submit = () => {
        //   const linksData = links.allIds.map((id) => links.byId[id])
        //   const errors = validateLinks(linksData)
        //
        //   setLinkErrors(errors)
        //   handleSubmit()
        // }

        return (
          <Flex
            sx={{
              width: '672px',
              paddingX: '2px',
              flexDirection: 'column',
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto',
              position: 'relative',
            }}
            {...getRootProps()}
          >
            <Row>
              <InputFormik
                name="objectName"
                title="Наименование объекта"
                required
              />
            </Row>
            <Row>
              <SelectFormik
                name="idServiceType"
                title="Вид услуги"
                options={getOption(serviceType)}
                icon={ServiceTypeInfoIcon}
                required
                maxMenuHeight={120}
              />
              <SelectFormik
                name="idObjectType"
                title="Тип объекта"
                options={getOption(objectType)}
              />
            </Row>
            <Collapse
              hideDefaultText
              leftArrow
              isOpen={isOpen}
              onClick={handleCollapseClick}
              HeaderContent={<div>Адрес</div>}
            >
              <Row sx={{ marginTop: '20px' }}>
                <InputFormik name="landmark" title="Адресный ориентир" />
              </Row>
              <Row>
                <SelectFormik
                  name="idRegion"
                  title="Регион"
                  maxMenuHeight={240}
                  options={getOption(region)}
                />
                <SelectFormik
                  name="idCity"
                  title="Город / населенный пункт"
                  maxMenuHeight={240}
                  options={getOption(city)}
                />
              </Row>
              <Row>
                <SelectFormik
                  name="idPrefect"
                  title="Округ"
                  menuPlacement="top"
                  options={getOption(prefect)}
                />
                <SelectFormik
                  name="idDistrict"
                  title="Район"
                  menuPlacement="top"
                  options={getOption(district)}
                />
              </Row>
              <Row>
                <SelectFormik
                  name="idStreet"
                  title="Улица"
                  menuPlacement="top"
                  options={getOption(street)}
                />
                <InputFormik name="building" title="Дом / строение" />
              </Row>
            </Collapse>

            {linksAllIds.map((id) => (
              <Row key={id}>
                <LinkItem
                  ref={linkRefs[id]}
                  data={linksById[id]}
                  errors={linkErrors[id]}
                  onDelete={removeLink}
                  onLinkChange={handleLinkChange}
                  onCommentChange={handleCommentChange}
                />
              </Row>
            ))}

            <Row>
              <Tooltip title="Добавить">
                <Button
                  variant="secondary"
                  sx={{ width: '100%', height: '40px' }}
                  onClick={addLink}
                >
                  +
                </Button>
              </Tooltip>
            </Row>

            {!!fileList.length && (
              <Flex>
                <Flex
                  sx={{
                    position: 'relative',
                    flexWrap: 'wrap',
                    border: '1px dotted',
                    borderColor: 'border',
                    padding: '12px',
                  }}
                >
                  <FileListLocal files={fileList} onDelete={handleFileDelete} />
                </Flex>
              </Flex>
            )}
            <DocumentsList sx={{ margin: '10px 0 20px 0' }} />
            <Flex sx={{ justifyContent: 'space-between', marginTop: 'auto' }}>
              <Flex>
                <FileUploader
                  title="Загрузить документы"
                  name="file"
                  onChange={fileUpload}
                />
                <TooltipTippy
                  theme="light"
                  interactive
                  arrow
                  html={
                    documentsForApplicationList.length ? (
                      <DocumentsList />
                    ) : (
                      <div>Выберите Вид услуги</div>
                    )
                  }
                >
                  <Box sx={{ marginLeft: '10px' }}>
                    <QuestionmarkIcon />
                  </Box>
                </TooltipTippy>
              </Flex>

              <Flex>
                <Button
                  type="submit"
                  variant="primary"
                  sx={{ width: '120px' }}
                  mr={3}
                  onClick={handleSubmit}
                >
                  Отправить
                </Button>
                <Button
                  variant="secondary"
                  onClick={onClose}
                  sx={{ width: '120px' }}
                >
                  Отмена
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )
      }}
    </Formik>
  )
}
