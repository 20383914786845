import React from 'react'
import { Box, Flex } from '@theme-ui/components'
import { Scrollbars } from 'react-custom-scrollbars'


function ImageViewer({ fileUrl }) {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Scrollbars>
        <Flex sx={{ justifyContent: 'center', padding: '16px', width: '100%' }}>
          <img src={fileUrl} alt="изображение" />
        </Flex>
      </Scrollbars>
    </Box>
  )
}

export default ImageViewer
