import React from 'react'
import { AutoSizer, SizeInfo } from 'react-virtualized'
import { Flex } from 'theme-ui'
import { ErrorBoundary } from '@lk-stu3/components'

export const Wrapper = ({
  navbar,
  children,
}: {
  navbar: React.ReactNode
  children: React.ReactNode
}): React.ReactElement => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <ErrorBoundary>{navbar}</ErrorBoundary>
      <Flex
        sx={{
          padding: '16px',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </Flex>
    </Flex>
  )
}

export const WrapperWithBoxParams = ({
  navbar,
  children,
}: {
  navbar: React.ReactNode
  children: (width: number, height: number) => React.ReactNode
}): React.ReactElement => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <ErrorBoundary>{navbar}</ErrorBoundary>
      <div style={{ flex: 1 }}>
        <AutoSizer>
          {({ height, width }: SizeInfo): React.ReactElement => {
            return (
              <Flex
                sx={{
                  height: `${height}px`,
                  width: `${width}px`,
                  flexDirection: 'column',
                  overflowY: 'auto',
                }}
              >
                <ErrorBoundary>{children(width, height)}</ErrorBoundary>
              </Flex>
            )
          }}
        </AutoSizer>
      </div>
    </Flex>
  )
}
