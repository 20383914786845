import React from 'react'
import { Flex } from 'theme-ui'
import { status, StatusT } from '@lk-stu3/utils'

import { TabsNotification } from './tabs'
import { TableNotification } from './table'
import { TablePages } from './table-pages'

export const Notification = ({
  close,
}: {
  close: () => void
}): React.ReactElement => {
  const tabs = [
    { title: 'Непрочитанные', value: status.UNREAD },
    { title: 'Прочитанные', value: status.READ },
  ]
  const [activeTab, setActiveTab] = React.useState<StatusT>(tabs[0].value)
  return (
    <Flex
      sx={{
        height: '50vh',
        width: '80vw',
        minHeight: '300px',
        minWidth: '500px',

        flexDirection: 'column',
      }}
    >
      <TabsNotification
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          overflowY: 'auto',
          border: '1px solid',
          borderColor: 'border',
          borderTop: 'none',
          borderBottom: 'none',
        }}
      >
        <TableNotification activeTab={activeTab} close={close} />
      </Flex>
      <TablePages activeTab={activeTab} />
    </Flex>
  )
}
