import { theme } from '@ursip/components'

const { colors: themeColors } = theme

export const colors = {
  ...themeColors,
  primary: '#419af9',
  primaryLight: '#dceeff',
  light: '#f5f5f5',
  border: '#ecebeb',
  text: '#3a3a3a',
  grey: '#8e8e8e',
  secondary: '#ecebeb',
  success: '#66bb6a',
  disabled: 'hsl(0,0%,60%)',
  danger: '#ef5350',
}

export const customTheme = {
  ...theme,
  styles: {
    root: {
      ...theme.styles.root,
      '.ursip-table thead td, .ursip-table thead th': {
        fontWeight: '500',
        fontSize: '14px',
      },
      '.ursip-table th, .ursip-table td': {
        fontSize: '12px',
        fontWeight: '300',
      },
      'input:disabled': {
        cursor: 'default !important',
        backgroundColor: `${colors.light} !important`,
        color: `${colors.disabled} !important`,
      },
    },
  },
  colors,
  fonts: {
    body: '"HelveticaNeue", Helvetica, Arial, sans-serif',
    heading: '"HelveticaNeue", Helvetica, Arial, sans-serif',
    monospace: '"HelveticaNeue", Helvetica, Arial, sans-serif',
  },
  text: {
    error: {
      color: 'error',
      fontSize: '12px',
      fontStyle: 'italic',
    },
  },
  buttons: {
    tranparent: {
      backgroundColor: 'transparent',
      color: colors.text,
      cursor: 'pointer',
      paddingY: '12px',
      borderRadius: '0px',
      '&:hover': {
        backgroundColor: colors.light,
      },
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    primary: {
      opacity: '1',
      transition: 'all .2s',
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        opacity: '0.8',
      },
      '&:active': {
        boxShadow: '0 0 10px rgba(0,0,0,0.5)',
      },
    },
    badges: {
      green: {
        color: '#fff',
        bg: '#66bb6a',
        padding: '2px 10px',
      },
      grey: {
        color: '#fff',
        bg: '#b5b5b5',
        padding: '2px 10px',
      },
      brown: {
        color: '#fff',
        bg: '#6e4b3f',
        padding: '2px 10px',
      },
      red: {
        color: '#fff',
        bg: '#ef5350',
        padding: '2px 10px',
      },
    },
    secondary: {
      opacity: '1',
      transition: 'opacity .2s',
      backgroundColor: colors.light,
      color: colors.text,
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        opacity: '0.8',
      },
      '&:active': {
        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
      },
    },
    outlined: {
      border: '1px solid #fff',
      opacity: '1',
      transition: 'all .2s',
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        opacity: '0.8',
      },
      '&:active': {
        boxShadow: '0 0 10px rgba(255,255,255,0.5)',
      },
    },
    flat: {
      cursor: 'pointer',
      borderColor: 'transparent !important',
      color: `${colors.primary} !important`,
      backgroundColor: 'transparent',
      textDecoration: 'none !important',
      transition: 'all .2s',
      '&:active': {
        boxShadow: 'none !important',
        textDecoration: 'underline !important',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    bordered: {
      color: colors.text,
      backgroundColor: colors.background,
      border: '1px solid',
      borderColor: colors.border,
      borderRadius: '4px',
      transition: 'background 0.2s linear, color 0.2s linear',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        cursor: 'pointer',
        color: colors.background,
        backgroundColor: colors.primary,
      },
    },
    ring: {
      opacity: '1',
      transition: 'all .2s',
      cursor: 'pointer',
      borderRadius: '50%',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        opacity: '0.8',
      },
      '&:active': {
        boxShadow: '0 0 10px rgba(255,255,255,0.5)',
      },
    },
    'ring-black': {
      opacity: '1',
      transition: 'all .2s',
      cursor: 'pointer',
      borderRadius: '50%',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        opacity: '0.8',
      },
      '&:active': {
        boxShadow: '0 0 10px rgba(0,0,0,0.5)',
      },
    },
  },
}

export type CustomThemeT = {
  theme: typeof customTheme
}
