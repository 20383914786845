import React from 'react'
import { Grid, Text, Card } from 'theme-ui'
import { Spinner } from '@lk-stu3/components'
import { Layout } from './layout'

export const LogInWrapper = ({
  inputs,
  buttons,
  onSubmit,
  loading = false,
  error = false,
  errorMessage,
}: {
  inputs: React.ReactNode
  buttons: React.ReactNode
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  loading?: boolean
  error?: boolean
  errorMessage?: string | null
}): React.ReactElement => {
  if (loading) {
    return (
      <Layout>
        <Spinner logo={false} title="Загрузка профиля" />
      </Layout>
    )
  }
  if (!loading && error && !errorMessage) {
    return (
      <Card
        sx={{
          backgroundColor: 'background',
          boxShadow: '0 0 6px rgba(0,0,0,0.5)',
          padding: '28px 28px 28px 28px',
          maxWidth: 'calc(100vw - 30%)',
        }}
      >
        <Text>Ошибка при загрузке профиля.</Text>
        <Text>Перезагрузите страницу или обратитесь в тех.поддержку.</Text>
      </Card>
    )
  }
  return (
    <Layout>
      <form onSubmit={onSubmit}>
        <Grid
          gap="24px"
          sx={{
            padding: '16px',
            gridColumn: '1fr',
            gridTemplateRows: '1fr',
          }}
        >
          {inputs}

          {buttons}

          <Text
            sx={{
              textAlign: 'center',
              color: 'red',
              minHeight: '1rem',
              marginBottom: '-1rem',
            }}
          >
            {error && errorMessage && errorMessage}
            {error && !errorMessage && 'ошибка авторизации'}
          </Text>
        </Grid>
      </form>
    </Layout>
  )
}
