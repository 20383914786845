import * as types from './types'

export const setSearchParamsAC = (params) => {
  return {
    type: types.SET_SEARCH_PARAMS,
    payload: params,
  }
}
export const clearSearchParamsAC = () => {
  return {
    type: types.CLEAR_SEARCH_PARAMS,
  }
}

export const setDictsAC = (payload) => {
  return {
    type: types.SET_DICTS,
    payload,
  }
}

export const setStatusesAC = (payload) => {
  return {
    type: types.SET_STATUSES,
    payload,
  }
}

export const setRequestsAC = (payload) => {
  return {
    type: types.SET_REQUESTS,
    payload,
  }
}

export const setCurrentTaskAC = (payload) => {
  return {
    type: types.SET_CURRENT_TASK,
    payload,
  }
}

export const setFlagsAC = (payload) => {
  return {
    type: types.SET_FLAG,
    payload,
  }
}
