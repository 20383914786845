import React, { ReactElement } from 'react'
import { NormalizeAppealInfoStatusI } from 'packages/utils'
import { Box, Flex, Text } from 'theme-ui'
import moment from 'moment'
import {
  ClockIcon,
  CubesIcon,
  DocumentSuccessIcon,
  ErrorIcon,
  SuccessIcon,
} from 'packages/dashboard/src/svg-icons'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getIcon = (icon: string) => {
  switch (icon) {
    case 'grey':
      return <DocumentSuccessIcon color={icon} />
    case 'blue':
      return <CubesIcon color={icon} />
    case 'brown':
      return <ErrorIcon color={icon} />
    case 'green':
      return <SuccessIcon color={icon} />
    case 'red':
      return <ClockIcon color={icon} />
    default:
      return ''
  }
}

export const AppealStatusItem = ({
  data,
}: {
  data: NormalizeAppealInfoStatusI
}): ReactElement => {
  const { parentItem, statusColor, lastElem, items } = data
  const viewDash = Boolean(
    parentItem.dateValue || parentItem.numberValue || parentItem.textValue
  )
  return (
    <Box
      sx={{
        padding: '20px 15px',
        borderBottom: '1px solid',
        borderColor: 'border',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        {getIcon(statusColor)}
        <Text
          sx={{
            color: `${statusColor}`,
            fontSize: '16px',
            marginLeft: '5px',
          }}
        >
          {parentItem.idStatusLkElement.groupName}
        </Text>
      </Flex>
      <Box
        key={parentItem.id}
        sx={{
          marginBottom: '5px',
          color: `${
            lastElem === parentItem.statusOrder ? statusColor : 'black'
          }`,
        }}
      >
        {viewDash ? '- ' : ''}
        {parentItem.dateValue
          ? moment(parentItem.dateValue).format('DD/MM/YYYY')
          : ''}{' '}
        {parentItem?.numberValue} {parentItem?.textValue}
      </Box>
      {items.map((el) => (
        <Box
          key={el.id}
          sx={{
            marginBottom: '5px',
            color: `${lastElem === el.statusOrder ? statusColor : 'black'}`,
          }}
        >
          - {el.idStatusLkElement.name}{' '}
          {el.dateValue ? moment(el.dateValue).format('DD/MM/YYYY') : ''}{' '}
          {el?.numberValue} {el?.textValue}
        </Box>
      ))}
    </Box>
  )
}
