import { createSelector } from 'reselect'
import { initialState, InitialStateI } from './reducers'
import { moduleName, ModuleI } from './index'

const namespaceStateSelector = (state: ModuleI): InitialStateI =>
  state[moduleName] || initialState

export const unreadS = createSelector(
  namespaceStateSelector,
  (state) => state.UNREAD
)

export const readS = createSelector(
  namespaceStateSelector,
  (state) => state.READ
)

export const unreadMessageCountS = createSelector(
  namespaceStateSelector,
  (state) => state.UNREAD.totalElements
)

export const ringNotificationsS = createSelector(
  namespaceStateSelector,
  (state) => {
    const { notifications } = state.UNREAD
    if (notifications.length > 10) {
      const firstTenMessage = notifications.slice(0, 10)
      return firstTenMessage
    }
    return notifications
  }
)

export const errorS = createSelector(
  namespaceStateSelector,
  (state) => state.error
)

export const loadingS = createSelector(
  namespaceStateSelector,
  (state) => state.loading
)

export const authorS = createSelector(
  namespaceStateSelector,
  (state) => state.author
)

export const readSearchParamsS = createSelector(
  namespaceStateSelector,
  (state) => state.readSearchParams
)
export const unreadSearchParamsS = createSelector(
  namespaceStateSelector,
  (state) => state.unreadSearchParams
)
