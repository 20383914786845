import { ISagaModule } from 'redux-dynamic-modules-saga'
import { WsNotificationsService } from '@lk-stu3/services'
import { ActionTypes, actions } from './actions'
import { InitialStateI, initialState, reducer } from './reducers'
import { sagas } from './sagas'

const moduleName = '_notifications'

export interface ModuleI {
  [moduleName: string]: InitialStateI
}

const moduleNotifications = (login: string): ISagaModule<ModuleI> => {
  return {
    id: moduleName,
    reducerMap: {
      [moduleName]: (
        state: InitialStateI = initialState,
        action: ActionTypes
      ): InitialStateI => ({
        ...state,
        ...reducer(state, action),
      }),
    },
    initialActions: [
      actions.setAuthor(login),
      WsNotificationsService.actions.searchNotifications(
        initialState.unreadSearchParams
      ),
      WsNotificationsService.actions.searchNotifications(
        initialState.readSearchParams
      ),
    ],
    sagas: [sagas],
  }
}

export { moduleName, moduleNotifications }
