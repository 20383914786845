import styled from '@emotion/styled'
import { CustomThemeT } from './theme'

export const Skeleton = styled.div<CustomThemeT>`
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  & * {
    z-index: -1;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${(props): string => props.theme.colors.light};
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e8e8e8 50%,
      transparent 100%
    );
    animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;

    @keyframes load {
      from {
        left: -150px;
      }
      to {
        left: 100%;
      }
    }
  }
`
