import { all, takeEvery, put } from 'redux-saga/effects'
import { setDictAC } from './actions'
import { WsStuNsiService } from '@lk-stu3/services'
import { success } from '@redux-requests/core'

function* setDictSaga({ payload, meta }) {
  yield put(setDictAC({ [meta.dictName]: payload.data.elements }))
}

export function* sagas() {
  yield all([takeEvery(success(WsStuNsiService.actions.getDict.toString()), setDictSaga)])
}
