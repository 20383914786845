import React from 'react'
import { connect, FormikProps, FormikValues } from 'formik'
import { ValueType, Props } from 'react-select'
import { Select } from '../select'
import { TitledComponent, TitledComponentT } from '../titled-component'

interface SelectT extends Props {
  name: string
  loading?: boolean
  formik?: FormikProps<FormikValues>
  sideEffectFnc?: <T, U>(setFieldValue: T, value: U) => void
}

interface ValueI {
  label: string
  value: string
}

type SelectFormikT = TitledComponentT & SelectT

export const SelectFormik = connect(
  ({
    title,
    required,
    sideEffectFnc,
    name,
    formik,
    sx,
    icon,
    ...props
  }: SelectFormikT): React.ReactElement => {
    const { setFieldValue, setFieldTouched, values } = formik || {}

    const handleChange = async (value: ValueType<ValueI>): Promise<void> => {
      if (sideEffectFnc) sideEffectFnc(value, setFieldValue)
      if (setFieldValue) await setFieldValue(name, value)
      if (setFieldTouched) await setFieldTouched(name, true)
    }
    const formikErrors = formik && formik.errors[name]
    const formikTouched = formik?.touched[name]

    return (
      <TitledComponent
        title={title}
        required={required}
        error={formikErrors}
        touched={!!formikTouched}
        icon={icon}
      >
        <Select
          onChange={handleChange}
          value={values && values[name]}
          isClearable
          {...props}
        />
      </TitledComponent>
    )
  }
)
