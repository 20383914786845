import { AppDispatchT } from '@lk-stu3/main/src/store'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { MenuActionsI } from '@lk-stu3/components/interfaces'
import {
  findRelatedElements,
  TreeDataNode,
  FolderI,
  FileI,
} from '@lk-stu3/utils'
import * as modalActions from '../../../modals/module/actions'

const {
  folderDelete,
  fileUpload,
  folderEdit,
  fileEdit,
} = WsStuFileAndFolder.actions

export const MenuActions = (
  dispatch: AppDispatchT,
  idAppeal: string,
  idRegistrator: string,
  treeData: TreeDataNode[]
): MenuActionsI => {
  return {
    newFolder: (node: FolderI): void => {
      dispatch(
        modalActions.openModal<FolderI>('new_folder', {
          ...node,
          idAppeal,
        })
      )
    },
    renameFolder: (node: FolderI): void => {
      dispatch(
        modalActions.openModal<FolderI>('rename_folder', {
          ...node,
          idAppeal,
        })
      )
    },
    deleteFolder: (node: FolderI): void => {
      const elements = findRelatedElements(treeData, node.id)
      if (elements.related.length > 0) {
        dispatch(
          modalActions.openModal('delete_folder_with_files', {
            node,
            ...elements,
          })
        )
      } else {
        dispatch(folderDelete(node.id, node.idParentFolder))
      }
    },
    downloadFile: (node: FileI): string => {
      return WsStuFileAndFolder.getFileUrl(`${node.idFile}`)
    },
    deleteFile: (node: TreeDataNode): void => {
      dispatch(
        modalActions.openModal<TreeDataNode>('delete_file', {
          ...node,
          idAppeal,
        })
      )
    },
    addFile: (
      fileList: FileList,
      node: FileI,
      { onStart = () => {}, onFinish = () => {} }
    ): void => {
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i]
        const formData = new FormData()
        const properties = {
          dateLoading: new Date().toISOString().slice(0, -1),
          idAppeal,
          idFolder: node.id,
          userLoading: idRegistrator,
        }
        formData.append('file', file)
        formData.append('properties', JSON.stringify(properties))
        onStart()
        dispatch(fileUpload(formData, node.id)).then(onFinish)
      }
    },
    changeParentId: (node: TreeDataNode, parentId: string): void => {
      if (node.type === 'FILE') {
        // изменение pId у файла
        dispatch(
          fileEdit({
            idFolder: parentId,
            dateLoading: node.dateLoading || '',
            id: node.id,
            idAppeal: node.idAppeal || '',
            idFile: node.idFile || '',
            userLoading: idRegistrator,
          })
        )
      } else {
        // изменение pId у папки
        dispatch(
          folderEdit({
            global: node.global || false,
            idAppeal: node.idAppeal || '',
            idParentFolder: parentId,
            name: node.name || '',
          })
        )
      }
    },
  }
}
