import { uniq } from 'lodash'
import { TreeDataNode } from './dashboard-appeal-item-files-utils'
import { hasOwnProperty } from './type-utils'

export const flattenDataToTree = (array: TreeDataNode[]): TreeDataNode[] => {
  const dataArray = uniq(array)
  const unFlatten = (list: TreeDataNode[] = []): TreeDataNode[] => {
    if (list.length === 0) return []
    const map: { [id: string]: number } = {}
    let node
    const roots = []
    let i

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i // initialize the map
      // eslint-disable-next-line no-param-reassign
      list[i].children = [] // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i]
      if (node.pId !== null && node.pId !== undefined) {
        // if you have dangling branches check that map[node.pId] exists

        const checkNodeIsExist =
          node &&
          hasOwnProperty(node, 'pId') &&
          hasOwnProperty(map, node.pId) &&
          hasOwnProperty(list[map[node.pId]], 'children')

        if (checkNodeIsExist) {
          const currentNode = list[map[node.pId]]
          if (Array.isArray(currentNode.children))
            currentNode.children.push(node)
        }
      } else {
        roots.push(node)
      }
    }
    return roots
  }
  return unFlatten(dataArray)
}
