import { createAction } from '@reduxjs/toolkit'
// import {makeKey} from '@lk-stu3/utils'

/*
 *  MAIN STU3 http://stu3-dev.ursip.local/ws-notifications/swagger-ui.html#/
 *  nginx proxy method
 */

const controller = 'ws-notifications'
const api = `/${controller}/v1`

const NOTIFICATIONS_SEARCH = 'WS_NOTIFICATIONS_IDREQUEST_SERVICE-NOTIFICATIONS_SEARCH' as const
const NOTIFICATIONS_MARK_READ = 'WS_NOTIFICATIONS_IDREQUEST_SERVICE-NOTIFICATIONS_MARK_READ' as const
const SEARCH_SUBSCRIPTIONS = 'WS_NOTIFICATIONS_IDREQUEST_SERVICE-SEARCH_SUBSCRIPTIONS' as const
const SUBSCRIPTION = 'WS_NOTIFICATIONS_IDREQUEST_SERVICE-SUBSCRIPTION' as const
const UNSUBSCRIBE = 'WS_NOTIFICATIONS_IDREQUEST_SERVICE-UNSUBSCRIBE' as const

interface NotificationsSearchPOST {
  status: 'READ' | 'UNREAD'
  page: number
  size: number
  type?: string
  sort?: {
    property: string
    direction: 'ASC' | 'DESC'
  }[]
  authId: number
}

const actions = {
  searchNotifications: createAction(
    NOTIFICATIONS_SEARCH,
    (payload: NotificationsSearchPOST) => ({
      payload: {
        request: {
          url: `${api}/notifications/search`,
          method: 'POST',
          body: JSON.stringify({ ...payload, subscriptionType: 'WS' }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      },
      meta: {
        type: payload.status,
      },
    })
  ),
  markReadNotification: createAction(NOTIFICATIONS_MARK_READ, (id) => ({
    payload: {
      request: {
        url: `${api}/notifications/${id}/markread`,
        method: 'PUT',
      },
    },
  })),
  searchSubscriptions: createAction(SEARCH_SUBSCRIPTIONS, () => ({
    payload: {
      request: {
        url: `${api}/subscriptions?page=0&size=5000`,
        method: 'GET',
      },
    },
  })),
  subscription: createAction(SUBSCRIPTION, (payload) => ({
    payload: {
      request: {
        url: `${api}/subscriptions`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
  unsubscribe: createAction(UNSUBSCRIBE, (id) => ({
    payload: {
      request: {
        url: `${api}/subscriptions/${id}`,
        method: 'DELETE',
        responseType: null,
      },
    },
  })),
  searchNotificationsInbox: createAction(
    'search-notifications-inbox',
    (payload) => ({
      payload: {
        request: {
          url: `${api}/notifications/search`,
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      },
    })
  ),
  searchNotificationsOutbox: createAction(
    'search-notifications-outbox',
    (payload) => ({
      payload: {
        request: {
          url: `${api}/notifications/search`,
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      },
    })
  ),
  resendNotification: createAction('resend-notification', (id) => ({
    payload: {
      request: {
        url: `${api}/notifications/${id}/resend`,
        method: 'POST',
        responseType: null,
      },
    },
  })),
}

export const WsNotificationsItemService = {
  actions,
}
