import produce from 'immer'
import * as types from './types'
import { InitialState } from './models'
import { UNREAD_ID } from '../inbox'

const defaultPagination = {
  page: 0,
  size: 20,
  total: 0,
}

const defaultData = {
  byId: {},
  allIds: [],
}

export const initialState: InitialState = {
  checkedElements: {
    inbox: {},
    outbox: {},
  },
  checkAll: {
    inbox: false,
    outbox: false,
  },
  inbox: defaultData,
  outbox: defaultData,
  loading: {
    inbox: false,
    outbox: false,
  },
  pagination: {
    inbox: defaultPagination,
    outbox: defaultPagination,
  },
}

export default {
  [types.SET_PAGINATION]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.pagination = {
        ...state.pagination,
        ...payload,
      }
    }),
  [types.SET_INBOX_PAGINATION]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.pagination.inbox = {
        ...state.pagination.inbox,
        ...payload,
      }

      draftState.checkedElements.inbox = {}
      draftState.checkAll.inbox = false
    }),
  [types.SET_OUTBOX_PAGINATION]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.pagination.outbox = {
        ...state.pagination.outbox,
        ...payload,
      }

      draftState.checkedElements.outbox = {}
      draftState.checkAll.outbox = false
    }),
  [types.SET_INTERNAL_PAGINATION]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.pagination.internal = {
        ...state.pagination.internal,
        ...payload,
      }

      draftState.checkedElements.internal = {}
      draftState.checkAll.internal = false
    }),

  [types.SET_LOADING]: (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.loading = {
        ...state.loading,
        ...payload,
      }
    }),
  [types.SET_INBOX]: (state, { payload }) =>
    produce(state, (draftState) => {
      const allIds = payload.map((el) => el.notificationId)
      const byId = payload.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.notificationId]: curr,
        }
      }, {})

      draftState.inbox = {
        allIds,
        byId,
      }
    }),
  [types.SET_OUTBOX]: (state, { payload }) =>
    produce(state, (draftState) => {
      const allIds = payload.map((el) => el.notificationId)
      const byId = payload.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.notificationId]: curr,
        }
      }, {})

      draftState.outbox = {
        allIds,
        byId,
      }
    }),
  [types.SET_INTERNAL]: (state, { payload }) =>
    produce(state, (draftState) => {
      const allIds = payload.map((el) => el.notificationId)
      const byId = payload.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.notificationId]: curr,
        }
      }, {})

      draftState.internal = {
        allIds,
        byId,
      }
    }),
  [types.TOGGLE_INBOX_ELEMENT]: (state, { payload: notificationId }) =>
    produce(state, (draftState) => {
      draftState.checkedElements.inbox[notificationId] = !state.checkedElements
        .inbox[notificationId]

      const { allIds, byId } = state.inbox
      const { page, size } = state.pagination.inbox
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data
        .filter((el) => el.status === UNREAD_ID)
        .map((el) => el.notificationId)
      const checked = draftState.checkedElements.inbox
      const hasUnchecked = checkableNotificationIds.reduce(
        (acc, curr) => acc || !checked[curr],
        false
      )

      draftState.checkAll.inbox = !hasUnchecked
    }),
  [types.CHECK_INBOX_ELEMENT_BULK]: (state) =>
    produce(state, (draftState) => {
      const { allIds, byId } = state.inbox
      const { page, size } = state.pagination.inbox
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data
        .filter((el) => el.status === UNREAD_ID)
        .map((el) => el.notificationId)

      for (const id of checkableNotificationIds) {
        draftState.checkedElements.inbox[id] = true
      }
      draftState.checkAll.inbox = true
    }),
  [types.UNCHECK_INBOX_ELEMENT_BULK]: (state) =>
    produce(state, (draftState) => {
      const { allIds, byId } = state.inbox
      const { page, size } = state.pagination.inbox
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data
        .filter((el) => el.status === UNREAD_ID)
        .map((el) => el.notificationId)

      for (const id of checkableNotificationIds) {
        draftState.checkedElements.inbox[id] = false
      }
      draftState.checkAll.inbox = false
    }),
  //
  [types.TOGGLE_OUTBOX_ELEMENT]: (state, { payload: notificationId }) =>
    produce(state, (draftState) => {
      draftState.checkedElements.outbox[notificationId] = !state.checkedElements
        .outbox[notificationId]

      const { allIds, byId } = state.outbox
      const { page, size } = state.pagination.outbox
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data.map((el) => el.notificationId)
      const checked = draftState.checkedElements.outbox
      const hasUnchecked = checkableNotificationIds.reduce(
        (acc, curr) => acc || !checked[curr],
        false
      )

      draftState.checkAll.outbox = !hasUnchecked
    }),
  [types.CHECK_OUTBOX_ELEMENT_BULK]: (state) =>
    produce(state, (draftState) => {
      const { allIds, byId } = state.outbox
      const { page, size } = state.pagination.outbox
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data.map((el) => el.notificationId)

      for (const id of checkableNotificationIds) {
        draftState.checkedElements.outbox[id] = true
      }
      draftState.checkAll.outbox = true
    }),
  [types.UNCHECK_OUTBOX_ELEMENT_BULK]: (state) =>
    produce(state, (draftState) => {
      const { allIds, byId } = state.outbox
      const { page, size } = state.pagination.outbox
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data.map((el) => el.notificationId)

      for (const id of checkableNotificationIds) {
        draftState.checkedElements.outbox[id] = false
      }
      draftState.checkAll.outbox = false
    }),
  //
  [types.TOGGLE_INTERNAL_ELEMENT]: (state, { payload: notificationId }) =>
    produce(state, (draftState) => {
      draftState.checkedElements.internal[notificationId] = !state
        .checkedElements.internal[notificationId]

      const { allIds, byId } = state.internal
      const { page, size } = state.pagination.internal
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data.map((el) => el.notificationId)
      const checked = draftState.checkedElements.internal
      const hasUnchecked = checkableNotificationIds.reduce(
        (acc, curr) => acc || !checked[curr],
        false
      )

      draftState.checkAll.internal = !hasUnchecked
    }),
  [types.CHECK_INTERNAL_ELEMENT_BULK]: (state) =>
    produce(state, (draftState) => {
      const { allIds, byId } = state.internal
      const { page, size } = state.pagination.internal
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data.map((el) => el.notificationId)

      for (const id of checkableNotificationIds) {
        draftState.checkedElements.internal[id] = true
      }
      draftState.checkAll.internal = true
    }),
  [types.UNCHECK_INTERNAL_ELEMENT_BULK]: (state) =>
    produce(state, (draftState) => {
      const { allIds, byId } = state.internal
      const { page, size } = state.pagination.internal
      const data = allIds
        .map((id) => byId[id])
        .slice(page * size, page * size + size)
      const checkableNotificationIds = data.map((el) => el.notificationId)

      for (const id of checkableNotificationIds) {
        draftState.checkedElements.internal[id] = false
      }
      draftState.checkAll.internal = false
    }),
}
