import React from 'react'
import { ErrorBoundary, NotFound } from '@lk-stu3/components'

import { Auth } from '@lk-stu3/auth'
import Dashboard from '@lk-stu3/dashboard'

const paths = [
  {
    key: 'dashboard',
    path: '/dashboard/',
    privatePath: true,
    component: Dashboard,
  },
  {
    key: 'not_found',
    component: NotFound,
  },
]

export const Routes = (): React.ReactElement => {
  return (
    <ErrorBoundary>
      <Auth paths={paths} />
    </ErrorBoundary>
  )
}
