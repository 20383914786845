import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotificationTable } from '@lk-stu3/notification'
import { getAuthUserInfo, getClientUser } from '@lk-stu3/auth'

import { ModalWrapper } from './modal-wrapper'
import { closeModal } from './module/actions'
import { modalIsOpenS, modalPropsS, modalTypeS } from './module/selectors'

// modals
import { TableSetting } from './table-setting'
import { NewAppeal } from './new-appeal'
import { NewFolder } from './new-folder'
import { RenameFolder } from './rename-folder'
import { DeleteFolderWithFiles } from './delete-folder-with-files'
import { ReplaceDocuments } from './replace-documents'
import { DeleteFile } from './delete-file'
import { ChangeAppeal } from './change-appeal'
import { RefusalAppeal } from './refusal-appeal'
import { Profile } from './profile'
import { TechSupportRequestModal } from './tech-support-modals/tech-support-request/tech-support-request'
import { DeleteRequestModal } from './tech-support-modals/delete-request/delete-request'

export * from './module'

export const Modals = (): React.ReactElement | null => {
  const modalProps = useSelector(modalPropsS)
  const modalType = useSelector(modalTypeS)
  const modalIsOpen = useSelector(modalIsOpenS)
  const clientUser = useSelector(getClientUser)
  const authUser = useSelector(getAuthUserInfo)

  const dispatch = useDispatch()
  const onClose = (): void => {
    dispatch(closeModal())
  }

  switch (modalType) {
    case 'new_folder':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Создание новой папки"
        >
          <NewFolder props={modalProps} onClose={onClose} />
        </ModalWrapper>
      )
    case 'rename_folder':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Переименование папки"
        >
          <RenameFolder props={modalProps} onClose={onClose} />
        </ModalWrapper>
      )
    case 'delete_folder_with_files':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="В удаляемой папке есть файлы. Выберите дальнейшее действие."
        >
          <DeleteFolderWithFiles props={modalProps} onClose={onClose} />
        </ModalWrapper>
      )
    case 'replace_documents':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Выберите папку"
        >
          <ReplaceDocuments props={modalProps} onClose={onClose} />
        </ModalWrapper>
      )
    case 'create_appeal':
      return <NewAppeal modalIsOpen={modalIsOpen} {...modalProps} />
    case 'table_setting':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Настройка таблицы"
        >
          <TableSetting onClose={onClose} />
        </ModalWrapper>
      )
    case 'delete_file':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Удаление файла"
        >
          <DeleteFile props={modalProps} onClose={onClose} />
        </ModalWrapper>
      )
    case 'change_appeal':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Запрос на изменение заявки"
        >
          <ChangeAppeal props={modalProps} onClose={onClose} />
        </ModalWrapper>
      )

    case 'refusal_appeal':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Запрос на отказ"
        >
          <RefusalAppeal props={modalProps} onClose={onClose} />
        </ModalWrapper>
      )
    case 'notification':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Уведомления"
        >
          <NotificationTable close={onClose} />
        </ModalWrapper>
      )
    case 'profile':
      if (clientUser && authUser)
        return (
          <ModalWrapper isOpen={modalIsOpen} onClose={onClose}>
            <Profile
              onClose={onClose}
              userData={clientUser}
              authUser={authUser}
            />
          </ModalWrapper>
        )
      return null
    case 'tech-support-request':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Создание запроса в техподдержку"
        >
          <TechSupportRequestModal onClose={onClose} />
        </ModalWrapper>
      )
    case 'delete-hd-request':
      return (
        <ModalWrapper
          isOpen={modalIsOpen}
          onClose={onClose}
          title="Вы действительно хотите удалить запрос?"
        >
          <DeleteRequestModal onClose={onClose} />
        </ModalWrapper>
      )
    default:
      return null
  }
}
