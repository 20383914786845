import React from 'react'
import { Flex, Button } from 'theme-ui'
import { useDispatch } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
import { TextAreaFormik } from '@lk-stu3/components'
import { WsLkService } from '@lk-stu3/services'

type InitialValuesT = {
  message: string
}

const initialValues: InitialValuesT = {
  message: '',
}

const shema = object().shape({
  message: string()
    .max(255, 'длина поля не более 255 символов')
    .required('введите текст'),
})

export const ChangeAppeal = ({
  props: { idRequest, idAppeal },
  onClose,
}: {
  onClose: () => void
  props: { idAppeal: string; idRequest: string }
}): React.ReactElement => {
  const dispatch = useDispatch()
  const onSubmit = (
    values: InitialValuesT,
    actions: FormikHelpers<InitialValuesT>
  ): void => {
    dispatch(
      WsLkService.actions.notePOST({
        idAppeal,
        idRequest,
        type: 'CHANGE',
        text: values.message,
      })
    )
    actions.setSubmitting(false)
    onClose()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={shema}
    >
      {({ handleSubmit }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Flex sx={{ flexDirection: 'column', minWidth: '600px' }}>
            <TextAreaFormik maxHeight={80} name="message" title="" />
            <Flex
              sx={{
                marginTop: '32px',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="submit"
                variant="primary"
                sx={{ width: '120px' }}
                mr={3}
              >
                Отправить
              </Button>
              <Button
                type="submit"
                variant="secondary"
                sx={{ width: '120px' }}
                onClick={(e): void => {
                  e.preventDefault()
                  onClose()
                }}
              >
                Отмена
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
