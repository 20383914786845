import React from 'react'
import styled from '@emotion/styled'
import { Text, Flex } from 'theme-ui'
import { colors } from './theme'
import { Logo } from './logo'

// const Loader = styled.div`
//   width: 73px;
//   height: 73px;
//   margin: 0 auto;
//   margin-top: 49px;
//   position: relative;
//   transform: rotateZ(45deg);
//   -o-transform: rotateZ(45deg);
//   -ms-transform: rotateZ(45deg);
//   -webkit-transform: rotateZ(45deg);
//   -moz-transform: rotateZ(45deg);

//   & .cssload-cube {
//     position: relative;
//     transform: rotateZ(45deg);
//     -o-transform: rotateZ(45deg);
//     -ms-transform: rotateZ(45deg);
//     -webkit-transform: rotateZ(45deg);
//     -moz-transform: rotateZ(45deg);
//   }
//   & .cssload-cube {
//     float: left;
//     width: 50%;
//     height: 50%;
//     position: relative;
//     transform: scale(1.1);
//     -o-transform: scale(1.1);
//     -ms-transform: scale(1.1);
//     -webkit-transform: scale(1.1);
//     -moz-transform: scale(1.1);
//   }
//   & .cssload-cube:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgb(43, 160, 199);
//     animation: cssload-fold-thecube 2.76s infinite linear both;
//     -o-animation: cssload-fold-thecube 2.76s infinite linear both;
//     -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
//     -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
//     -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
//     transform-origin: 100% 100%;
//     -o-transform-origin: 100% 100%;
//     -ms-transform-origin: 100% 100%;
//     -webkit-transform-origin: 100% 100%;
//     -moz-transform-origin: 100% 100%;
//   }
//   & .cssload-c2 {
//     transform: scale(1.1) rotateZ(90deg);
//     -o-transform: scale(1.1) rotateZ(90deg);
//     -ms-transform: scale(1.1) rotateZ(90deg);
//     -webkit-transform: scale(1.1) rotateZ(90deg);
//     -moz-transform: scale(1.1) rotateZ(90deg);
//   }
//   & .cssload-c3 {
//     transform: scale(1.1) rotateZ(180deg);
//     -o-transform: scale(1.1) rotateZ(180deg);
//     -ms-transform: scale(1.1) rotateZ(180deg);
//     -webkit-transform: scale(1.1) rotateZ(180deg);
//     -moz-transform: scale(1.1) rotateZ(180deg);
//   }
//   & .cssload-c4 {
//     transform: scale(1.1) rotateZ(270deg);
//     -o-transform: scale(1.1) rotateZ(270deg);
//     -ms-transform: scale(1.1) rotateZ(270deg);
//     -webkit-transform: scale(1.1) rotateZ(270deg);
//     -moz-transform: scale(1.1) rotateZ(270deg);
//   }
//   & .cssload-c2:before {
//     animation-delay: 0.35s;
//     -o-animation-delay: 0.35s;
//     -ms-animation-delay: 0.35s;
//     -webkit-animation-delay: 0.35s;
//     -moz-animation-delay: 0.35s;
//   }
//   & .cssload-c3:before {
//     animation-delay: 0.69s;
//     -o-animation-delay: 0.69s;
//     -ms-animation-delay: 0.69s;
//     -webkit-animation-delay: 0.69s;
//     -moz-animation-delay: 0.69s;
//   }
//   & .cssload-c4:before {
//     animation-delay: 1.04s;
//     -o-animation-delay: 1.04s;
//     -ms-animation-delay: 1.04s;
//     -webkit-animation-delay: 1.04s;
//     -moz-animation-delay: 1.04s;
//   }

//   @keyframes cssload-fold-thecube {
//     0%,
//     10% {
//       transform: perspective(136px) rotateX(-180deg);
//       opacity: 0;
//     }
//     25%,
//     75% {
//       transform: perspective(136px) rotateX(0deg);
//       opacity: 1;
//     }
//     90%,
//     100% {
//       transform: perspective(136px) rotateY(180deg);
//       opacity: 0;
//     }
//   }

//   @-o-keyframes cssload-fold-thecube {
//     0%,
//     10% {
//       -o-transform: perspective(136px) rotateX(-180deg);
//       opacity: 0;
//     }
//     25%,
//     75% {
//       -o-transform: perspective(136px) rotateX(0deg);
//       opacity: 1;
//     }
//     90%,
//     100% {
//       -o-transform: perspective(136px) rotateY(180deg);
//       opacity: 0;
//     }
//   }

//   @-ms-keyframes cssload-fold-thecube {
//     0%,
//     10% {
//       -ms-transform: perspective(136px) rotateX(-180deg);
//       opacity: 0;
//     }
//     25%,
//     75% {
//       -ms-transform: perspective(136px) rotateX(0deg);
//       opacity: 1;
//     }
//     90%,
//     100% {
//       -ms-transform: perspective(136px) rotateY(180deg);
//       opacity: 0;
//     }
//   }

//   @-webkit-keyframes cssload-fold-thecube {
//     0%,
//     10% {
//       -webkit-transform: perspective(136px) rotateX(-180deg);
//       opacity: 0;
//     }
//     25%,
//     75% {
//       -webkit-transform: perspective(136px) rotateX(0deg);
//       opacity: 1;
//     }
//     90%,
//     100% {
//       -webkit-transform: perspective(136px) rotateY(180deg);
//       opacity: 0;
//     }
//   }

//   @-moz-keyframes cssload-fold-thecube {
//     0%,
//     10% {
//       -moz-transform: perspective(136px) rotateX(-180deg);
//       opacity: 0;
//     }
//     25%,
//     75% {
//       -moz-transform: perspective(136px) rotateX(0deg);
//       opacity: 1;
//     }
//     90%,
//     100% {
//       -moz-transform: perspective(136px) rotateY(180deg);
//       opacity: 0;
//     }
//   }
// `

const Wrapper = styled.div`
  position: relative;
`

const Circ = styled.div`
  margin: 2px;
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid silver;
  animation: circ-anim 1.2s linear infinite;

  border-width: 0px;
  border-radius: 50%;
  border-top: 3px solid ${colors.primary};
  border-right: 3px solid transparent;

  @keyframes circ-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const Spinner = ({
  title,
  logo = true,
}: {
  title?: string
  logo?: boolean
}): React.ReactElement => {
  return (
    <Flex
      id="spinner"
      sx={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* <Loader>
        <div className="cssload-cube cssload-c1" />
        <div className="cssload-cube cssload-c2" />
        <div className="cssload-cube cssload-c4" />
        <div className="cssload-cube cssload-c3" />
      </Loader> */}
      <Wrapper>
        <Circ />
        {logo && (
          <Logo
            sxContainer={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          />
        )}
      </Wrapper>
      {title && (
        <Text
          as="h3"
          sx={{
            color: 'primary',
            marginTop: '60px',
            fontWeight: 'bold',
            '&:after': {
              content: "' .'",
              animation: 'dots 1s steps(5, end) infinite',
            },
            '@keyframes dots': {
              '0%, 20%': {
                color: 'rgba(0,0,0,0)',
                textShadow: ' .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
              },
              '40%': {
                color: 'primary',
                textShadow: ' .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
              },
              '60%': {
                textShadow: ` .25em 0 0 ${colors.primary}, .5em 0 0 rgba(0,0,0,0)`,
              },
              '80%, 100%': {
                textShadow: ` .25em 0 0 ${colors.primary}, .5em 0 0 ${colors.primary}`,
              },
            },
          }}
        >
          {title}
        </Text>
      )}
    </Flex>
  )
}
