/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable redux-saga/no-unhandled-errors */
import { all, put, takeEvery } from 'redux-saga/effects'
import { success } from '@redux-requests/core'
import { helpDeskService } from '@lk-stu3/services'
import * as actions from './actions'

function* setTaskSaga({ payload }) {
  yield put(actions.setTaskInfo(payload.data))
}

function* setFilesSaga({ payload }) {
  yield put(actions.setFilesAC(payload.data))
}

function* setTaskLoading() {
  yield put(actions.setTaskLoading(true))
}

export function* sagas() {
  yield all([
    takeEvery(helpDeskService.actions.getTask.toString(), setTaskLoading),
    takeEvery(success(helpDeskService.actions.getTask.toString()), setTaskSaga),
    takeEvery(helpDeskService.actions.deleteRequest.toString(), setTaskLoading),
    takeEvery(
      success(helpDeskService.actions.getFiles.toString()),
      setFilesSaga
    ),
  ])
}
