export const namespace = 'help-desk'

export const SET_SEARCH_PARAMS = `${namespace}/SET_SEARCH_PARAMS`
export const CLEAR_SEARCH_PARAMS = `${namespace}/CLEAR_SEARCH_PARAMS`
export const SET_LOADING = `${namespace}/SET_LOADING`
export const SET_DICTS = `${namespace}/SET_DICTS`
export const TOGGLE_FULLSCREEN = `${namespace}/TOGGLE_FULLSCREEN`
export const SET_STATUSES = `${namespace}/SET_STATUSES`
export const SET_REQUESTS = `${namespace}/SET_REQUESTS`
export const SET_CURRENT_TASK = `${namespace}/SET_CURRENT_TASK`
export const SET_IS_EDIT = `${namespace}/SET_IS_EDIT`
export const SET_IS_SUBMITTING = `${namespace}/SET_IS_SUBMITTING`
export const SET_FLAG = `${namespace}/SET_FLAG`
