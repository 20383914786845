import { AnyAction } from 'redux'
import { TStatusRecords } from '@lk-stu3/services/src/ws-stu/status-record-lk/models'
import * as types from './types'
import {
  SearchParamsI,
  QueryParamsI,
  AppealsResponse,
  PaginationParamsI,
} from './interfaces'

interface IsetAppealId extends AnyAction {
  type: typeof types.SET_APPEALS
  payload?: AppealsResponse[]
}

export const setAppeals = (appeals: AppealsResponse[]): IsetAppealId => {
  return {
    type: types.SET_APPEALS,
    payload: appeals,
  } as const
}

interface SetAppealsPaginationI extends AnyAction {
  type: typeof types.SET_APPEALS_PAGINATION
  payload?: PaginationParamsI
}

export const setAppealsPagination = (
  paginationParams: PaginationParamsI
): SetAppealsPaginationI => {
  return {
    type: types.SET_APPEALS_PAGINATION,
    payload: paginationParams,
  } as const
}

interface SetSearchParamsI extends AnyAction {
  type: typeof types.SET_SEARCH_PARAMS
  payload?: {
    searchParams: SearchParamsI | null
    queryParams: QueryParamsI | null
  }
}

export const setSearchParams = (
  searchParams: SearchParamsI | null,
  queryParams: QueryParamsI | null
): SetSearchParamsI => {
  return {
    type: types.SET_SEARCH_PARAMS,
    payload: { searchParams, queryParams },
  } as const
}

interface SetErrorI extends AnyAction {
  type: typeof types.SET_ERROR
}

export const setError = (): SetErrorI => {
  return {
    type: types.SET_ERROR,
  } as const
}

interface ResetSearchParamsI extends AnyAction {
  type: typeof types.RESET_SEARCH_PARAMS
}

export const resetSearchParams = (): ResetSearchParamsI => {
  return {
    type: types.RESET_SEARCH_PARAMS,
  } as const
}

export const setStatusRecordsAC = (payload: TStatusRecords) => {
  return {
    type: types.SET_STATUS_RECORDS,
    payload,
  }
}
