import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface PrivateRouteI extends RouteProps {
  readonly isAuth: boolean
}

export const PrivateRoute = ({
  isAuth,
  location,
  ...rest
}: PrivateRouteI): React.ReactElement => {
  const path = location?.pathname
  return isAuth ? (
    <Route {...rest} />
  ) : (
    <Redirect to={{ pathname: '/signin', state: { path } }} />
  )
}
