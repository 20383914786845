import { TaskI } from '@lk-stu3/utils'
import * as types from './types'

export interface ModalPropsT {
  [key: string]: string | boolean | number
}

interface SetTasklInfoI {
  type: typeof types.SET_TASK_INFO
  payload: TaskI
}

export const setTaskInfo = (dataTask: TaskI): SetTasklInfoI => {
  return {
    type: types.SET_TASK_INFO,
    payload: dataTask,
  } as const
}

interface SetTaskLoadingI {
  type: typeof types.SET_TASK_LOADING
  payload: boolean
}

export const setTaskLoading = (loading: boolean): SetTaskLoadingI => {
  return {
    type: types.SET_TASK_LOADING,
    payload: loading,
  } as const
}

export const setFilesAC = (payload) => {
  return {
    type: types.SET_FILES,
    payload,
  }
}
