import React from 'react'
import { Flex, Grid, Button } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import { Moment, isMoment } from 'moment'
import { mapValues, pickBy } from 'lodash'
import {
  InputFormik,
  SelectFormik,
  RangeDatePickerFormik,
  Collapse,
} from '@lk-stu3/components'
import { Formik, FormikHelpers } from 'formik'

import { WsStuNsiService } from '@lk-stu3/services'
import { DictValues } from '@lk-stu3/services/src/ws-stu/nsi-dicts'

import { ServiceTypeInfoIcon } from '@lk-stu3/components/custom'
import { HeaderContent } from './header-content'
import { queryParamsS, searchParamsS } from './module/selectors'
import { setSearchParams, resetSearchParams } from './module/actions'

const {
  actions,
  dictsSelector,
  getOptionFromNSIDict,
  dictNames,
} = WsStuNsiService

const {
  statusRequest,
  serviceType,
  objectType,
  region,
  city,
  prefect,
  district,
  street,
} = dictNames

// разметка
const gridTemplateAreas = `
"appealNumber idStatus idServiceType idObjectType dateRegistration contractNumber"
"objectName objectName objectName landmark landmark landmark"
"idRegion idCity idPrefect idDistrict idStreet building"
`
type SelectValueT = {
  label: string
  value: string
}
type InitialValuesT = {
  appealNumber: string
  idStatus: SelectValueT | null
  idServiceType: SelectValueT | null
  idObjectType: SelectValueT | null
  dateRegistration: Moment[] | null
  contractNumber: string
  objectName: string
  landmark: string
  idRegion: SelectValueT | null
  idCity: SelectValueT | null
  idPrefect: SelectValueT | null
  idDistrict: SelectValueT | null
  idStreet: SelectValueT | null
  building: string
}

const initialValues: InitialValuesT = {
  appealNumber: '',
  idStatus: null,
  idServiceType: null,
  idObjectType: null,
  dateRegistration: null,
  contractNumber: '',
  objectName: '',
  landmark: '',
  idRegion: null,
  idCity: null,
  idPrefect: null,
  idDistrict: null,
  idStreet: null,
  building: '',
}

type WithoutEmptyValuesI<G extends {}> = {
  [T in keyof G]: string
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return {}.hasOwnProperty.call(obj, prop)
}
const deleteEmptyValues = <ValuesT extends {}>(
  values: ValuesT
): Partial<
  | WithoutEmptyValuesI<Omit<InitialValuesT, 'dateRegistration'>>
  | { dateRegistration: Moment[] }
> => {
  const mapped = mapValues(values, (value) => {
    if (typeof value === 'string' && value) return value
    if (Array.isArray(value) && isMoment(value[0])) return value
    if (
      value !== null &&
      hasOwnProperty(value, 'value') &&
      typeof value.value === 'string'
    )
      return value.value
    return null
  })
  return pickBy(mapped, (value) => value !== null)
}

export const CollapsePanel = (): React.ReactElement => {
  const dispatch = useDispatch()
  const searchParams = useSelector(searchParamsS)

  React.useEffect(() => {
    dispatch(
      actions.dicts([
        statusRequest,
        serviceType,
        objectType,
        region,
        city,
        prefect,
        district,
        street,
      ])
    )
  }, [dispatch])

  const dicts = useSelector(dictsSelector)
  const getOption = (name: string): DictValues[] =>
    getOptionFromNSIDict(name, dicts)

  const queryParams = useSelector(queryParamsS)

  const onSubmit = (
    values: InitialValuesT,
    actions: FormikHelpers<InitialValuesT>
  ): void => {
    const searchParams2 = deleteEmptyValues<InitialValuesT>({
      ...searchParams,
      ...values,
    })
    dispatch(setSearchParams(searchParams2, { ...queryParams, page: 0 }))
    actions.setSubmitting(false)
  }

  const onReset = async (): void => {
    await dispatch(resetSearchParams())
  }

  return (
    <Flex sx={{ zIndex: 2 }}>
      <Collapse HeaderContent={<HeaderContent />}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          onReset={onReset}
        >
          {({ handleSubmit, resetForm }): React.ReactElement => (
            <Flex
              sx={{
                padding: '16px',
                flexDirection: 'column',
              }}
            >
              <Grid
                sx={{
                  rowGap: '0px',
                  columnGap: '16px',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                  gridTemplateRows: '1fr 1fr 1fr',
                  gridTemplateAreas,
                }}
              >
                <InputFormik title="Номер заявки" name="appealNumber" />
                <SelectFormik
                  title="Статус заявки"
                  name="idStatus"
                  options={getOption(statusRequest)}
                />

                <SelectFormik
                  title="Вид услуги"
                  icon={ServiceTypeInfoIcon}
                  name="idServiceType"
                  options={getOption(serviceType)}
                />
                <SelectFormik
                  title="Тип объекта"
                  name="idObjectType"
                  options={getOption(objectType)}
                />
                <RangeDatePickerFormik
                  title="Дата регистрации"
                  name="dateRegistration"
                />
                <InputFormik title="Номер договора" name="contractNumber" />
                <InputFormik title="Наименование объекта" name="objectName" />
                <InputFormik title="Адресный ориентир" name="landmark" />
                <SelectFormik
                  title="Регион"
                  name="idRegion"
                  options={getOption(region)}
                />
                <SelectFormik
                  title="Город"
                  name="idCity"
                  options={getOption(city)}
                />
                <SelectFormik
                  title="Округ"
                  name="idPrefect"
                  options={getOption(prefect)}
                />
                <SelectFormik
                  title="Район"
                  name="idDistrict"
                  options={getOption(district)}
                />
                <SelectFormik
                  title="Улица"
                  name="idStreet"
                  options={getOption(street)}
                />
                <InputFormik title="Дом / строение" name="building" />
              </Grid>
              <Flex sx={{ justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  variant="flat"
                  py={0}
                  onClick={handleSubmit}
                >
                  Найти
                </Button>

                <Button
                  variant="flat"
                  py={0}
                  ml={2}
                  onClick={async () => {
                    await resetForm()
                    handleSubmit()
                  }}
                >
                  Сбросить фильтры
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </Collapse>
    </Flex>
  )
}
