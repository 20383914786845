import React from 'react'
import { AutoSizer, SizeInfo } from 'react-virtualized'
import { Box } from 'theme-ui'

export const AutoSizeTableWrap = ({
  children,
}: {
  children(height: number, width: number): React.ReactElement
}): React.ReactElement => {
  return (
    <div style={{ flex: 1 }}>
      <AutoSizer>
        {({ height, width }: SizeInfo): React.ReactElement => {
          const getTableHeaderHeight = (): number => {
            const element = document.getElementsByTagName('thead')[0]
            if (element) return element.clientHeight
            return 0
          }
          return (
            <Box
              sx={{
                height: `${height}px`,
                width: `${width}px`,
                overflow: 'hidden',
                border: '1px solid',
                borderColor: 'border',
                borderTop: 'none',
                '& .ursip-table-body': {
                  height: `${height - getTableHeaderHeight()}px`,
                },
              }}
            >
              {/* Передаем параметры для скролла таблицы: высоту с вычетом шапки таблицы и ширину с вычетом стандартного скроллбара */}
              {children(height - getTableHeaderHeight(), width - 20)}
            </Box>
          )
        }}
      </AutoSizer>
    </div>
  )
}
