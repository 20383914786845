import React from 'react'
import { Flex, Text, Box } from '@design-system/atoms'

function DocumentLoading() {
  return (
    <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
      <Box>
        <Text color="white">Загрузка документа...</Text>
      </Box>
    </Flex>
  )
}

export default DocumentLoading
