import { createSelector } from 'reselect'
import { namespace } from './types'

const techSupportSelector = (state) => state[namespace]

export const searchParamsSelector = createSelector(
  techSupportSelector,
  (state) => state?.searchParams
)
export const dictsSelector = createSelector(
  techSupportSelector,
  (state) => state.dicts
)
export const flagsSelector = createSelector(
  techSupportSelector,
  (state) => state.flags
)

export const statusesSelector = createSelector(
  techSupportSelector,
  (state) => state.statuses
)

export const usersSelector = createSelector(
  techSupportSelector,
  (state) => state.allUsers
)
export const requestsSelector = createSelector(
  techSupportSelector,
  (state) => state.requests
)
export const totalElementsSelector = createSelector(
  techSupportSelector,
  (state) => state.totalElements
)
export const totalPagesSelector = createSelector(
  techSupportSelector,
  (state) => state.totalPages
)
