import React from 'react'
import { TreeDataNode } from '@lk-stu3/utils'
import { Flex, Button, Text } from 'theme-ui'
import { WsStuFileAndFolder } from '@lk-stu3/services'
import { useDispatch } from 'react-redux'
import * as actions from './module/actions'

export const DeleteFolderWithFiles = ({
  props,
  onClose,
}: {
  props: {
    node: TreeDataNode
    related: TreeDataNode[]
    notRelated: TreeDataNode[]
  }
  onClose: () => void
}): React.ReactElement => {
  const { node, related } = props
  const dispatch = useDispatch()
  const files = related.filter((el) => el.isLeaf)
  const fileNames = files.map((file) => file.title).join(', ')
  const folders = related.filter((el) => !el.isLeaf)
  const folderNames = folders.map((folder) => folder.title).join(', ')

  const openReplaceModal = (): void => {
    dispatch(actions.openModal('replace_documents', props))
  }

  const deleteAll = (): void => {
    // удаляем все папки
    for (let i = 0; i < folders.length; i += 1) {
      const currentFolder = folders[i]
      dispatch(
        WsStuFileAndFolder.actions.folderDelete(
          currentFolder.id,
          node.idParentFolder
        )
      )
    }
    // удаляем все файлы
    for (let i = 0; i < files.length; i += 1) {
      const currentFile = files[i]
      dispatch(WsStuFileAndFolder.actions.fileDelete(currentFile))
    }
    // удаляем корневую папку
    dispatch(
      WsStuFileAndFolder.actions.folderDelete(node.id, node.idParentFolder)
    )
    onClose()
  }

  return (
    <Flex sx={{ flexDirection: 'column', maxWidth: 'calc(100vw - 20%)' }}>
      <Text>Вложенныe файлы:</Text>
      <Text as="span" sx={{ fontStyle: 'italic', marginY: '8px' }}>
        {fileNames}
      </Text>
      <Text>Вложенныe папки:</Text>
      <Text as="span" sx={{ fontStyle: 'italic', marginY: '8px' }}>
        {folderNames}
      </Text>
      <Flex
        sx={{
          marginTop: '32px',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="primary" mr={3} onClick={openReplaceModal}>
          Переместить файлы в другую папку
        </Button>
        <Button
          variant="primary"
          sx={{ backgroundColor: 'danger' }}
          mr={3}
          onClick={deleteAll}
        >
          Удалить вместе с файлами
        </Button>
        <Button
          type="submit"
          variant="secondary"
          sx={{ width: '120px' }}
          onClick={(e): void => {
            e.preventDefault()
            onClose()
          }}
        >
          Отмена
        </Button>
      </Flex>
    </Flex>
  )
}
