import React, { FC, useCallback, useMemo } from 'react'
import { Flex, Text, Spinner } from 'theme-ui'
import { Checkbox } from '@ursip/components'
import { Icon } from '@lk-stu3/components'
import { useDispatch, useSelector } from 'react-redux'
import { TableBottom } from './table-bottom'
import {
  inboxCheckedAllSelector,
  inboxCheckedSelector,
  inboxSelector,
  loadingSelector,
  paginationSelector,
} from './module/selectors'
import { Row } from './row'
import {
  checkAllInboxAC,
  toggleInboxElement,
  uncheckAllInboxAC,
} from './module/actions'
import { HeaderRow } from './header-row'
import { Layout } from './layout'

export const UNREAD_ID = 'UNREAD'

export const Inbox: FC = () => {
  const dispatch = useDispatch()
  const { allIds, byId } = useSelector(inboxSelector)
  const { inbox: loading } = useSelector(loadingSelector)
  const checked = useSelector(inboxCheckedSelector)
  const checkedAll = useSelector(inboxCheckedAllSelector)

  const {
    inbox: { page, size },
  } = useSelector(paginationSelector)

  const data = useMemo(
    () => allIds.map((id) => byId[id]).slice(page * size, page * size + size),
    [allIds, byId, page, size]
  )
  const checkableNotificationIds = useMemo(
    () =>
      data
        .filter((el) => el.status === UNREAD_ID)
        .map((el) => el.notificationId),
    [data]
  )

  const handleCheck = (notificationId: string) => () => {
    dispatch(toggleInboxElement(notificationId))
  }

  const toggleCheckAll = useCallback(
    (e: Event) => {
      if (checkedAll) {
        dispatch(uncheckAllInboxAC())
      } else {
        dispatch(checkAllInboxAC())
      }
    },
    [checkedAll, dispatch]
  )

  if (loading) {
    return (
      <Flex
        sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}
      >
        <Spinner color="black" />
      </Flex>
    )
  }

  return (
    <Layout>
      <Flex
        sx={{
          flexDirection: 'column',
          overflowY: 'scroll',
          height: '100%',
        }}
      >
        {data.length > 0 ? (
          <HeaderRow>
            <Flex sx={{ width: '30px', alignItems: 'center' }}>
              <Checkbox
                checked={checkedAll}
                onChange={toggleCheckAll}
                disabled={checkableNotificationIds.length === 0}
              />
            </Flex>
            <Text>Уведомление</Text>
          </HeaderRow>
        ) : (
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            Отсутствуют входящие уведомления
          </Flex>
        )}

        {data.map((notification) => (
          <Row
            key={notification.notificationId}
            onClick={
              notification.status === UNREAD_ID
                ? handleCheck(notification.notificationId)
                : undefined
            }
          >
            <Flex sx={{ width: '30px', alignItems: 'center' }}>
              {notification.status === UNREAD_ID && (
                <Checkbox checked={checked[notification.notificationId]} />
              )}
            </Flex>
            <Flex sx={{ width: '30px', height: '30px', alignItems: 'center' }}>
              {notification.isEmail ? (
                <Icon
                  name="mail"
                  title="Уведомление отправлено на почту"
                  sx={{
                    color:
                      notification.status === UNREAD_ID ? '#0091EA' : '#000',
                  }}
                />
              ) : (
                <Icon
                  name="notifications"
                  title="Уведомление отправлено в колокольчик"
                  sx={{
                    color:
                      notification.status === UNREAD_ID ? '#0091EA' : '#000',
                  }}
                />
              )}
            </Flex>
            <Flex
              sx={{
                alignItems: 'center',
                color: notification.status === UNREAD_ID && '#0091EA',
                width: '100%',
              }}
            >
              {notification?.note || notification?.text}
            </Flex>
          </Row>
        ))}
      </Flex>

      <TableBottom type="inbox" />
    </Layout>
  )
}
