import React, { useState } from 'react'
import { Flex, Button } from 'theme-ui'
import { Modal, Icon } from '@ursip/components'
import * as PDFJS from 'pdfjs-dist'

import Viewer2D from './Viewer2D'
import ImageViewer from './ImageViewer'
import DefaultViewer from './DefaultViewer'

import './globalStyleFix.css'

console.log('PDFJS', PDFJS)

PDFJS.PDFJS.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`

const imageFormats = [
  'apng',
  'bmp',
  'gif',
  'ico',
  'cur',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'webp',
]

export const Preview = (props) => {
  const { selectedFile, fileUrl } = props

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(true)

  const closeModal = () => {
    setModalOpen(false)
  }

  let App

  if (
    typeof selectedFile?.fileExt === 'string' &&
    (selectedFile?.fileExt.toLowerCase() === 'pdf' ||
      selectedFile?.idFileConverted)
  ) {
    App = Viewer2D
  } else if (
    typeof selectedFile?.fileExt === 'string' &&
    imageFormats.includes(selectedFile?.fileExt.toLowerCase())
  ) {
    App = ImageViewer
  } else {
    App = DefaultViewer
  }

  if (!selectedFile?.idFile) {
    return null
  }

  return (
    <Flex
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          zIndex: modalOpen ? 0 : 100,
          height: 24,
          width: 24,
          top: 16,
          right: 16,
          position: 'absolute',
          background: '#f5f5f5',
          borderRadius: 4,
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={openModal}
      >
        <Button
          variant="secondary"
          sx={{
            lineHeight: 1,
            padding: '8px',
            minWidth: 'auto',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="black"
            width="18px"
            height="18px"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z" />
          </svg>
        </Button>
      </Flex>
      <Modal isOpen={modalOpen} onRequestClose={closeModal}>
        <Flex
          sx={{
            height: '100vh',
            width: '99vw',
            alignItems: 'center',
            justifyContent: 'center',
            overflowX: 'hidden',
          }}
        >
          <App fileId={selectedFile?.idFile} fileUrl={fileUrl} />
        </Flex>
      </Modal>
      <App noThumbnail fileId={selectedFile?.idFile} fileUrl={fileUrl} />
    </Flex>
  )
}
