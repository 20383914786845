import React from 'react'
import Tree, { TreeProps } from 'rc-tree'
import { TreeDataNode } from '@lk-stu3/utils'
import { TreeWrapper } from './styled-components'
import { calcNewState } from './tree-utils'
import { MenuActionsI } from './interfaces'

interface DirectoryTreeI extends Omit<TreeProps, 'prefixCls'> {
  changeParentId?: () => void
  prefixCls?: string
  treeData: TreeDataNode[]
  menuActions?: MenuActionsI
}

export const DirectoryTree = ({
  treeData = [],
  menuActions,
  ...props
}: DirectoryTreeI): React.ReactElement => {
  const [data, setData] = React.useState<TreeDataNode[]>([])
  const initialTreeData = React.useMemo(() => treeData, [treeData])

  React.useEffect(() => {
    if (initialTreeData) setData(initialTreeData)
  }, [initialTreeData])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrop = (info: any): void => {
    if (menuActions?.changeParentId)
      menuActions.changeParentId(info.dragNode, info.node.id)
    setData((prevState) => calcNewState(info, prevState))
  }

  return (
    <TreeWrapper>
      <Tree
        autoExpandParent
        draggable
        onDrop={onDrop}
        treeData={data}
        {...props}
      />
    </TreeWrapper>
  )
}
