import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Flex, Box, Text } from 'theme-ui'
import { WsNotificationsService } from '@lk-stu3/services'
import { ringNotificationsS, errorS } from './module/selectors'
import { NotificationRowLink } from './components'

const NotificationIcon = ({
  onClick,
}: {
  onClick: () => void
}): React.ReactElement => (
  <Box title="Пометить как прочитанное" onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </svg>
  </Box>
)

export const NotificationList = (): React.ReactElement => {
  const items = useSelector(ringNotificationsS)
  const error = useSelector(errorS)
  const dispatch = useDispatch()
  const markRead = (id: string): void => {
    dispatch(WsNotificationsService.actions.markReadNotification(id))
  }

  const isEmpty = !error && items.length === 0

  return (
    <Flex
      sx={{
        padding: '12px',
        maxHeight: '200px',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      {isEmpty && (
        <Text sx={{ textAlign: 'center', padding: '8px' }}>
          У вас нет новых уведомлений
        </Text>
      )}
      {items.map(
        (item): React.ReactElement => (
          <Grid
            title="перейти в заявку"
            key={item.id}
            sx={{
              padding: '7px',
              cursor: 'pointer',
              borderBottom: '1px solid',
              borderBottomColor: 'border',
              alignItems: 'center',
              gridTemplateColumns: '18px 1fr',
              '&:hover': {
                backgroundColor: 'light',
              },
              '& > svg': {
                fill: 'text',
                marginRight: '8px',
              },
            }}
          >
            <NotificationIcon onClick={(): void => markRead(item.id)} />
            <NotificationRowLink
              to={`/dashboard/appeal/${item.idRequest}/info`}
            >
              {item.note}
            </NotificationRowLink>
          </Grid>
        )
      )}
    </Flex>
  )
}
