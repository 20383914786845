import React from 'react'
import { Flex, Link } from '@theme-ui/components'
import { Button } from '@lk-stu3/components'
import { WsStuFileAndFolder } from '@lk-stu3/services'

const { getFileUrl } = WsStuFileAndFolder

export const FilesList = ({ files, onDelete, hideDefault, sx }) => {
  if (!files?.length) {
    return hideDefault ? null : 'Файлы отсутствуют'
  }

  return (
    <Flex sx={{ flexDirection: 'column', ...sx }}>
      {files?.map(({ fileName, fileExt, idFile }) => {
        if (!fileName) {
          return null
        }

        return (
          <Flex
            key={idFile}
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <Link
              href={getFileUrl(idFile)}
              target="_blank"
            >{`${fileName}.${fileExt}`}</Link>
            {!!onDelete && (
              <Button type="flat" onClick={() => onDelete(idFile)} size="small">
                X
              </Button>
            )}
          </Flex>
        )
      })}
    </Flex>
  )
}
