import * as types from './types'

export interface ModalPropsT {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [key: string]: any
}
interface OpenModalI<T> {
  type: typeof types.OPEN_MODAL
  payload: {
    modalType: string
    modalProps?: T
  }
}

export const openModal = <T>(
  modalType: string,
  modalProps?: T
): OpenModalI<T> => {
  return {
    type: types.OPEN_MODAL,
    payload: { modalType, modalProps },
  } as const
}

interface CloseModalFormI {
  type: typeof types.CLOSE_MODAL_FORM
}

export const closeModalForm = (): CloseModalFormI =>
  ({
    type: types.CLOSE_MODAL_FORM,
  } as const)

interface ClearModalDataI {
  type: typeof types.CLEAR_MODAL_DATA
}

export const clearModalData = (): ClearModalDataI =>
  ({
    type: types.CLEAR_MODAL_DATA,
  } as const)

interface CloseModalI {
  type: typeof types.CLOSE_MODAL
}

export const closeModal = (): CloseModalI =>
  ({
    type: types.CLOSE_MODAL,
  } as const)
