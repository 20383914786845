import React, { FC, useCallback, useMemo } from 'react'
import { Label, Box, Badge, Text } from 'theme-ui'

type Props = {
  variant: string
  title: string
  badge?: number
  activeValue: string
  value: string
  onClick: (e: unknown) => void
  sx?: Record<string, string>
}

export const BadgeLk: FC<Props> = ({
  variant,
  title,
  activeValue,
  badge,
  value,
  onClick,
  children,
  sx,
}: Props) => {
  const handleClick = useCallback(() => {
    onClick(value)
  }, [value, onClick])

  const isActive = useMemo(() => value === activeValue, [value, activeValue])

  const badgeSx = useMemo(() => {
    const sxBase = {
      color: '#fff',
      padding: '2px 10px',
    }

    switch (variant) {
      case 'green':
        return {
          ...sxBase,
          bg: '#66bb6a',
        }

      case 'grey':
        return {
          ...sxBase,
          bg: '#b5b5b5',
        }

      case 'brown':
        return {
          ...sxBase,
          bg: '#6e4b3f',
        }

      case 'red':
        return {
          ...sxBase,
          bg: '#ef5350',
        }

      case 'orange':
        return {
          ...sxBase,
          bg: '#ff9d23',
        }

      default:
        return {}
    }
  }, [variant])

  return (
    <Box
      title={title}
      sx={{
        marginRight: '15px',
        padding: '6px 10px',
        borderRadius: '10px',
        transition: 'background 0.2s',
        cursor: 'pointer',
        backgroundColor: isActive ? 'rgba(0,145,234,0.2)' : 'transparent',
        '&:hover': { backgroundColor: 'rgba(0,145,234,0.2)' },
        zIndex: 100,
        ...sx,
      }}
      onClick={handleClick}
    >
      <Label sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <Badge
          variant={variant}
          sx={{ fontSize: '1rem', cursor: 'pointer', ...badgeSx }}
        >
          {badge}
        </Badge>
        <Text sx={{ marginLeft: '8px' }}>{children}</Text>
      </Label>
    </Box>
  )
}
