import { TaskI } from 'packages/utils'
import * as types from './types'
import { ActionTypes } from './actions.types'

export interface InitialStateI {
  currentTask: TaskI | null
  files: {
    request: any[]
    response: any[]
  }
  loading: boolean
}

export const initialState: InitialStateI = {
  currentTask: null,
  files: {
    request: [],
    response: [],
  },
  loading: false,
}

export const reducer = (
  state: InitialStateI = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case types.SET_TASK_INFO: {
      if (action.payload) {
        return {
          ...state,
          currentTask: action.payload,
          loading: false,
        }
      }
      return {
        ...state,
        currentTask: null,
        loading: false,
      }
    }
    case types.SET_TASK_LOADING: {
      if (action.payload) {
        return {
          ...state,
          loading: action.payload,
        }
      }
      return state
    }
    case types.SET_FILES: {
      if (action.payload) {
        const request = action.payload.filter(
          (el) => el.sourceFile === 'REQUEST'
        )
        const response = action.payload.filter(
          (el) => el.sourceFile === 'RESPONSE'
        )

        return {
          ...state,
          files: {
            request,
            response,
          },
        }
      }

      return state
    }
    default:
      return state
  }
}
