import { ISagaModule } from 'redux-dynamic-modules-saga'
import { ActionTypes } from './actions.types'
import { reducer, initialState, InitialStateT } from './reducers'

import { sagas } from './sagas'

const moduleName = 'new_appeal'

export interface NewAppealI {
  [moduleName]: InitialStateT
}

const moduleNewAppeal = (): ISagaModule<NewAppealI> => {
  return {
    id: moduleName,
    reducerMap: {
      [moduleName]: (
        state: InitialStateT = initialState,
        action: ActionTypes
      ): InitialStateT => ({
        ...state,
        ...reducer(state, action),
      }),
    },
    sagas: [sagas],
  }
}

export { moduleName, moduleNewAppeal }
