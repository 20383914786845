import React from 'react'
import { Button, Flex, Grid, Checkbox, Label } from 'theme-ui'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { object, string } from 'yup'
import { InputFormik } from '@lk-stu3/components'
import { Formik, FormikHelpers, FormikErrors } from 'formik'
import { WsStuNsiService } from '@lk-stu3/services'
import { mapValues } from 'lodash'
import type { PhysT } from './module/reducers'

type ValuesT = {
  personType: string
  lastName: string
  firstName: string
  middleName: string
  phone: string
  email: string
  dateBirth: string
  comment: string
}

const initialValues = (initialData: PhysT | undefined): ValuesT => {
  const defaultParams: ValuesT = {
    personType: 'CLIENT',
    lastName: '',
    firstName: '',
    middleName: '',
    phone: '',
    email: '',
    dateBirth: '',
    comment: '',
  }
  if (initialData) {
    defaultParams.phone = initialData.phone
    defaultParams.lastName = initialData.lastName
    defaultParams.firstName = initialData.firstName
    defaultParams.middleName = initialData.middleName
    defaultParams.email = initialData.email
    defaultParams.dateBirth = initialData.dateBirth
  }

  return defaultParams
}
const shema = object().shape({
  lastName: string().required('введите фамилию'),
  firstName: string().required('введите имя'),
  phone: string()
    .matches(
      // TODO: переписать регулярное выражение по правилам eslint
      // eslint-disable-next-line security/detect-unsafe-regex
      /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
      'введите корректный номер телефона'
    )
    .required('введите номер телефона'),
  email: string()
    .email('не корректный email адрес')
    .required('введите электронную почту'),
})
type Nullable<T> = {
  [K in keyof T]: T[K] | null
}
type InitialValuesT = ReturnType<typeof initialValues>
export interface SubmitPhysValuesI extends Omit<Nullable<InitialValuesT>, ''> {
  type: 'PHYS'
}

const validate = (values: InitialValuesT): FormikErrors<InitialValuesT> => {
  const errors: FormikErrors<InitialValuesT> = {}
  const { dateBirth } = values
  if (
    typeof dateBirth === 'string' &&
    dateBirth.length > 0 &&
    !moment(dateBirth, 'DD.MM.YYYY').isValid()
  ) {
    errors.dateBirth = 'укажите корректную дату'
  }
  return errors
}

export const PhysFormComponent = ({
  toLogin,
  CustomButtons,
  CustomSubmit,
  initialData,
}: {
  toLogin?: () => void
  CustomButtons?: (props: {
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }) => React.ReactElement
  CustomSubmit?: (values: SubmitPhysValuesI) => void
  initialData?: PhysT
}): React.ReactElement => {
  const dispatch = useDispatch()
  const getGuestToken = async (): Promise<null | string> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await dispatch({
      type: 'GUEST_TOKEN',
      payload: {
        request: {
          url: `/ws-guest/v1/guest-access`,
          method: 'GET',
        },
      },
      meta: {
        runOnRequest: false,
      },
    })
    if (!res.action.error) return res.action.payload.data.token

    return null
  }

  const onSubmit = async (
    formValues: InitialValuesT,
    actions: FormikHelpers<InitialValuesT>
  ): Promise<void> => {
    const values = mapValues(formValues, (value) => value || null)
    if (CustomSubmit) {
      CustomSubmit({ type: 'PHYS', ...values })
    } else {
      const { dateBirth } = values

      const token = await getGuestToken()
      if (token)
        dispatch(
          WsStuNsiService.actions.createPhys(token, {
            ...values,
            dateBirth:
              dateBirth !== null
                ? moment(dateBirth, 'DD.MM.YYYY').format(
                    'YYYY-MM-DDT00:00:00.000'
                  ) // TODO: вынести форматирование дат в utils!!!
                : null,
          })
        )
    }
    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues(initialData)}
      onSubmit={onSubmit}
      validationSchema={shema}
      validate={validate}
    >
      {({ handleSubmit }): React.ReactElement => (
        <>
          <Grid
            sx={{
              overflowY: 'auto',
              rowGap: '0px',
            }}
          >
            <InputFormik title="Фамилия" required name="lastName" />
            <InputFormik title="Имя" required name="firstName" />
            <InputFormik title="Отчество" name="middleName" />
            <InputFormik
              title="Телефон"
              required
              name="phone"
              mask="+7 (999) 999-99-99"
            />
            <InputFormik title="Электронная почта" required name="email" />
            <InputFormik
              title="Дата рождения"
              mask="99.99.9999"
              name="dateBirth"
            />
            <Label
              sx={{
                marginTop: '24px',
                alignItems: 'center',
                cursor: 'pointer',
                '& input:focus ~ svg': {
                  backgroundColor: 'transparent !important',
                },
              }}
            >
              <Checkbox defaultChecked />В соответствии с ФЗ 152 «О персональных
              данных» я даю свое согласие на обработку моих персональных данных
              *
            </Label>
          </Grid>

          {CustomButtons ? (
            <CustomButtons handleSubmit={handleSubmit} />
          ) : (
            <Flex sx={{ justifyContent: 'space-between' }}>
              {toLogin && (
                <Button
                  variant="secondary"
                  onClick={toLogin}
                  sx={{ width: '110px' }}
                >
                  Назад
                </Button>
              )}
              <Button
                type="submit"
                onClick={(): void => handleSubmit()}
                sx={{ width: '110px' }}
              >
                Отправить
              </Button>
            </Flex>
          )}
        </>
      )}
    </Formik>
  )
}
