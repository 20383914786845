import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'theme-ui'
// Topbar components
import { WrapperWithBoxParams } from '../wrapper'
import { TopPannel } from './top-pannel'
import { Tabs } from './tabs'
// Route components
import { Info } from './info'
import { Files } from './files'
import { Notification } from './notification'
// module
import { setRequestId } from './module/actions'
import { requestIdS } from './module/selectors'
import { moduleAppealItem } from './module'
import { moduleNotifications } from './notification/module'
import { AppealStatus } from './appeal-status'

const Navbar = (): React.ReactElement => (
  <Flex sx={{ flexDirection: 'column', width: '100%' }}>
    <TopPannel />
    <Tabs />
  </Flex>
)

type TParams = { id: string }

export const AppealItemModule = ({
  match,
  location,
}: RouteComponentProps<TParams>): React.ReactElement => {
  const {
    url,
    params: { id },
  } = match

  const dispatch = useDispatch()

  const requestId = useSelector(requestIdS)

  React.useEffect(() => {
    if (requestId !== id) dispatch(setRequestId(id))
  }, [requestId, dispatch, id])

  return (
    <WrapperWithBoxParams navbar={<Navbar />}>
      {(width, height): React.ReactElement => (
        <Switch key={url.split('/')[3]} location={location}>
          <Route exact path={`${url}/info`} component={Info} />
          <Route path={`${url}/notification`} component={Notification} />
          <Route exact path={`${url}/appeal-status`} component={AppealStatus} />
          <Route
            exact
            path={`${url}/files`}
            component={(): React.ReactElement => (
              <Files width={width} height={height} />
            )}
          />
        </Switch>
      )}
    </WrapperWithBoxParams>
  )
}

export const AppealItem = (
  props: RouteComponentProps<TParams>
): React.ReactElement => {
  const {
    match: { url },
  } = props

  return (
    <DynamicModuleLoader modules={[moduleAppealItem(), moduleNotifications()]}>
      <Route path={`${url}/:id/`} component={AppealItemModule} />
    </DynamicModuleLoader>
  )
}
