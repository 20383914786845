import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import { WsStuAppeal } from '@lk-stu3/services'
import { appealIdS, appealStatusInfo } from '../module/selectors'
import { AppealStatusItem } from './appeal-status-item'

export const AppealStatus = (): ReactElement => {
  const dispatch = useDispatch()

  const idAppeal = useSelector(appealIdS)
  const appealStatusInfoData = useSelector(appealStatusInfo)

  React.useEffect(() => {
    if (idAppeal) dispatch(WsStuAppeal.actions.appealsStatusGET({ idAppeal }))
  }, [dispatch, idAppeal])

  if (appealStatusInfoData) {
    return (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Flex
          sx={{
            margin: '16px',
            flexDirection: 'column',
            border: '1px solid',
            borderColor: 'border',
          }}
        >
          {appealStatusInfoData.map((status, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <AppealStatusItem data={status} key={index} />
          })}
        </Flex>
      </Box>
    )
  }

  return <></>
}
