/** @jsx jsx */
import { jsx, Flex, SxProps } from 'theme-ui'
import React from 'react'
import { FileI } from '@lk-stu3/utils'

interface FileUploaderI {
  name?: string
  title?: string
  addFile?: (
    fileList: FileList,
    node: FileI,
    { onStart, onFinish }: { onStart: () => any; onFinish: () => any }
  ) => void
  onStart: () => any
  onFinish: () => any
  children: React.ReactNode
  node: FileI
  sx?: SxProps
}

export const FileUploader = ({
  sx,
  node,
  title = '',
  addFile,
  onStart,
  onFinish,
  children,
}: FileUploaderI): React.ReactElement => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.currentTarget
    if (addFile && files)
      addFile(files, node, {
        onStart,
        onFinish,
      })
  }

  return (
    <Flex
      title={title}
      sx={{
        position: 'relative',
        transition: 'all 0.2s linear',
        cursor: 'pointer',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        '&:active': {
          boxShadow: '0 0 10px rgba(0,0,0,0.5)',
        },
        ...sx,
      }}
    >
      <label
        htmlFor={`${node.key}`}
        sx={{
          cursor: 'pointer',
          backgroundColor: 'transparent',
        }}
      >
        <input
          type="file"
          name={`${node.key}`}
          id={`${node.key}`}
          onChange={onChange}
          multiple
          sx={{
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1',
          }}
        />
        {children}
      </label>
    </Flex>
  )
}
