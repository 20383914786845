import { useEffect, useState } from 'react'
import * as PDFJS from 'pdfjs-dist'

function PdfLoader({ url, children, beforeLoad }) {
  const [pdfDocument, setPdfDocument] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    setLoading(true)
    PDFJS.getDocument(url).then(pdfDocument => {
      setPdfDocument(pdfDocument)
      setLoading(false)
    })
  }, [url])

  if (!pdfDocument || loading) {
    return beforeLoad
  }

  return children(pdfDocument)
}

export default PdfLoader
