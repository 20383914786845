import React from 'react'
import { connect, FormikProps, FormikValues } from 'formik'
import { SxProps } from 'theme-ui'
import { TitledComponent, TitledComponentT } from '../titled-component'
import { Textarea, TextAreaI } from '../text-area'

type TextareaT = {
  name: string
  loading?: boolean
  formik?: FormikProps<FormikValues>
  sx?: SxProps
  sideEffectFnc?: <T, U>(setFieldValue: T, value: U) => void
  gridAreaName?: boolean
}

type TextAreaFormikT = TitledComponentT & TextareaT & TextAreaI

export const TextAreaFormik = connect(
  ({
    title,
    required,
    icon,
    sideEffectFnc,
    name,
    formik,
    gridArea,
    gridAreaName,
    sx,
    ...props
  }: TextAreaFormikT): React.ReactElement => {
    const { setFieldValue, setFieldTouched, values } = formik || {}
    const handleChange = async (
      event: React.FormEvent<HTMLTextAreaElement>
    ): Promise<void> => {
      const { value } = event.currentTarget
      if (sideEffectFnc) sideEffectFnc(setFieldValue, value)
      if (setFieldValue) await setFieldValue(name, value)
      if (setFieldTouched) await setFieldTouched(name, true)
    }
    const formikErrors = name && formik ? formik.errors[name] : ''

    const formikTouched = formik?.touched[name]

    return (
      <TitledComponent
        title={title}
        required={required}
        error={formikErrors}
        touched={!!formikTouched}
        icon={icon}
        gridArea={gridAreaName ? name : null}
      >
        <Textarea
          onChange={handleChange}
          value={values && values[name]}
          sx={{ width: '100%', ...sx }}
          {...props}
        />
      </TitledComponent>
    )
  }
)
