import { RequestAction } from '@redux-requests/core'
import { AnyAction } from 'redux'
import {
  getTokens,
  checkIfUnauthorized,
  checkToken,
  removeTokens,
} from '@lk-stu3/utils'
import { AppDispatchT } from './store'

interface OnRequestI {
  headers: {
    [key: string]: string
    'X-Authorization': string
  }
}

export const onRequest = (
  request: OnRequestI | OnRequestI[]
): OnRequestI | OnRequestI[] => {
  const { token } = getTokens()
  if (token && Array.isArray(request)) {
    return request.map((el) => ({
      ...el,
      headers: {
        ...el.headers,
        'X-Authorization': `Bearer ${token}`,
      },
    }))
  }
  if (token && !Array.isArray(request)) {
    return {
      ...request,
      headers: {
        ...request.headers,
        'X-Authorization': `Bearer ${token}`,
      },
    }
  }

  return request
}

interface ErrorI {
  status: number
  data: {
    message: string
  }
}

export const onError = async (
  error: ErrorI,
  requestAction: RequestAction,
  { dispatch }: { dispatch: AppDispatchT }
): Promise<Error | AnyAction | ErrorI> => {
  const tokensExists = checkToken()
  if (!tokensExists) {
    removeTokens()
  } else {
    const errorIsUnauthorized = checkIfUnauthorized(error)
    if (errorIsUnauthorized) {
      const { refreshToken } = getTokens()

      const refreshRequest = new Request(`/token/refresh`, {
        method: 'GET',
        headers: {
          'X-Authorization': `Bearer ${refreshToken}`,
        },
      })
      try {
        const response = await window.fetch(refreshRequest)
        if (response.status === 200) {
          const newTokens: {
            token: string
            refreshToken: string
          } = await response.json()
          localStorage.setItem('token', newTokens.token)
          localStorage.setItem('refreshToken', newTokens.refreshToken)

          const NewRequest: AnyAction = {
            type: requestAction.type || 'REPEATED_REQUEST',
            ...requestAction,
            meta: {
              ...requestAction.meta,
              silent: true,
              runOnSuccess: false,
              runOnRequest: false,
            },
          }

          const newResponse = await dispatch(NewRequest)

          return newResponse
        }
        removeTokens()
        throw new Error()
      } catch (fetchError) {
        removeTokens()
        throw new Error(`Не удалось обновить токен. ${fetchError}`)
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw error
  }

  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw error
}
