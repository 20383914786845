import React, { ReactNode } from 'react'
import { Flex, Box } from 'theme-ui'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Spinner } from '@lk-stu3/components'
import { WsLkService } from '@lk-stu3/services'
import { ColumnsType } from 'rc-table/lib/interface'
import { SettingsT } from '@lk-stu3/services/src/ws-stu/nsi-dicts'
import { getAuthUserInfo } from '@lk-stu3/auth'
import { AppealTableI } from './module/interfaces'
import {
  appealsS,
  loadingS,
  errorS,
  searchParamsS,
  queryParamsS,
} from './module/selectors'

import { setSearchParams } from './module/actions'
import { AutoSizeTableWrap } from './table-wrap'

import 'react-resizable/css/styles.css'

const { settingsSelector } = WsLkService

type RecordType = AppealTableI

const compareByColumnIndex = (a: SettingsT, b: SettingsT): number => {
  if (a.ColumnIndex > b.ColumnIndex) {
    return 1
  }
  if (a.ColumnIndex < b.ColumnIndex) {
    return -1
  }
  return 0
}

const compareByFixed = (a: SettingsT, b: SettingsT): number => {
  if (!a.IsFixed && b.IsFixed) {
    return 1
  }
  if (a.IsFixed && !b.IsFixed) {
    return -1
  }
  return 0
}

const toColumns = (settings: SettingsT[]): ColumnsType<RecordType> => {
  return settings
    .filter((el) => el.IsVisible)
    .sort(compareByColumnIndex)
    .sort(compareByFixed)
    .map((el) => {
      return {
        title: el.ShowName,
        width: el.ColumnWidth,
        dataIndex: el.FieldName,
        key: el.SortField,
        fixed: el.IsFixed,
        render: (value, row): ReactNode => {
          const isRed = value === row.status && row.expired

          return <Box style={{ color: isRed ? 'red' : 'black' }}>{value}</Box>
        },
      }
    })
}

const clearKeys = (
  object: { [key: string]: string | number | boolean } | null,
  array: string[]
): { [key: string]: string | number | boolean } => {
  if (object !== null) {
    const newObject: { [key: string]: string | number | boolean } = {}
    const filteredKeys = Object.keys(object).filter(
      (key) => !array.find((filter) => filter === key)
    )

    for (let i = 0; i < filteredKeys.length; i += 1) {
      const currentKey = filteredKeys[i]
      const currentValue = object[currentKey]
      newObject[currentKey] = currentValue
    }

    return newObject
  }
  return {}
}

export const AppealTable = (): React.ReactElement => {
  const settings = useSelector(settingsSelector)
  const columns = toColumns(settings?.settingsValue || [])
  const appeals = useSelector(appealsS)
  const searchParams = useSelector(searchParamsS)
  const { sortedField, orderBy } = useSelector(queryParamsS)
  const loading = useSelector(loadingS)
  const error = useSelector(errorS)
  const isEmpty = appeals.length === 0
  const hasParams = Object.keys(searchParams || {}).length > 0

  const queryParams = useSelector(queryParamsS)

  const dispatch = useDispatch()

  const sortChange = (
    sortedField: string | null,
    orderBy: 'ASC' | 'DESC' | null
  ): void => {
    if (sortedField && orderBy) {
      dispatch(
        setSearchParams(searchParams, { ...queryParams, sortedField, orderBy })
      )
    } else {
      const newQuerryParams = clearKeys(queryParams, ['sortedField', 'orderBy'])
      const { page, size } = queryParams
      dispatch(
        setSearchParams(searchParams, { page, size, ...newQuerryParams })
      )
    }
  }

  const { login } = useSelector(getAuthUserInfo) || {}

  const onChangeColumnWidth = (dataIndex: string, width: number): void => {
    if (settings) {
      const currentSetting = settings.settingsValue.find(
        (el) => el.FieldName === dataIndex
      )
      const settingWithNewWidth = { ...currentSetting, ColumnWidth: width }
      const filteredSettings = settings.settingsValue.filter(
        (el) => el.FieldName !== dataIndex
      )
      dispatch(
        WsLkService.actions.saveSettings({
          settingName: 'RequestTableSettings',
          settingValue: JSON.stringify({
            RequestTableSettings: [...filteredSettings, settingWithNewWidth],
          }),
          userLogin: login,
        })
      )
    }
  }

  return (
    <Flex sx={{ zIndex: 1, height: '100%' }}>
      <AutoSizeTableWrap>
        {(height, width): React.ReactElement => {
          const EmptyText = (): React.ReactElement => {
            return (
              <Flex
                sx={{
                  height: `${height}px`,
                  width: `${width}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {loading && !error && <Spinner title="Загрузка заявок" />}
                {!loading && !error && isEmpty && !hasParams && 'Нет заявок'}
                {!loading &&
                  !error &&
                  isEmpty &&
                  hasParams &&
                  'По данным параметрам заявки не найдены'}
                {!loading &&
                  error &&
                  'Произошла ошибка попробуйте перезагрузить страницу или обратитесь в службу поддержки'}
              </Flex>
            )
          }

          return (
            <Table<RecordType>
              scroll={{ x: width, y: height }}
              sortOnChange={sortChange}
              onChangeColumnWidth={onChangeColumnWidth}
              style={{
                width: '100%',
                height: '100%',
              }}
              rowKey="id"
              rowLinkCreator={(id: string | number): string =>
                `/dashboard/appeal/${id}/info`
              }
              data={appeals}
              columns={columns}
              emptyText={EmptyText}
              initialSort={{
                sortField: sortedField,
                sortMethod: orderBy,
              }}
            />
          )
        }}
      </AutoSizeTableWrap>
    </Flex>
  )
}
