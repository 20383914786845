/* eslint-disable redux-saga/no-unhandled-errors */
import { ISagaModule } from 'redux-dynamic-modules-saga'
import { createAction } from '@reduxjs/toolkit'
import { all, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { AnyAction } from 'redux'
import { TableType, getTableType } from 'packages/utils/src/dashboard-utils'
import { createNamespace } from './config'

/* Request Controller http://stu3-dev.ursip.local/ws-lk/swagger-ui.html#/ */

const controller = 'ws-lk'
const api = `/${controller}/v1`
const namespace = createNamespace(controller)

const GET_SETTINGS = 'WS_LK_SERVICE-GET_SETTINGS' as const
const GET_SETTINGS_SUCCESS = 'WS_LK_SERVICE-GET_SETTINGS_SUCCESS' as const
const SAVE_SETTINGS = 'WS_LK_SERVICE-SAVE_SETTINGS' as const
const SAVE_SETTINGS_SUCCESS = 'WS_LK_SERVICE-SAVE_SETTINGS_SUCCESS' as const
const POST_NOTE = 'WS_LK_SERVICE-POST_NOTE' as const
const USER_POST = 'WS_LK_SERVICE-USER_POST' as const

type NotePOSTT = {
  idRequest: string
  type: string
  text: string
  idAppeal: string
}

type ClientUserT = {
  id: string
  idPhysic: string | null
  idOrg: string | null
  idUser: string
  user: {
    email: string | null
    login: string | null
    nameFirst: string | null
    nameLast: string | null
    nameMiddle: string | null
    password?: string | null
    phone: string | null
    position: string | null
    department: string | null
    role: string | null
    customParam1: string | null
    customParam2: string | null
    customParam3: string | null
    customParam4: string | null
  }
}

const getQueryParams = (params: { [key: string]: string }): string => {
  const { sortedField, orderBy, ...object } = params
  const keys = Object.keys(object)
  const queryArray = []
  for (let i = 0; i < keys.length; i += 1) {
    const currentKey = keys[i]
    queryArray.push(`${currentKey}=${object[currentKey]}`)
  }
  if (sortedField && orderBy)
    return `?${queryArray.join(
      '&'
    )}&sort=${sortedField},${orderBy.toLowerCase()}`
  return `?${queryArray.join('&')}`
}

const actions = {
  requestPOST: createAction(`${namespace}_request`, (data) => ({
    payload: {
      request: {
        url: `${api}/request`,
        method: 'POST',
        body: data,
      },
    },
  })),
  requestGET: createAction(`${namespace}_get-by-id-request`, (id) => ({
    payload: {
      request: {
        url: `${api}/request/${id}`,
        method: 'GET',
      },
    },
  })),
  settingsSearchPOST: createAction(`${namespace}_settings-search`, (data) => ({
    payload: {
      request: {
        url: `${api}/settings/search`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
  clientUserGET: createAction(`${namespace}_client-user`, () => ({
    payload: {
      request: {
        url: `${api}/client-user`,
        method: 'GET',
      },
    },
  })),
  clientUserPUT: createAction(
    `${namespace}_client-user-put`,
    (userData: ClientUserT) => ({
      payload: {
        request: {
          url: `${api}/client-user`,
          method: 'PUT',
          body: JSON.stringify(userData),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      },
    })
  ),
  requestSearchPOST: createAction(
    `${namespace}request-search`,
    (data = {}, queryParams) => ({
      payload: {
        request: {
          url: `${api}/request/search${getQueryParams(queryParams)}`,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      },
    })
  ),
  getSettings: createAction(GET_SETTINGS, (payload) => ({
    payload: {
      request: {
        url: `${api}/settings/search`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
    meta: {
      settingName: payload.settingName,
    },
  })),
  saveSettings: createAction(SAVE_SETTINGS, (payload) => ({
    payload: {
      request: {
        url: `${api}/settings`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
    meta: {
      settingName: payload.settingName,
      userLogin: payload.userLogin,
    },
  })),
  notePOST: createAction(POST_NOTE, (payload: NotePOSTT) => ({
    payload: {
      request: {
        url: `${api}/note`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  })),
  userPOST: createAction(
    USER_POST,
    (payload: { email: string }, token: string) => ({
      meta: {
        runOnRequest: false,
      },
      payload: {
        request: {
          url: `${api}/user`,
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': `Bearer ${token}`,
          },
        },
      },
    })
  ),
}

type SettingsSearchRequestT = {
  id: string
  settingName: string
  settingValue: string
  userLogin: string
}

interface ActionTypes {
  type: typeof GET_SETTINGS_SUCCESS
  payload?: {
    data: {
      settingName: string
      settingValue: string
    }[]
  }
}

export type SettingsT = {
  ColumnIndex: number
  Name: string
  FieldName: string
  ShowName: string
  IsVisible: boolean
  IsFixed: boolean
  ColumnWidth: number
}

interface TableSettingsI {
  settingsValue: SettingsT[] | null
  tableType: TableType | null | undefined
}

export interface InitialStateI {
  tableSettings: TableSettingsI
}

const initialState: InitialStateI = {
  tableSettings: {
    settingsValue: null,
    tableType: null,
  },
}

const wsLkReducer = (
  state = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS: {
      if (action.payload && action.payload.data) {
        const { settingName } = (action.payload?.data || [])[0] || {}
        const { settingValue } = (action.payload?.data || [])[0] || {}
        const tableSettings =
          typeof settingValue === 'string' || null
            ? {
                settingsValue: settingName
                  ? JSON.parse(settingValue)[settingName]
                  : JSON.parse(settingValue),
                tableType: settingName ? getTableType.get(settingName) : null,
              }
            : {
                settingsValue: null,
                tableType: null,
              }
        return {
          ...state,
          tableSettings,
        }
      }
      return state
    }
    default:
      return state
  }
}

const moduleNamespace = 'ws-lk' as const

export interface DictsModuleI {
  [moduleName: string]: InitialStateI
}

const namespaceStateSelector = (state: DictsModuleI): InitialStateI =>
  state[moduleNamespace] || {}

const settingsSelector = createSelector(
  namespaceStateSelector,
  (state) => state.tableSettings
)

interface CheckSettingsT extends AnyAction {
  payload: {
    data: SettingsSearchRequestT[]
  }
}

function* updateSettings({ meta }: AnyAction): Generator {
  yield put(
    actions.getSettings({
      settingName: meta.settingName,
      userLogin: meta.userLogin,
    })
  )
}

function* checkSettings({ payload, meta }: CheckSettingsT): Generator {
  if (payload.data.length === 0) {
    yield put(actions.getSettings({ settingName: meta.settingName }))
  }
  if (payload.data.length !== 0 && payload.data[0].settingValue === '[]') {
    yield put(actions.getSettings({ settingName: meta.settingName }))
  }
}

function* sagas(): Generator {
  yield all([
    takeLatest(GET_SETTINGS_SUCCESS, checkSettings),
    takeLatest(SAVE_SETTINGS_SUCCESS, updateSettings),
  ])
}

export interface ModuleI {
  [moduleNamespace: string]: InitialStateI
}

const wsLkModule = (): ISagaModule<ModuleI> => {
  return {
    id: moduleNamespace,
    reducerMap: {
      [moduleNamespace]: (
        state: InitialStateI = initialState,
        action: ActionTypes
      ): InitialStateI => ({
        ...state,
        ...wsLkReducer(state, action),
      }),
    },
    sagas: [sagas],
  }
}
export const WsLkService = {
  namespace,
  actions,
  wsLkModule,
  sagas,
  settingsSelector,
}
