import { ISagaModule } from 'redux-dynamic-modules-saga'

import { reducer, initialState, InitialStateI } from './reducers'

import { ActionTypes } from './actions.types'

import { sagas } from './sagas'

const moduleName = 'auth'

export interface AuthI {
  [moduleName: string]: InitialStateI
}

const moduleAuth = (): ISagaModule<AuthI> => {
  return {
    id: moduleName,
    reducerMap: {
      [moduleName]: (
        state: InitialStateI = initialState,
        action: ActionTypes
      ): InitialStateI => ({
        ...state,
        ...reducer(state, action),
      }),
    },
    sagas: [sagas],
  }
}

export { moduleName, moduleAuth }
