import { toTableData } from '@lk-stu3/utils'
import produce from 'immer'
import { TStatusRecords } from '@lk-stu3/services/src/ws-stu/status-record-lk/models'
import * as types from './types'
import { ActionTypes } from './actions.types'
import {
  SearchParamsI,
  QueryParamsI,
  AppealTableI,
  PaginationParamsI,
} from './interfaces'

export interface InitialStateI {
  appeals: AppealTableI[] | null
  searchParams: null | SearchParamsI
  queryParams: QueryParamsI
  loading: boolean
  error: boolean
  pagination: PaginationParamsI
  statusRecords: TStatusRecords | null
}

export const initialState: InitialStateI = {
  appeals: null,
  searchParams: null,
  queryParams: { page: 0, size: 10 },
  loading: false,
  error: false,
  statusRecords: null,
  pagination: {
    totalElements: 0,
    totalPages: 1,
    number: 0,
    size: 10,
  },
}

export const reducer = (
  state: InitialStateI = initialState,
  action: ActionTypes
): InitialStateI => {
  switch (action.type) {
    case types.SET_APPEALS: {
      return {
        ...state,
        loading: false,
        error: false,
        appeals: toTableData(action.payload || []) || initialState.appeals,
      }
    }
    case types.SET_SEARCH_PARAMS: {
      return {
        ...state,
        appeals: initialState.appeals,
        error: false,
        loading: true,
        searchParams: action.payload?.searchParams || initialState.searchParams,
        queryParams: action.payload?.queryParams || initialState.queryParams,
      }
    }
    case types.SET_APPEALS_PAGINATION: {
      return {
        ...state,
        loading: false,
        pagination: action.payload || initialState.pagination,
      }
    }
    case types.SET_ERROR: {
      return {
        ...initialState,
        loading: false,
        error: true,
      }
    }
    case types.RESET_SEARCH_PARAMS: {
      return produce(state, (draftState) => {
        draftState.searchParams = {
          conditionStatus: state.searchParams?.conditionStatus,
        }

        draftState.error = false
        draftState.loading = true
      })
    }

    case types.SET_STATUS_RECORDS:
      return produce(state, (draftState) => {
        draftState.statusRecords = action.payload
      })
    default:
      return state
  }
}
