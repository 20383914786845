import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactDom from 'react-dom'
import styled from 'styled-components'

import { Flex, Box, Scrollbars } from '@design-system/atoms'

import { PDFJS } from 'pdfjs-dist/web/pdf_viewer'

import { getGlobalEventBus } from './PDFJS/ui_utils'

import { PDFRenderingQueue } from './PDFJS/pdf_rendering_queue'
import { PDFThumbnailViewer } from './PDFJS/pdf_thumbnail_viewer'

import 'pdfjs-dist/web/pdf_viewer.css'
import './style/pdf_viewer.css'

import './style/PdfAnnotator.css'

import getBoundingRect from './lib/get-bounding-rect'
import getClientRects from './lib/get-client-rects'
import getAreaAsPng from './lib/get-area-as-png'

import { getPageFromRange, getPageFromElement, findOrCreateContainerLayer } from './lib/pdfjs-dom'

import TipContainer from './TipContainer'
import MouseSelection from './MouseSelection'

import TextLayerBuilder_bindMouse from './PDFJS/TextLayerBuilder_bindMouse'

import { scaledToViewport, viewportToScaled } from './lib/coordinates'

const { PDFViewer, PDFLinkService, TextLayerBuilder } = PDFJS

const StyleWrapper = styled(Box)`
  width: 100%;
  height: 100%;

  .PdfAnnotator {
    padding-top: 16px;
    z-index: 4;
  }
  .PdfAnnotator::-webkit-scrollbar {
    /* This is the magic bit for WebKit */
    width: 6px;
    height: 6px;
  }

  .PdfAnnotator::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
  }
  .PdfAnnotator::-webkit-scrollbar-corner {
    background: transparent;
  }

  .thumbnail {
    margin: 0 0px 16px 0px;
  }

  .thumbnailImage {
    border: 1px solid transparent;
    z-index: 99;
    background-color: white;
    background-clip: content-box;
  }

  .thumbnail.selected > .thumbnailSelectionRing > .thumbnailImage {
    border: 1px solid #0091ea;
  }
`

TextLayerBuilder.prototype._bindMouse = TextLayerBuilder_bindMouse

const CLICK_TIMEOUT = 300
const EMPTY_ID = 'empty-id'

let clickTimeoutId = 0

const parseIdFromHash = () => window.location.hash.slice('#highlight-'.length)

function PdfAnnotator({
  highlightTransform,
  highlights,
  pdfDocument,
  onScrollChange,
  onSelectionFinished,
  enableAreaSelection,
  onReset,
  getHighlightById,
  noThumbnail,
}) {
  const [ghostHighlight, setGhostHighlight] = useState(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [range, setRange] = useState(null)
  const [scrolledToHighlightId, setScrolledToHighlightId] = useState(EMPTY_ID)
  const [isAreaSelectionInProgress, setIsAreaSelectionInProgress] = useState(null)
  const [isMouseDown, setIsMouseDown] = useState(null)
  const [tip, setTip] = useState(null)

  const viewer = useRef()
  const pdfThumbnailViewer = useRef()

  const containerNode = useRef()
  const viewerNode = useRef()
  const thumbnailView = useRef()

  const pdfLinkService = useRef()

  const pdfRenderingQueue = useRef()

  const sidebarContent = useRef()

  const findOrCreateHighlightLayer = page => {
    if (viewer.current) {
      const textLayer = viewer.current.getPageView(page - 1) ? viewer.current.getPageView(page - 1).textLayer : null

      if (!textLayer) {
        return null
      }

      return findOrCreateContainerLayer(textLayer.textLayerDiv, 'PdfAnnotator__highlight-layer')
    }
  }

  const groupHighlightsByPage = highlights => {
    return [...highlights, ghostHighlight].filter(Boolean).reduce((res, highlight) => {
      const { page } = highlight.meta
      res[page] = res[page] || []
      res[page].push(highlight)
      return res
    }, {})
  }

  const showTip = (highlight, content) => {
    const highlightInProgress = !isCollapsed || ghostHighlight

    if (highlightInProgress || isMouseDown || isAreaSelectionInProgress) {
      return
    }

    renderTipAtPosition(highlight.position, content)
  }

  const scaledPositionToViewport = ({ pageNumber, boundingRect, rects, usePdfCoordinates }) => {
    const { viewport } = viewer.current.getPageView(pageNumber - 1)

    return {
      boundingRect: scaledToViewport(boundingRect, viewport, usePdfCoordinates),
      rects: (rects || []).map(rect => scaledToViewport(rect, viewport, usePdfCoordinates)),
      pageNumber,
    }
  }

  const viewportPositionToScaled = ({ pageNumber, boundingRect, rects }) => {
    const { viewport } = viewer.current.getPageView(pageNumber - 1)

    return {
      boundingRect: viewportToScaled(boundingRect, viewport),
      rects: (rects || []).map(rect => viewportToScaled(rect, viewport)),
      pageNumber,
    }
  }

  const screenshot = (position, pageNumber) => {
    const { canvas } = viewer.current.getPageView(pageNumber - 1)

    return getAreaAsPng(canvas, position)
  }

  const renderHighlights = useCallback(() => {
    const highlightsByPage = groupHighlightsByPage(highlights)

    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
      const highlightLayer = findOrCreateHighlightLayer(pageNumber)

      if (highlightLayer) {
        ReactDom.render(
          <div>
            {(highlightsByPage[String(pageNumber)] || []).map((highlight, index) => {
              const { position, ...rest } = highlight

              const viewportHighlight = {
                position: scaledPositionToViewport(position),
                ...rest,
              }

              if (tip && tip.highlight._id === String(highlight._id)) {
                showTip(tip.highlight, tip.callback(viewportHighlight))
              }

              const isScrolledTo = Boolean(scrolledToHighlightId === highlight.id)

              return highlightTransform(
                viewportHighlight,
                index,
                (highlight, callback) => {
                  setTip({ highlight, callback })

                  showTip(highlight, callback(highlight))
                },
                hideTipAndSelection,
                rect => {
                  const { viewport } = viewer.current.getPageView(pageNumber - 1)

                  return viewportToScaled(rect, viewport)
                },
                boundingRect => screenshot(boundingRect, pageNumber),
                isScrolledTo,
              )
            })}
          </div>,
          highlightLayer,
        )
      }
    }
  })

  const hideTipAndSelection = () => {
    const tipNode = findOrCreateContainerLayer(viewer.current.viewer, 'PdfAnnotator__tip-layer')

    ReactDom.unmountComponentAtNode(tipNode)

    setGhostHighlight(null)
    setTip(null)
  }

  const renderTipAtPosition = (position, inner) => {
    const { boundingRect, page: pageNumber } = position

    const page = {
      node: viewer.current.getPageView(pageNumber - 1).div,
    }

    const pageBoundingRect = page.node.getBoundingClientRect()

    const tipNode = findOrCreateContainerLayer(viewer.current.viewer, 'PdfAnnotator__tip-layer')

    ReactDom.render(
      <TipContainer
        scrollTop={viewer.current.container.scrollTop}
        pageBoundingRect={pageBoundingRect}
        style={{
          left: page.node.offsetLeft + boundingRect.left + boundingRect.width / 2,
          top: boundingRect.top + page.node.offsetTop,
          bottom: boundingRect.top + page.node.offsetTop + boundingRect.height,
        }}
      >
        {inner}
      </TipContainer>,
      tipNode,
    )
  }

  const onTextLayerRendered = () => {
    renderHighlights()
  }

  const scrollTo = highlight => {
    const { page: pageNumber, boundingRect, usePdfCoordinates } = highlight.meta.position

    viewer.current.container.removeEventListener('scroll', onScroll)

    const page = viewer.current.getPageView(pageNumber - 1)

    if (page) {
      const pageViewport = viewer.current.getPageView(pageNumber - 1).viewport

      const scrollMargin = 10

      viewer.current.scrollPageIntoView({
        pageNumber,
        destArray: [
          null,
          { name: 'XYZ' },
          ...pageViewport.convertToPdfPoint(
            0,
            scaledToViewport(boundingRect, pageViewport, usePdfCoordinates).top - scrollMargin,
          ),
          0,
        ],
      })

      setScrolledToHighlightId(highlight._id)

      // wait for scrolling to finish
      setTimeout(() => {
        viewer.current.container.addEventListener('scroll', onScroll)
      }, 100)
    }
  }

  const scrollToHighlightFromHash = useCallback(() => {
    const highlight = getHighlightById(parseIdFromHash())

    if (highlight) {
      scrollTo(highlight)
    }
  }, [getHighlightById, scrollTo])

  const onSelectionChange = () => {
    const selection = window.getSelection()

    if (selection.isCollapsed) {
      setIsCollapsed(true)
      return
    }

    const newRange = selection.getRangeAt(0)

    if (!newRange) {
      return
    }

    setRange(newRange)
    setIsCollapsed(false)
  }

  const onScroll = useCallback(() => {
    onScrollChange()

    setScrolledToHighlightId(EMPTY_ID)

    viewer.current.container.removeEventListener('scroll', onScroll)
  })

  const onMouseDown = event => {
    if (!(event.target instanceof HTMLElement)) {
      return
    }

    if (event.target.closest('.PdfAnnotator__tip-container')) {
      return
    }

    hideTipAndSelection()

    clickTimeoutId = setTimeout(() => setIsMouseDown(true), CLICK_TIMEOUT)
  }

  const handleKeyDown = event => {
    if (event.code === 'Escape') {
      hideTipAndSelection()
    }
  }

  const onMouseUp = () => {
    clearTimeout(clickTimeoutId)
    setIsMouseDown(false)

    if (!range || isCollapsed) {
      return
    }

    const page = getPageFromRange(range)

    if (!page) {
      return
    }

    const rects = getClientRects(range, page.node)

    if (rects.length === 0) {
      return
    }

    const boundingRect = getBoundingRect(rects)

    const viewportPosition = { boundingRect, rects, pageNumber: page.number }

    const content = {
      text: range.toString(),
    }

    const scaledPosition = viewportPositionToScaled(viewportPosition)

    onSelectionFinished(scaledPosition, content)
  }

  const toggleTextSelection = flag => {
    viewer.current.viewer.classList.toggle('PdfAnnotator--disable-selection', flag)
  }

  useEffect(() => {
    window.addEventListener('hashchange', scrollToHighlightFromHash, false)
    return () => {
      window.removeEventListener('hashchange', scrollToHighlightFromHash)
    }
  }, [scrollToHighlightFromHash])

  /*   useEffect(() => {
    renderHighlights()
  }, [highlightTransform, highlights, ghostHighlight, tip, scrolledToHighlightId, renderHighlights]) */

  useEffect(() => {
    const eventBus = getGlobalEventBus('eventBusDispatchToDOM')

    pdfLinkService.current = new PDFLinkService({ eventBus })

    pdfRenderingQueue.current = new PDFRenderingQueue()

    viewer.current = new PDFViewer({
      eventBus,
      container: containerNode.current,
      // viewer: viewerNode.current,
      enhanceTextSelection: true,
      removePageBorders: true,
      linkService: pdfLinkService.current,
      enableWebGL: true,
      //	renderingQueue: pdfRenderingQueue.current,
    })

    pdfRenderingQueue.current.setViewer(viewer.current)
    pdfLinkService.current.setViewer(viewer.current)

    pdfRenderingQueue.current.isThumbnailViewEnabled = true
    pdfRenderingQueue.current.renderHighestPriority()

    pdfThumbnailViewer.current = new PDFThumbnailViewer({
      container: thumbnailView.current,
      linkService: pdfLinkService.current,
      renderingQueue: pdfRenderingQueue.current,
    })

    pdfRenderingQueue.current.setThumbnailViewer(pdfThumbnailViewer.current)

    viewer.current.setDocument(pdfDocument)
    pdfLinkService.current.setDocument(pdfDocument)
    pdfThumbnailViewer.current.setDocument(pdfDocument)
    // window.PdfViewer = this

    viewer.current.forceRendering()

    document.addEventListener('selectionchange', onSelectionChange)
    //    document.addEventListener('keydown', handleKeyDown)

    function webViewerPageRendered(evt) {
      const { pageNumber } = evt
      const pageIndex = pageNumber - 1
      const pageView = viewer.current.getPageView(pageIndex)

      // Prevent errors in the edge-case where the PDF document is removed *before*
      // the 'pagerendered' event handler is invoked.
      if (!pageView) {
        return
      }

      const thumbnailView = pdfThumbnailViewer.current.getThumbnail(pageIndex)
      thumbnailView.setImage(pageView)

      // Use the rendered page to set the corresponding thumbnail image.
    }

    function webViewerPageChanging(evt) {
      const page = evt.pageNumber
      pdfThumbnailViewer.current.scrollThumbnailIntoView(page)
    }

    eventBus.on('pagechanging', webViewerPageChanging)
    eventBus.on('pagerendered', webViewerPageRendered)
    setTimeout(() => {
      viewer.current.container.addEventListener('scroll', onScroll)
      eventBus.dispatch('documentloaded')
      pdfThumbnailViewer.current.forceRendering()
    }, 100)

    setTimeout(() => {
      pdfThumbnailViewer.current.forceRendering()
    }, 1000)

    setTimeout(() => {
      pdfThumbnailViewer.current.forceRendering()
    }, 2000)

    containerNode.current && containerNode.current.addEventListener('textlayerrendered', onTextLayerRendered)
    //    containerNode.current && containerNode.current.addEventListener('mousedown', onMouseDown)
    return () => {
      document.removeEventListener('selectionchange', onSelectionChange)
      //      document.removeEventListener('keydown', handleKeyDown)
      eventBus.off('pagechanging', webViewerPageChanging)
      eventBus.off('pagerendered', webViewerPageRendered)

      containerNode.current && containerNode.current.removeEventListener('textlayerrendered', onTextLayerRendered)
      //      containerNode.current && containerNode.current.removeEventListener('mousedown', onMouseDown)
    }
  }, [handleKeyDown, onMouseDown, onScroll, onTextLayerRendered, pdfDocument])

  return (
    <StyleWrapper>
      <Flex width="100%" height="100%">
        <Box height="100%" width="100%" flex="1">
          <div ref={containerNode} className="PdfAnnotator">
            <div className="pdfViewer" ref={viewerNode} />
            {typeof enableAreaSelection === 'function' ? (
              <MouseSelection
                onDragStart={() => toggleTextSelection(true)}
                onDragEnd={() => toggleTextSelection(false)}
                onReset={onReset}
                onChange={isVisible => setIsAreaSelectionInProgress(isVisible)}
                shouldStart={event => event.target instanceof HTMLElement && Boolean(event.target.closest('.page'))}
                onSelection={(startTarget, boundingRect) => {
                  const page = getPageFromElement(startTarget)

                  if (!page) {
                    return
                  }

                  const pageBoundingRect = {
                    ...boundingRect,
                    top: boundingRect.top - page.node.offsetTop,
                    left: boundingRect.left - page.node.offsetLeft,
                  }

                  const viewportPosition = {
                    boundingRect: pageBoundingRect,
                    rects: [],
                    pageNumber: page.number,
                  }

                  const scaledPosition = viewportPositionToScaled(viewportPosition)

                  const image = screenshot(pageBoundingRect, page.number)

                  onSelectionFinished(scaledPosition, { image })
                }}
              />
            ) : null}
          </div>
        </Box>

        <Box width={66} mx={14} style={{ overflow: 'hidden', zIndex: 200, display: noThumbnail ? 'none' : 'auto' }}>
          <Scrollbars autoHeight autoHide style={{ height: '100%' }} autoHeightMin="100%" autoHeightMax="100%">
            <div
              ref={sidebarContent}
              style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                paddingTop: '16px',
              }}
            >
              <div ref={thumbnailView} style={{ overflow: 'hidden', width: '100%', height: '100%' }} />
            </div>
          </Scrollbars>
        </Box>
      </Flex>
    </StyleWrapper>
  )
}

export default PdfAnnotator
