import React from 'react'
import { Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Background } from '@lk-stu3/components'
import { useSelector } from 'react-redux'
import { Login } from './login'
import { authSelector, routerStateSelector } from './module/selectors'
import { Registration } from './registration'
import { RecoveryPassword } from './recovery-password'
import './components/fade.css'

const registration = 'registration'
const login = 'login'
const recoveryPassword = 'recovery_password'

export const Signin = (): React.ReactElement => {
  const isAuth = useSelector(authSelector)
  const { path } = useSelector(routerStateSelector)

  const [tab, changeTab] = React.useState(login)

  const toRegistration = (): void => changeTab(registration)
  const toLogin = (): void => changeTab(login)
  const toPasswordRecovery = (): void => changeTab(recoveryPassword)

  if (isAuth) return <Redirect to={path || '/dashboard/appeals'} />

  return (
    <Background>
      <TransitionGroup>
        <CSSTransition
          key={tab}
          timeout={500}
          classNames={tab === 'login' ? 'fade_left' : 'fade_right'}
        >
          <section className="route-section">
            {tab === login && (
              <Login
                toRegistration={toRegistration}
                toPasswordRecovery={toPasswordRecovery}
              />
            )}
            {tab === registration && <Registration toLogin={toLogin} />}
            {tab === recoveryPassword && <RecoveryPassword toLogin={toLogin} />}
          </section>
        </CSSTransition>
      </TransitionGroup>
    </Background>
  )
}
