import React from 'react'
import { useSelector } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { ContextMenu } from '@lk-stu3/components'
import { ContextMenuChildrenPropT } from '@lk-stu3/components/types'
import { Flex, Grid, Button, Box, Badge } from 'theme-ui'
import { moduleNotifications } from './module'
import { unreadMessageCountS } from './module/selectors'
import { ContextMenuWrapper } from './components'
import { NotificationList } from './notification-list'

interface ChildrenT extends Omit<ContextMenuChildrenPropT, ''> {
  countNotifications: number
}

type NotificationT = {
  children: (props: ChildrenT) => React.ReactNode
  login: string
  openNotificationTable?: () => void
}

const WIDTH = 500

export const Notification = ({
  children,
  login,
  openNotificationTable,
}: NotificationT): React.ReactElement => {
  const [{ top = 0, left = 0 }, setRect] = React.useState<{
    top: number
    left: number
  }>({
    top: 0,
    left: 0,
  })

  const Menu = ({
    close,
  }: {
    close: () => void
    isOpen: boolean
  }): React.ReactElement => {
    return (
      <ContextMenuWrapper width={WIDTH} style={{ top, left: left - WIDTH }}>
        <Grid sx={{ gap: '0px' }}>
          <NotificationList />
          <Button
            variant="tranparent"
            onClick={(): void => {
              if (openNotificationTable) openNotificationTable()
              close()
            }}
            sx={{
              borderTop: '1px solid',
              borderTopColor: 'border',
              fontSize: '12px',
            }}
          >
            Посмотреть все уведомления
          </Button>
        </Grid>
      </ContextMenuWrapper>
    )
  }

  const countNotifications = useSelector(unreadMessageCountS)

  return (
    <DynamicModuleLoader modules={[moduleNotifications(login)]}>
      <ContextMenu menu={Menu}>
        {({ openPortal, isOpen }): React.ReactNode => {
          const onClick = (event: React.SyntheticEvent): void => {
            event.stopPropagation()
            const {
              bottom = 0,
              right = 0,
            } = event.currentTarget.getBoundingClientRect()
            setRect({ top: bottom, left: right })
            openPortal(event)
          }

          return (
            <Flex
              sx={{
                animation:
                  countNotifications > 0 ? 'pulse 3s infinite' : 'none',
                borderRadius: '50%',
                '@keyframes pulse': {
                  '0%': {
                    boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)',
                  },
                  '70%': {
                    boxShadow: '0 0 0 10px rgba(255, 82, 82, 0)',
                  },
                  '100%': {
                    boxShadow: '0 0 0 0 rgba(255, 82, 82, 0)',
                  },
                },
              }}
            >
              {children({ countNotifications, openPortal: onClick, isOpen })}
              {countNotifications > 0 && (
                <Box
                  sx={{
                    alignItems: 'center',
                    zIndex: 1,
                    height: 0,
                    width: 0,
                  }}
                >
                  <Badge
                    sx={{
                      backgroundColor: 'danger',
                      borderRadius: '35%',
                      marginLeft: '-5px',
                    }}
                  >
                    {countNotifications}
                  </Badge>
                </Box>
              )}
            </Flex>
          )
        }}
      </ContextMenu>
    </DynamicModuleLoader>
  )
}
